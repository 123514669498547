import React, { useEffect, useState } from "react";

import styles from "./dashboardLayout.module.css";
import Navbar from "./components/Navbar/Navbar";
import Header from "./components/Header/Header";
import StatisticsRow from "./components/StatisticsRow/StatisticsRow";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useFaculty } from "../../../query/admin/useFaculty";
import { useDepartment } from "../../../query/admin/useDepartment";
import { useCourse } from "../../../query/admin/useCourse";
import { useApplication } from "../../../query/admin/applicationRequest";
import { useStudent } from "../../../query/admin/useStudent";
import { useTutors } from "../../../query/admin/useTutors";

export default function DashboardLayout({
  children,
  currentTab,
  setCurrentTab,
}) {
  /** State management */
  const user = useSelector((state) => state.user.user);
  const location = useLocation();

  const { getFaculty } = useFaculty();
  const { getDepartments } = useDepartment();
  const { getCourses } = useCourse();
  const { getApplicants } = useApplication();
  const { getTutorsRecord } = useTutors();
  const { getStudents } = useStudent();

  async function getAllData(userRole) {
    if (userRole === "superadmin") {
      getFaculty();
      getDepartments();
      getCourses();
      getApplicants();
      getStudents();
      getTutorsRecord();
    } else if (userRole === "teacher") {
    } else if (userRole === "student" || userRole === "superadmin") {
      // getAllJobs();
    }
  }

  useEffect(() => {
    if (user.role !== "") {
      getAllData(user.role);
    }
  }, []);

  return (
    <div>
      <Navbar currentTab={currentTab} setCurrentTab={setCurrentTab} />

      <div className={`${styles["sub-container"]}`}>
        <Header />
        {user?.role === "superadmin" &&
          !location.pathname.startsWith("/library") &&
          !location.pathname.startsWith("/chats") && <StatisticsRow />}
        <div style={{ height: "fit-content", minHeight: "70vh" }}>
          {children}
        </div>
      </div>
    </div>
  );
}
