import React, { useRef, useState } from "react";
import styles from "./uploadFileComponent.module.css";
import { S3 } from "aws-sdk";
import PDFCard from "../../pdfCard/PDFCard";
import TextInput from "../TextInput/TextInput";
import Button from "../../Button/customButton/Button";

export default function UploadFileComponent({
  title,
  type,
  file,
  setFile,
  identifier,
  multiple,
}) {
  const [fileURL, setFileURL] = useState(null);
  const [progress, setProgress] = useState(null);
  const [loading, setLoading] = useState(null);
  const [upload, setUpload] = useState(null);
  const [documentTitle, setDocumentTitle] = useState("");
  const [components, setComponents] = useState([]);

  const s3 = new S3({
    accessKeyId: process.env.AWS_ACCESS_TOKEN_KEY,
    secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY,
  });

  function handleFile(event) {
    const selectedFile = event.target.files[0];
    if (type === "img") {
      setFileURL(URL.createObjectURL(event.target.files[0]));
    }
    setFile({
      fileName: selectedFile.name,
      file: selectedFile,
      preview: URL.createObjectURL(event.target.files[0]),
    });
  }

  function multiFileAdd(event) {
    if (documentTitle === "") {
      alert("Please add a document title first before uploading the file.");
    } else {
      const selectedFile = event.target.files[0];
      const file = {
        fileName: documentTitle,
        file: selectedFile,
        preview: URL.createObjectURL(event.target.files[0]),
      };
      setComponents((prevArr) => [...prevArr, file]);
      setFile((prevArr) => [...prevArr, file]);
      setDocumentTitle("");
    }
  }

  function handleRemoveFile(fileName) {
    if (multiple) {
      setFile((prevArr) =>
        prevArr.filter((file) => file.fileName !== fileName)
      );
      setComponents((prevArr) =>
        prevArr.filter((file) => file.fileName !== fileName)
      );
    } else {
      setFile({ fileName: "", file: "", preview: "" });
    }
  }
  return (
    <>
      {multiple ? (
        <>
          {components.length < 5 && (
            <>
              <TextInput
                label={"Document Title"}
                required={false}
                value={documentTitle}
                setValue={setDocumentTitle}
                placeholder={"Write here..."}
              />
              <div
                className={`${styles["upload-container"]}`}
                style={{ marginBlock: 24 }}
              >
                <div className={`${styles["upload-container__main"]}`}>
                  <label for={identifier}>
                    Click to Upload Image or drag 'n' drop
                  </label>
                  <input
                    type="file"
                    name={identifier}
                    id={identifier}
                    accept={".pdf"}
                    onChange={multiFileAdd}
                  />
                </div>
              </div>
              <div>{/* <Button text={"Add"} onClick={()=>}/> */}</div>
            </>
          )}

          <div className={`${styles["multiple-file-container"]}`}>
            {file.length >= 1 &&
              file.map((component, index) => {
                return (
                  <PDFCard
                    key={index}
                    name={component.fileName}
                    handleClick={() => handleRemoveFile(component.fileName)}
                  />
                );
              })}
          </div>
        </>
      ) : (
        <div className={`${styles["upload-container"]}`}>
          {title && <h5>Upload Identity Card (Scanned PDF format)</h5>}
          {file.fileName === "" ? (
            <div className={`${styles["upload-container__main"]}`}>
              <label for={identifier}>
                Click to Upload Image or drag 'n' drop
              </label>
              <input
                type="file"
                name={identifier}
                id={identifier}
                accept={type === "img" ? "image/*" : ".pdf"}
                onChange={handleFile}
              />
            </div>
          ) : (
            <>
              {type === "img" ? (
                <div className={`${styles["img-container"]}`}>
                  <p
                    className={`${styles.close}`}
                    onClick={() => {
                      handleRemoveFile(file.fileName);
                    }}
                  >
                    &times;
                  </p>
                  <img src={file.preview} alt="profile picture" />
                  {/* <p>{fileURL}</p> */}
                </div>
              ) : (
                <PDFCard
                  name={file.fileName}
                  handleClick={() => handleRemoveFile(file.fileName)}
                />
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}
