import React from "react";
import styles from "./groupCard.module.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const RANDOMCOLORS = [
  "#2218A7",
  "#5248D5",
  "#951B95",
  "#BF39BF",
  "#363736",
  "#000000",
];

export default function GroupCard({ item }) {
  /** State management */
  const index = Math.floor(Math.random() * RANDOMCOLORS.length);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);

  return (
    <div
      className={`${styles["main-group-container"]}`}
      onClick={() =>
        navigate(
          user.role === "teacher"
            ? `/tutor/manage-groups/${item.group.id}`
            : `/dashboard/my-groups/${item.group.id}`
        )
      }
    >
      <div
        className={`${styles["sub-container"]}`}
        style={{ backgroundColor: RANDOMCOLORS[index] }}
      >
        <h4>{item?.group?.name}</h4>
        <h2>{item?.course?.course_title}</h2>
      </div>

      <div className={`${styles["group-card-details"]}`}>
        <div></div>
        <p>{item?.course?.course_code}</p>
      </div>
    </div>
  );
}
