import React from "react";

export default function EmailIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      {...props}
    >
      <rect x="3" y="6" width="18" height="12" rx="2" ry="2"></rect>
      <polyline points="3 6 12 13 21 6"></polyline>
    </svg>
  );
}
