import { useDispatch, useSelector } from "react-redux";
import client from "../../utils/api/axios";
import { assessmentSlice } from "../../redux/features/assessmentSlice";
import { useIonToast } from "@ionic/react";

export function useAssessments() {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.user.token);
    const [toast] = useIonToast();

    async function handleGetAssessments() {
        client
            .get(`/api/list-assessments/`, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            })
            .then((response) => {
                const data = response.data;
                if (data) {
                    dispatch(
                        assessmentSlice.actions.setAssessments({
                            data
                        })
                    );
                }
                return response;
            })
            .catch((error) => {
                return console.error(`Error: ${error.message}`);
            });
    }

    async function handleCreateAssessments(data) {
        client
            .post(`/api/create_assessment/`, data, {
                headers: {
                    Authorization: `Token ${token}`,
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                const data = response.data;
                if (data) {
                    dispatch(
                        assessmentSlice.actions.newAssessment({
                            data,
                        })
                    );
                }
                return response;
            })
            .catch((error) => {
                return console.error(`Error: ${error.message}`);
            });
    }

    async function handleGetAssessmentDetails(id) {
        const response = await client
            .get(`/api/assessments/${id}/`, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return console.error(`Error: ${error.message}`);
            });
        return response?.data
    }

    async function handleCreateAssessmentsQuestion(data) {
        const response = await client
            .post(`/api/assessments/${data.id}/add_mcq_question/`, data, {
                headers: {
                    Authorization: `Token ${token}`,
                    "Content-Type": "application/json"
                },
            })
            .then((response) => {
                toast({
                    message: `Success`,
                    color: "success",
                    buttons: ["Ok"],
                });
                return response;
            })
            .catch((error) => {
                return console.error(`Error: ${error.message}`);
            });
        return response.data;
    }

    async function getCAAssessmentResult() {
        const response = await client
            .get(`/api/list-assessments/ca/`, {
                headers: {
                    Authorization: `Token ${token}`,
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                const data = response.data;

                return data;
            })
            .catch((error) => {
                console.error(`Error: ${error.message}`);
                return null;
            });
        return response;
    }

    async function getExamAssessmentResult() {
        const response = await client
            .get(`/api/list-assessments/exam/`, {
                headers: {
                    Authorization: `Token ${token}`,
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                const data = response.data;

                return data;
            })
            .catch((error) => {
                console.error(`Error: ${error.message}`);
                return null;
            });
        return response;
    }
    async function handleUpdateAssessmentsQuestion(data) {
        const response = await client
            .put(`/api/assessments/${data.id}/${data.qID}/`, data, {
                headers: {
                    Authorization: `Token ${token}`,
                    "Content-Type": "application/json"
                },
            })
            .then((response) => {
                toast({
                    message: `Question Updated`,
                    color: "success",
                    buttons: ["Ok"],
                });
                return response;
            })
            .catch((error) => {
                return console.error(`Error: ${error.message}`);
            });
        return response
    }

    async function handleCreateTextQuestion(data) {
        const response = await client
            .post(`/api/assessments/${data.id}/add_text_question/`, data, {
                headers: {
                    Authorization: `Token ${token}`,
                    "Content-Type": "application/json"
                },
            })
            .then((response) => {
                toast({
                    message: `Success`,
                    color: "success",
                    buttons: ["Ok"],
                });
                return response;
            })
            .catch((error) => {
                return console.error(`Error: ${error.message}`);
            });
        return response.data
    }

    async function handleUpdateTextQuestion(data) {
        const response = await client
            .put(`/api/assessments/${data.id}/${data.qID}/`, data, {
                headers: {
                    Authorization: `Token ${token}`,
                    "Content-Type": "application/json"
                },
            })
            .then((response) => {
                toast({
                    message: `Question Updated`,
                    color: "success",
                    buttons: ["Ok"],
                });
                return response;
            })
            .catch((error) => {
                return console.error(`Error: ${error.message}`);
            });
        return response
    }

    async function handleListSubmissions(id) {
        const response = await client
            .get(`/api/assessment-submissions/${id}/`, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return console.error(`Error: ${error.message}`);
            });
        return response.data
    }

    async function handleGradeAssessment(data) {
        const response = await client
            .post(`/api/assessments/${data.assessment}/record-score/${data.student}/`, data, {
                headers: {
                    Authorization: `Token ${token}`,
                    "Content-Type": "application/json"
                },
            })
            .then((response) => {
                toast({
                    message: `Success`,
                    color: "success",
                    buttons: ["Ok"],
                });
                return response;
            })
            .catch((error) => {
                return console.error(`Error: ${error.message}`);
            });
        return response
    }

    async function handleGetAssessmentsGrades(id) {
        const response = await client
            .get(`/api/assessment-results/${id}/`, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return console.error(`Error: ${error.message}`);
            });
        return response?.data
    }

    async function handleGetStudentGrades(data) {
        const response = await client
            .get(`/api/assessments/${data.assessment}/grade/${data.student}/`, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return console.error(`Error: ${error.message}`);
            });
        return response?.data
    }

    return {
        getCAAssessmentResult,
        getExamAssessmentResult,
        handleGetAssessments,
        handleCreateAssessments,
        handleGetAssessmentDetails,
        handleCreateTextQuestion,
        handleUpdateTextQuestion,
        handleCreateAssessmentsQuestion,
        handleListSubmissions,
        handleUpdateAssessmentsQuestion,
        handleGradeAssessment,
        handleGetAssessmentsGrades,
        handleGetStudentGrades
    };
}
