import React from "react";
import styles from "./whyYouNeedUs.module.css";
import home_style from "../../home.module.css";

export default function WhyYouNeedUs() {
  return (
    <div className={`${styles.our_partners_container}`}>
      <div className={styles.container}>
        <div className={`${styles.our_partners_header}`}>
          <h2 className={`${home_style.section_heading_title}`}>
            ThIS IS WHY YOU NEED US
          </h2>
          <p className={`${home_style.section_heading_desc}`}>
            Empowering Your Success, Every Step of the Way.
          </p>
        </div>
        <p className={`${styles.extensive_desc}`}>
          At the heart of our mission is a commitment to empowering your success.
          We understand the challenges you face and the aspirations you hold,
          which is why we're here to offer unparalleled support, guidance, and
          expertise. Whether you're a budding entrepreneur, a seasoned
          professional, or an ambitious dreamer, we're your trusted partner on the
          journey towards achieving your goals. With our unwavering dedication and
          personalized approach, we provide the tools, resources, and
          encouragement you need to thrive in an ever-evolving world. Choose us,
          and together, we'll unlock your full potential and pave the way for a
          brighter future.
        </p>
      </div>
    </div>
  );
}
