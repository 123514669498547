import React from "react";
import styles from "./ourCoreProgram.module.css";

export default function OurCoreProgram() {
  return (
    <div className={styles.container}>
      <div className={`${styles.our_core_program_main_container}`}>
        <div className={`${styles.our_core_program_container}`}>
          <div className={`${styles.sub_core_container_desc}`}>
            <h1>OUR CORE PROGRAMS</h1>
            <p>At our core, we strive to empower future innovators with cutting-edge programs
              designed to nurture creativity, critical thinking, and problem-solving skills.
              From introductory courses to advanced workshops, we offer a diverse range of programs tailored to inspire and prepare
              the next generation of leaders in technology, entrepreneurship, and beyond.</p>
          </div>

          <div className={`${styles.course_container}`}>
            {Array(4)
              .fill(0)
              .map((_v, index) => {
                return <ShortProgramDesc key={index} />;
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

function ShortProgramDesc() {
  return (
    <div className={`${styles.course_card_container}`}>
      <div className={`${styles.course_card_image}`}>
        <img src="/blog_image0.png" />
      </div>
      <h5>Introduction to ReactJS</h5>
      <p>
        This course is an Introduction to react.js. Join thousand of students
      </p>
    </div>
  );
}
