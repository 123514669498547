import React, { useEffect, useState } from "react";
import Loader from "../../../../../components/Loader/Loader";
import Button from "../../../../../components/Button/customButton/Button";
import Search from "../../../../../components/Search/Search";
import styles from "./chat.module.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ChatMessagesComponent from "./components/ChatMessagesComponent";
import { FaPlus } from "react-icons/fa6";

export default function Chats() {
  /** State management */
  const userInfo = useSelector((state) => state.user.user);
  const [viewNewChatModal, setViewNewChatModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo.role !== "superadmin" && userInfo.role !== "teacher") {
      navigate("/");
    }
  }, []);

  return (
    <div className={styles.chatWrapper}>
      {isLoading && <Loader />}
      <div className={`${styles["header-container"]}`}>
        <h2>My Chats</h2>

        <div className={`${styles["action-container"]}`}>
          <Button
            text={"New Chat"}
            onClick={() => setViewNewChatModal(true)}
            width={"fit-content"}
            paddingBlock={16}
            rightIcon={<FaPlus />}
          />

          <div style={{ width: "100%" }}>
            <Search
              placeholder={"Find Chat"}
              value={searchValue}
              setValue={setSearchValue}
            />
          </div>
        </div>
      </div>

      <div className={`${styles["chat-main-container"]}`}>
        {/* Discussion people */}
        <div className={`${styles["discussion-container"]}`}>
          {Array(18)
            .fill(0)
            .map((_v, index) => {
              return <ProfileMessageCard key={index} />;
            })}
        </div>

        {/* Chat messages */}
        <div className={`${styles["chatMessage"]}`}>
          <ChatMessagesComponent />
        </div>
      </div>
    </div>
  );
}

function ProfileMessageCard({ active }) {
  /** State management */
  const text = "lorem ipsum dolor lorem ipsum";

  return (
    <article
      className={`${styles["profile-message-main-container"]} ${active && styles["active-chat"]
        }`}
    >
      <div
        className={`${styles["profile-message-sub-container"]} ${active && styles["active-chat"]
          }`}
      >
        <div
          className={`${styles["profile-message-card-image-container"]} ${active && styles["active-chat"]
            }`}
        >
          <img
            src="https://avatars.githubusercontent.com/u/72765949?v=4?s=400"
            alt=""
          />
        </div>
        <div
          className={`${styles["chat-details-container"]} ${active && styles["active-chat"]
            }`}
        >
          <h5>Lara Mueller</h5>
          <div
            className={`${styles["message-overview-container"]} ${active && styles["active-chat"]
              }`}
          >
            <p>{text.length > 15 ? text.substring(0, 20) + "..." : text}</p>

            <div
              className={`${styles["count"]} ${active && styles["active-chat"]
                }`}
            >
              <p style={{ color: "white", fontSize: 10 }}>3</p>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`${styles["timeline"]} ${active && styles["active-chat"]}`}
      >
        <p>Yesterday</p>
      </div>
    </article>
  );
}
