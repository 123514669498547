import React, { useEffect, useState } from "react";
import TextInput from "../../Inputs/TextInput/TextInput";
import Button from "../../Button/customButton/Button";
import styles from "./createFaculty.module.css";
import {
  createFaculty,
  getFaculty,
  useFaculty,
} from "../../../query/admin/useFaculty";
import RichTextInput from "../../Inputs/RichTextInput/RichTextInput";
import { useDispatch, useSelector } from "react-redux";
import { institutionSlice } from "../../../redux/features/institutionSlice";
import MultiSelectDropdown from "../../Inputs/MultiSelectDropdown/MultiSelectDropdown";
import { COURSESTATUS } from "../../../utils/CONSTANTS/courseStatus";
import { LEVELDATA } from "../../../utils/CONSTANTS/Level";
import { IonSpinner } from "@ionic/react";

export default function CreateFacultyModal({ setViewFacultyModal, facultyId }) {
  /** State management */
  const [facultyName, setFacultyName] = useState("");
  const [facultyAbbr, setFacultyAbbr] = useState("");
  const [facultyDesc, setFacultyDesc] = useState("");
  const [levels, setLevels] = useState([]);
  const [aboutFaculty, setAboutFaculty] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isGettingInfo, setIsGettingInfo] = useState(false);

  const {
    createFaculty,
    getSingleFacultyInfo,
    updateFacultyInfo,
    deleteFaculty,
    getFaculty,
  } = useFaculty();
  const token = useSelector((state) => state.user.token);
  const dispatch = useDispatch();

  async function handleCreateFaculty() {
    setIsLoading(true);
    const tempLevels = levels.map((level) => {
      return {
        name: level,
      };
    });
    const faculty = {
      name: facultyName,
      abbrev: facultyAbbr,
      description: facultyDesc,
      levels: tempLevels,
      about: aboutFaculty,
    };
    await createFaculty(faculty).then((response) => {
      if (response) {
        setViewFacultyModal(false);
        getFaculty();
      }
      setIsLoading(false);
    });
  }

  useEffect(() => {
    if (facultyId !== "") {
      setIsGettingInfo(true);
      getSingleFacultyInfo(facultyId).then((faculty) => {
        if (faculty) {
          const levelId = faculty.levels.map((level) => {
            return level.name;
          });
          setFacultyName(faculty.name);
          setFacultyAbbr(faculty.abbrev);
          setLevels(levelId);
          setAboutFaculty(faculty.about);
        }
        setIsGettingInfo(false);
      });
    }
  }, []);

  function handleUpdateFaculty() {
    setIsUpdating(true);
    const tempLevels = levels.map((level) => {
      return {
        name: level,
      };
    });

    const faculty = {
      name: facultyName,
      abbrev: facultyAbbr,
      description: facultyDesc,
      levels: tempLevels,
      about: aboutFaculty,
    };

    updateFacultyInfo(faculty, facultyId).then((response) => {
      if (response) {
        setViewFacultyModal(false);
        getFaculty();
      }
      setIsUpdating(false);
    });
  }

  function handleDeleteFaculty() {
    setIsDeleting(true);
    deleteFaculty(facultyId).then((response) => {
      if (response) {
        setViewFacultyModal(false);
        getFaculty();
      }
      setIsDeleting(false);
    });
  }

  return (
    <div className={`${styles.container}`}>
      {facultyId !== "" && isGettingInfo ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "50vh",
            height: "50vh",
            background: "#fff",
          }}
        >
          <IonSpinner
            name="lines"
            color={"dark"}
            style={{ width: 50, height: 50 }}
          />
        </div>
      ) : (
        <div className={`${styles.sub_container}`}>
          <div
            style={{ position: "absolute", right: 20, top: 0 }}
            onClick={() => !isLoading && setViewFacultyModal(false)}
          >
            <p style={{ fontSize: 50, cursor: "pointer", color: "red" }}>
              &times;
            </p>
          </div>
          <h2 className={`${styles.title}`}>
            {facultyId !== ""
              ? "Update Faculty Information"
              : "Add New Faculty"}
          </h2>
          <div className={`${styles.form_container}`}>
            <div>
              <TextInput
                label={"Faculty name"}
                placeholder={"Faculty of Engineering and Technology"}
                value={facultyName}
                setValue={setFacultyName}
              />
            </div>
            <div>
              <TextInput
                label={"Abbreviation"}
                placeholder={"FET"}
                value={facultyAbbr}
                setValue={setFacultyAbbr}
              />
            </div>
            <div>
              <TextInput
                label={"Faculty Short Description"}
                placeholder={
                  "This is a short text that shows as a smal description of the given faculty..."
                }
                value={facultyDesc}
                setValue={setFacultyDesc}
              />
            </div>
            <div>
              <MultiSelectDropdown
                label={"Add Levels"}
                placeholder={"400"}
                value={levels}
                setValue={setLevels}
                data={LEVELDATA}
                keyItem={"id"}
                disabled={isLoading}
              />
            </div>

            <div>
              <RichTextInput
                label={"About"}
                value={aboutFaculty}
                setValue={setAboutFaculty}
              />
            </div>
          </div>

          {
            <div
              style={{
                marginTop: 60,
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {facultyId !== "" ? (
                <div style={{ display: "flex", gap: 10 }}>
                  <Button
                    text={"Delete"}
                    onClick={() => handleDeleteFaculty()}
                    isLoading={isLoading || isDeleting}
                    disabled={isLoading || isUpdating || isDeleting}
                    width={"fit-content"}
                    backgroundColor={"red"}
                  />

                  <Button
                    text={"Update Faculty info"}
                    onClick={() => handleUpdateFaculty()}
                    isLoading={isUpdating || isLoading}
                    disabled={
                      facultyName === "" ||
                      facultyAbbr === "" ||
                      facultyDesc === "" ||
                      levels.length === 0 ||
                      aboutFaculty === "" ||
                      isUpdating ||
                      isDeleting
                    }
                    width={"fit-content"}
                  />
                </div>
              ) : (
                <Button
                  text={"Save"}
                  onClick={() => handleCreateFaculty()}
                  disabled={
                    facultyName === "" ||
                    facultyAbbr === "" ||
                    facultyDesc === "" ||
                    levels.length === 0 ||
                    aboutFaculty === "" ||
                    isLoading
                  }
                  isLoading={isLoading}
                  width={"fit-content"}
                />
              )}
            </div>
          }
        </div>
      )}
    </div>
  );
}
