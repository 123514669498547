import React from "react";
import styles from "./registrationStepCrumb.module.css";

export default function RegistrationStepCrumbs({ active = 4, setActive }) {
  return (
    <ul className={`${styles["crumb-container"]}`}>
      <li
        className={`${styles["crumb-link"]} ${
          active >= 2 && styles["active-crumb-link"]
        }`}
        onClick={() => setActive(1)}
      >
        1
      </li>
      <li
        className={`${styles["crumb-link"]} ${
          active >= 3 && styles["active-crumb-link"]
        }`}
        onClick={() => setActive(2)}
      >
        2
      </li>
      <li
        className={`${styles["crumb-link"]} ${
          active >= 4 && styles["active-crumb-link"]
        }`}
        onClick={() => setActive(3)}
      >
        3
      </li>
      <li
        className={`${styles["crumb-link"]} ${
          active >= 5 && styles["active-crumb-link"]
        }`}
        onClick={() => setActive(4)}
      >
        4
      </li>
      <li
        onClick={() => {
          setActive(5);
        }}
      >
        5
      </li>
    </ul>
  );
}
