export const LEVELDATA = [
  {
    id: "200",
    name: "200",
  },
  {
    id: "300",
    name: "300",
  },
  {
    id: "400",
    name: "400",
  },
  {
    id: "500",
    name: "500",
  },
];
