import { useDispatch, useSelector } from "react-redux";
import client from "../../utils/api/axios";
import { opportunitySlice } from "../../redux/features/opportunitySlice";
import { useIonToast } from "@ionic/react";

export function useOpportunities() {
    /** State management */
    const dispatch = useDispatch();
    const token = useSelector((state) => state.user.token);
    const [toast] = useIonToast();

    async function getAllJobs() {
        client
            .get(`/api/opportunities`)
            .then((response) => {
                const data = response.data;
                if (data) {
                    dispatch(
                        opportunitySlice.actions.setOpportunities({
                            opportunities: data.results
                        })
                    );
                }
                return response;
            })
            .catch((error) => {
                return alert(`Error: ${error.message}`);
            });
    }

    async function handleCreateJob(data) {
        client
            .post(`/api/opportunities/`, data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
            })
            .then((response) => {
                const data = response.data;
                if (data) {
                    dispatch(
                        opportunitySlice.actions.newOpportunity({
                            data
                        })
                    );
                }
                return response;
            })
            .catch((error) => {
                return alert(`Error: ${error.message}`);
            });
    }

    return {
        getAllJobs,
        handleCreateJob,
    };
}