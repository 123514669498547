import React from "react";
import styles from "./testimonial.module.css";
import home_style from "../../home.module.css";
import { testimonials } from "../../../../utils/DATA/testimonials";

export default function Testimonials() {
  return (
    <section className={`${styles.testimonial_section}`}>
      <div className={styles.container}>
        <h3 className={`${home_style.section_heading_title}`}>TESTIMONIAL</h3>
        <p className={`${home_style.section_heading_desc}`}>
          Real Voices, Real Impact: Hear What Others Have to Say
        </p>

        <div className={`${styles.testimonial_card_container}`}>
          {testimonials.map((testimonial, index) => {
            return <TestimonialCard key={index} testimonial={testimonial} />;
          })}
        </div>
      </div>
    </section>
  );
}

function TestimonialCard({ testimonial }) {
  return (
    <div className={`${styles.testimonial_container}`}>
      <div className={`${styles.testimonial_profile_container}`}>
        <div className={`${styles.profile_image}`}>
          <img src={testimonial?.imgURL} />
        </div>

        <div className={`${styles.testimonial_personnal_info}`}>
          <h4>{testimonial?.name}</h4>
          <p>{testimonial?.position}</p>
        </div>
      </div>
      <p className={`${styles.testimonial_description}`}>
        “{testimonial?.testimonial}”
      </p>
    </div>
  );
}
