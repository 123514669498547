import React from "react";

export default function FileIcon({ ...props }) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="212"
        height="212"
        viewBox="0 0 212 212"
        fill="none"
        {...props}
      >
        <path
          d="M191.418 126.317L187.885 170.484C186.56 183.999 185.5 194.334 161.562 194.334H50.4384C26.5 194.334 25.44 183.999 24.115 170.484L20.5817 126.317C19.875 118.985 22.1717 112.184 26.3234 106.972C26.4117 106.884 26.4117 106.884 26.5 106.795C31.3584 100.877 38.69 97.167 46.905 97.167H165.095C173.31 97.167 180.553 100.877 185.323 106.619C185.412 106.707 185.5 106.795 185.5 106.884C189.828 112.095 192.213 118.897 191.418 126.317Z"
          stroke="white"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M30.9167 100.965V55.4733C30.9167 25.44 38.425 17.9316 68.4584 17.9316H79.6767C90.895 17.9316 93.4567 21.2883 97.6967 26.9416L108.915 41.9583C111.742 45.6683 113.42 47.965 120.928 47.965H143.453C173.487 47.965 180.995 55.4733 180.995 85.5067V101.318"
          stroke="white"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M83.2983 150.167H128.702"
          stroke="white"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
