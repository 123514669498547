import React from "react";
import styles from "./studentDistributionChart.module.css";
import ChartIcon from "../../../../../../../assets/icons/chart.icon";
import { BarChart, Bar, XAxis, Tooltip, ResponsiveContainer } from "recharts";
import BarchartIcon from "../../../../../../../assets/icons/barchart.icon";

export default function StudentDistributionChart() {
  const data = [
    {
      year: "2019",
      student: 20,
    },
    {
      year: "2020",
      student: 40,
    },
    {
      year: "2021",
      student: 30,
    },
    {
      year: "2022",
      student: 50,
    },
    {
      year: "2023",
      student: 70,
    },
    {
      year: "2024",
      student: 40,
    },
  ];

  const RoundedBar = (props) => {
    const { x, y, width, height, fill } = props;

    return (
      <g>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          rx={10}
          ry={10}
          fill={fill}
        />
      </g>
    );
  };
  const maxStudentValue = Math.max(...data.map((item) => item.student));

  return (
    <div className={`${styles["distribution-container"]}`}>
      <div className={`${styles["distribution-header-container"]}`}>
        <h4>Student Distribution</h4>
        <div className={`${styles["chart-icon"]}`}>
          <BarchartIcon width={24} height={24} />
        </div>
      </div>
      <h4 className={`${styles["total-number-of-students"]}`}>300 Students</h4>

      <div className={`${styles["chart-container"]}`}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={250}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis dataKey="year" axisLine={false} tick={{ dy: 10 }} />
            <Tooltip />
            <Bar
              dataKey="student"
              fill="#82ca9d"
              style={{ borderBlockStart: 20 }}
              shape={<RoundedBar />}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
