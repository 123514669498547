import React from "react";
import styles from "./residenceInformation.module.css";

export default function ResidenceInfoDetails({
  primaryLocation,
  secondaryLocation,
}) {
  return (
    <div className={`${styles["container"]}`}>
      <div className={`${styles["info"]}`}>
        <div className={`${styles["grid-column"]}`}>
          <h5>Primary Location</h5>
          <p>{primaryLocation}</p>
        </div>
        <div className={`${styles["grid-column"]}`}>
          <h5>Secondary Location</h5>
          <p>{secondaryLocation}</p>
        </div>
      </div>
    </div>
  );
}
