import React, { useEffect, useState } from "react";
import Button from "../../../../../components/Button/customButton/Button";
import Search from "../../../../../components/Search/Search";
import ChevronIcon from "../../../../../assets/icons/chevron.icon";
import styles from "../generalStyles.module.css";
import { useDispatch, useSelector } from "react-redux";
import DropDownS from "../../../../../components/Inputs/DropdownS/DropDownS";
import { useFaculty } from "../../../../../query/admin/useFaculty";
import { useDepartment } from "../../../../../query/admin/useDepartment";
import { useStudent } from "../../../../../query/admin/useStudent";
import Loader from "../../../../../components/Loader/Loader";
import { useTutors } from "../../../../../query/admin/useTutors";
import { useApplication } from "../../../../../query/admin/applicationRequest";
import { useCourse } from "../../../../../query/admin/useCourse";
import { checkTokenExpiry } from "../../../../../utils/functions/checkTokenExpiry";
import { useNavigate } from "react-router";
import { getDataFromLocalStorage } from "../../../../../utils/functions/handleLocalStorage";
import { userSlice } from "../../../../../redux/features/userSlice";
import { IonSpinner } from "@ionic/react";

export default function Students() {
  /** state management */
  const studentData = useSelector((state) => state.institution.studentData);
  const facultyData = useSelector((state) => state.institution.facultyData);
  const [studentFilterData, setStudentFilterData] = useState(studentData);
  const [departmentData, setDepartmentData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filterFaculty, setFilterFaculty] = useState("");
  const [filterDepartment, setFilterDepartment] = useState("");
  const [gender, setGender] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  /** Query functions */
  const { getFilteredStudents, getStudents, searchStudent } = useStudent();
  const { getFilterDepartments } = useDepartment();

  async function getAllData() {
    await getStudents();
  }

  useEffect(() => {
    getFilteredStudents(gender, filterFaculty, filterDepartment).then(
      (response) => {
        console.log("selected faculty, student screen:", response);
        if (response) {
          setStudentFilterData(response);
        }
        setIsLoading(false);
      }
    );
  }, [gender, filterFaculty, filterDepartment]);

  useEffect(() => {
    if (filterFaculty !== "") {
      setIsLoading(true);

      getFilterDepartments(filterFaculty).then((response) => {
        if (response) {
          setDepartmentData(response.results);
        }
        setIsLoading(false);
      });
    } else {
      setDepartmentData([]);
    }
  }, [filterFaculty]);

  useEffect(() => {
    if (searchValue !== "") {
      setIsSearching(true);

      searchStudent(searchValue).then((response) => {
        if (response) {
          setStudentFilterData(response);
        }
        setIsSearching(false);
      });
    } else {
      setStudentFilterData(studentData);
    }
  }, [searchValue]);

  useEffect(() => {
    setIsLoading(true);
    const tokenExpiry = checkTokenExpiry();

    if (tokenExpiry) {
      dispatch(userSlice.actions.setIsAuth(false));

      navigate("/login");
    } else {
      const userInfo = getDataFromLocalStorage("enchird-userInfo");
      const token = getDataFromLocalStorage("enchird-token");
      if (userInfo && token) {
        dispatch(userSlice.actions.setUserToken(token.token));
        dispatch(userSlice.actions.setIsAuth(true));

        dispatch(userSlice.actions.setUser(userInfo.user));
        getAllData().then(() => {
          setIsLoading(false);
        });
      } else {
        navigate("/login");
      }
    }
  }, []);
  return (
    <div>
      {isLoading && <Loader />}
      <div className={`${styles["header-container"]}`}>
        <h2>Manage Students</h2>

        <div className={`${styles["action-container"]}`}>
          <DropDownS
            value={filterFaculty}
            setValue={setFilterFaculty}
            placeholder={"Select Faculty"}
            data={facultyData.results}
            keyItem={"name"}
          />
          <DropDownS
            value={filterDepartment}
            setValue={setFilterDepartment}
            placeholder={"Select Department"}
            data={departmentData}
            keyItem={"name"}
          />

          <div style={{ maxWidth: 300 }}>
            <Search
              value={searchValue}
              setValue={setSearchValue}
              placeholder={"Find Student Record"}
            />
          </div>
        </div>
      </div>

      {/* Faculty Table */}
      <div className={`${styles["table-container"]}`}>
        <table>
          <thead>
            <tr className={`${styles["table-row"]}`}>
              <th className={`${styles["table-header"]}`}>
                <div className={`${styles["first-square"]}`}>
                  <div className={`${styles["check-box"]}`}></div>
                  <p>Registration Number</p>
                </div>
              </th>
              <th className={`${styles["table-header"]}`}>First Name</th>
              <th className={`${styles["table-header"]}`}>Last Name</th>
              <th className={`${styles["table-header"]}`}>Gender</th>
              <th className={`${styles["table-header"]}`}>DOB</th>
              <th className={`${styles["table-header"]}`}>Nationality</th>
              <th className={`${styles["table-header"]}`}>Email</th>
              <th className={`${styles["table-header"]}`}>Contact</th>
            </tr>
          </thead>

          {isSearching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "70%",
                height: "20vh",
              }}
            >
              <IonSpinner
                name="lines"
                color={"dark"}
                style={{ width: 50, height: 50 }}
              />
            </div>
          ) : (
            <tbody>
              {studentFilterData.results?.map((student, index) => {
                return (
                  <tr
                    key={index}
                    className={`${styles["table-row-container"]}`}
                  >
                    <td
                      className={`${styles["table-row"]}`}
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className={`${styles["check-box"]}`}
                        style={{ marginInlineEnd: 16 }}
                      ></div>
                      <p style={{ whiteSpace: "nowrap" }}>
                        {student.student_id}
                      </p>
                    </td>
                    <td className={` ${styles["table-row"]}`}>
                      <p>{student.user.first_name}</p>
                    </td>
                    <td className={` ${styles["table-row"]}`}>
                      <p>{student.user.last_name}</p>
                    </td>
                    <td>{student.user.gender}</td>
                    <td>{student.user.date_of_birth}</td>
                    <td className={` ${styles["table-row"]}`}>
                      <p>{student.user.nationality}</p>
                    </td>
                    <td className={` ${styles["table-row"]}`}>
                      <p>{student.user.email}</p>
                    </td>
                    <td>{student.user.phone}</td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
      <div className={`${styles["pagination-container"]}`}>
        <p>
          Page <span style={{ fontWeight: "bold" }}>1/1</span> Total:{" "}
          <span style={{ fontWeight: "bold" }}>
            {studentFilterData.count ?? 0} Students
          </span>
        </p>
        <div className={`${styles["btn-container"]}`}>
          <div className={`${styles.icons} ${styles["left-icon"]}`}>
            <ChevronIcon />
          </div>
          <div className={`${styles.icons} ${styles["right-icon"]}`}>
            <ChevronIcon />
          </div>
        </div>
      </div>
    </div>
  );
}
