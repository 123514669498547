import React, { useState, useEffect } from "react";
import styles from "./ManageProfile.module.css";
import { useDropzone } from "react-dropzone";
import imagePlaceholder from "../../../../../assets/images/placeholder.jpeg";
// import CameraIcon from "../../../../../assets/icons/camera.icon";
import HouseIcon from "../../../../../assets/icons/house.icon";
import PhoneIcon from "../../../../../assets/icons/phone.icon";
import EmailIcon from "../../../../../assets/icons/email.icon";
import TextInput from "../../../../../components/Inputs/TextInput/TextInput";
import RichTextInput from "../../../../../components/Inputs/RichTextInput/RichTextInput";
import Button from "../../../../../components/Button/customButton/Button";
import { useSelector } from "react-redux";
import { useTutorAuth } from "../../../../../query/tutor/auth";
import docUpload from "../../../../../utils/functions/handleUploadToFirebase";

const ManageProfile = () => {
  const [profileImage, setProfileImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [location, setLocation] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [nationality, setNationality] = useState("");
  const [primaryContact, setPrimaryContact] = useState("");
  const [secondaryContact, setSecondaryContact] = useState("");
  const [aboutLecturer, setAboutLecturer] = useState("");
  const [description, setDescription] = useState("");
  const [faculties, setFacultiesDetails] = useState([]);
  const [departments, setDepartmentsDetails] = useState([]);
  const [courses, setCoursesDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { getTutorInfo, handleTutorUpdate } = useTutorAuth();

  const userInfo = useSelector((state) => state.user);

  const { getRootProps: getProfileImage, getInputProps: getImageInput } =
    useDropzone({
      onDrop: (acceptedFiles) => {
        setProfileImage(acceptedFiles[0]);

        const objectUrl = URL.createObjectURL(acceptedFiles[0]);
        setImagePreview(objectUrl);
        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl);
      },
      accept: {
        "image/jpeg": [],
        "image/png": [],
      },
    });

  const getTutorData = async () => {
    if (userInfo.user.id !== undefined) {
      const id = userInfo.user.id;
      getTutorInfo(id);
      return;
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const profileUrl = await docUpload(
      profileImage,
      "teacher_profile_images",
      profileImage.name
    );
    const data = new FormData();
    data.append("tutorId", userInfo.user.id);
    data.append("email", email);
    data.append("first_name", firstName);
    data.append("last_name", lastName);
    data.append("picture", profileUrl);
    data.append("phone", primaryContact);
    data.append("gender", gender);
    data.append("nationality", nationality);
    data.append("date_of_birth", dateOfBirth);
    data.append("about", aboutLecturer);
    data.append("description", description);

    const newData = {
      tutorId: userInfo.user.id,
      data,
    };
    handleTutorUpdate(newData).then(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getTutorData();
  }, [userInfo.user.id]);

  useEffect(() => {
    if (userInfo.user !== undefined) {
      const user = userInfo.user;
      setFirstName(user.first_name);
      setLastName(user.last_name);
      setEmail(user.email);
      setGender(user.gender);
      setDateOfBirth(user.date_of_birth);
      setNationality(user.nationality);
      setPrimaryContact(user.phone);
      setAboutLecturer(user.about);
      setDescription(user.description);
      setLocation(user.nationality);
      setImagePreview(user.picture);

      setCoursesDetails(user.courses_detail);
      setDepartmentsDetails(user.departments_detail);
      setFacultiesDetails(user.faculties_detail);
    }
  }, [userInfo.user]);

  return (
    <div>
      <h3 style={{ marginTop: 10, fontSize: 22 }}>My Profile</h3>
      <div className={styles.profile_details}>
        <div className={styles.profile}>
          <div {...getProfileImage()} className={styles.profileImage}>
            <input {...getImageInput()} />
            <img
              src={imagePreview || imagePlaceholder}
              alt={"preview"}
              className={styles.image}
            />
            {/* <div className={styles.imageIcon}>
              <CameraIcon width={35} height={35} />
            </div> */}
          </div>
          <div className={styles.contactInfo}>
            <h3>Contact Information</h3>
            <div>
              <p className={styles.contactInfoParagraph}>
                <div>
                  <EmailIcon width={20} height={20} />
                </div>
                <span className={styles.spanText}>{email}</span>
              </p>
              <p className={styles.contactInfoParagraph}>
                <div>
                  <PhoneIcon width={18} height={18} />
                </div>
                <span className={styles.spanText}>{primaryContact}</span>
              </p>
              <p className={styles.contactInfoParagraph}>
                <div>
                  <HouseIcon width={18} height={18} />
                </div>
                <span className={styles.spanText}>Happicam Molyko</span>
              </p>
            </div>
          </div>
        </div>
        <div className={styles.responsibilitySection}>
          <h3>Assigned Responsibilities </h3>
          <div className={styles.responsibilities}>
            <div style={styles.sections}>
              <h4>Faculties</h4>
              <div className={styles.section_div}>
                {faculties?.length > 0 ? (
                  faculties.map((item) => (
                    <span className={styles.tooltips} key={item.id}>
                      {item.name}
                    </span>
                  ))
                ) : (
                  <span className={styles.tooltips}>No Faculty Found</span>
                )}
              </div>
            </div>
            <div className={styles.sections}>
              <h4>Departments</h4>
              <div className={styles.section_div}>
                {departments?.length > 0 ? (
                  departments.map((item) => (
                    <span className={styles.tooltips} key={item.id}>
                      {item.name}
                    </span>
                  ))
                ) : (
                  <span className={styles.tooltips}>No Department Found</span>
                )}
              </div>
            </div>
            <div className={styles.sections}>
              <h4>Courses</h4>
              <div className={styles.section_div}>
                {courses?.length > 0 ? (
                  courses.map((item) => (
                    <span className={styles.tooltips} key={item.id}>
                      {item.course_title}
                    </span>
                  ))
                ) : (
                  <span className={styles.tooltips}>No Course Found</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <h3 style={{ marginTop: 20, fontSize: 22 }}>Identification</h3>
      <div className={styles.form}>
        <form onSubmit={handleUpdate}>
          <div className={styles.inputGroup}>
            <div className={styles.inputField}>
              <TextInput
                label={"First Name"}
                placeholder={"Mbianou"}
                value={firstName}
                setValue={setFirstName}
              />
            </div>
            <div className={styles.inputField}>
              <TextInput
                label={"Last Name"}
                placeholder={"Mbianou"}
                value={lastName}
                setValue={setLastName}
              />
            </div>
          </div>
          <div className={styles.inputGroup}>
            <div className={styles.inputField}>
              <TextInput
                label={"Email"}
                placeholder={"janemike@gmail.com"}
                value={email}
                setValue={setEmail}
              />
            </div>
            <div className={styles.inputField}>
              <TextInput
                label={"Gender"}
                placeholder={"Male"}
                value={gender}
                setValue={setGender}
              />
            </div>
          </div>
          <div className={styles.inputGroup}>
            <div className={styles.inputField}>
              <TextInput
                label={"Date of Birth"}
                placeholder={"Mbianou"}
                inputType={"date"}
                value={dateOfBirth}
                setValue={setDateOfBirth}
              />
            </div>
            <div className={styles.inputField}>
              <TextInput
                label={"Nationality"}
                placeholder={"Cameroonian"}
                value={nationality}
                setValue={setNationality}
              />
            </div>
          </div>
          <div className={styles.inputGroup}>
            <div className={styles.inputField}>
              <TextInput
                label={"Short Caption (displays below your name)"}
                placeholder={
                  "This is a short text that shows as a smart description of the given tutor..."
                }
                value={description}
                setValue={setDescription}
              />
            </div>
            <div className={styles.inputField}>
              <TextInput
                label={"Nationality"}
                placeholder={
                  "Cameroonian"
                }
                value={nationality}
                setValue={setNationality}
              />
            </div>
          </div>
          <div className={styles.inputGroup}>
            <div className={styles.inputField}>
              <TextInput
                label={"Primary Contact"}
                placeholder={"+237 6 50 99 93 16"}
                value={primaryContact}
                setValue={setPrimaryContact}
              />
            </div>
            <div className={styles.inputField}>
              <TextInput
                label={"Secondary Contact"}
                placeholder={"+237 6 50 99 93 16"}
                value={secondaryContact}
                setValue={setSecondaryContact}
              />
            </div>
          </div>
          <div className={styles.inputGroup}>
            <div className={styles.richInputField}>
              <RichTextInput
                label={"About Lecturer"}
                value={aboutLecturer}
                setValue={setAboutLecturer}
              />
            </div>
          </div>
        </form>

      </div>
      <div className={styles.updateBtn}>
        <Button
          text="Update Profile Data"
          isLoading={isLoading}
          disabled={isLoading}
        />
      </div>
    </div>
  );
};

export default ManageProfile;
