import client from "../utils/api/axios";

// constants
const SET_STUDENT = "session/SET_STUDENT";
const LOAD_STUDENT = "details/LOAD_STUDENT";
const REMOVE_STUDENT = "session/REMOVE_STUDENT";
const NEW_DETAIL = "session/NEW_DETAIL";
const setStudent = (student) => ({
  type: SET_STUDENT,
  payload: student,
});

const removeStudent = () => ({
  type: REMOVE_STUDENT,
});

const newStudentDetails = (detail) => ({
  type: NEW_DETAIL,
  detail,
});

const loadDetails = (student) => ({
  type: LOAD_STUDENT,
  payload: student,
});

const initialState = { student: null };

export const authenticate = () => async (dispatch) => {
  const response = await fetch("/api/auth/", {
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (response.ok) {
    const data = await response.json();
    if (data.error) {
      return;
    }

    dispatch(setStudent(data));
  }
};
export const studentRegistration = (studentObject) => async (dispatch) => {
  const response = await fetch("/api/applicant/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(studentObject),
  });

  if (response.ok) {
    const data = await response.json();
    dispatch(setStudent(data));
    return null;
  } else if (response.status < 500) {
    const data = await response.json();
    if (data.error) {
      return data.error;
    }
  } else {
    return "An error occurred. Please try again.";
  }
};

export async function handleCreateStudent(studentObject) {
  await client
    .post("/api/applicant/", studentObject)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      alert(`An error occurred. Please try again. ${error.message}`);
    });
}

export const login = (email, password) => async (dispatch) => {
  client
    .post(
      "/api/auth/login/",
      { email, password },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      const data = response.data;
      // console.log("Login credential:", data);
      dispatch(setStudent(data));
    })
    .catch((error) => {
      alert(`Error: ${error.message}`);
    });
};

export const logout = () => async (dispatch) => {
  const response = await fetch("/api/auth/logout/", {
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (response.ok) {
    dispatch(removeStudent());
  }
};

export const forgotPassword = (email) => async (dispatch) => {
  const response = await fetch("/api/forgotpassword/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
    }),
  });

  if (response.ok) {
    const data = await response.json();
    dispatch(setStudent(data));
    return null;
  } else if (response.status < 500) {
    const data = await response.json();
    if (data.error) {
      return data.error;
    }
  } else {
    return "An error occurred. Please try again.";
  }
};

export const createNewPassword = (password) => async (dispatch) => {
  const response = await fetch("/api/newpassword/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      password,
    }),
  });

  if (response.ok) {
    const data = await response.json();
    dispatch(setStudent(data));
    return null;
  } else if (response.status < 500) {
    const data = await response.json();
    if (data.error) {
      return data.error;
    }
  } else {
    return "An error occurred. Please try again.";
  }
};

export const loadAllStudents = () => async (dispatch) => {
  const response = await fetch(`/api/student/`);
  if (response.ok) {
    const details = await response.json();
    dispatch(loadDetails(details));
  }
};

export const loadStudentDetails = () => async (dispatch) => {
  const response = await fetch(`/api/student/<user_id>/`);
  if (response.ok) {
    const details = await response.json();
    dispatch(loadDetails(details));
  }
};

export const updateStudentDetails = (data) => async (dispatch) => {
  const response = await fetch(`/api/student/<user_id>/`, {
    method: "PUT",
    body: data,
  });
  if (response.ok) {
    const detail = await response.json();
    if (detail.error) {
      return detail;
    } else if (!detail.error) {
      dispatch(newStudentDetails(detail));
      return detail;
    }
  } else {
    return `{ 'error': ['an error has occured please verify your file types'] }`;
  }
  if (response.ok) {
    const detail = await response.json();
    dispatch(newStudentDetails(detail));
    return detail;
  }
};

export const deleteStudent = (id) => async (dispatch) => {
  const response = await fetch(`/api/student/<user_id>/`, {
    method: "DELETE",
  });
  if (response.ok) {
    await response.json();
    dispatch(removeStudent(id));
    return id;
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_STUDENT:
      return { student: action.payload };

    case NEW_DETAIL: {
      const newState = {
        ...state,
        [action.detail.id]: action.detail,
      };
      return newState;
    }
    case LOAD_STUDENT:
      const newState = { ...state };
      action?.details?.forEach((student) => {
        newState[student.id] = student;
      });
      return newState;

    case REMOVE_STUDENT:
      return { student: null };
    default:
      return state;
  }
}
