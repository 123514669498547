import React, { useState } from "react";
import StudentLayout from "../components/Layout/StudentLayout";
import styles from "./tuitionFees.module.css";
import Dropdown from "../../../../components/Inputs/Dropdown/Dropdown";
import Button from "../../../../components/Button/customButton/Button";
import PaymentInput from "./components/PaymentInput/PaymentInput";
import { usePayment } from "../../../../query/payment/usePayment";
import Loader from "../../../../components/Loader/Loader";

export default function TuitionFees() {
  /** State management */
  const [activeStep, setActiveStep] = useState(0);
  const [paymentMode, setPaymentMode] = useState("");
  const [paymentType, setPaymentType] = useState("");

  /** Stripe details */
  const [cardNumber, setCardNumber] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [cvv, setCvv] = useState("");

  /** Paypal Details */
  const [accountName, setAccountName] = useState("");
  const [accountEmail, setAccountEmail] = useState("");

  /** pending management */
  const [isLoading, setIsLoading] = useState(false);

  const { stripePayment, paypalPayment } = usePayment();

  function handlePayment() {
    setIsLoading(true);
    const paymentDetails = {
      amount:
        paymentMode === ""
          ? 0
          : paymentMode === "Half Installment"
          ? 5000
          : 10000,
      cancel_url: "http://127.0.0.1:3002/dashboard/tuition-fees",
      success_url: "http://127.0.0.1:3002/dashboard/tuition-fees",
    };
    paymentType === "stripe"
      ? stripePayment(paymentDetails).then((response) => {
          if (response) {
            window.open(response.session_url, "_blank");
          }
          setIsLoading(false);
        })
      : paypalPayment(paymentDetails).then((response) => {
          if (response) {
            window.open(response.session_url, "_blank");
          }
          setIsLoading(false);
        });
  }

  return (
    <StudentLayout>
      {isLoading && <Loader />}
      <div className={`${styles["fees-payment-container"]}`}>
        <div className={`${styles["fees-payment-sub-container"]}`}>
          <h2 className={`${styles["heading"]}`}>
            Select Payment Method Below
          </h2>
          <p className={`${styles["desc"]}`}>
            Please choose your payment method to pay Registration Fee for
            2023/2024 academic year{" "}
            {paymentMode !== "" && activeStep >= 1 && (
              <strong>
                (Total: $
                {paymentMode === ""
                  ? "0"
                  : paymentMode === "Half Installment"
                  ? "5,000"
                  : "10,000"}
                )
              </strong>
            )}
            . Fees can be paid in two (2) installments.
          </p>

          <div className={`${styles["paying-steps-container"]}`}>
            {activeStep === 0 ? (
              <>
                <Dropdown
                  label={"Select payable mode"}
                  value={paymentMode}
                  setValue={setPaymentMode}
                  data={["Half Installment", "Full Installment"]}
                />
                <div className={`${styles["amount-container"]}`}>
                  Total Amount:{" "}
                  <strong>
                    $
                    {paymentMode === ""
                      ? "0"
                      : paymentMode === "Half Installment"
                      ? "5,000"
                      : "10,000"}
                  </strong>
                </div>
              </>
            ) : (
              <>
                <div>
                  <div>
                    <div
                      className={`${styles["payment-option-sub-container"]}`}
                      onClick={() => {
                        setActiveStep(2);
                        setPaymentType("stripe");
                      }}
                    >
                      <div
                        className={`${styles["ring-border"]} ${
                          // activeStep === 2

                          paymentType === "stripe" && styles["active-border"]
                        }`}
                      ></div>
                      <h4>Stripe Payment</h4>{" "}
                    </div>

                    {
                      // activeStep === 2
                      false && (
                        <div
                          className={`${styles["sub-container_payment-details"]}`}
                        >
                          <PaymentInput
                            value={cardNumber}
                            setValue={setCardNumber}
                            placeholder={"Card number"}
                            cardNumber={"number"}
                            maxLength={16}
                          />
                          <div
                            className={`${styles["sub-container__payment"]}`}
                          >
                            <div
                              className={`${styles["sub-container__payment-expiration"]}`}
                            >
                              <PaymentInput
                                value={expirationDate}
                                setValue={setExpirationDate}
                                placeholder={"MM / YY"}
                                cardNumber={"date"}
                                maxLength={5}
                              />
                            </div>
                            <PaymentInput
                              value={cvv}
                              setValue={setCvv}
                              placeholder={"CVV"}
                              maxLength={3}
                              cardNumber={"number"}
                            />
                          </div>
                        </div>
                      )
                    }
                  </div>

                  <div>
                    <div
                      className={`${styles["payment-option-sub-container"]}`}
                      onClick={() => {
                        setActiveStep(3);
                        setPaymentType("paypal");
                      }}
                    >
                      <div
                        className={`${styles["ring-border"]} ${
                          // activeStep === 3
                          paymentType === "paypal" && styles["active-border"]
                        }`}
                      ></div>
                      <h4>Paypal</h4>
                    </div>

                    {
                      // activeStep === 3
                      false && (
                        <div
                          className={`${styles["sub-container_payment-details"]}`}
                        >
                          <PaymentInput
                            value={accountName}
                            setValue={setAccountName}
                            placeholder={"Account Name"}
                          />
                          <PaymentInput
                            value={accountEmail}
                            setValue={setAccountEmail}
                            placeholder={"Email Address"}
                          />
                        </div>
                      )
                    }
                  </div>
                </div>
              </>
            )}

            <div className={`${styles["btn-container"]}`}>
              <Button
                text={activeStep === 0 ? "Proceed to Pay" : "Launch Payment"}
                width={"fit-content"}
                backgroundColor={activeStep === 0 ? "#2218A7" : "#363736"}
                disabled={
                  activeStep === 0
                    ? paymentMode === ""
                    : paymentType === ""
                    ? true
                    : false
                }
                onClick={() => {
                  activeStep === 0 && setActiveStep(1);
                  activeStep >= 2 && handlePayment();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </StudentLayout>
  );
}
// : activeStep === 2
// ? cardNumber.length < 8 ||
//   expirationDate === "" ||
//   cvv.length > 3
// : activeStep === 3
// ? accountName === "" || accountEmail === ""
