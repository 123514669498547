import React, { useState, useEffect } from "react";
import Button from "../../../../components/Button/customButton/Button";
import GroupCard from "./components/GroupCard/GroupCard";
import styles from "./manageGroups.module.css";
import { useSelector } from "react-redux";
import CreateGroupModal from "./CreateGroup"
import { useCourses } from "../../../../query/tutor/useCourses";
import { useTutorAuth } from "../../../../query/tutor/auth";
import Loader from "../../../../components/Loader/Loader";
import { FaPlus } from "react-icons/fa6";

export default function ManageGroups() {
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [coursesData, setCoursesData] = useState([]);
  const [groups, setGroups] = useState([]);
  const [structuredData, setStructuredData] = useState([]);

  const user = useSelector((state) => state.user.user);
  const { handleGetCourseGroups } = useCourses()
  const { getTutorInfo } = useTutorAuth();


  useEffect(() => {
    setCoursesData(user?.courses_detail);
  }, [user]);

  const getTutorData = async () => {
    if (user.id !== undefined) {
      const id = user.id;
      getTutorInfo(id);
      return;
    }
  };

  const getGroups = async () => {
    setIsLoading(true)
    await handleGetCourseGroups().then((res) => {
      setGroups(res)
      setIsLoading(false)
    }).catch((err) => {
      console.error(err)
      setIsLoading(false);
    })
  };

  const filterData = () => {
    const filteredCourses = [];

    groups?.forEach((item) => {
      const filtered = coursesData.filter((crs) => crs.id === item.course)
      if (filtered.length > 0) {
        filteredCourses.push({
          group: item,
          course: filtered[0],
        });
      }
    })

    setStructuredData(filteredCourses)
  }

  useEffect(() => {
    getTutorData();
    getGroups();
  }, []);

  useEffect(() => {
    filterData()
  }, [coursesData, groups])

  console.log(groups)

  return (
    <div className={`${styles["manage-group-container"]}`}>
      {isLoading && <Loader />}
      {openModal && (
        <CreateGroupModal
          setGroupModal={setOpenModal}
          courses={coursesData}
        />
      )}
      <div>
        <div className={styles.header}>
          <h2 className={`${styles["manage-group-heading"]}`}>Classroom Groups</h2>
          <div className={`${styles["button-container"]}`}>
            {user.role === "teacher" && (
              <Button
                text={"Create New Group"}
                rightIcon={<FaPlus />}
                width={"fit-content"}
                onClick={() => {
                  setOpenModal(true);
                }}
              />
            )}
          </div>
        </div>
        <div className={`${styles["groups-container"]}`}>
          {structuredData.length > 0 ? structuredData?.map((item, index) => {
            return <GroupCard key={index} item={item} />;
          }) : <div className={styles.notFound}>
            <p>No Groups Found</p></div>}
        </div>
      </div>
    </div>
  );
}
