import { useDispatch, useSelector } from "react-redux";
import client from "../../utils/api/axios";
import { useIonToast } from "@ionic/react";
import { institutionSlice } from "../../redux/features/institutionSlice";

export function useStudent() {
  /** State management */
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);
  const [toast] = useIonToast();

  async function getCourseStudents(courseId) {
    client
      .get(`/api/student/students_for_course/?course_id=${courseId}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        const result = response.data;
        if (result) {
          dispatch(institutionSlice.actions.setStudent(result));
        }
        return response.data;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.message}`,
          color: "danger",
          buttons: ["Ok"],
        });
      });
  }

  /**
   * updateFstudentInfo Updates department information using department id
   * @param department department object to be updated
   */
  // async function updateStudentInfo(student) {
  //   client.put(`/api/student/${student.id}/`, {
  //     headers: {
  //       Authorization: `Token ${token}`,
  //     },
  //     data: student,
  //   });
  // }

  // async function getSingleStudentInfo(studentId) {
  //   return await client
  //     .get(`/api/student/${studentId}/`, {
  //       headers: {
  //         Authorization: `Token ${token}`,
  //       },
  //     })
  //     .then((response) => {
  //       return response.data;
  //     })
  //     .catch((error) => {
  //       toast({
  //         message: `Error: ${error.message}`,
  //         color: "danger",
  //         buttons: ["Ok"],
  //       });
  //       return null;
  //     });
  // }

  async function getFilteredStudents(course_id, keyword) {
    client
      .get(`/api/tutor-search/student/${course_id}/?keyword=${keyword}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        const result = response.data;
        if (result) {
          dispatch(institutionSlice.actions.setStudent(result.students));
        }
        return response.data;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.message}`,
          color: "danger",
          buttons: ["Ok"],
        });
      });
  }

  return {
    getCourseStudents,
    // getSingleStudentInfo,
    // updateStudentInfo,
    getFilteredStudents,
  };
}
