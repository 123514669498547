import React, { useState } from "react";
import styles from "./dropdown.module.css";
import ChevronIcon from "../../../assets/icons/chevron.icon";

export default function Dropdown({
  label,
  inputIcon,
  placeholder,
  value,
  setValue,
  required = true,
  data,
  keyItem,
  disabled,
}) {
  /** State management */
  const [option, setOption] = useState("");
  const [viewDropDown, setViewDropDown] = useState(false);

  const OPTIONDATA = [
    "Faculty of Engineering",
    "Faculty of Science",
    "Faculty of Laws",
    "Faculty of Health Science",
    "Faculty of Art",
    "Faculty of Social and Management",
    "College of Technology",
    "Advanced School of Translation",
    "Faculy of Journalism and Mass Communication",
    "Faculty of Political Sciences",
  ];

  /** Handle Select */
  function handleSelect(item) {
    setOption(item);
    setValue(item);
    setViewDropDown(false);
  }
  return (
    <div>
      <h5 className={`${styles["dropdown-label"]}`}>
        {label} {required && <span>*</span>}
      </h5>
      <div className={`${styles["main-container"]}`}>
        <div
          className={`${styles["filter-container"]} `}
          onClick={() => setViewDropDown((prevState) => !prevState)}
        >
          <p>
            {keyItem === "name"
              ? value
              : keyItem === "id"
              ? data?.filter((item) => item.id === value)[0]?.name
              : value}
          </p>
          <div
            className={`${
              viewDropDown ? styles.active : styles["dropdown-icon"]
            }`}
          >
            <ChevronIcon />
          </div>
        </div>
        {viewDropDown && !disabled && (
          <ul className={`${styles["dropdown-list"]}`}>
            <li
              onClick={() => {
                handleSelect("");
              }}
              className={`${value === "" && styles["active-option"]}`}
            ></li>
            {data?.map((opt, index) => {
              return (
                <li
                  key={index}
                  onClick={() => {
                    handleSelect(
                      keyItem === "name"
                        ? opt.name
                        : keyItem === "id"
                        ? opt.id
                        : opt
                    );
                  }}
                  className={`${
                    value ===
                      (keyItem === "name"
                        ? opt.name
                        : keyItem === "id"
                        ? opt.id
                        : opt) && styles["active-option"]
                  }`}
                >
                  {keyItem === "name"
                    ? opt.name
                    : keyItem === "id"
                    ? opt.name
                    : opt}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
}
