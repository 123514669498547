import React, { useState } from "react";
import styles from "./personalInformation.module.css";
import TextInput from "../../../../../components/Inputs/TextInput/TextInput";
import PersonIcon from "../../../../../assets/icons/person.icon";
import PhoneIcon from "../../../../../assets/icons/phone.icon";
import MessageIcon from "../../../../../assets/icons/message.icon";
import MessageGrayIcon from "../../../../../assets/icons/message-gray.icon";
import Dropdown from "../../../../../components/Inputs/Dropdown/Dropdown";

export default function PersonalInformation({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  nationality,
  setNationality,
  gender,
  setGender,
  birthDate,
  setBirthDate,
  email,
  setEmail,
  phone,
  setPhone,
}) {
  return (
    <div className={`${styles["info-container"]}`}>
      <form className={`${styles["info-form"]}`}>
        <TextInput
          label={"First Name"}
          value={firstName}
          setValue={setFirstName}
          placeholder={"Joe"}
          inputIcon={<PersonIcon width={32} height={32} />}
        />
        <TextInput
          label={"Last Name"}
          value={lastName}
          setValue={setLastName}
          placeholder={"Doe"}
          inputIcon={<PersonIcon width={32} height={32} />}
        />
        <TextInput
          label={"Nationality"}
          value={nationality}
          setValue={setNationality}
          placeholder={"Cameroonian"}
        />

        <Dropdown
          label={"Gender"}
          value={gender}
          setValue={setGender}
          data={[
            { id: "male", name: "Male" },
            { id: "female", name: "Female" },
          ]}
          keyItem={"id"}
        />
        <TextInput
          label={"Date of Birth"}
          value={birthDate}
          setValue={setBirthDate}
          placeholder={"12 / 28 / 1995"}
          inputType={"date"}
        />
        <TextInput
          label={"Email Address"}
          value={email}
          setValue={setEmail}
          placeholder={"joedoe@example.com"}
          inputIcon={<MessageGrayIcon width={32} height={32} />}
        />
        <TextInput
          label={"Contact / Phone Number"}
          value={phone}
          setValue={setPhone}
          placeholder={"+237 6 50 99 93 16"}
          inputIcon={<PhoneIcon width={32} height={32} />}
        />
      </form>
    </div>
  );
}
