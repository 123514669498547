import React from "react";

export default function UserIcon({ ...props }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            {...props}
        >
            <path d="M21 10c0-5-4-9-9-9s-9 4-9 9"></path>
            <path d="M12 19c-5 0-9-4-9-9"></path>
            <path d="M12 19c2-3 4-7 4-9"></path>
            <circle cx="12" cy="10" r="1"></circle>
        </svg>

    );
}



