import React, { useState, useEffect } from "react";
import TextInput from "../../../../../../components/Inputs/TextInput/TextInput";
import Button from "../../../../../../components/Button/customButton/Button";
import Dropdown from "../../../../../../components/Inputs/Dropdown/Dropdown";
import styles from "./Create.module.css"
import { useZoom } from "../../../../../../query/tutor/useZoom";
import { useIonToast } from "@ionic/react";

export default function CreateMeetingModal({ setViewModal, courses, courseId }) {
    const [toast] = useIonToast();
    const { handleCreateMeeting } = useZoom()
    const [course, setCourse] = useState("");
    const [title, setTitle] = useState("");
    const [date, setDate] = useState("");
    const [startTime, setStartTime] = useState("");
    const [duration, setDuration] = useState("");
    const [isLoading, setIsLoading] = useState(false)

    const formatDate = (dateString) => {
        if (!dateString) return '';

        const dateObject = new Date(dateString);
        const year = dateObject.getFullYear();
        const month = String(dateObject.getMonth() + 1).padStart(2, '0');
        const day = String(dateObject.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    async function handleCreateNewLink() {
        try {
            const newdate = formatDate(date)
            if (course && title && startTime && duration && newdate) {
                setIsLoading(true);
                const data = {
                    course,
                    topic: title,
                    start_time: `${newdate} ${startTime}`,
                    duration,
                };
                await handleCreateMeeting(data).then((res) => {
                    toast({
                        message: `Success`,
                        color: "success",
                        buttons: ["Ok"],
                    });
                    console.log(res)
                    setViewModal(false);
                    setIsLoading(false);
                })
            } else {
                toast({
                    message: `All fields are required`,
                    color: "danger",
                    buttons: ["Ok"],
                });
            }
        } catch (error) {
            console.error(error)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (courseId != undefined) {
            setCourse(courseId)
        }
    }, [courseId])

    return (
        <div className={`${styles.container}`}>
            <div className={`${styles.sub_container}`}>
                <div
                    style={{ position: "absolute", right: 20, top: 0 }}
                    onClick={() => !isLoading && setViewModal(false)}
                >
                    <p style={{ fontSize: 50, cursor: "pointer", color: "red" }}>
                        &times;
                    </p>
                </div>
                <h2 className={`${styles.title}`}>Create Meeting Link</h2>
                <div className={`${styles.form_container}`}>
                    {!courseId && <div>
                        <Dropdown
                            label={"Select Course"}
                            placeholder={"select course for assessment"}
                            value={course}
                            setValue={setCourse}
                            data={courses}
                            disabled={isLoading}
                            keyItem={"id"}
                        />
                    </div>}

                    <div>
                        <TextInput
                            label={"Meeeting Topic"}
                            placeholder={"e.g Introduction to Computer"}
                            value={title}
                            setValue={setTitle}
                            disabled={isLoading}
                        />
                    </div>

                    <div>
                        <TextInput
                            inputType={"date"}
                            label={"Date"}
                            placeholder={"e.g 2024-02-28"}
                            value={date}
                            setValue={setDate}
                            disabled={isLoading}
                        />
                    </div>

                    <div>
                        <TextInput
                            inputType={"time"}
                            label={"Time"}
                            placeholder={"e.g 10:00 AM"}
                            value={startTime}
                            setValue={setStartTime}
                            disabled={isLoading}
                        />
                    </div>

                    <div>
                        <TextInput
                            inputType={"number"}
                            label={"Meeting Duration in Minutes"}
                            placeholder={"e.g 60"}
                            value={duration}
                            setValue={setDuration}
                            disabled={isLoading}
                        />
                    </div>
                </div>

                {
                    <div
                        style={{
                            marginTop: 50,
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Button
                            text={"Save"}
                            onClick={() => handleCreateNewLink()}
                            isLoading={isLoading}
                            disabled={(title === "" &&
                                startTime === "" &&
                                duration === "") ||
                                isLoading}
                            width={"fit-content"}
                        />
                    </div>
                }
            </div>
        </div>
    );
}
