import React from "react";
import StudentLayout from "../../components/Layout/StudentLayout";
import Button from "../../../../../components/Button/customButton/Button";
import styles from "../generalPaymentStyles.module.css";
import failure_image from "../../../../../assets/images/Something-Went-Wrong.png";
import { useNavigate } from "react-router-dom";

export default function FailedTransaction() {
  /** state management */
  const navigate = useNavigate();

  return (
    <StudentLayout>
      <div className={`${styles.payment_container}`}>
        <div className={`${styles.image_container}`}>
          <img src={failure_image} alt="fail transaction image" />
        </div>

        <div className={`${styles.payment_desc_container}`}>
          <p className={`${styles.payment_desc_header}`}>
            SORRY, PAYMENT WAS UNSUCESSFUL DUE TO AN ERROR
          </p>
          <p className={`${styles.payment_desc}`}>
            An error occurred in processing the payment, try again. Please if
            the error persist, contact the administration.
          </p>

          <div className={`${styles.payment_btn_container}`}>
            <Button
              text={"Go Home"}
              width={"fit-content"}
              backgroundColor={"transparent"}
              border={1}
              borderColor={"#2218A7"}
              color={"#2218A7"}
              paddingBlock={16}
              onClick={() => navigate("/dashboard/main")}
            />
            <Button
              text={"Try Again"}
              width={"fit-content"}
              color={"white"}
              paddingBlock={16}
            />
          </div>
        </div>
      </div>
    </StudentLayout>
  );
}
