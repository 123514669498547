import React from "react";
import { Link } from "react-router-dom";
import LogoIcon from "../../assets/icons/logo.icon";
import styles from "./logo.module.css";
import LogoWhiteIcon from "../../assets/icons/logo-white.icon";
import logo_image from "../../assets/images/xashy_logo1.png";

export default function Logo({ logoType }) {
  return (
    <div>
      <Link to="/" style={{ textDecoration: "none" }}>
        <div>
          <div className={`${styles["logo-container"]}`}>
            <div className={`${styles["logo-icon"]}`}>
              <img src={logo_image} alt="Xash Tech Logo" />
            </div>
            <span>Xashy Tech</span>
          </div>
          <div className={`${styles["logo-subtitle"]}`}>
            <p>Bring learning closer to you</p>
          </div>
        </div>
      </Link>
    </div>
  );
}
