import React, { useState, useEffect } from "react";
import styles from "../Assesments.module.css";
// import DropDownS from "../../../../../../components/Inputs/DropdownS/DropDownS";
import Search from "../../../../../../components/Search/Search";
import AssessmentCard from "../AssessmentCard";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAssessments } from "../../../../../../query/tutor/useAssessments";
import { useTutorAuth } from "../../../../../../query/tutor/auth";

const ListingSubmissions = () => {
    const navigate = useNavigate()
    const { getTutorInfo } = useTutorAuth();
    const { handleGetAssessments } = useAssessments()
    const [isLoading, setIsLoading] = useState(false)
    const [coursesData, setCoursesData] = useState([])
    const [tutorAssessments, setTutorAssessments] = useState([])
    const userInfo = useSelector((state) => state.user);
    const assessments = useSelector((state) => state.assessment.assessments.data);

    // Filter variables
    const [selectedFaculty, setSelectedFaculty] = useState("");
    const [selectedDepartment, setSelectedDepartment] = useState("");
    const [selectedLevel, setSelectedLevel] = useState("");

    // Seach text variable
    const [searchText, setSearchText] = useState("");

    const facultyOptions = ["one", "two"]

    const getTutorData = async () => {
        if (userInfo.user.id !== undefined) {
            setIsLoading(true);
            const id = userInfo.user.id;
            getTutorInfo(id);
            setIsLoading(false);
            return;
        }
    };

    const getAssessments = async () => {
        if (userInfo.user.id !== undefined) {
            setIsLoading(true);
            const id = userInfo.user.id;
            await handleGetAssessments(id);
            setIsLoading(false);
            return;
        }
    };

    const getTutorAssessments = () => {
        if (assessments?.length > 0) {
            const assmts = assessments.filter((item) => item.instructor === userInfo.user.id)
            const filtered = assmts.filter((item) => item?.structure === "text")
            setTutorAssessments(filtered)
        }
    }

    useEffect(() => {
        const courses = userInfo.user?.courses_detail
        const orderedCourses =
            courses?.length > 0
                ? courses.map((item) => {
                    return (
                        { id: item.id, name: item.course_title }
                    )
                })
                : [];
        setCoursesData(orderedCourses);
    }, [userInfo.user]);

    useEffect(() => {
        getTutorData()
        getAssessments()
    }, [userInfo.user.id])

    useEffect(() => {
        getTutorAssessments()
    }, [assessments])

    return (
        <div>
            <h3 style={{ marginTop: 10, fontSize: 20 }}>Assessments Submissions</h3>
            <div className={styles.filter_row_container}>
                {/* <DropDownS
                    data={facultyOptions}
                    placeholder="Assessment type"
                    setValue={setSelectedFaculty}
                /> */}
                {/* <DropDownS
                    data={facultyOptions}
                    placeholder="Filter By Faculty"
                    setValue={setSelectedFaculty}
                />
                <DropDownS
                    data={facultyOptions}
                    placeholder="Filter By Department"
                    setValue={setSelectedDepartment}
                />
                <DropDownS
                    data={facultyOptions}
                    placeholder="Select Level"
                    setValue={setSelectedLevel}
                /> */}
                <div style={{}}>
                    <Search
                        placeholder={"Assessment title"}
                        value={searchText}
                        setValue={setSearchText}
                    />
                </div>
            </div>
            {tutorAssessments.length > 0 ?
                <div className={styles.listingAssessments} style={{ marginTop: 20 }}>
                    {tutorAssessments && tutorAssessments?.length > 0 && coursesData && coursesData?.length > 0 && tutorAssessments?.map((item, index) => {
                        const courseTitle = coursesData.filter((c) => item.course === c.id)[0]?.name
                        return (
                            <AssessmentCard link={`/tutor/assessment/${item.id}/course-submissions/${courseTitle}`} title={item.title} desc={item.description} course={courseTitle} courseId={item.course} key={index}
                                assessment_type={item.assessment_type} structure={item.structure} />
                        )
                    }).reverse()}
                </div> :
                <div className={styles.notFound}>
                    <p>No Submissions Found</p>
                </div>}
        </div>
    );
};

export default ListingSubmissions;


