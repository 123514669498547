import React, { useEffect, useState } from "react";
import Button from "../../../../../components/Button/customButton/Button";
import Search from "../../../../../components/Search/Search";
import own_styles from "./manageFaculties.module.css";
import styles from "../generalStyles.module.css";
import ChevronIcon from "../../../../../assets/icons/chevron.icon";
import CreateFacultyModal from "../../../../../components/Modals/CreateFacultyModal/CreateFacultyModal";
import { useSelector } from "react-redux";
import { useFaculty } from "../../../../../query/admin/useFaculty";
import { IonSpinner } from "@ionic/react";

export default function ManageFaculties() {
  /** State management */
  const facultiesData = useSelector((state) => state.institution.facultyData);
  const [facultyFilterData, setFacultyFilterData] = useState(facultiesData);
  const [searchValue, setSearchValue] = useState("");
  const [isCreatingFaculty, setIsCreatingFaculty] = useState(false);
  const [isEditingFaculty, setIsEditingFaculty] = useState(false);

  const { getFaculty, searchFaculty } = useFaculty();

  const [selectedFaculty, setSelectedFaculty] = useState("");

  /** Loading state management */
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    getFaculty();
  }, []);

  useEffect(() => {
    if (searchValue !== "") {
      setIsSearching(true);
      searchFaculty(searchValue).then((response) => {
        if (response) {
          setFacultyFilterData(response);
        }
        setIsSearching(false);
      });
    } else {
      setFacultyFilterData(facultyFilterData);
    }
  }, [searchValue]);

  return (
    <div>
      <div className={`${styles["header-container"]}`}>
        <h2>Manage Faculties</h2>

        <div className={`${styles["action-container"]}`}>
          <Button
            text={"Add New Faculty"}
            onClick={() => setIsCreatingFaculty(true)}
            width={"fit-content"}
            paddingBlock={20}
          />
          <div style={{ maxWidth: 300 }}>
            <Search
              placeholder={"Find faculty"}
              value={searchValue}
              setValue={setSearchValue}
            />
          </div>
        </div>
      </div>

      {/* Faculty Table */}
      <div className={`${own_styles["table-container"]}`}>
        <table>
          <thead>
            <tr className={`${styles["table-row"]}`}>
              <th className={`${own_styles["table-header"]}`}>
                <div className={`${styles["first-square"]}`}>
                  <div className={`${styles["check-box"]}`}></div>
                  <p>Name</p>
                </div>
              </th>
              <th className={`${own_styles["table-header"]}`}>Abbreviations</th>
              <th className={`${own_styles["table-header"]}`}>Description</th>
            </tr>
          </thead>
          {isSearching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "70%",
                height: "20vh",
              }}
            >
              <IonSpinner
                name="lines"
                color={"dark"}
                style={{ width: 50, height: 50 }}
              />
            </div>
          ) : (
            <tbody>
              {facultyFilterData?.results?.map((data, index) => {
                return (
                  <tr
                    key={index}
                    onClick={() => {
                      setSelectedFaculty(data.id);
                      setIsEditingFaculty(true);
                    }}
                    className={`${styles["table-row-container"]}`}
                  >
                    <td
                      className={`${styles["table-row"]}`}
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className={`${styles["check-box"]}`}
                        style={{ marginInlineEnd: 16 }}
                      ></div>
                      <p style={{ whiteSpace: "nowrap" }}>{data.name}</p>
                    </td>
                    <td className={` ${styles["table-row"]}`}>
                      <p>{data.abbrev}</p>
                    </td>
                    <td className={` ${styles["table-row"]}`}>
                      <p>{data.description}</p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
      <div className={`${styles["pagination-container"]}`}>
        <p>
          Page <span style={{ fontWeight: "bold" }}>1/1</span> Total:{" "}
          <span style={{ fontWeight: "bold" }}>
            {facultyFilterData.count ?? 0} Faculties
          </span>
        </p>
        <div className={`${styles["btn-container"]}`}>
          <div className={`${styles.icons} ${styles["left-icon"]}`}>
            <ChevronIcon />
          </div>
          <div className={`${styles.icons} ${styles["right-icon"]}`}>
            <ChevronIcon />
          </div>
        </div>
      </div>
      {isCreatingFaculty && (
        <CreateFacultyModal
          setViewFacultyModal={setIsCreatingFaculty}
          facultyId={""}
        />
      )}

      {isEditingFaculty && (
        <CreateFacultyModal
          setViewFacultyModal={setIsEditingFaculty}
          facultyId={selectedFaculty}
        />
      )}
    </div>
  );
}
