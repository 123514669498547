import { useDispatch } from "react-redux";
import client from "../../utils/api/axios";
import { useSelector } from "react-redux";
import { institutionSlice } from "../../redux/features/institutionSlice";
import { useIonToast } from "@ionic/react";

export function useTutors() {
  /** State management */
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);
  const [toast] = useIonToast();

  async function createTutor(tutor) {
    return await client
      .post("/api/teacher/", tutor, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then(() => {
        getTutorsRecord();
        return true;
      })
      .catch((error) => {
        console.log(error);
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          duration: 3000,
          buttons: ["Ok"],
        });
        return null;
      });
  }

  async function getTutorsRecord() {
    client
      .get("/api/teacher/", {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        const result = response.data;
        if (result) {
          dispatch(institutionSlice.actions.setTutorsRecords(result));
        }
        return response.data;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          duration: 3000,
          buttons: ["Ok"],
        });
        return null;
      });
  }

  /**
   * updateFacultyInfo Updates faculty-member information using faculty-member id
   * @param tutor faculty-member object to be updated
   */
  async function updateTutorInfo(tutor, tutorId) {
    return await client
      .put(`/api/teacher/${tutorId}/`, tutor, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then(() => {
        toast({
          message: "Tutor information updated Successfully!",
          color: "success",
          duration: 3000,
          buttons: ["Ok"],
        });

        return true;
      })
      .catch((error) => {
        toast({
          message: `Error Updating Tutor Info: ${error.message}`,
          color: "danger",
          duration: 3000,
          buttons: ["Ok"],
        });
        return null;
      });
  }

  async function getSingleTutorInfo(tutorId) {
    return await client
      .get(`/api/teacher/${tutorId}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          duration: 3000,
          buttons: ["Ok"],
        });
        return null;
      });
  }
  async function getFilteredTutorsRecord(
    gender,
    faculty_name,
    department_name,
    orderByCreatedAt
  ) {
    return await client
      .get("/api/teacher/", {
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          gender,
          faculty_name,
          department_name,
          order: orderByCreatedAt,
        },
      })
      .then((response) => {
        const result = response.data;

        return result;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          duration: 3000,
          buttons: ["Ok"],
        });
        return null;
      });
  }

  async function searchTutor(keyword) {
    return await client
      .get("/api/teacher/", {
        params: {
          keyword,
        },
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          duration: 3000,
          buttons: ["Ok"],
        });
        return null;
      });
  }

  async function deleteTutor(tutorId) {
    return await client
      .delete(`/api/teacher/${tutorId}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then(() => {
        getTutorsRecord();
        return true;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          duration: 3000,
          buttons: ["Ok"],
        });
        return null;
      });
  }

  return {
    createTutor,
    getTutorsRecord,
    updateTutorInfo,
    getSingleTutorInfo,
    getFilteredTutorsRecord,
    searchTutor,
    deleteTutor,
  };
}
