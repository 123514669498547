import React from "react";

export default function SubscriptionIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M4.615 21q-.69 0-1.152-.462Q3 20.075 3 19.385v-8.77q0-.69.463-1.152Q3.925 9 4.615 9h14.77q.69 0 1.152.463q.463.462.463 1.152v8.77q0 .69-.462 1.152q-.463.463-1.153.463zm0-1h14.77q.23 0 .423-.192q.192-.193.192-.423v-8.77q0-.23-.192-.423q-.193-.192-.423-.192H4.615q-.23 0-.423.192q-.192.193-.192.423v8.77q0 .23.192.423q.193.192.423.192m5.885-1.923L15.115 15L10.5 11.923zM4.5 7.23v-1h15v1zm3-2.77v-1h9v1zM4 20V10z"
      />
    </svg>
  );
}
