import React, { useEffect, useState } from "react";
import Button from "../../../../../components/Button/customButton/Button";
import Search from "../../../../../components/Search/Search";
import ChevronIcon from "../../../../../assets/icons/chevron.icon";
import own_styles from "./courses.module.css";
import styles from "../generalStyles.module.css";
import CreateCourseModal from "../../../../../components/Modals/CreateCourseModal/CreateCourseModal";
import { useSelector } from "react-redux";
import DropDownS from "../../../../../components/Inputs/DropdownS/DropDownS";
import { useCourse } from "../../../../../query/admin/useCourse";
import { useFaculty } from "../../../../../query/admin/useFaculty";
import Loader from "../../../../../components/Loader/Loader";
import { useDepartment } from "../../../../../query/admin/useDepartment";
import { IonSpinner } from "@ionic/react";

export default function Courses() {
  /** State management */
  const [filterFaculty, setFilterFaculty] = useState("");
  const [filterDepartment, setFilterDepartment] = useState("");
  const [filterLevel, setFilterLevel] = useState("");

  const facultyData = useSelector((state) => state.institution.facultyData);
  const [departmentData, setDepartmentData] = useState([]);
  const [levelData, setLevelData] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const courseData = useSelector((state) => state.institution.courseData);
  const [courseFilterData, setCourseFilterData] = useState(courseData);
  const [viewCreateModal, setViewCreateModal] = useState(false);
  const [viewUpdateModal, setViewUpdateModal] = useState(false);
  const [selectedCourseId, setSelectedCourseId] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const { getFilteredCourses, searchCourse } = useCourse();
  const { getLevelsForFaculty } = useFaculty();
  const { getFilterDepartments } = useDepartment();

  useEffect(() => {
    if (searchValue !== "") {
      setIsSearching(true);
      searchCourse(searchValue).then((response) => {
        if (response) {
          setCourseFilterData(response);
        }
        setIsSearching(false);
      });
    } else {
      setCourseFilterData(courseData);
    }
  }, [searchValue]);

  useEffect(() => {
    setIsLoading(true);
    getFilteredCourses(`${filterFaculty}`, filterDepartment, filterLevel).then(
      (response) => {
        if (response) {
          setCourseFilterData(response);
        }
        setIsLoading(false);
      }
    );
  }, [filterFaculty, filterDepartment, filterLevel]);

  useEffect(() => {
    if (filterFaculty !== "") {
      setIsLoading(true);
      getFilterDepartments(filterFaculty).then((response) => {
        if (response) {
          setDepartmentData(response.results);
        }
        setIsLoading(false);
      });

      const facultyId = facultyData.results.filter((faculty) => {
        return faculty.name === filterFaculty;
      })[0]?.id;
      getLevelsForFaculty(facultyId).then((response) => {
        if (response) {
          setLevelData(response);
        }
      });
    } else {
      setDepartmentData([]);
      setLevelData([]);
    }
  }, [filterFaculty]);

  function handleCourseFiltering() {}

  console.log("Filtered courses", departmentData);

  return (
    <div>
      {isLoading && <Loader />}
      <div className={`${styles["header-container"]}`}>
        <h2>Manage Courses</h2>

        <div className={`${styles["action-container"]}`}>
          <Button
            text={"Add New Course"}
            onClick={() => setViewCreateModal(true)}
            width={"fit-content"}
            paddingBlock={20}
          />
          <DropDownS
            value={filterFaculty}
            setValue={setFilterFaculty}
            placeholder={"Filter By Faculty"}
            data={facultyData.results}
            keyItem={"name"}
          />
          <DropDownS
            value={filterDepartment}
            setValue={setFilterDepartment}
            placeholder={"Filter By Department"}
            data={departmentData}
            keyItem={"name"}
          />
          <DropDownS
            value={filterLevel}
            setValue={setFilterLevel}
            placeholder={"Select Level"}
            data={levelData}
            keyItem={"id"}
          />
          <div style={{ maxWidth: 300 }}>
            <Search
              placeholder={"Find Course"}
              value={searchValue}
              setValue={setSearchValue}
            />
          </div>
        </div>
      </div>

      {/* Course Table */}
      <div className={`${styles["table-container"]}`}>
        <table>
          <thead>
            <tr className={`${styles["table-row"]}`}>
              <th className={`${styles["table-header"]}`}>
                <div className={`${styles["first-square"]}`}>
                  <div className={`${styles["check-box"]}`}></div>
                  <p>Course Name</p>
                </div>
              </th>
              <th className={`${styles["table-header"]}`}>Course code</th>
              <th className={`${styles["table-header"]}`}>Status</th>
              <th className={`${styles["table-header"]}`}>Faculty</th>
              <th className={`${styles["table-header"]}`}>Department</th>
              <th className={`${styles["table-header"]}`}>Level</th>
            </tr>
          </thead>

          {isSearching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "70%",
                height: "20vh",
              }}
            >
              <IonSpinner
                name="lines"
                color={"dark"}
                style={{ width: 50, height: 50 }}
              />
            </div>
          ) : (
            <tbody>
              {courseFilterData.results?.map((course, index) => {
                return (
                  <tr
                    key={index}
                    className={`${styles["table-row-container"]}`}
                    onClick={() => {
                      setSelectedCourseId(course.id);
                      setViewUpdateModal(true);
                    }}
                  >
                    <td
                      className={`${styles["table-row"]}`}
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className={`${styles["check-box"]}`}
                        style={{ marginInlineEnd: 16 }}
                      ></div>
                      <p style={{ whiteSpace: "nowrap" }}>
                        {course.course_title}
                      </p>
                    </td>
                    <td className={` ${styles["table-row"]}`}>
                      <p>{course.course_code}</p>
                    </td>
                    <td className={` ${styles["table-row"]}`}>
                      <p>{course.course_status}</p>
                    </td>
                    <td>
                      {course.faculty_details.name.length > 20
                        ? course.faculty_details.abbrev
                        : course.faculty_details.name}
                    </td>
                    <td className={` ${styles["table-row"]}`}>
                      <p>
                        {course.department_details.name.length > 20
                          ? course.department_details.abbrev
                          : course.department_details.name}
                      </p>
                    </td>
                    <td className={` ${styles["table-row"]}`}>
                      <p>{course.course_level}</p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
      <div className={`${styles["pagination-container"]}`}>
        <p>
          Page <span style={{ fontWeight: "bold" }}>1/1</span> Total:{" "}
          <span style={{ fontWeight: "bold" }}>
            {courseFilterData.count ?? 0} Courses
          </span>
        </p>
        <div className={`${styles["btn-container"]}`}>
          <div className={`${styles.icons} ${styles["left-icon"]}`}>
            <ChevronIcon />
          </div>
          <div className={`${styles.icons} ${styles["right-icon"]}`}>
            <ChevronIcon />
          </div>
        </div>
      </div>
      {viewCreateModal && (
        <CreateCourseModal
          setViewCourseModal={setViewCreateModal}
          courseId={""}
        />
      )}

      {viewUpdateModal && (
        <CreateCourseModal
          setViewCourseModal={setViewUpdateModal}
          courseId={selectedCourseId}
        />
      )}
    </div>
  );
}
