import React, { useState } from "react";
import styles from "./faqs.module.css";
import { FAQs } from "../../../../utils/DATA/FAQs";

export default function Faqs() {
  /** state management */
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <div className={`${styles.faq_container}`}>
      <div className={styles.container}>
        <h4 className={`${styles.faq_header}`}>FAQ</h4>
        <p className={`${styles.faq_desc}`}>
          The Reasons Why We Keep Growing Stronger
        </p>
      </div>
      <ul className={`${styles.faq_main_card_container}`}>
        {FAQs.map((faq, index) => {
          return (
            <FAQCard
              key={index}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              index={index}
              faq={faq}
            />
          );
        })}
      </ul>
    </div>
  );
}

function FAQCard({ index, setActiveIndex, activeIndex, faq }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <li className={`${styles.faq_card_container}`}>
      <h4
        className={`${styles.faq_card_heading}`}
        onClick={() => {
          setActiveIndex(index);
          setIsOpen((prevState) => !prevState);
        }}
      >
        {index + 1}. {faq?.question}
      </h4>
      {(activeIndex === index || isOpen) && (
        <p className={`${styles.faq_card_content}`}>{faq?.answer}</p>
      )}
    </li>
  );
}
