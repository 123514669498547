import { useDispatch, useSelector } from "react-redux";
import client from "../../utils/api/axios";
import { userSlice } from "../../redux/features/userSlice";
import { storeDataToLocalStorage } from "../../utils/functions/handleLocalStorage";
import { useIonToast } from "@ionic/react";

export function useCourses() {
  /** State management */
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);
  const [toast] = useIonToast();

  async function handleSearchCourse(keyword) {
    client
      .get(`/api/tutor-search/course/?keyword=${keyword}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data) {
          dispatch(
            userSlice.actions.updateUserState({
              courses_detail: data.courses,
            })
          );
        }
        return response;
      })
      .catch((error) => {
        return alert(`Error: ${error.message}`);
      });
  }

  async function handleCreateClassGroup(data) {
    const response = client
      .post(`/api/courses/${data.courseId}/create-group/`, data, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return alert(`Error: ${error.message}`);
      });
    return response
  }

  async function handleGetCourseGroups() {
    const response = await client
      .get(`/api/list-groups/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return console.error(`Error: ${error.message}`);
      });
    return response?.data
  }

  async function handleGetGroupDetails(id) {
    const response = await client
      .get(`/api/groups/${id}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return console.error(`Error: ${error.message}`);
      });
    return response?.data
  }

  async function handleGetGroupMessages(id) {
    const response = await client
      .get(`/api/groups/${id}/messages/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return console.error(`Error: ${error.message}`);
      });
    return response?.data
  }

  async function handleCreateClassGroupMessage(data) {
    const response = client
      .post(`/api/groups/${data.id}/send-message/`, data, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return alert(`Error: ${error.message}`);
      });
    return response
  }

  return {
    handleSearchCourse,
    handleCreateClassGroup,
    handleGetCourseGroups,
    handleGetGroupDetails,
    handleGetGroupMessages,
    handleCreateClassGroupMessage
  };
}
