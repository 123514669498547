import React, { useEffect, useState } from "react";
import TextInput from "../../../../../components/Inputs/TextInput/TextInput";
import Button from "../../../../../components/Button/customButton/Button";
import styles from "./CreateAssessment.module.css"
import Dropdown from "../../../../../components/Inputs/Dropdown/Dropdown";
import { useAssessments } from "../../../../../query/tutor/useAssessments";
import { useIonToast } from "@ionic/react";

const assessmentTypes = [
    "ca",
    "exam"

]

const assessmentStructure = [
    "mcq",
    "text"
]

export default function CreateAssessmentModal({ setViewCourseModal, courses }) {
    const [toast] = useIonToast();
    const { handleCreateAssessments } = useAssessments()
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [course, setCourse] = useState("");
    const [assessment_type, setAssessmentType] = useState("");
    const [assessment_structure, setAssessmentStructure] = useState("");
    const [duration, setDuration] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    async function handleCreateNewJob() {
        try {
            if (title && description && course && assessment_type && assessment_structure && duration) {
                setIsLoading(true);
                const data = {
                    title,
                    description,
                    course,
                    assessment_type,
                    structure: assessment_structure,
                    duration
                };
                await handleCreateAssessments(data).then(() => {
                    setViewCourseModal(false);
                    setIsLoading(false);
                })
            } else {
                toast({
                    message: `All fields are required`,
                    color: "danger",
                    buttons: ["Ok"],
                });
            }
        } catch (error) {
            console.error(error)
            setIsLoading(false)
        }


    }

    return (
        <div className={`${styles.container}`}>
            <div className={`${styles.sub_container}`}>
                <div
                    style={{ position: "absolute", right: 20, top: 0 }}
                    onClick={() => !isLoading && setViewCourseModal(false)}
                >
                    <p style={{ fontSize: 50, cursor: "pointer", color: "red" }}>
                        &times;
                    </p>
                </div>
                <h2 className={`${styles.title}`}>Add New Assesment</h2>
                <div className={`${styles.form_container}`}>

                    <div>
                        <TextInput
                            label={"Assessment Title"}
                            placeholder={"e.g Continuous Assessment"}
                            value={title}
                            setValue={setTitle}
                            disabled={isLoading}
                        />
                    </div>

                    <div>
                        <TextInput
                            label={"Assessment Description"}
                            placeholder={"e.g First Semester"}
                            value={description}
                            setValue={setDescription}
                            disabled={isLoading}
                        />
                    </div>
                    <div>
                        <Dropdown
                            label={"Course"}
                            placeholder={"select course for assessment"}
                            value={course}
                            setValue={setCourse}
                            data={courses}
                            disabled={isLoading}
                            keyItem={"id"}
                        />
                    </div>

                    <div>
                        <Dropdown
                            label={"Select Assessment Type"}
                            placeholder={"mcq"}
                            value={assessment_type}
                            setValue={setAssessmentType}
                            data={assessmentTypes}
                            disabled={isLoading}
                            keyItem={"assessmentType"}
                        />
                    </div>

                    <div>
                        <Dropdown
                            label={"Select Assessment Structure"}
                            placeholder={"mcq"}
                            value={assessment_structure}
                            setValue={setAssessmentStructure}
                            data={assessmentStructure}
                            disabled={isLoading}
                            keyItem={"assessmentStructure"}
                        />
                    </div>

                    <div>
                        <TextInput
                            label={"Assessment Duration"}
                            placeholder={"e.g 60"}
                            value={duration}
                            setValue={setDuration}
                            disabled={isLoading}
                        />
                    </div>
                </div>

                {
                    <div
                        style={{
                            marginTop: 50,
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Button
                            text={"Save"}
                            onClick={() => handleCreateNewJob()}
                            isLoading={isLoading}
                            disabled={(title === "" &&
                                description === "" &&
                                course === "" &&
                                assessment_type === "") ||
                                isLoading}
                            width={"fit-content"}
                        />
                    </div>
                }
            </div>
        </div>
    );
}
