import React from "react";
import Button from "../Button/customButton/Button";
import styles from "./navbar.module.css";
import MainLogo from "../logo/MainLogo";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

export default function NavBar() {
  /** State management */
  const navigate = useNavigate();
  const isAuth = useSelector((state) => state.user.globalAuth);
  const user = useSelector((state) => state.user.user);
  return (
    <nav className={`${styles.navbar_container}`}>
      <div className={styles.container}>
        <MainLogo />

        <div className={`${styles.navbar_subcontainer}`}>
          <ul className={`${styles.navbar_list}`}>
            <li>
              <NavLink
                style={{ textDecoration: "none" }}
                className={`${styles.link}`}
                to={"/"}
              >
                Home
              </NavLink>
            </li>

            <li className={`${styles.link}`}>
              <NavLink
                style={{ textDecoration: "none" }}
                className={`${styles.link}`}
                to={"/academics"}
              >
                Academics
              </NavLink>
            </li>
            <li className={`${styles.link}`}>
              <NavLink
                style={{ textDecoration: "none" }}
                className={`${styles.link}`}
                to={"/admissions"}
              >
                Admission
              </NavLink>
            </li>
            <li>
              <NavLink
                style={{ textDecoration: "none" }}
                className={`${styles.link}`}
                to={"/blogs"}
              >
                Activities
              </NavLink>
            </li>
            <li>
              <NavLink
                style={{ textDecoration: "none" }}
                className={`${styles.link}`}
                to={"/"}
                state={{ direction: "contact" }}
              >
                Contact Us
              </NavLink>
            </li>
          </ul>

          <div className={`${styles.btn_container}`}>
            {!isAuth ? (
              <>
                <Button
                  text={"Log In"}
                  backgroundColor={"transparent"}
                  borderColor={"white"}
                  color={"white"}
                  border={1}
                  paddingBlock={10}
                  paddingInline={18}
                  onClick={() => navigate("/login")}
                />
                <Button
                  text={"Apply Now"}
                  backgroundColor={"#800080"}
                  paddingBlock={10}
                  paddingInline={18}
                  onClick={() => navigate("/register")}
                />
              </>
            ) : (
              <Button
                text={"Dashboard"}
                backgroundColor={"#800080"}
                paddingBlock={10}
                paddingInline={18}
                onClick={() =>
                  navigate(
                    user.role === "superadmin"
                      ? "/admin/dashboard"
                      : user.role === "teacher"
                      ? "/tutor/dashboard"
                      : "/dashboard/main"
                  )
                }
              />
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}
