import React from "react";
import styles from "./upcomingEvent.module.css";
import ArrowIcon from "../../../../../../../assets/icons/arrow.icon";
import { Link } from "react-router-dom";

export default function UpcomingEvents() {
  const textMessage = "Environmental Discussion";

  return (
    <div className={`${styles["main-event-container"]}`}>
      <h2 className={`${styles["header"]}`}>Upcoming Event</h2>

      <div className={`${styles["main-event-sub_container"]}`}>
        {Array(3)
          .fill(0)
          .map((item, index) => {
            return (
              <div className={`${styles["event-container"]}`} key={index}>
                <div className={`${styles["event-left-border"]}`}></div>
                <div
                  className={`${styles["event-container-message-container"]}`}
                >
                  <p className={`${styles["message-overview"]}`}>
                    {textMessage.length > 50
                      ? textMessage.substring(0, 38) + "..."
                      : textMessage}
                  </p>
                  <p className={`${styles["create-at"]}`}>01:00PM - 02:00 PM</p>
                </div>
              </div>
            );
          })}
      </div>

      <div className={`${styles["view-more-container"]}`}>
        <div className={`${styles["view-more"]}`}>
          <Link to={"/admin/chats"} style={{ textDecoration: "none" }}>
            <p>
              View all{" "}
              <span>
                <ArrowIcon />
              </span>
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
}
