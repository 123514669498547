import React, { useEffect, useState } from "react";
import styles from "../../coursesAndPrograms.module.css";
import Search from "../../../../../../components/Search/Search";
import Button from "../../../../../../components/Button/customButton/Button";
import TrashIcon from "../../../../../../assets/icons/trash.icon";
import { useSelector } from "react-redux";
import { useCourse } from "../../../../../../query/admin/useCourse";
import { IonSpinner } from "@ionic/react";

export default function RegisteredCourses({ setRegisterCourse }) {
  /** State management */
  const [searchValue, setSearchValue] = useState("");
  const [selectedCourseId, setSelectedCourseId] = useState("");
  const [viewDropCoursePopUp, setViewDropCoursePopUp] = useState(false);

  const courseData = useSelector((state) => state.institution.courseData);
  const registeredCourses = useSelector(
    (state) => state.student.registeredCourses
  );
  const registeredCourseId = registeredCourses?.map((course) => course.id);
  const [courseFilterData, setCourseFilterData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const { searchCourse, getCourses, registerCourse, getRegisteredCourses } =
    useCourse();

  useEffect(() => {
    if (searchValue !== "") {
      setIsSearching(true);
      searchCourse(searchValue).then((response) => {
        if (response) {
          setCourseFilterData(response);
        }
        setIsSearching(false);
      });
    } else {
      setCourseFilterData(courseData);
    }
  }, [searchValue]);

  useEffect(() => {
    setIsLoading(true);
    getCourses().then((response) => {
      if (response) {
        setCourseFilterData(response);
      }
      setIsLoading(false);
    });
  }, []);

  function handleRegisterCourse(courseId) {
    setIsRegistering(true);
    registerCourse(courseId).then((response) => {
      if (response) {
        getRegisteredCourses();
      }
      setIsRegistering(false);
    });
  }

  return (
    <div>
      <h2>Register Course</h2>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBlock: 20,
        }}
      >
        <Button
          text={"View Registered Courses"}
          width={"fit-content"}
          onClick={() => setRegisterCourse(false)}
        />

        <div>
          <Search
            value={searchValue}
            setValue={setSearchValue}
            placeholder={"Find Course"}
          />
        </div>
      </div>

      <div className={`${styles["table-container"]}`}>
        <table>
          <thead>
            <tr className={`${styles["table-row"]}`}>
              <th className={`${styles["table-header"]}`}>
                <div className={`${styles["first-square"]}`}>
                  <div className={`${styles["check-box"]}`}></div>
                  <p>Course Name</p>
                </div>
              </th>
              <th className={`${styles["table-header"]}`}>Course code</th>
              <th className={`${styles["table-header"]}`}>Status</th>
              <th className={`${styles["table-header"]}`}>Actions</th>
            </tr>
          </thead>
          {isSearching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "70%",
                height: "20vh",
              }}
            >
              <IonSpinner
                name="lines"
                color={"dark"}
                style={{ width: 50, height: 50 }}
              />
            </div>
          ) : (
            <tbody>
              {courseFilterData?.results?.map((course, index) => {
                return (
                  <tr
                    key={index}
                    className={`${styles["table-row-container"]}`}
                    onClick={() => {
                      setSelectedCourseId(course.id);
                      setViewDropCoursePopUp(true);
                    }}
                  >
                    <td
                      className={`${styles["table-row"]}`}
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className={`${styles["check-box"]}`}
                        style={{ marginInlineEnd: 16 }}
                      ></div>
                      <p style={{ whiteSpace: "nowrap" }}>
                        {course.course_title}
                      </p>
                    </td>
                    <td className={` ${styles["table-row"]}`}>
                      <p>{course.course_code}</p>
                    </td>
                    <td className={` ${styles["table-row"]}`}>
                      <p>{course.course_status}</p>
                    </td>
                    <td className={` ${styles["table-row"]}`}>
                      <Button
                        text={
                          registeredCourseId.includes(course.id)
                            ? "Course Registered"
                            : "Register Course"
                        }
                        backgroundColor={"#951b95"}
                        width={"fit-content"}
                        paddingBlock={10}
                        paddingInline={24}
                        onClick={() => handleRegisterCourse(course.id)}
                        disabled={
                          isRegistering ||
                          registeredCourseId.includes(course.id)
                        }
                        isLoading={isRegistering}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
}
