export const storeDataToLocalStorage = async (key, value) => {
  try {
    const jsonValue = JSON.stringify(value);
    await localStorage.setItem(`@${key}`, jsonValue);
  } catch (e) {
    console.log("Error storing value to storage", e.message);
  }
};

export const getDataFromLocalStorage = (key) => {
  try {
    const jsonValue = localStorage.getItem(`@${key}`);
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    // error reading value
    console.log("Error retrieving value to storage", e.message);
  }
};

export const removDataFromLocalStorage = async (key) => {
  try {
    await localStorage.removeItem(`@${key}`);

    console.log("Done.");
    return true;
  } catch (e) {
    // remove error
    console.log("Error deleting value to storage", e.message);
    return null;
  }
};
