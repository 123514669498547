import React, { useState, useEffect } from "react";
import SubscriptionIcon from "../../../../../assets/icons/subscription.icon";
import GroupPeopleIcon from "../../../../../assets/icons/group-people.icon";
import { useSelector } from "react-redux";
import styles from "./Courses.module.css";
import globalstyles from "../../../admin/components/generalStyles.module.css";
import { useNavigate } from "react-router-dom";
import Search from "../../../../../components/Search/Search";
import ChevronIcon from "../../../../../assets/icons/chevron.icon";
import { useTutorAuth } from "../../../../../query/tutor/auth";
import { useCourses } from "../../../../../query/tutor/useCourses";
import Loader from "../../../../../components/Loader/Loader";

const Courses = () => {
  const navigate = useNavigate();
  const { getTutorInfo } = useTutorAuth();
  const { handleSearchCourse } = useCourses();
  const userInfo = useSelector((state) => state.user);
  const numOfStudents = useSelector((state) => state.institution.studentData);

  const [isLoading, setIsLoading] = useState(false);
  const [coursesData, setCoursesData] = useState([]);
  const [filterdCoursesData, setFilteredCoursesData] = useState([]);

  const [selectedFaculty, setSelectedFaculty] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");

  const [searchText, setSearchText] = useState("");

  const getTutorData = async () => {
    if (userInfo.user.id !== undefined) {
      setIsLoading(true)
      const id = userInfo.user.id;
      getTutorInfo(id).then(() => setIsLoading(false))
      return;
    }
  };

  const filterCourses = () => {
    const filtered = coursesData.filter((item) => {
      // Check each condition separately and use logical OR (||)
      const facultyMatch =
        !selectedFaculty || item.faculty_details.name === selectedFaculty;
      const departmentMatch =
        !selectedDepartment ||
        item.department_details.name === selectedDepartment;
      const levelMatch = !selectedLevel || item.course_level === selectedLevel;

      // Return true if at least one condition is met
      return facultyMatch && departmentMatch && levelMatch;
    });

    setFilteredCoursesData(filtered);
  };

  const searchCourses = async () => {
    await handleSearchCourse(searchText);
  };

  useEffect(() => {
    getTutorData();
  }, []);

  useEffect(() => {
    setCoursesData(userInfo.user?.courses_detail);
  }, [userInfo.user]);

  useEffect(() => {
    filterCourses();
  }, [selectedFaculty, selectedDepartment, selectedLevel]);

  useEffect(() => {
    searchCourses();
    return;
  }, [searchText]);

  const facultyOptions =
    coursesData?.length > 0
      ? coursesData.map((item) => item.faculty_details.name)
      : [];

  const departmentOptions =
    coursesData?.length > 0
      ? coursesData.map((item) => item.department_details.name)
      : [];

  const levelsOptions =
    coursesData?.length > 0 ? coursesData.map((item) => item.course_level) : [];

  const CardOptions = [
    {
      title: "Courses",
      icon: <SubscriptionIcon color="white" />,
      color: "#BF39BF",
      data: coursesData?.length,
    },

    // {
    //   title: "Students",
    //   icon: <GroupPeopleIcon color="white" />,
    //   color: "#800080",
    //   data: numOfStudents.count ?? 0,
    // },
  ];
  return (
    <div>
      {isLoading && <Loader />}
      <div>
        <h2>Manage Courses</h2>
        <div className={styles.statistics_row_container}>
          {CardOptions.map((item, index) => {
            return (
              <div className={styles.card} key={index}>
                <div className={index === 0 ? styles.videoicon : styles.groupicon}>
                  {item.icon}
                </div>
                <div className={styles.details}>
                  <h3>{item.title}</h3>
                  <p>{item.data}</p>
                </div>
              </div>
            );
          })}
        </div>
        <div className={styles.filter_row_container}>
          {/* <DropDownS
            data={facultyOptions}
            placeholder="Filter By Faculty"
            setValue={setSelectedFaculty}
          />
          <DropDownS
            data={departmentOptions}
            placeholder="Filter By Department"
            setValue={setSelectedDepartment}
          />
          <DropDownS
            data={levelsOptions}
            placeholder="Select Level"
            setValue={setSelectedLevel}
          /> */}
          {/* <div style={{}}>
            <Search
              placeholder={"Find Course"}
              value={searchText}
              setValue={setSearchText}
            />
          </div> */}
        </div>
        <div>
          <div className={`${globalstyles["table-container"]}`}>
            <table>
              <thead>
                <tr className={`${globalstyles["table-row"]}`}>
                  <th className={`${globalstyles["table-header"]}`}>
                    <div className={`${globalstyles["first-square"]}`}>
                      {/* <div className={`${globalstyles["check-box"]}`}></div> */}
                      <p>Course Name</p>
                    </div>
                  </th>
                  <th className={`${globalstyles["table-header"]}`}>
                    Course Code
                  </th>
                  <th className={`${globalstyles["table-header"]}`}>Status</th>
                  <th className={`${globalstyles["table-header"]}`}>Faculty</th>
                  <th className={`${globalstyles["table-header"]}`}>
                    Department
                  </th>
                  <th className={`${globalstyles["table-header"]}`}>Level</th>
                  {/* <th className={`${globalstyles["table-header"]}`}>Action</th> */}
                </tr>
              </thead>

              <tbody>
                {filterdCoursesData?.length > 0 ? (
                  filterdCoursesData?.map((item, index) => {
                    return (
                      <tr
                        key={index}
                        className={`${globalstyles["table-row-container"]}`}
                        onClick={() =>
                          navigate(
                            `/tutor/course/${item.course_title}/${item.id}/students`
                          )
                        }
                      >
                        <td
                          className={`${globalstyles["table-row"]}`}
                          style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className={`${globalstyles["check-box"]}`}
                            style={{ marginInlineEnd: 16 }}
                          ></div>
                          <p style={{ whiteSpace: "nowrap" }}>
                            {item.course_title}
                          </p>
                        </td>
                        <td className={` ${globalstyles["table-row"]}`}>
                          <p>{item.course_code}</p>
                        </td>
                        <td className={` ${globalstyles["table-row"]}`}>
                          <p>{item.course_status}</p>
                        </td>
                        <td>{item.faculty_details.abbrev}</td>
                        <td>{item.department_details.abbrev}</td>
                        <td className={` ${globalstyles["table-row"]}`}>
                          {item.course_level}
                        </td>
                        {/* <td className={` ${globalstyles["table-row"]}`}>
                          <button className={styles.createAssessmentBtn}>Create Assessment</button>
                        </td> */}
                      </tr>
                    );
                  })
                ) : coursesData && coursesData.length > 0 ? (
                  coursesData.map((item, index) => {
                    return (
                      <tr
                        key={index}
                        className={`${globalstyles["table-row-container"]}`}
                        onClick={() =>
                          navigate(
                            `/tutor/course/${item.course_title}/${item.id}/students`
                          )
                        }
                      >
                        <td
                          className={`${globalstyles["table-row"]}`}
                          style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          {/* <div
                            className={`${globalstyles["check-box"]}`}
                            style={{ marginInlineEnd: 16 }}
                          ></div> */}
                          <p style={{ whiteSpace: "nowrap" }}>
                            {item.course_title}
                          </p>
                        </td>
                        <td className={` ${globalstyles["table-row"]}`}>
                          <p>{item.course_code}</p>
                        </td>
                        <td className={` ${globalstyles["table-row"]}`}>
                          <p>{item.course_status}</p>
                        </td>
                        <td>{item.faculty_details.abbrev}</td>
                        <td>{item.department_details.abbrev}</td>
                        <td className={` ${globalstyles["table-row"]}`}>
                          {item.course_level}
                        </td>
                        {/* <td className={` ${globalstyles["table-row"]}`}>
                          <button className={styles.createAssessmentBtn}>Create Assessment</button>
                        </td> */}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={6}>No Course Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className={`${globalstyles["pagination-container"]}`}>
            <p>
              Page <span style={{ fontWeight: "bold" }}>1/1</span> Total:{" "}
              <span style={{ fontWeight: "bold" }}>
                {coursesData?.length ?? 0} Courses
              </span>
            </p>
            <div className={`${globalstyles["btn-container"]}`}>
              <div
                className={`${globalstyles.icons} ${globalstyles["left-icon"]}`}
              >
                <ChevronIcon />
              </div>
              <div
                className={`${globalstyles.icons} ${globalstyles["right-icon"]}`}
              >
                <ChevronIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Courses;
