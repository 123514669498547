import React from "react";
import styles from "./Jobcard.module.css"
import { format } from 'timeago.js';
import Location from "../../../../../../assets/icons/location.icon";
import Time from "../../../../../../assets/icons/time.icon";
import TimeAgo from "../../../../../../assets/icons/timeAgo.icon";
import PersonIcon from "../../../../../../assets/icons/person.icon";
import ArrowIcon from "../../../../../../assets/icons/arrow.icon";
import Experience from "../../../../../../assets/icons/experience.icon";

export default function Jobcard({
    jobTitle,
    company,
    city,
    yrsOfExp,
    jobType,
    description,
    date,
    salary,
    link
}) {

    return (
        <div className={styles.jobcardwrapper}>
            <div className={styles.header}>
                <div className={styles.brand}>
                    <div className={styles.imageIcon}>
                        <PersonIcon color="white" />
                    </div>
                    <div className={styles.brandtitle}>
                        <h3>{jobTitle}</h3>
                        <p>{company}</p>
                    </div>
                </div>
                <div className={styles.viewBtn}>
                    <a href={link} target="_blank">View <ArrowIcon /></a>
                </div>
            </div>
            <div className={styles.details}>
                <div className={styles.detailsItem}>
                    <Location />{" "}
                    <p>{city}</p>
                </div>
                <div className={styles.detailsItem}>
                    <Experience />{" "}
                    <p>{yrsOfExp} years exp.</p>
                </div>
                <div className={styles.detailsItem}>
                    <Time />{" "}
                    <p>{jobType}</p>
                </div>
            </div>
            <div className={styles.desc}>
                <p>
                    {description}
                </p>
            </div>
            <div className={styles.cardFooter}>
                <div className={styles.footerTime}>
                    <TimeAgo />
                    <p>Posted {format(date)} </p>
                </div>
                <div>
                    <h4>${salary}k/mo</h4>
                </div>
            </div>
        </div>
    )

}
