import React, { useEffect, useState } from "react";
import Button from "../../../../../../components/Button/customButton/Button";
import styles from "../../coursesAndPrograms.module.css";
import Search from "../../../../../../components/Search/Search";
import TrashIcon from "../../../../../../assets/icons/trash.icon";
import { useCourse } from "../../../../../../query/admin/useCourse";
import GroupPeopleIcon from "../../../../../../assets/icons/group-people.icon";

export default function ManageRegisteredCourse({ setRegisterCourse }) {
  /** State management */
  const [searchValue, setSearchValue] = useState("");
  const [selectedCourseId, setSelectedCourseId] = useState("");
  const [viewDropCoursePopUp, setViewDropCoursePopUp] = useState(false);
  const [courseData, setCourseData] = useState([]);

  const { getRegisteredCourses, dropRegisterCourse } = useCourse();
  const [isLoading, setIsLoading] = useState(false);
  const [isDropping, setIsDropping] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getRegisteredCourses().then((response) => {
      if (response) {
        setCourseData(response);
      }
      setIsLoading(false);
    });
  }, []);

  function handleDropCourse(courseId) {
    setIsDropping(true);
    dropRegisterCourse(courseId).then((response) => {
      if (response) {
        setIsLoading(true);
        getRegisteredCourses().then((response) => {
          if (response) {
            setCourseData(response);
          }
          setIsLoading(false);
        });
      }
      setIsDropping(false);
    });
  }

  return (
    <div>
      <h2>Manage My Courses</h2>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBlock: 20,
        }}
      >
        <Button
          text={"Register New Course"}
          width={"fit-content"}
          onClick={() => setRegisterCourse(true)}
        />

        {/* <div>
          <Search
            value={searchValue}
            setValue={setSearchValue}
            placeholder={"Find Course"}
          />
        </div> */}
      </div>

      <div className={`${styles["table-container"]}`}>
        <table>
          <thead>
            <tr className={`${styles["table-row"]}`}>
              <th className={`${styles["table-header"]}`}>
                <div className={`${styles["first-square"]}`}>
                  <div className={`${styles["check-box"]}`}></div>
                  <p>Course Name</p>
                </div>
              </th>
              <th className={`${styles["table-header"]}`}>Course code</th>
              <th className={`${styles["table-header"]}`}>Status</th>
              <th className={`${styles["table-header"]}`}>Actions</th>
            </tr>
          </thead>

          <tbody>
            {courseData?.length === 0 && (
              <p style={{ color: "black" }}>No registered courses</p>
            )}
            {courseData?.map((course, index) => {
              return (
                <tr
                  key={index}
                  className={`${styles["table-row-container"]}`}
                  onClick={() => {
                    setSelectedCourseId("course.id");
                    setViewDropCoursePopUp(true);
                  }}
                >
                  <td
                    className={`${styles["table-row"]}`}
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className={`${styles["check-box"]}`}
                      style={{ marginInlineEnd: 16 }}
                    ></div>
                    <p style={{ whiteSpace: "nowrap" }}>
                      {course.course_title}
                    </p>
                  </td>
                  <td className={` ${styles["table-row"]}`}>
                    <p>{course.course_code}</p>
                  </td>
                  <td className={` ${styles["table-row"]}`}>
                    <p>{course.course_status}</p>
                  </td>
                  <td
                    className={` ${styles["table-row"]}`}
                    style={{ display: "flex", gap: 8 }}
                  >
                    <Button
                      text={"Drop"}
                      backgroundColor={"#CE2727"}
                      width={"fit-content"}
                      paddingBlock={10}
                      paddingInline={24}
                      leftIcon={<TrashIcon width={20} height={20} />}
                      disabled={isDropping}
                      isLoading={isDropping}
                      onClick={() => {
                        handleDropCourse(course.id);
                      }}
                    />

                    <Button
                      text={"Join Class Group"}
                      // backgroundColor={"#CE2727"}
                      width={"fit-content"}
                      paddingBlock={10}
                      paddingInline={24}
                      leftIcon={<GroupPeopleIcon width={20} height={20} />}
                      disabled={isDropping}
                      isLoading={isDropping}
                      onClick={() => {
                        // handleDropCourse(course.id);
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
