import React from "react";
import styles from "./aboutUs.module.css";
import home_styles from "../../home.module.css";

export default function AboutUs() {
  return (
    <div className={styles.container}>
      <div id="aboutus" className={`${styles.about_us_container}`}>
        <div className={`${styles.about_us_image}`}>
          <img src="/admission_image1.png" />
        </div>
        <div className={`${styles.about_us_desc_container}`}>
          <h2 className={`${home_styles.section_heading_title}`}>ABOUT US</h2>
          <div className={`${styles.detail_container}`}>
            <div className={`${styles.details}`}>
              <h5>Our Vision</h5>
              <p>
                Our vision is to pave the way for a future where innovation knows
                no bounds. With a relentless commitment to pushing boundaries and
                embracing change, we envision a world where creativity flourishes,
                possibilities are endless, and solutions are limitless. Together,
                let's dare to dream and shape a brighter tomorrow.
              </p>
            </div>

            <div className={`${styles.details}`}>
              <h5>Our Mission</h5>
              <p>
                Our mission is to empower individuals and organizations to thrive
                in an ever-evolving world. Through transformative learning
                experiences, collaborative partnerships, and innovative solutions,
                we're on a mission to unlock human potential, drive positive
                change, and inspire growth at every turn. Together, let's make a
                lasting impact and create a better future for all.
              </p>
            </div>

            <div className={`${styles.details}`}>
              <h5>Our Driving Force</h5>
              <p>
                Our driving force stems from a deep-seated passion for innovation
                and a steadfast commitment to making a difference. With
                determination as our compass and resilience as our fuel, we
                navigate through challenges, overcome obstacles, and forge ahead
                towards our goals. Together, let's harness the power of purpose
                and drive meaningful change in the world.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
