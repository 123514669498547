import React from "react";
import styles from "./statsroll.module.css";

export default function StaticsRoll() {
  return (
    <div className={`${styles.stats_container}`}>
      <div className={styles.container}>
        <div className={`${styles.stats}`}>
          <h1>150+</h1>
          <p>Student Graduated</p>
        </div>

        <div className={`${styles.stats}`}>
          <h1>132+</h1>
          <p>Qualified Tutors</p>
        </div>

        <div className={`${styles.stats}`}>
          <h1>12+</h1>
          <p>Courses Offered</p>
        </div>

        <div className={`${styles.stats}`}>
          <h1>1</h1>
          <p>Mission</p>
        </div>
      </div>
    </div>
  );
}
