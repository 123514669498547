import React from "react";
import PersonIcon from "../../../assets/icons/person.icon";
import styles from "./textInput.module.css";

export default function TextInput({
  label,
  inputIcon,
  inputName,
  placeholder,
  value,
  setValue,
  inputType,
  required = true,
  disabled,
}) {
  return (
    <div className={`${styles["input-container"]}`}>
      {label && (
        <label className={`${styles["input-label"]}`}>
          <span>{label}</span>
          {required && <span>*</span>}
        </label>
      )}
      <div className={`${styles["input-holder"]}`}>
        <div>{inputIcon ?? <span></span>}</div>
        <input
          type={inputType ?? "text"}
          id={inputName}
          name={inputName}
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
    </div>
  );
}
