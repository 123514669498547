import React from "react";
import styles from "./ourPartners.module.css";
import home_styles from "../../home.module.css";
import harvard from "../../../../assets/images/harvard-university.png";
import princeton from "../../../../assets/images/princeton-university.png";
import xavier from "../../../../assets/images/xavier-university.png";
import tufts from "../../../../assets/images/tufts-university.png";

export default function OurPartners() {
  /** State management */

  const PARTNERS = [xavier, tufts, harvard, princeton];
  return (
    <div className={styles.container}>
      <div id="partnership" className={`${styles.our_partners_container}`}>
        <div className={`${styles.our_partners_header}`}>
          <h2 className={`${home_styles.section_heading_title}`}>OUR PARTNERS</h2>
          <p className={`${home_styles.section_heading_desc}`}>
            Building Bridges, Fostering Growth
          </p>
        </div>
        <p className={`${styles.extensive_desc}`}>
          Our partners are the backbone of our success, each one bringing unique
          expertise, resources, and perspectives to the table. Together, we form a
          dynamic network of collaboration and innovation, united by a shared
          vision and commitment to excellence. Through mutual trust, respect, and
          collaboration, we leverage each other's strengths to achieve greater
          impact and drive positive change in our communities and beyond. With our
          partners by our side, we are empowered to tackle challenges, seize
          opportunities, and make a meaningful difference in the world."
        </p>

        <div className={`${styles.partner_logo_container}`}>
          {PARTNERS.map((university, index) => {
            return (
              <div key={index} className={`${styles.university_logo}`}>
                <img src={university} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
