import React from "react";
import styles from "./chatComponent.module.css";
import placeholder from "../../../../../../../../assets/images/placeholder.jpeg"

export default function ChatComponent({ item, notFirst }) {
  const dateObject = new Date(item?.timestamp);
  const formattedDate = dateObject.toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
  const formattedTime = dateObject.toLocaleTimeString(undefined, {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  });
  return (
    <li
      className={`${styles["chat-main-container"]} ${notFirst && styles["border-top"]
        }`}
    >
      <div className={`${styles["chat-profile-container"]}`}>
        <div className={`${styles["chat-profile"]}`}>
          <img src={placeholder} alt="profile" />
        </div>
        <div className={`${styles["profile-details"]}`}>
          <h4>{item?.sender?.first_name} {item?.sender?.last_name}</h4>
          <p>{formattedDate} {formattedTime}</p>
        </div>
      </div>

      <div className={`${styles["chat-messages"]}`}>
        <p>
          {item?.content}
        </p>
      </div>
    </li>
  );
}
