import {
  getDataFromLocalStorage,
  removDataFromLocalStorage,
} from "./handleLocalStorage";

export const checkTokenExpiry = () => {
  const token = getDataFromLocalStorage("enchird-token");
  if (token !== null) {
    const tokenTimestamp = token.timestamps;
    const currentTime = new Date().getTime();
    const tokenAge = currentTime - parseInt(tokenTimestamp, 10);

    // Token expires after 4 hours (4 * 60 * 60 * 1000 milliseconds)
    const tokenExpiryDuration = 4 * 60 * 60 * 1000;

    if (tokenAge > tokenExpiryDuration) {
      // Token expired, redirect to login page
      removDataFromLocalStorage("enchird-token");
      removDataFromLocalStorage("enchird-userInfo");
      return true;
    } else return false;
  } else {
    // Token not found, redirect to login page
    return true;
  }
};
