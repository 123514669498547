
import { storage } from "../../../src/config/firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";




const docUpload = async (file, folder, fileName) => {
    const imgRef = ref(storage, `${folder}/${fileName}`);

    return await uploadBytes(imgRef, file).then(
        async () => {
            const link = {
                name: file.name,
                type: file.name.split(".")[1],
                url: "",
            };

            return await getDownloadURL(imgRef)
                .then((downloadURL) => {
                    console.log("Download Url:", downloadURL);
                    link.url = downloadURL;
                    console.log("File Link", link);
                    return link;
                })
                .catch((error) => {
                    alert(`Error dowloading url, ${error.message}`);
                });
        }
    ).catch((err) => {
        alert(`Error Uploading file..., ${err.message}`);
    });
}

export default docUpload;