import React from "react";
import PDFCard from "../../../../../../../components/pdfCard/PDFCard";
import styles from "./identity.module.css";

export default function IdentityDetails({
  idCardNumber,
  idCardDocument,
  profilePicture,
  locat,
}) {
  return (
    <div>
      <div className={`${styles["grid-column"]}`}>
        <h5>Indenty Card Number</h5>
        <p>{idCardNumber}</p>
      </div>

      <div className={`${styles["identity-info"]}`}>
        <div className={`${styles["img-container"]}`}>
          <img
            src={
              locat !== "dashboard" ? profilePicture.preview : profilePicture
            }
            alt="profile picture"
          />
        </div>
        {idCardDocument && (
          <PDFCard
            name={locat !== "dashboard" ? idCardDocument.fileName : "ID Card"}
            editable={true}
          />
        )}
      </div>
    </div>
  );
}
