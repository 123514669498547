import React, { useState, useEffect, Component } from "react";
import {
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useNavigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authenticate } from "./store/session";
import "./auth.css";
import "./index.css";
import "./index.scss";
import "@ionic/react/css/core.css";
import { setupIonicReact } from "@ionic/react";
import Login from "./pages/auth/Login/login";
import CreateNewPassword from "./pages/auth/Reset Password/Create new password/CreateNewPassword";
import ForgotPassword from "./pages/auth/Reset Password/Forgot Password/ForgotPassword";
import Error from "./pages/Error/Error";
import StudentRegistration from "./pages/auth/Signup/SignUp";
import ComponentLists from "./components/ComponentLists/ComponentLists";
import Home from "./pages/Home/Home";
import { checkTokenExpiry } from "./utils/functions/checkTokenExpiry";
import { getDataFromLocalStorage } from "./utils/functions/handleLocalStorage";
import { userSlice } from "./redux/features/userSlice";
import ManageDepartments from "./pages/dashboard/admin/components/ManageDepartments/ManageDepartments";
import ManageFaculties from "./pages/dashboard/admin/components/ManageFaculties/ManageFaculties";
import Tutors from "./pages/dashboard/admin/components/Tutors/Tutors";
import ApplicationRequestDetail from "./pages/dashboard/admin/components/ApplicationRequestDetail/ApplicationRequestDetail";
import ApplicationRequests from "./pages/dashboard/admin/components/ApplicationRequest/ApplicationRequest";
import Courses from "./pages/dashboard/admin/components/Courses/Courses";
import Settings from "./pages/dashboard/admin/components/Settings/Settings";
import Chats from "./pages/dashboard/admin/components/Chats/Chats";
import Students from "./pages/dashboard/admin/components/Students/Students";
import DashboardLayout from "./components/Layout/dashboard/DashboardLayout";
import MainDashboard from "./pages/dashboard/admin/components/MainDashboard/MainDashboard";

// Tutor Imports
import TutorMainDashboard from "./pages/dashboard/tutor/components/MainDashboard/MainDashboard";
import TutorManageProfile from "./pages/dashboard/tutor/components/ManageProfile/ManageProfile";
import TutorCourses from "./pages/dashboard/tutor/components/Courses/Courses";
import TutorCourseStudents from "./pages/dashboard/tutor/components/Students/Students";
import GradingAssessment from "./pages/dashboard/tutor/components/Assesments/Grading/GradingAssessment";
import ListingSubmissions from "./pages/dashboard/tutor/components/Assesments/Grading/ListingSubmissions";
import CourseSubmissions from "./pages/dashboard/tutor/components/Assesments/Grading/CourseSubmissions";
import Zoom from "./pages/dashboard/tutor/components/Zoom/Zoom";
import Assessments from "./pages/dashboard/tutor/components/Assesments/Assesments";
import CreateAssessmentQuestions from "./pages/dashboard/tutor/components/Assesments/CreateAssessmentQuestions";
import TutorCAResults from "./pages/dashboard/tutor/components/ResultsCourses/Courses";
import TutorStudentsResults from "./pages/dashboard/tutor/components/ResultsStudents/Students";
import StudentResultDetails from "./pages/dashboard/tutor/components/ResultsStudents/ResultDetails";

// student opportunities
import JobsListing from "./pages/dashboard/student/Opportunities/Opportunities";

import ManageGroups from "./pages/dashboard/tutors/ManageGroups/ManageGroups";
import GroupDetails from "./pages/dashboard/tutors/ManageGroups/components/GroupDetails/GroupDetails";

/** Student dashboard inputs */
import MainStudentDashboard from "./pages/dashboard/student/MainDashboard/MainDashboard";
import TuitionFees from "./pages/dashboard/student/TuitionFess/TuitionFees";
import CoursesAndPrograms from "./pages/dashboard/student/CoursesAndPrograms/CoursesAndPrograms";
import CAResults from "./pages/dashboard/student/CAResults/CAResults";
import Library from "./pages/dashboard/admin/components/library/Library";
import AddBookToLibrary from "./pages/dashboard/admin/components/library/AddBookToLibrary/AddBookToLibrary";

import { useFaculty } from "./query/admin/useFaculty";
import { useDepartment } from "./query/admin/useDepartment";
import { useApplication } from "./query/admin/applicationRequest";
import { useTutors } from "./query/admin/useTutors";
import { useStudent } from "./query/admin/useStudent";
import { useCourse } from "./query/admin/useCourse";
import ExamResults from "./pages/dashboard/student/Exams/Exams";
import Opportunities from "./pages/dashboard/admin/components/Opportunities/Opportunities";
import FailedTransaction from "./pages/dashboard/student/PaymentStatus/FailedTransaction/FailedTransaction";
import SuccessTransaction from "./pages/dashboard/student/PaymentStatus/SuccessTransaction/SuccessTransaction";
import Academics from "./pages/Academics/Academics";
import Admissions from "./pages/Admissions/Admissions";
import Blogs from "./pages/blogs/Blogs";
import BlogDetail from "./pages/BlogDetail/BlogDetail";
import EmployeeDetails from "./pages/EmployeeDetails/EmployeeDetails";
import UpdateStudentDetails from "./pages/dashboard/student/UpdateStudentDetails/UpdateStudentDetails";
import ApplicationSubmitted from "./pages/auth/Signup/components/ApplicationSubmitted/ApplicationSubmitted";

setupIonicReact();

function MyApp() {
  const navigate = useNavigate();
  // const user = useSelector((state) => state.user.token);
  const userRole = useSelector((state) => state.user.user.role);
  const dispatch = useDispatch();

  const { getFaculty } = useFaculty();
  const { getDepartments } = useDepartment();
  const { getCourses } = useCourse();
  const { getApplicants } = useApplication();
  const { getTutorsRecord } = useTutors();
  const { getStudents } = useStudent();

  async function getAllData(userRole) {
    if (userRole === "superadmin") {
      getFaculty();
      getDepartments();
      getCourses();
      getApplicants();
      getStudents();
      getTutorsRecord();
    } else if (userRole === "teacher") {
    } else if (userRole === "student" || userRole === "superadmin") {
      // getAllJobs();
    }
  }

  useEffect(() => {
    const tokenExpiry = checkTokenExpiry();
    if (tokenExpiry) {
      dispatch(userSlice.actions.setIsAuth(false));
    } else {
      const userInfo = getDataFromLocalStorage("enchird-userInfo");
      const token = getDataFromLocalStorage("enchird-token");
      if (userInfo && token) {
        dispatch(userSlice.actions.setIsAuth(true));
        dispatch(userSlice.actions.setUserToken(token.token));
        dispatch(userSlice.actions.setUser(userInfo.user));
      } else {
        navigate("/login");
      }
    }
  }, []);

  useEffect(() => {
    if (userRole !== "") {
      getAllData(userRole);
    }
  }, []);

  return (
    <>
      <Outlet />
    </>
  );
}

const router = createBrowserRouter([
  {
    element: <MyApp />,
    errorElement: <Error />,
    children: [
      { path: "/", element: <Home /> },
      { path: "/academics", element: <Academics /> },
      { path: "/admissions", element: <Admissions /> },
      { path: "/blogs", element: <Blogs /> },
      { path: "/blogs/:id", element: <BlogDetail /> },
      { path: "/employees-details/:id", element: <EmployeeDetails /> },

      // Authentication routes
      { path: "/login", element: <Login /> },
      { path: "/register", element: <StudentRegistration /> },
      { path: "/register/success", element: <ApplicationSubmitted /> },
      { path: "/create-password", element: <CreateNewPassword /> },
      { path: "/forgot-password", element: <ForgotPassword /> },

      // Admin routes
      {
        path: "/admin",
        element: (
          <DashboardLayout>
            <Outlet />
          </DashboardLayout>
        ),

        children: [
          { path: "dashboard", element: <MainDashboard /> },
          { path: "students", element: <Students /> },
          { path: "courses", element: <Courses /> },
          { path: "application-requests", element: <ApplicationRequests /> },
          {
            path: "application-requests/:id",
            element: <ApplicationRequestDetail />,
          },
          { path: "tutors", element: <Tutors /> },
          { path: "manage-faculties", element: <ManageFaculties /> },
          { path: "manage-departments", element: <ManageDepartments /> },
          { path: "chats", element: <Chats /> },
          { path: "opportunities", element: <Opportunities /> },
        ],
      },

      {
        path: "/dashboard/tutor",
        element: (
          <DashboardLayout>
            <Outlet />
          </DashboardLayout>
        ),
        children: [
          { path: "manage-groups", element: <ManageGroups /> },
          { path: "manage-groups/:id", element: <GroupDetails /> },
        ],
      },

      // Tutor routes
      {
        path: "/tutor",
        element: (
          <DashboardLayout>
            <Outlet />
          </DashboardLayout>
        ),

        children: [
          { path: "dashboard", element: <TutorMainDashboard /> },
          { path: "manage-groups", element: <ManageGroups /> },
          { path: "manage-groups/:id", element: <GroupDetails /> },
          { path: "courses", element: <TutorCourses /> },
          {
            path: "course/:course/:id/students",
            element: <TutorCourseStudents />,
          },
          { path: "assessments", element: <Assessments /> },
          {
            path: "assessment/:id/:course",
            element: <CreateAssessmentQuestions />,
          },
          //
          { path: "assessment-submissions", element: <ListingSubmissions /> },
          {
            path: "assessment/:id/course-submissions/:course",
            element: <CourseSubmissions />,
          },
          {
            path: "assessment/:id/:student/grade/:course",
            element: <GradingAssessment />,
          },
          //
          { path: "zoom", element: <Zoom /> },
          //
          { path: "ca-results", element: <TutorCAResults /> },
          {
            path: "ca-results/:course/:id/results",
            element: <TutorStudentsResults />,
          },
          {
            path: "ca-results/:course/:student/:details",
            element: <StudentResultDetails />,
          },
          {
            path: "manage-profile",
            element: <TutorManageProfile />,
          },
        ],
      },

      // Student routes
      // Student routes
      {
        path: "/dashboard",
        path: "/dashboard",
        element: (
          <DashboardLayout>
            <Outlet />
          </DashboardLayout>
        ),
        children: [
          { path: "main", element: <MainStudentDashboard /> },
          { path: "my-groups", element: <ManageGroups /> },
          { path: "my-groups/:id", element: <GroupDetails /> },
          { path: "tuition-fees", element: <TuitionFees /> },
          {
            path: "tuition-fees/successful-transaction",
            element: <SuccessTransaction />,
          },
          {
            path: "tuition-fees/failed-transaction",
            element: <FailedTransaction />,
          },
          { path: "courses-and-programs", element: <CoursesAndPrograms /> },
          { path: "ca-results", element: <CAResults /> },
          { path: "exam", element: <ExamResults /> },
          { path: "jobs", element: <JobsListing /> },
          {
            path: "update-detail-information",
            element: <UpdateStudentDetails />,
          },
        ],
      },

      // Other routes
      { path: "/components", element: <ComponentLists /> },
      {
        path: "/settings",
        element: (
          <DashboardLayout>
            <Settings />
          </DashboardLayout>
        ),
      },
      {
        path: "/chats",
        element: (
          <DashboardLayout>
            <Chats />
          </DashboardLayout>
        ),
      },

      {
        path: "/library",
        element: (
          <DashboardLayout>
            <Library />
          </DashboardLayout>
        ),
      },
      {
        path: "/library/add-new-book",
        element: (
          <DashboardLayout>
            <AddBookToLibrary />
          </DashboardLayout>
        ),
      },
    ],
  },
]);

function App() {
  return (
    <div className="App text-slate-800 max-w-[1500px] mx-auto">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
