import React from "react";
import SearchIcon from "../../assets/icons/search.icon";
import styles from "./search.module.css";

export default function Search({ placeholder, value, setValue, extraStyles }) {
  return (
    <div className={`${styles["search-container"]}`} style={extraStyles}>
      <SearchIcon />
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </div>
  );
}
