import { useDispatch, useSelector } from "react-redux";
import client from "../../utils/api/axios";
import { institutionSlice } from "../../redux/features/institutionSlice";
import { useIonToast } from "@ionic/react";

export function useFaculty() {
  /** State management */
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);
  const [toast] = useIonToast();

  async function createFaculty(faculty) {
    return await client
      .post("/api/admin/faculty/", faculty, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response) {
          getFaculty();
          toast({
            message: `Faculty Created successfully!`,
            color: "success",
            duration: 3000,
            buttons: ["Ok"],
          });
        }
        return true;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          duration: 3000,
          buttons: ["Ok"],
          duration: 4000,
        });
        return null;
      });
  }

  async function getFaculty() {
    return client
      .get("/api/admin/faculty")
      .then((response) => {
        const result = response.data;
        if (result) {
          dispatch(institutionSlice.actions.setFaculty(result));
        }
        return response.data;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          duration: 3000,
          buttons: ["Ok"],
          duration: 4000,
        });
        return null;
      });
  }
  /**
   * updateFacultyInfo Updates faculty information using faculty id
   * @param faculty faculty object to be updated
   */
  async function updateFacultyInfo(faculty, facultyId) {
    return await client
      .put(`/api/admin/faculty/${facultyId}/`, faculty, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then(() => {
        getFaculty();
        return true;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          duration: 3000,
          buttons: ["Ok"],
        });
        return null;
      });
  }
  async function getSingleFacultyInfo(facultyId) {
    return await client
      .get(`/api/admin/faculty/${facultyId}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          duration: 3000,
          buttons: ["Ok"],
        });
        return null;
      });
  }

  async function getLevelsForFaculty(facultyId) {
    return await client
      .get(`/api/admin/faculty/${facultyId}/levels/`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          duration: 3000,
          buttons: ["Ok"],
        });
        return null;
      });
  }

  async function searchFaculty(keyword) {
    return await client
      .get("/api/admin/faculty/", {
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          keyword,
        },
      })
      .then((response) => {
        const result = response.data;
        return result;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          buttons: ["Ok"],
          duration: 2000,
        });
        return null;
      });
  }

  async function deleteFaculty(facultyId) {
    return await client
      .delete(`/api/admin/faculty/${facultyId}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then(() => {
        getFaculty();
        toast({
          message: "Faculty deleted successfully",
          color: "success",
          duration: 3000,
          buttons: ["Ok"],
        });
        return true;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          duration: 3000,
          buttons: ["Ok"],
        });
        return null;
      });
  }

  return {
    createFaculty,
    getFaculty,
    updateFacultyInfo,
    getSingleFacultyInfo,
    getLevelsForFaculty,
    searchFaculty,
    deleteFaculty,
  };
}
