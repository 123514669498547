import React, { useState } from "react";
import styles from "./guardianInformation.module.css";
import TextInput from "../../../../../components/Inputs/TextInput/TextInput";

export default function GuardianInformation({
  guardianOneName,
  setGuardianOneName,
  guardianOneContact,
  setGuardianOneContact,
  guardianTwoName,
  setGuardianTwoName,
  guardianTwoContact,
  setGuardianTwoContact,
}) {
  return (
    <div className={`${styles["guardian-container"]}`}>
      <TextInput
        label={"Guardian 1 Name"}
        placeholder={"Enter Guardian 1 Name"}
        value={guardianOneName}
        setValue={setGuardianOneName}
      />
      <TextInput
        label={"Guardian 1 Contact"}
        placeholder={"Enter Guardian 1 Contact"}
        value={guardianOneContact}
        setValue={setGuardianOneContact}
      />
      <TextInput
        label={"Guardian 2 Name"}
        placeholder={"Enter Guardian 1 Name"}
        value={guardianTwoName}
        setValue={setGuardianTwoName}
        required={false}
      />
      <TextInput
        label={"Guardian 2 Contact"}
        placeholder={"Enter Guardian 1 Contact"}
        value={guardianTwoContact}
        setValue={setGuardianTwoContact}
        required={false}
      />
    </div>
  );
}
