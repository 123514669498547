import React, { useEffect } from "react";
import styles from "./home.module.css";
import Logo from "../../components/logo/Logo";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import NavBar from "../../components/NavBar/NavBar";
import Hero from "./components/Hero/Hero";
import OurCoreProgram from "./components/OurCoreProgram/OurCoreProgram";
import AboutUs from "./components/AboutUs/AboutUs";
import OurPartners from "./components/OurPartners/OurPartners";
import WhyYouNeedUs from "./components/WhyYouNeedUs/WhyYouNeedUs";
import StaticsRoll from "./components/staticsRoll/StaticsRoll";
import Footer from "../../components/Footer/Footer";
import Testimonials from "./components/Testimonials/Testimonials";
import ContactUs from "./components/ContactUs/ContactUs";
import LatestStories from "./components/LatestStories/LatestStories";
import StaticLayout from "../../components/Layout/StaticLayout/StaticLayout";

export default function Home() {
  /** State management */
  const isAuth = useSelector((state) => state.user.globalAuth);
  const userInfo = useSelector((state) => state.user.user);

  const navigate = useNavigate();
  const location = useLocation();

  /** Handle navigate to sign up */
  function handleNavigateToSignUp() {
    navigate("/register");
  }

  useEffect(() => {
    // Get the ID from the URL (e.g., /view-contact-details/partnership)
    const idFromUrl = location.state?.direction;
    // Scroll to the element with the specified ID
    const element = document.getElementById(idFromUrl);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  return (
    <StaticLayout>
      <Hero />
      <OurCoreProgram />
      <main className={`${styles.home_main_container}`}>
        <AboutUs />
        <OurPartners />
      </main>
      <WhyYouNeedUs />
      <StaticsRoll />

      <section className={`${styles.home_main_container}`}>
        <Testimonials />
      </section>
      <ContactUs />
      <section children={styles.home_main_container}>
        <LatestStories />
      </section>
    </StaticLayout>
  );
}
