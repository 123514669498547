import React from "react";
import styles from "./feeAnnoucement.module.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function FeeAnnoucementBanner() {
  /** State management */
  const userInfo = useSelector((state) => state.user.user);
  return (
    <div className={`${styles["announcement-container"]}`}>
      <p>
        {userInfo.first_name} {userInfo.last_name}, you have not paid your
        tuition fee for the academic year 2023/2024.{" "}
        <span>
          <Link
            to={"/dashboard/tuition-fees"}
            style={{ textDecoration: "none" }}
          >
            Click here
          </Link>
        </span>
      </p>
    </div>
  );
}
