import React, { useState } from "react";
import StudentLayout from "../components/Layout/StudentLayout";
import TextInput from "../../../../components/Inputs/TextInput/TextInput";
import MessageGrayIcon from "../../../../assets/icons/message-gray.icon";
import PhoneIcon from "../../../../assets/icons/phone.icon";

import styles from "./updateDetailInformation.module.css";
import Button from "../../../../components/Button/customButton/Button";
import { BsCamera } from "react-icons/bs";
import { useStudent } from "../../../../query/admin/useStudent";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loader/Loader";

export default function UpdateStudentDetails() {
  /** state management */
  const studentInfo = useSelector((state) => state.user.user);
  const [email, setEmail] = useState(studentInfo?.email);
  const [phone, setPhone] = useState(studentInfo?.phone);
  const [primaryLocation, setPrimaryLocation] = useState(
    studentInfo?.primary_location
  );
  const [secondaryLocation, setSecondaryLocation] = useState(
    studentInfo?.secondary_location
  );
  const [guardianOneName, setGuardianOneName] = useState(
    studentInfo?.guardian_one_name
  );
  const [guardianOneContact, setGuardianOneContact] = useState(
    studentInfo?.guardian_one_contact
  );
  const [guardianTwoName, setGuardianTwoName] = useState(
    studentInfo?.guardian_two_name
  );
  const [guardianTwoContact, setGuardianTwoContact] = useState(
    studentInfo?.guardian_two_contact
  );

  /** State management for updates process */
  const { updateStudentInfo } = useStudent();
  const [isLoading, setIsLoading] = useState(false);
  const [updatedProfile, setUpdatedProfile] = useState("");
  const navigate = useNavigate();

  function handleUpdateStudentInfo() {
    const student = {
      id: studentInfo?.id,
      email,
      phone,
      primary_location: primaryLocation,
      secondary_location: secondaryLocation,
      guardian1_name: guardianOneName,
      guardian1_contact: guardianOneContact,
      guardian2_name: guardianTwoName,
      guardian2_contact: guardianTwoContact,
    };
    setIsLoading(true);

    updateStudentInfo(student).then((response) => {
      if (response) {
        navigate("/dashboard");
      }
      setIsLoading(false);
    });
  }

  function handleChangeProfile(e) {
    const image = e.target.files[0];
    console.log("image object", image);
    const reader = new FileReader();
    reader.onload = (e) => {
      setUpdatedProfile(e.target.result);
    };

    reader.readAsDataURL(image);
  }

  return (
    <StudentLayout showProgram={true}>
      {isLoading && <Loader />}
      <div className={`${styles.updateMainContainer}`}>
        {/** Photo container */}
        <div className={`${styles.profileMainContainer}`}>
          <div className={`${styles.profileImageContainer}`}>
            <img
              src={
                updatedProfile === ""
                  ? studentInfo?.profile_picture
                  : updatedProfile
              }
              alt="Profile image"
            />
            <label
              htmlFor="updateProfile"
              className={`${styles.camerasContainer}`}
            >
              <BsCamera
                style={{
                  color: "white",
                  fontSize: 36,
                }}
              />
              <input
                type="file"
                name="updateProfile"
                id="updateProfile"
                onChange={handleChangeProfile}
                className={`${styles.profileInput}`}
                accept="image/*"
              />
            </label>
          </div>

          <Button
            text={
              updatedProfile === ""
                ? "Change Profile Image"
                : "Save New Profile Picture"
            }
            width={"fit-content"}
            paddingBlock={16}
            backgroundColor={"#363736"}
          />
        </div>

        {/* containers for student details */}
        <div className={`${styles.updateInfoContainer}`}>
          <TextInput
            label={"Email Address"}
            value={email}
            setValue={setEmail}
            placeholder={"joedoe@example.com"}
            inputIcon={<MessageGrayIcon width={32} height={32} />}
          />
          <TextInput
            label={"Contact / Phone Number"}
            value={phone}
            setValue={setPhone}
            placeholder={"+237 6 50 99 93 16"}
            inputIcon={<PhoneIcon width={32} height={32} />}
          />
          <TextInput
            label={"Primary Location"}
            placeholder={"Buea, Cameroon"}
            value={primaryLocation}
            setValue={setPrimaryLocation}
          />
          <TextInput
            label={"Secondary Location"}
            placeholder={"Douala, Cameroon"}
            value={secondaryLocation}
            setValue={setSecondaryLocation}
          />

          <TextInput
            label={"Guardian 1 Name"}
            placeholder={"Enter Guardian 1 Name"}
            value={guardianOneName}
            setValue={setGuardianOneName}
          />
          <TextInput
            label={"Guardian 1 Contact"}
            placeholder={"Enter Guardian 1 Contact"}
            value={guardianOneContact}
            setValue={setGuardianOneContact}
          />
          <TextInput
            label={"Guardian 2 Name"}
            placeholder={"Enter Guardian 1 Name"}
            value={guardianTwoName}
            setValue={setGuardianTwoName}
            required={false}
          />
          <TextInput
            label={"Guardian 2 Contact"}
            placeholder={"Enter Guardian 1 Contact"}
            value={guardianTwoContact}
            setValue={setGuardianTwoContact}
            required={false}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBlock: 40,
          }}
        >
          <Button
            text={"Update Profile information"}
            width={"fit-content"}
            paddingInline={32}
            paddingBlock={16}
            onClick={() => {
              handleUpdateStudentInfo();
            }}
            disabled={
              (email === "" &&
                phone === "" &&
                primaryLocation === "" &&
                secondaryLocation === "" &&
                guardianOneContact === "" &&
                guardianTwoContact === "" &&
                guardianOneName === "" &&
                guardianTwoName === "") ||
              isLoading
            }
          />
        </div>
      </div>
    </StudentLayout>
  );
}
