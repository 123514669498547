export const FAQs = [
  {
    question: "What are the admission requirements for your college?",
    answer:
      "Our college admission requirements typically include submitting an application form, official high school transcripts, standardized test scores (such as the SAT or ACT), letters of recommendation, and a personal statement or essay. Specific requirements may vary depending on the program or major you are applying to.",
  },
  {
    question: "Is there an application deadline for admissions?",
    answer:
      "Yes, there is an application deadline for admissions. Please visit our university's website or contact our admissions office for specific deadline information.",
  },
  {
    question: "Can I apply for financial aid or scholarships?",
    answer:
      "Yes, you can apply for financial aid and scholarships. Our university offers various financial aid options and scholarships to eligible students. We encourage you to explore the financial aid opportunities available and submit your applications accordingly.",
  },
  {
    question: "Are there opportunities for campus visits or tours?",
    answer:
      "Yes, we offer opportunities for campus visits and tours. We understand the importance of experiencing our campus firsthand. Please check our university's website or contact our admissions office to schedule a campus visit or tour. Our staff will be delighted to show you around and answer any questions you may have.",
  },
  {
    question: "What support services are available for incoming students?",
    answer:
      "We provide a range of support services for incoming students to ensure a smooth transition and successful academic journey. These services may include academic advising, tutoring programs, career counseling, student organizations, wellness programs, and more. Our dedicated staff is here to support and guide you throughout your time at our university.",
  },
];
