import React, { useState } from "react";
import styles from "../generalStyles.module.css";
import own_styles from "./settings.module.css";
import TextInputS from "../../../../../components/Inputs/TextInput/TextInputS/TextInputS";
import PersonIcon from "../../../../../assets/icons/person.icon";
import MessageGrayIcon from "../../../../../assets/icons/message-gray.icon";
import LockIcon from "../../../../../assets/icons/lock.icon";
import Button from "../../../../../components/Button/customButton/Button";
import { useSelector } from "react-redux";
import { useAuth } from "../../../../../query/admin/auth";

export default function Settings() {
  /** state management */
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const userInfo = useSelector((state) => state.user.user);

  /** Loading management */
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [isChangingPersonalInfo, setIsChangingPersonalInfo] = useState(false);

  /** Hooks */
  const { changePassword } = useAuth();

  /** Change password */
  function handleChangePassword() {
    if (confirmPassword === newPassword) {
      setIsChangingPassword(true);

      const passwords = {
        old_password: password,
        new_password: newPassword,
      };
      changePassword(passwords).then((response) => {
        if (response) {
          setNewPassword("");
          setConfirmPassword("");
          setPassword("");
        }
        setIsChangingPassword(false);
      });
    } else {
      alert("Sorry New Password and Confirm Password MUST match!");
    }
  }

  return (
    <div>
      <div className={`${styles["header-container"]}`}>
        <h2>Account Settings </h2>
      </div>

      <div className={`${own_styles.sub_container}`}>
        {userInfo.role === "superadmin" && (
          <section className={`${own_styles.sub_container_section}`}>
            <h4>Profile Mangement</h4>
            <TextInputS
              icon={<PersonIcon width={24} height={24} />}
              placeholder={"name"}
              value={name}
              setValue={setName}
              disabled={false}
            />
            <TextInputS
              placeholder={"email"}
              value={email}
              setValue={setEmail}
              icon={<MessageGrayIcon width={24} height={24} />}
              disabled={false}
              type={"email"}
            />

            <div style={{ width: 300, marginBlockStart: 20 }}>
              <Button
                text={"Update Profile Information"}
                disabled={isChangingPassword || isChangingPersonalInfo}
              />
            </div>
          </section>
        )}

        <section className={`${own_styles.sub_container_section}`}>
          <h4>Change Password</h4>
          <TextInputS
            icon={<LockIcon width={24} height={24} color={"#4E504E"} />}
            placeholder={"Old Password"}
            value={password}
            setValue={setPassword}
            disabled={false}
            type={"password"}
          />
          <TextInputS
            placeholder={"New Password"}
            value={newPassword}
            setValue={setNewPassword}
            icon={<LockIcon width={24} height={24} color={"#4E504E"} />}
            disabled={false}
            type={"password"}
          />
          <TextInputS
            placeholder={"Confirm New Password"}
            value={confirmPassword}
            setValue={setConfirmPassword}
            icon={<LockIcon width={24} height={24} color={"#4E504E"} />}
            disabled={false}
            type={"password"}
          />

          <div style={{ width: 300, marginBlockStart: 20 }}>
            <Button
              text={"Change Password"}
              onClick={() => handleChangePassword()}
              disabled={isChangingPassword || isChangingPersonalInfo}
              isLoading={isChangingPassword}
            />
          </div>
        </section>
      </div>
    </div>
  );
}
