import React from "react";

export default function Location({ ...props }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="16"
            height="16"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        >
            <path d="M12 2c-3.31 0-6 2.69-6 6 0 5.25 6 14 6 14s6-8.75 6-14c0-3.31-2.69-6-6-6zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
            <circle cx="12" cy="8.5" r="2.5" />
        </svg>

    );
}

