import React from "react";
import styles from "./supportingDocuments.module.css";
import PDFCard from "../../../../../../../components/pdfCard/PDFCard";

export default function SupportingDocumentsDetails({
  coverLetter,
  documents,
  locat,
}) {
  return (
    <div className={`${styles["container"]}`}>
      <div className={`${styles["info"]}`}>
        <div className={`${styles["grid-column"]}`}>
          <h5>Cover / Application Letter</h5>
          <p>{coverLetter}</p>
        </div>

        <div className={`${styles["grid-column"]}`}>
          <h5>
            Past Achievements / Certificates / Transcript (Scanned PDF Formats)*
          </h5>
          <div className={`${styles["docs-info"]}`}>
            {documents?.map((document, index) => {
              return (
                <PDFCard
                  key={index}
                  name={
                    locat !== "dashboard" ? document.fileName : document.name
                  }
                  editable={true}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
