import React, { useEffect, useState } from "react";
import styles from "./addBookToLibrary.module.css";
import UploadIcon from "../../../../../../assets/icons/upload.icon";
import DocumentIcon from "../../../../../../assets/icons/document.icon";
import TrashIcon from "../../../../../../assets/icons/trash.icon";
import CheckIcon from "../../../../../../assets/icons/check.icon";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function AddBookToLibrary() {
  /** State management */
  const [bookTitle, setBookTitle] = useState("");

  const userInfo = useSelector((state) => state.user.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo && userInfo.role !== "superadmin") {
      navigate("/*");
    }
  }, []);

  return (
    <div>
      <h2>Library Management</h2>

      <div className={`${styles["new-book-form"]}`}>
        <div className={`${styles["input-container"]}`}>
          <input
            value={bookTitle}
            onChange={(e) => setBookTitle(e.target.value)}
            placeholder="Enter Book Title"
          />
        </div>

        <div className={`${styles["upload-container"]}`}>
          <div className={`${styles["upload-sub-container"]}`}>
            <div className={`${styles["upload-icon"]}`}>
              <UploadIcon color={"#A020F0"} />
            </div>
            <input
              type="file"
              accept="application/*"
              name="upload"
              id="upload"
              disabled={bookTitle === ""}
            />
            <p
              className={`${styles["upload-desc"]}`}
              style={{
                opacity: bookTitle === "" ? 0.5 : 1,
                cursor: bookTitle === "" && "not-allowed",
              }}
            >
              <label
                htmlFor="upload"
                title={
                  bookTitle === "" &&
                  "Please Enter a book title before selecting document"
                }
                style={{
                  cursor: bookTitle === "" ? "not-allowed" : "pointer",
                }}
              >
                Click to Upload
              </label>{" "}
              or drag and drop
            </p>
            <p className={`${styles["upload-desc-limit"]}`}>
              (Max. File size: 25MB)
            </p>
          </div>
        </div>
      </div>

      <BookCard />
    </div>
  );
}

export function BookCard({ isUploading, progressPercentage }) {
  return (
    <div className={`${styles["book-card-container"]}`}>
      <div>
        <div className={`${styles["book-detail-container"]}`}>
          <div>
            <DocumentIcon width={20} height={20} />
          </div>

          <div className={`${styles["book-detail-container__details"]}`}>
            <h2>Learn Physics for Amateurs</h2>
            <p>200 KB</p>
            {!isUploading && (
              <div className={`${styles["view-document"]}`}>
                <p>Click to view</p>
              </div>
            )}
          </div>
        </div>

        {isUploading && (
          <div className={`${styles["progress-container"]}`}>
            <div className={`${styles["progress-bar"]}`}>
              <div
                style={{
                  height: "100%",
                  borderRadius: 4,
                  backgroundColor: "#50C878",
                  width: "40%",
                }}
              ></div>
            </div>{" "}
            <p>40%</p>
          </div>
        )}
      </div>

      <div>
        {progressPercentage === 100 ? (
          <CheckIcon width={20} height={20} color={"#50C878"} />
        ) : (
          <TrashIcon width={20} height={20} style={{ cursor: "pointer" }} />
        )}
      </div>
    </div>
  );
}
