import React, { useEffect, useState } from "react";
import Button from "../Button/customButton/Button";
import MessageIcon from "../../assets/icons/message.icon";
import Logo from "../logo/Logo";
import RegistrationStepCrumbs from "../RegistrationStepCrumbs/RegistrationStepCrumbs";
import TextInput from "../Inputs/TextInput/TextInput";
import AuthLayout from "../Layout/auth/AuthLayout";
import SignUp from "../../pages/auth/Signup/SignUp";
import ImageBlock from "../ImageBlock/ImageBlock";
import Home from "../../pages/Home/Home";
import MultiSelectDropdown from "../Inputs/MultiSelectDropdown/MultiSelectDropdown";
import { useSelector } from "react-redux";
import DropDownS from "../Inputs/DropdownS/DropDownS";
import Loader from "../Loader/Loader";
import { useFaculty } from "../../query/admin/useFaculty";
import Dropdown from "../Inputs/Dropdown/Dropdown";
import RecentChats from "../../pages/dashboard/admin/components/MainDashboard/components/RecentChats/RecentChats";
import UpcomingEvents from "../../pages/dashboard/admin/components/MainDashboard/components/UpcomingEvent/UpcomingEvent";
import GroupCard from "../../pages/dashboard/tutors/ManageGroups/components/GroupCard/GroupCard";

export default function ComponentLists() {
  const [active, setActive] = useState(1);
  const [data, setData] = useState([]);
  const [value, setValue] = useState("");
  const tempData = useSelector((state) => state.institution.facultyData);
  const { getFaculty } = useFaculty();

  useEffect(() => {
    getFaculty();
  }, []);

  const facultyData = tempData.results;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "yellowgreen",
      }}
    >
      {/* <Button text={"Button Component"} rightIcon={<MessageIcon />} rounded /> */}
      {/* <div>
        <Logo />
      </div>

      <div>
        <RegistrationStepCrumbs active={active} setActive={setActive} />
      </div>

      <div style={{ marginBlockStart: 20 }}>
        <TextInput />
      </div> */}
      {/* <AuthLayout /> */}
      {/* <SignUp /> */}
      {/* <Home /> */}
      {/* <MultiSelectDropdown
        label={"Select Faculty"}
        setValue={setData}
        value={data}
        data={[
          { id: 1, name: "fet" },
          { id: 2, name: "feD" },
          { id: 3, name: "fhs" },
          { id: 4, name: "flps" },
        ]}
        required
      /> */}
      {/* <Dropdown
        label={"Select Faculty"}
        setValue={setValue}
        value={value}
        placeholder={"Select Faculty"}
      /> */}
      {/* <DropDownS
        value={value}
        setValue={setValue}
        data={tempData.results}
        placeholder={"Select Faculty"}
        keyItem={"name"}
      /> */}

      {/* <p>Select value: {value}</p> */}
      {/* <Loader /> */}

      {/* <RecentChats /> */}
      {/* <UpcomingEvents /> */}
      <GroupCard />
    </div>
  );
}
