import React, { useEffect, useState } from "react";
import TextInput from "../../Inputs/TextInput/TextInput";
import Button from "../../Button/customButton/Button";
import RichTextInput from "../../Inputs/RichTextInput/RichTextInput";
import styles from "./createTutor.module.css";
import Dropdown from "../../Inputs/Dropdown/Dropdown";
import { useTutors } from "../../../query/admin/useTutors";
import MultiSelectDropdown from "../../Inputs/MultiSelectDropdown/MultiSelectDropdown";
import { useSelector } from "react-redux";
import { IonSpinner } from "@ionic/react";
import { COUNTRIES } from "../../../utils/CONSTANTS/countryList";

export default function CreateTutorModal({ setViewTutorModal, tutorId }) {
  /** State management */
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [picture, setPicture] = useState("image");
  const [department, setDepartment] = useState([]);
  const [faculty, setFaculty] = useState([]);
  const [courses, setCourses] = useState([]);
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [DOB, setDOB] = useState("");
  const [nationality, setNationality] = useState("");
  const [description, setDescription] = useState("");
  const [about, setAbout] = useState("");
  const [username, setUsername] = useState("Tutor");

  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isGettingInfo, setIsGettingInfo] = useState(false);

  /** Data from store */
  const facultyData = useSelector((state) => state.institution.facultyData);
  const departmentData = useSelector(
    (state) => state.institution.departmentData
  );
  const courseData = useSelector((state) => state.institution.courseData);
  const {
    createTutor,
    updateTutorInfo,
    getSingleTutorInfo,
    deleteTutor,
    getTutorsRecord,
  } = useTutors();

  function handleCreateTutor() {
    setIsLoading(true);
    const tutorInfo = {
      email,
      first_name: firstName,
      last_name: lastName,
      phone: contact,
      gender,
      nationality,
      picture,
      date_of_birth: DOB,
      description,
      about,
      courses,
      faculties: faculty,
      departments: department,
      username: `${firstName.toLowerCase()}_${lastName?.toLowerCase()}`,
    };
    createTutor(tutorInfo).then((response) => {
      if (response) {
        setViewTutorModal(false);
        getTutorsRecord();
      }
      setIsLoading(false);
    });
  }

  useEffect(() => {
    if (tutorId !== "") {
      setIsGettingInfo(true);
      getSingleTutorInfo(tutorId).then((response) => {
        console.log(response);
        if (response) {
          const departmentIds = response.departments_detail?.map((item) => {
            return item.id;
          });
          const facultyIds = response.faculties_detail?.map((item) => {
            return item.id;
          });
          const courseIds = response.courses_detail?.map((item) => {
            return item.id;
          });
          console.log("department names:", departmentIds);
          setFirstName(response.user.first_name);
          setLastName(response.user.last_name);
          setGender(response.user.gender);
          setEmail(response.user.email);
          setContact(response.user.phone);
          setAbout(response.about);
          setDescription(response.description);
          setDOB(response.user.date_of_birth);
          setDepartment(departmentIds);
          setFaculty(facultyIds);
          setCourses(courseIds);
          setNationality(response.user.nationality);
        }
        setIsGettingInfo(false);
      });
    }
  }, [tutorId]);

  function handleUpdateTutor() {
    setIsUpdating(true);
    const tutorInfo = {
      email,
      first_name: firstName,
      last_name: lastName,
      phone: contact,
      gender,
      nationality,
      picture,
      date_of_birth: DOB,
      description,
      about,
      courses,
      faculties: faculty,
      departments: department,
      username: firstName.toLowerCase(),
    };

    updateTutorInfo(tutorInfo, tutorId).then((response) => {
      if (response) {
        console.log(response);
        setViewTutorModal(false);
      }
      setIsUpdating(false);
    });
  }

  /** handle delete course */
  function handleDeleteTutorRecord() {
    if (tutorId !== "") {
      deleteTutor(tutorId).then((response) => {
        if (response) {
          setViewTutorModal(false);
        }
      });
    }
  }

  return (
    <div className={`${styles.container}`}>
      {tutorId !== "" && isGettingInfo ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "50vh",
            height: "50vh",
            background: "#fff",
          }}
        >
          <IonSpinner
            name="lines"
            color={"dark"}
            style={{ width: 50, height: 50 }}
          />
        </div>
      ) : (
        <div className={`${styles.sub_container}`}>
          <div
            style={{ position: "absolute", right: 20, top: 0 }}
            onClick={() => !isLoading && setViewTutorModal(false)}
          >
            <p style={{ fontSize: 50, cursor: "pointer", color: "red" }}>
              &times;
            </p>
          </div>
          <h2 className={`${styles.title}`}>
            {tutorId !== "" ? "Update Tutor Information" : "Add New Tutors"}
          </h2>
          <div className={`${styles.form_container}`}>
            <div>
              <MultiSelectDropdown
                label={"Select Faculty to Add"}
                placeholder={"Faculty of Engineering and Technology"}
                value={faculty}
                setValue={setFaculty}
                data={facultyData.results}
                keyItem={"id"}
                disabled={isLoading}
                required
              />
            </div>

            <div>
              <MultiSelectDropdown
                label={"Select Department to Add"}
                placeholder={"Faculty of Engineering and Technology"}
                value={department}
                setValue={setDepartment}
                data={departmentData.results}
                keyItem={"id"}
                disabled={isLoading}
                required
              />
            </div>

            <div>
              <MultiSelectDropdown
                label={"Select Courses to Add"}
                placeholder={"Faculty of Engineering and Technology"}
                value={courses}
                setValue={setCourses}
                disabled={isLoading}
                keyItem={"title"}
                data={courseData.results}
                required
              />
            </div>
            <div>
              <TextInput
                label={"First Name"}
                placeholder={"Joe"}
                value={firstName}
                setValue={setFirstName}
                disabled={isLoading}
              />
            </div>
            <div>
              <TextInput
                label={"Last Name"}
                placeholder={"Doe"}
                value={lastName}
                setValue={setLastName}
                disabled={isLoading}
              />
            </div>

            <div>
              <Dropdown
                label={"Gender"}
                value={gender}
                setValue={setGender}
                data={[
                  { id: "male", name: "Male" },
                  { id: "female", name: "Female" },
                ]}
                keyItem={"id"}
              />
            </div>

            <div>
              <Dropdown
                label={"Nationality"}
                value={nationality}
                setValue={setNationality}
                data={COUNTRIES}
                keyItem={"id"}
                disabled={isLoading}
              />
            </div>

            <div>
              <TextInput
                label={"Date of Birth"}
                placeholder={"MM/DD/YY"}
                value={DOB}
                setValue={setDOB}
                disabled={isLoading}
                inputType={"date"}
              />
            </div>
            <div>
              <TextInput
                label={"Email"}
                placeholder={"example@example.com"}
                value={email}
                setValue={setEmail}
                disabled={isLoading}
              />
            </div>

            <div>
              <TextInput
                label={"Contact"}
                placeholder={"+237 670 00 00 00 "}
                value={contact}
                setValue={setContact}
                disabled={isLoading}
              />
            </div>

            <div>
              <TextInput
                label={"Tutor Short Description"}
                placeholder={
                  "This is a short text that shows as a smart description of the tutor"
                }
                value={description}
                setValue={setDescription}
                disabled={isLoading}
              />
            </div>

            <div>
              <RichTextInput
                label={"About Lecturer"}
                value={about}
                setValue={setAbout}
                disabled={isLoading}
              />
            </div>
          </div>

          <div
            style={{
              marginTop: 65,
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {tutorId !== "" ? (
              <div style={{ display: "flex", gap: 10 }}>
                <Button
                  text={"Delete"}
                  onClick={() => handleDeleteTutorRecord()}
                  isLoading={isLoading || isDeleting}
                  disabled={isLoading || isUpdating || isDeleting}
                  width={"fit-content"}
                  backgroundColor={"red"}
                />

                <Button
                  text={"Update Tutor Details"}
                  onClick={() => handleUpdateTutor()}
                  isLoading={isUpdating || isLoading || isDeleting}
                  disabled={
                    firstName === "" ||
                    lastName === "" ||
                    gender === "" ||
                    isLoading ||
                    about === "" ||
                    description === "" ||
                    DOB === "" ||
                    nationality === "" ||
                    courses.length === 0 ||
                    department.length === 0 ||
                    faculty.length === 0 ||
                    contact === ""
                  }
                  width={"fit-content"}
                />
              </div>
            ) : (
              <Button
                text={"Add New Tutor Record"}
                onClick={() => handleCreateTutor()}
                width={"fit-content"}
                isLoading={isLoading}
                disabled={
                  firstName === "" ||
                  lastName === "" ||
                  gender === "" ||
                  isLoading ||
                  about === "" ||
                  description === "" ||
                  DOB === "" ||
                  nationality === "" ||
                  courses.length === 0 ||
                  department.length === 0 ||
                  faculty.length === 0 ||
                  contact === ""
                }
              />
            )}
          </div>
        </div>
      )}
      ;
    </div>
  );
}
