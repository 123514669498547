import React from "react";
import Button from "../../../../components/Button/customButton/Button";
import styles from "./admissionProcess.module.css";
import { useNavigate } from "react-router-dom";

export default function AdmissionProcess() {
  const navigate = useNavigate();

  return (
    <div className={`${styles.application_process_container}`}>
      <div className={styles.container}>
        <div className={`${styles.application_process_images_container_main}`}>
          <ul className={`${styles.application_process_images_container}`}>
            {Array(4)
              .fill(0)
              .map((_v, index) => {
                return (
                  <li key={index} className={`${styles.application_image}`}>
                    <img src="/admission_image1.png" alt="" />
                  </li>
                );
              })}
          </ul>
        </div>

        <div className={`${styles.right_side_container}`}>
          <h4 className={`${styles.right_side_heading}`}>ADMISSIONS PROCESS</h4>
          <p className={`${styles.right_side_desc}`}>
            How to Apply and Become a Student
          </p>
          <ul className={`${styles.right_side_registration_steps}`}>
            {Array(5)
              .fill(0)
              .map((_v, index) => {
                return (
                  <li key={index} className={`${styles.registration_step}`}>
                    <p>
                      <strong>Step {index + 1}:</strong> Non arcu diam volutpat
                      massa sed consectetur neque tempor. Sed pharetra diam ipsum
                      est mus justo eu blandit. Mauris diam nunc nunc tincidunt.
                    </p>
                  </li>
                );
              })}
          </ul>
          <Button
            text={"Apply Now"}
            paddingBlock={18}
            paddingInline={18}
            width={"fit-content"}
            onClick={() => navigate("/register")}
          />
        </div>

        <div
          className={`${styles.application_process_images_container_main_mobile}`}
        >
          <ul className={`${styles.application_process_images_container}`}>
            {Array(4)
              .fill(0)
              .map((_v, index) => {
                return (
                  <li key={index} className={`${styles.application_image}`}>
                    <img src="" alt="" />
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
}
