import React, { useEffect, useState } from 'react'
import styles from "./CourseSubmissions.module.css"
import { useParams } from 'react-router-dom'
import GroupPeopleIcon from '../../../../../../assets/icons/group-people.icon'
import ChevronIcon from '../../../../../../assets/icons/chevron.icon'
// import Button from '../../../../../../components/Button/customButton/Button'
import Search from '../../../../../../components/Search/Search'
import globalstyles from "../../../../admin/components/generalStyles.module.css";
import { useNavigate } from 'react-router-dom'
import { useAssessments } from "../../../../../../query/tutor/useAssessments";
import Loader from '../../../../../../components/Loader/Loader'

const filteredSubmissions = []

const CourseSubmissions = () => {
    const { id, course } = useParams()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const { handleListSubmissions, handleGetAssessmentsGrades } = useAssessments()
    const [data, setData] = useState([])
    const [graded, setGraded] = useState([])


    const getSubmissions = async () => {
        setIsLoading(true);
        await handleListSubmissions(id).then((res) => {
            const filtered = res?.responses.filter((item) => item?.assessment?.structure === 'text')
            const uniqueStudentsMap = new Map();
            filtered.forEach(item => {
                const studentKey = `${item.student.id}`;
                if (!uniqueStudentsMap.has(studentKey)) {
                    uniqueStudentsMap.set(studentKey, { student: item.student, assessment: item.assessment, recorded_at: item.recorded_at });
                }
            });
            const newStudentsArray = Array.from(uniqueStudentsMap.values())
            setData(newStudentsArray)
            setIsLoading(false);
        }).catch((err) => {
            console.error(err)
            setIsLoading(false)
        })

    };

    const getGradedSubmissions = async () => {
        setIsLoading(true);
        await handleGetAssessmentsGrades(id).then((res) => {
            const uniqueStudentsMap = new Map();
            res.forEach(item => {
                const studentKey = `${item.student.id}`;
                if (!uniqueStudentsMap.has(studentKey)) {
                    uniqueStudentsMap.set(studentKey, { student: item.student, assessment: item.assessment, recorded_at: item.recorded_at });
                }
            });
            const newStudentsArray = Array.from(uniqueStudentsMap.values())
            setGraded(newStudentsArray)
            setIsLoading(false);
        }).catch((err) => {
            console.error(err)
            setIsLoading(false)
        })

    };

    useEffect(() => {
        getSubmissions()
        getGradedSubmissions()
    }, [id])
    return (
        <div>
            {isLoading && <Loader />}
            <h4>{course} / Assesments Submissions</h4>
            <div style={{ display: "flex", justifyContent: 'flex-end', marginTop: 20 }}>
                <div style={{ display: "flex", justifyContent: 'flex-end', width: "100%", }}>
                    {/* <div style={{ marginRight: 10, width: "22%" }}>
                        <Button text="Time of Submission" width={"100%"} />
                    </div>
                    <div style={{ marginRight: 10 }}>
                        <Button text="Status" width={"100%"} />
                    </div> */}
                    <div>
                        <Search placeholder={"Find Student"} width={"100%"} />
                    </div>
                </div>
            </div>
            <div className={styles.cards}>
                <div className={styles.card}>
                    <div className={styles.icondiv} style={{ background: "#2218a7" }}>
                        <GroupPeopleIcon className={styles.icon} />
                    </div>
                    <div className={styles.details}>
                        <p>Total Submissions</p>
                        <h2>{data?.length}</h2>
                    </div>
                </div>
                <div className={styles.card}>
                    <div className={styles.icondiv}>
                        <GroupPeopleIcon className={styles.icon} />
                    </div>
                    <div className={styles.details}>
                        <p>Total Number Graded</p>
                        <h2>{graded?.length}</h2>
                    </div>
                </div>
                <div className={styles.card}>
                    <div className={styles.icondiv}>
                        <GroupPeopleIcon className={styles.icon} />
                    </div>
                    <div className={styles.details}>
                        <p>Total Number Ungraded</p>
                        <h2>{data?.length - graded?.length}</h2>
                    </div>
                </div>
            </div>
            <div>
                <div className={`${globalstyles["table-container"]}`}>
                    <table>
                        <thead>
                            <tr className={`${globalstyles["table-row"]}`}>
                                <th className={`${globalstyles["table-header"]}`}>
                                    <div className={`${globalstyles["first-square"]}`}>
                                        <div className={`${globalstyles["check-box"]}`}></div>
                                        <p>Registration Number</p>
                                    </div>
                                </th>
                                <th className={`${globalstyles["table-header"]}`}>
                                    Fisrt Name
                                </th>
                                <th className={`${globalstyles["table-header"]}`}>Last Name</th>
                                <th className={`${globalstyles["table-header"]}`}>Time of Submission</th>
                                <th className={`${globalstyles["table-header"]}`}>
                                    Action
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {filteredSubmissions.length > 0 ? (
                                filteredSubmissions.map((item, index) => {
                                    return (
                                        <tr
                                            key={index}
                                            className={`${globalstyles["table-row-container"]}`}
                                            onClick={() =>
                                                navigate(
                                                    `/tutor/course/${item.rn}/${item.rn}/students`
                                                )
                                            }
                                        >
                                            <td
                                                className={`${globalstyles["table-row"]}`}
                                                style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div
                                                    className={`${globalstyles["check-box"]}`}
                                                    style={{ marginInlineEnd: 16 }}
                                                ></div>
                                                <p style={{ whiteSpace: "nowrap" }}>
                                                    {item.rn}
                                                </p>
                                            </td>
                                            <td className={` ${globalstyles["table-row"]}`}>
                                                <p>{item.fn}</p>
                                            </td>
                                            <td className={` ${globalstyles["table-row"]}`}>
                                                <p>{item.ln}</p>
                                            </td>
                                            <td>{item.time}</td>
                                            <td><button
                                                style={{
                                                    border: 'none',
                                                    padding: "10px 25px",
                                                    background: "#800080",
                                                    color: "white",
                                                    borderRadius: 5
                                                }}
                                            >Review this Assesment</button></td>
                                        </tr>
                                    );
                                })
                            ) : data && data.length > 0 ? (
                                data.map((item, index) => {
                                    return (
                                        <tr
                                            key={index}
                                            className={`${globalstyles["table-row-container"]}`}

                                        >
                                            <td
                                                className={`${globalstyles["table-row"]}`}
                                                style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div
                                                    className={`${globalstyles["check-box"]}`}
                                                    style={{ marginInlineEnd: 16 }}
                                                ></div>
                                                <p style={{ whiteSpace: "nowrap" }}>
                                                    {item?.student?.email}
                                                </p>
                                            </td>
                                            <td className={` ${globalstyles["table-row"]}`}>
                                                <p>{item?.student?.first_name}</p>
                                            </td>
                                            <td className={` ${globalstyles["table-row"]}`}>
                                                <p>{item?.student?.last_name}</p>
                                            </td>
                                            <td>{item?.recorded_at}</td>
                                            <td><button
                                                style={{
                                                    border: 'none',
                                                    padding: "10px 25px",
                                                    background: "#800080",
                                                    color: "white",
                                                    borderRadius: 5,
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() =>
                                                    navigate(
                                                        `/tutor/assessment/${item?.assessment?.id}/${item?.student?.id}/grade/${course}`
                                                    )
                                                }
                                            >Review this Assesment</button></td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={5}>No Submissions Found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className={`${globalstyles["pagination-container"]}`}>
                    <p>
                        Page <span style={{ fontWeight: "bold" }}>1/1</span> Total:{" "}
                        <span style={{ fontWeight: "bold" }}>
                            {data?.length ?? 0} Submissions
                        </span>
                    </p>
                    <div className={`${globalstyles["btn-container"]}`}>
                        <div
                            className={`${globalstyles.icons} ${globalstyles["left-icon"]}`}
                        >
                            <ChevronIcon />
                        </div>
                        <div
                            className={`${globalstyles.icons} ${globalstyles["right-icon"]}`}
                        >
                            <ChevronIcon />
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
}

export default CourseSubmissions