import React, { useEffect, useState } from "react";
import Button from "../../../../../components/Button/customButton/Button";
import Search from "../../../../../components/Search/Search";
import ChevronIcon from "../../../../../assets/icons/chevron.icon";
import own_styles from "./applicationRequest.module.css";
import styles from "../generalStyles.module.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DropDownS from "../../../../../components/Inputs/DropdownS/DropDownS";
import { useApplication } from "../../../../../query/admin/applicationRequest";
import { useDepartment } from "../../../../../query/admin/useDepartment";
import Loader from "../../../../../components/Loader/Loader";

export default function ApplicationRequests() {
  /** state management */
  const [filterFaculty, setFilterFaculty] = useState("");
  const [filterDepartment, setFilterDepartment] = useState("");
  const navigate = useNavigate();

  const requestData = useSelector(
    (state) => state.institution.applicationRequests
  );
  const [applicantFilterData, setApplicantFilterData] = useState(requestData);
  const facultyData = useSelector((state) => state.institution.facultyData);
  const [departmentData, setDepartmentData] = useState([]);
  const [genderFilter, setGenderFilter] = useState("");
  const [orderByCreatedAtFilter, setOrderByCreatedAtFilter] = useState("");
  const [status, setStatus] = useState("");

  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  /** Query functions */
  const { getFilterApplicants } = useApplication();
  // const { getLevelsForFaculty } = useFaculty();
  const { getFilterDepartments } = useDepartment();
  useEffect(() => {
    setIsLoading(true);
    getFilterApplicants(
      genderFilter,
      status,
      filterDepartment,
      filterFaculty,
      orderByCreatedAtFilter
    ).then((response) => {
      if (response) {
        setApplicantFilterData(response);
      }
      setIsLoading(false);
    });
  }, [filterFaculty, filterDepartment]);

  useEffect(() => {
    if (filterFaculty !== "") {
      setIsLoading(true);
      getFilterDepartments(filterFaculty).then((response) => {
        if (response) {
          setDepartmentData(response.results);
        }
        setIsLoading(false);
      });

      // getLevelsForFaculty(facultyId).then((response) => {
      //   if (response) {
      //     setLevelData(response);
      //   }
      // });
    } else {
      setFilterDepartment("");
      setDepartmentData([]);
    }
  }, [filterFaculty]);

  return (
    <div>
      {isLoading && <Loader />}
      <div className={`${styles["header-container"]}`}>
        <h2>Application Requests</h2>

        <div className={`${styles["action-container"]}`}>
          <DropDownS
            placeholder={"Select Faculty"}
            value={filterFaculty}
            setValue={setFilterFaculty}
            secondary={true}
            data={facultyData.results}
            keyItem={"name"}
          />
          <DropDownS
            placeholder={"Select Department"}
            value={filterDepartment}
            setValue={setFilterDepartment}
            data={departmentData}
            secondary={true}
            keyItem={"name"}
          />

          <div style={{ maxWidth: 300 }}>
            <Search
              value={searchValue}
              setValue={setSearchValue}
              placeholder={"Find Student Record"}
            />
          </div>
        </div>
      </div>

      {/* Faculty Table */}
      <div className={`${styles["table-container"]}`}>
        <table>
          <thead>
            <tr className={`${styles["table-row"]}`}>
              <th className={`${styles["table-header"]}`}>
                <div className={`${styles["first-square"]}`}>
                  <div className={`${styles["check-box"]}`}></div>
                  <p>Registration Number</p>
                </div>
              </th>
              <th className={`${styles["table-header"]}`}>First Name</th>
              <th className={`${styles["table-header"]}`}>Last Name</th>
              <th className={`${styles["table-header"]}`}>Gender</th>
              <th className={`${styles["table-header"]}`}>DOB</th>
              <th className={`${styles["table-header"]}`}>Nationality</th>
              <th className={`${styles["table-header"]}`}>Email</th>
              <th className={`${styles["table-header"]}`}>Contact</th>
            </tr>
          </thead>

          <tbody>
            {applicantFilterData.results?.map((requestData, index) => {
              return (
                <tr
                  key={index}
                  className={`${styles["table-row-container"]}`}
                  onClick={() =>
                    navigate(`/admin/application-requests/${requestData.id}`)
                  }
                >
                  <td
                    className={`${styles["table-row"]}`}
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className={`${styles["check-box"]}`}
                      style={{ marginInlineEnd: 16 }}
                    ></div>
                    <p style={{ whiteSpace: "nowrap" }}>
                      {requestData.applicant_id}
                    </p>
                  </td>
                  <td className={` ${styles["table-row"]}`}>
                    <p>{requestData.first_name}</p>
                  </td>
                  <td className={` ${styles["table-row"]}`}>
                    <p>{requestData.last_name}</p>
                  </td>
                  <td>{requestData.gender}</td>
                  <td>{requestData.date_of_birth}</td>
                  <td className={` ${styles["table-row"]}`}>
                    <p>{requestData.nationality}</p>
                  </td>
                  <td className={` ${styles["table-row"]}`}>
                    <p>{requestData.email}</p>
                  </td>
                  <td>{requestData.phone}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className={`${styles["pagination-container"]}`}>
        <p>
          Page <span style={{ fontWeight: "bold" }}>1/1</span> Total:{" "}
          <span style={{ fontWeight: "bold" }}>
            {applicantFilterData.count ?? 0} applicants
          </span>
        </p>
        <div className={`${styles["btn-container"]}`}>
          <div className={`${styles.icons} ${styles["left-icon"]}`}>
            <ChevronIcon />
          </div>
          <div className={`${styles.icons} ${styles["right-icon"]}`}>
            <ChevronIcon />
          </div>
        </div>
      </div>
    </div>
  );
}
