import React from "react";

export default function LogoWhiteIcon({ ...props }) {
  return (
    <div>
      <svg
        width="59"
        height="57"
        viewBox="0 0 59 57"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g id="&#240;&#159;&#166;&#134; icon &#34;book saved&#34;">
          <g id="Group">
            <path
              id="Vector"
              d="M57 8.34955V41.5422C57 44.1822 54.855 46.6569 52.215 46.9869L51.3075 47.0972C45.3125 47.8947 36.0725 50.9469 30.7925 53.8619C30.0775 54.2744 28.895 54.2744 28.1525 53.8619L28.0425 53.8072C22.7624 50.9197 13.5501 47.8947 7.58258 47.0972L6.78497 46.9869C4.14497 46.6569 2 44.1822 2 41.5422V8.32202C2 5.04952 4.66742 2.57455 7.93992 2.84955C13.7149 3.31705 22.4599 6.23213 27.355 9.28463L28.0425 9.69702C28.84 10.1921 30.16 10.1921 30.9575 9.69702L31.425 9.39458C33.1575 8.32208 35.3575 7.24955 37.75 6.28705V17.5071L43.25 13.8496L48.75 17.5071V3.15219C49.4925 3.01469 50.2075 2.93208 50.8675 2.87708H51.0325C54.305 2.60208 57 5.04955 57 8.34955Z"
              stroke="white"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_2"
              d="M29.5 10.6047V51.8547"
              stroke="white"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_3"
              d="M48.75 3.15228V17.5072L43.25 13.8496L37.75 17.5072V6.28715C41.3525 4.85715 45.3675 3.70228 48.75 3.15228Z"
              stroke="white"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}
