import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, NavLink } from "react-router-dom";
import logo_blue from "../SVG_Components/logo_blue.svg";
import logo from "../SVG_Components/logo.svg";
import loginpassicon from "../SVG_Components/loginpassicon.svg";
import view_password from "../SVG_Components/view_password.svg";
import create_pass_guy from "../SVG_Components/create_pass_guy_icon.svg";
import create_pass_guy_bkg from "../SVG_Components/create_pass_guy_bkgd.svg";
import { createNewPassword } from "../../../../store/session";
import Button from "../../../../components/Button/customButton/Button";

const CreateNewPassword = () => {
  const [errors, setErrors] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [isInputFocused, setInputFocus] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmPassword] = useState(false);
  const navigate = useNavigate();

  const user = useSelector((state) => state.session.user);
  const dispatch = useDispatch();

  const onNewPassword = async (e) => {
    e.preventDefault();
    const data = await dispatch(createNewPassword(password));
    if (data) {
      setErrors(data);
    }
  };

  const updatePassword = (e) => {
    setPassword(e.target.value);
  };
  const updateRepeatPassword = (e) => {
    setRepeatPassword(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPassword(!showConfirmPassword);
  };

  if (user) {
    return <Navigate to="/" />;
  }

  return (
    <div className="auth_container">
      <Link to="/" style={{ textDecoration: "none" }}>
        <div className="auth_logo_div hidden">
          <div className="flex_row_start">
            <span className="auth_logo_span">
              <img src={logo_blue} alt="Logo" className="auth_logo" />
              Enchird
            </span>
          </div>
          <div className="logo_subtitle">
            <span>Bring learning closer to you</span>
          </div>
        </div>
      </Link>
      <div className="auth_body">
        <div className="first_auth_container">
          <Link to="/" style={{ textDecoration: "none" }}>
            <div className="auth_logo_div">
              <div className="text_logo_div">
                <img src={logo} alt="Logo" className="auth_logo" />
                <span className="auth_logo_span"> Enchird </span>
              </div>
              <div className="logo_subtitle">
                <span>Bring learning closer to you</span>
              </div>
            </div>
          </Link>
          <div className="auth_footer cnp">
            <div className="cp_icon_div">
              <div className="cnp_icon_div">
                <div className="cnp_icon_overlay">
                  <img
                    src={create_pass_guy}
                    alt="Student"
                    className="cnp_icon"
                  />
                </div>
                <img
                  src={create_pass_guy_bkg}
                  alt="Student"
                  className="cnp_icon"
                />
              </div>
              <div>
                <p className="auth_footer_text">
                  Yay!, you can now reset your password by creating a new one.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="second_auth_container">
          <div className="outer_form_auth_div">
            <div className="auth_form_div">
              <form className="auth_form" onSubmit={onNewPassword}>
                <div className="auth_form_header_div">
                  <h1>Welcome Back</h1>
                </div>
                <div className="error_div cnp">
                  <p>{errors}</p>
                </div>
                <div className="input_Div">
                  <label className="input">
                    <input
                      className="input__field"
                      type={showPassword ? "text" : "password"}
                      placeholder=" "
                      value={password}
                      onChange={updatePassword}
                    />
                    <span className="input__label login">
                      <img
                        className="auth_icon"
                        src={loginpassicon}
                        alt="loginIcon"
                        width={32}
                        height={32}
                      />
                      New Password
                    </span>
                    <div
                      className="view_password_icon"
                      onClick={togglePasswordVisibility}
                    >
                      <img
                        className="auth_icon"
                        src={view_password}
                        alt="loginIcon"
                        width={32}
                        height={32}
                      />
                    </div>
                  </label>
                </div>
                <div className="input_Div">
                  <label className="input">
                    <input
                      className="input__field"
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder=" "
                      value={repeatPassword}
                      onChange={updateRepeatPassword}
                    />
                    <span className="input__label login">
                      <img
                        className="auth_icon"
                        src={loginpassicon}
                        alt="loginIcon"
                        width={32}
                        height={32}
                      />
                      Confirm New Password
                    </span>
                    <div
                      className="view_password_icon"
                      onClick={toggleConfirmPasswordVisibility}
                    >
                      <img
                        className="auth_icon"
                        src={view_password}
                        alt="loginIcon"
                        width={32}
                        height={32}
                      />
                    </div>
                  </label>
                </div>

                {/* <button className="login_btn">Create Password</button> */}
                <Button text={"Submit New Password"} rounded />
                <div className="hv_accnt">
                  <div className="hv_accnt_text">
                    <p>Remember your password?</p>
                  </div>
                  <Link to="/login" style={{ textDecoration: "none" }}>
                    <div className="hv_accnt_redirect">
                      <p>Login</p>
                    </div>
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNewPassword;
