import React, { useState } from "react";
import StudentLayout from "../components/Layout/StudentLayout";
import styles from "./mainDashboard.module.css";
import PersonalInformationDetails from "../../../auth/Signup/components/Confirmation/component/PersonalInformation/PersonalInformation";
import IdentityDetails from "../../../auth/Signup/components/Confirmation/component/Identity/Identity";
import ResidenceInfoDetails from "../../../auth/Signup/components/Confirmation/component/ResidenceInformation/ResidenceInfo";
import GuardianInfoDetails from "../../../auth/Signup/components/Confirmation/component/GuardianInformation/GuardianInfo";
import SupportingDocumentsDetails from "../../../auth/Signup/components/Confirmation/component/SupportingDocuments/SupportingDocuments";
import MessageGrayIcon from "../../../../assets/icons/message-gray.icon";
import PhoneIcon from "../../../../assets/icons/phone.icon";
import HouseIcon from "../../../../assets/icons/house.icon";
import Carousel from "../../../auth/Signup/components/Confirmation/component/Carousel";
import Button from "../../../../components/Button/customButton/Button";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function MainStudentDashboard() {
  /** State management */
  const studentInfo = useSelector((state) => state.user.user);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();

  const details = [
    {
      letter: "A",
      title: "Personal Information",
      node: (
        <PersonalInformationDetails
          firstName={studentInfo.first_name}
          lastName={studentInfo.last_name}
          nationality={studentInfo.nationality}
          gender={studentInfo.gender}
          birthDate={studentInfo.date_of_birth}
          email={studentInfo.email}
          phone={studentInfo.phone}
        />
      ),
    },
    {
      letter: "B",
      title: "Identity (KYC)",
      node: (
        <IdentityDetails
          idCardNumber={studentInfo.id_card_number}
          idCardDocument={studentInfo.scanned_id_document}
          profilePicture={studentInfo.profile_picture}
          locat={"dashboard"}
        />
      ),
    },
    {
      letter: "C",
      title: "Residence Information",
      node: (
        <ResidenceInfoDetails
          primaryLocation={studentInfo.primary_location}
          secondaryLocation={studentInfo.secondary_location}
        />
      ),
    },
    {
      letter: "D",
      title: "Guardian Information",
      node: (
        <GuardianInfoDetails
          guardianOneName={studentInfo.guardian1_name}
          guardianOneContact={studentInfo.guardian1_contact}
          guardianTwoContact={studentInfo.guardian2_contact}
          guardianTwoName={studentInfo.guardian2_name}
        />
      ),
    },
    {
      letter: "E",
      title: "Supporting Information",
      node: (
        <SupportingDocumentsDetails
          coverLetter={studentInfo.motivation_letter}
          documents={[...(studentInfo.past_achievement_documents ?? [])]}
          locat={"dashboard"}
        />
      ),
    },
  ];

  return (
    <div>
      <StudentLayout showProgram={true}>
        <>
          <div className={`${styles["summary_container"]}`}>
            <div className={`${styles["profile_picture"]}`}>
              <img src={studentInfo.profile_picture ?? ""} alt="" />
            </div>
            <div>
              <h3 className={`${styles["contact_info_heading"]}`}>
                Contact Information
              </h3>
              <ul className={`${styles["contact_info_container"]}`}>
                <li>
                  <p>
                    <span>
                      <MessageGrayIcon width={24} height={24} />
                    </span>
                    <span>{studentInfo.email}</span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <PhoneIcon width={24} height={24} />
                    </span>
                    <span>{studentInfo.phone}</span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <HouseIcon width={24} height={24} />
                    </span>
                    <span>{studentInfo.primary_location}</span>
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className={`${styles["btn_container"]}`}>
            <div>
              <Button
                text={"Program - Bachelors"}
                backgroundColor={"#800080"}
                width={"fit-content"}
              />
            </div>
            <div>
              <Button
                text={`Admission Number - ${studentInfo.applicant_id}`}
                backgroundColor={"#2218A7"}
                width={"fit-content"}
              />
            </div>
          </div>

          <div className={`${styles.detail_info}`}>
            {details.map((detail, index) => {
              return (
                <Carousel
                  key={index}
                  title={detail.title}
                  letter={detail.letter}
                  children={detail.node}
                  index={index}
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                  locat={"dashboard"}
                  // setGotToEditInfo={setGotToEditInfo}
                />
              );
            })}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBlock: 40,
            }}
          >
            <Button
              text={"Edit Profile information"}
              width={"fit-content"}
              onClick={() => navigate("/dashboard/update-detail-information")}
            />
          </div>
        </>
      </StudentLayout>
    </div>
  );
}
