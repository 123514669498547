import React, { useState } from "react";
import styles from "./signup.module.css";
import { Link, useNavigate } from "react-router-dom";
import AuthLayout from "../../../components/Layout/auth/AuthLayout";
import PersonalInformation from "./components/PersonalInformation/PersonalInformation";
import RegistrationStepCrumbs from "../../../components/RegistrationStepCrumbs/RegistrationStepCrumbs";
import Button from "../../../components/Button/customButton/Button";
import Identity from "./components/Identity/Identity";
import ResidenceInformation from "./components/ResidenceInformation/ResidenceInformation";
import GuardianInformation from "./components/GuardianInformation/GuardianInformation";
import SupportingInformation from "./components/SupportingInformation/SupportingInformation";
import PersonWhiteIcon from "../../../assets/icons/person-white.icon";
import ClockIcon from "../../../assets/icons/clock.icon";
import FileIcon from "../../../assets/icons/file.icon";
import GuardianIcon from "../../../assets/icons/guardian.icon";
import HouseIcon from "../../../assets/icons/house.icon";
import { handleCreateStudent } from "../../../store/session";
import FacultyAndProgram from "./components/FacultyAndProgram/FacultyAndProgram";
import ConfirmInfo from "./components/Confirmation/ConfirmInfo";
import { useIonToast } from "@ionic/react";
import ApplicationSubmitted from "./components/ApplicationSubmitted/ApplicationSubmitted";
import docUpload from "../../../utils/functions/handleUploadToFirebase";

export default function SignUp() {
  /** State management */
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [toast] = useIonToast();

  /** State managemen for Faculty and Program */
  const [faculty, setFaculty] = useState("");
  const [program, setProgram] = useState("");

  /** State management */
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nationality, setNationality] = useState("");
  const [gender, setGender] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  /** State management for guardian*/
  const [guardianOneName, setGuardianOneName] = useState("");
  const [guardianOneContact, setGuardianOneContact] = useState("");
  const [guardianTwoName, setGuardianTwoName] = useState("");
  const [guardianTwoContact, setGuardianTwoContact] = useState("");

  /** State Management for residential location*/
  const [primaryLocation, setPrimaryLocation] = useState("");
  const [secondaryLocation, setSecondaryLocation] = useState("");

  /** State management for Identity/KYC */
  const [idCardNumber, setIdCardNumber] = useState("");
  const [idCardDocument, setIdCardDocument] = useState({
    fileName: "",
    file: null,
    preview: "",
  });
  const [profilePicture, setProfilePicture] = useState({
    fileName: "",
    file: null,
    preview: "",
  });

  /** State management for supportive information*/
  const [coverLetter, setCoverLetter] = useState("");
  const [documents, setDocuments] = useState([]);

  const [activeStep, setActiveStep] = useState(0);
  let studentObject = {
    firstName,
    lastName,
    profilePicture,
    nationality,
    gender,
    birthDate,
    email,
    phone,
    faculty,
    program,
    idCardNumber,
    idCardDocument,
    primaryLocation,
    secondaryLocation,
    guardianOneName,
    guardianOneContact,
    guardianTwoName,
    guardianTwoContact,
    coverLetter,
    documents,
  };

  const registrationStepContent = [
    {
      title: "Faculty & Program",
      node: (
        <FacultyAndProgram
          faculty={faculty}
          setFaculty={setFaculty}
          program={program}
          setProgram={setProgram}
        />
      ),
      Icon: [
        <PersonWhiteIcon
          width={212}
          height={212}
          className={`${styles["large-person"]}`}
        />,
        <PersonWhiteIcon
          width={112}
          height={112}
          className={`${styles["medium-person"]}`}
        />,
      ],
    },
    {
      letter: "A",
      title: "Personal Information",
      node: (
        <PersonalInformation
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          nationality={nationality}
          setNationality={setNationality}
          gender={gender}
          setGender={setGender}
          birthDate={birthDate}
          setBirthDate={setBirthDate}
          email={email}
          setEmail={setEmail}
          phone={phone}
          setPhone={setPhone}
        />
      ),
      Icon: [
        <PersonWhiteIcon
          width={212}
          height={212}
          className={`${styles["large-person"]}`}
        />,
        <PersonWhiteIcon
          width={112}
          height={112}
          className={`${styles["medium-person"]}`}
        />,
      ],
    },
    {
      letter: "B",
      title: "Identity (KYC)",
      node: (
        <Identity
          idCardNumber={idCardNumber}
          setIdCardNumber={setIdCardNumber}
          idCardDocument={idCardDocument}
          setIdCardDocument={setIdCardDocument}
          profilePicture={profilePicture}
          setProfilePicture={setProfilePicture}
        />
      ),
      Icon: [
        <ClockIcon
          width={212}
          height={212}
          className={`${styles["large-person"]}`}
        />,
        <ClockIcon
          width={112}
          height={112}
          className={`${styles["medium-person"]}`}
        />,
      ],
    },
    {
      letter: "C",
      title: "Residence Information",
      node: (
        <ResidenceInformation
          primaryLocation={primaryLocation}
          setPrimaryLocation={setPrimaryLocation}
          secondaryLocation={secondaryLocation}
          setSecondaryLocation={setSecondaryLocation}
        />
      ),
      Icon: [
        <HouseIcon
          width={212}
          height={212}
          className={`${styles["large-person"]}`}
        />,
        <HouseIcon
          width={112}
          height={112}
          className={`${styles["medium-person"]}`}
        />,
      ],
    },
    {
      letter: "D",
      title: "Guardian Information",
      node: (
        <GuardianInformation
          guardianOneName={guardianOneName}
          guardianOneContact={guardianOneContact}
          setGuardianOneName={setGuardianOneName}
          setGuardianOneContact={setGuardianOneContact}
          guardianTwoContact={guardianTwoContact}
          guardianTwoName={guardianTwoName}
          setGuardianTwoName={setGuardianTwoName}
          setGuardianTwoContact={setGuardianTwoContact}
        />
      ),
      Icon: [
        <GuardianIcon
          width={212}
          height={212}
          className={`${styles["large-person"]}`}
        />,
        <GuardianIcon
          width={112}
          height={112}
          className={`${styles["medium-person"]}`}
        />,
      ],
    },
    {
      letter: "E",
      title: "Supporting Information",
      node: (
        <SupportingInformation
          coverLetter={coverLetter}
          setCoverLetter={setCoverLetter}
          documents={[...documents]}
          setDocuments={setDocuments}
        />
      ),
      Icon: [
        <FileIcon
          width={212}
          height={212}
          className={`${styles["large-person"]}`}
        />,
        <FileIcon
          width={112}
          height={112}
          className={`${styles["medium-person"]}`}
        />,
      ],
    },
    {
      node: (
        <ConfirmInfo
          studentInfo={studentObject}
          setGotToEditInfo={setActiveStep}
        />
      ),
      Icon: [
        <FileIcon
          width={212}
          height={212}
          className={`${styles["large-person"]}`}
        />,
        <FileIcon
          width={112}
          height={112}
          className={`${styles["medium-person"]}`}
        />,
      ],
    },
    {
      node: <ApplicationSubmitted />,
      Icon: [
        <FileIcon
          width={212}
          height={212}
          className={`${styles["large-person"]}`}
        />,
        <FileIcon
          width={112}
          height={112}
          className={`${styles["medium-person"]}`}
        />,
      ],
    },
  ];

  /**
   * ====================================
   *  Utility funcitons
   * ====================================
   */

  /** Handle Previous */
  function handlePrevious() {
    setActiveStep(activeStep - 1);
  }

  function handleNext() {
    setActiveStep(activeStep + 1);
  }

  async function handleSubmitApplication(e) {
    setIsLoading(true);
    const folder = `${firstName.split(" ")[0]}_${lastName.split(" ")[0]}`;
    console.log(
      profilePicture.fileName.split(".")[
        profilePicture.fileName.split(".").length - 1
      ]
    );
    const profileImage = await docUpload(
      profilePicture.file,
      folder,
      `${folder}_profile_picture.${
        profilePicture.fileName.split(".")[
          profilePicture.fileName.split(".").length - 1
        ]
      }`
    );
    const idCardDocs = await docUpload(
      idCardDocument.file,
      folder,
      `${folder}_id_card.pdf`
    );

    let documentWithUrl = [];

    async function handleUploadDocToAWS() {
      return documents.forEach(async (doc) => {
        const link = await docUpload(
          doc.file,
          folder,
          `${folder}_${doc.fileName}`
        );
        documentWithUrl.push({ name: doc.fileName, document_path: link });
      });
    }

    await handleUploadDocToAWS()
      .then((response) => {
        setTimeout(() => {
          studentObject = {
            first_name: firstName,
            last_name: lastName,
            profile_picture: profileImage,
            nationality,
            gender,
            date_of_birth: birthDate,
            email,
            phone,
            faculty,
            department: program,
            id_card_number: idCardNumber,
            scanned_id_document: idCardDocs,
            primary_location: primaryLocation,
            secondary_location: secondaryLocation,
            guardian1_name: guardianOneName,
            guardian1_contact: guardianOneContact,
            guardian2_name: guardianTwoName,
            guardian2_contact: guardianTwoContact,
            motivation_letter: coverLetter,
            documents: documentWithUrl,
          };
          handleCreateStudent(studentObject).then((response) => {
            if (response) {
              setActiveStep(7);
            }
            setIsLoading(false);
          });
        }, 3000);
      })
      .catch((error) => {
        toast({
          message: `Uploading file: ${error.message}`,
          color: "danger",
          buttons: ["Ok"],
        });
      });
  }

  return (
    <AuthLayout Icon={registrationStepContent[activeStep].Icon}>
      <div className={`${styles["registration-container"]}`}>
        {activeStep > 0 && activeStep <= 5 && (
          <>
            {" "}
            <h1>Registration Request</h1>
            <div className={`${styles.crumb}`}>
              <RegistrationStepCrumbs
                active={activeStep}
                setActive={setActiveStep}
              />
            </div>
            <h2>
              {registrationStepContent[activeStep].letter +
                "." +
                " " +
                registrationStepContent[activeStep].title}
            </h2>
          </>
        )}

        <>{registrationStepContent[activeStep].node}</>

        {activeStep < 7 && (
          <div className={`${styles["button-container"]}`}>
            {activeStep >= 1 && activeStep <= 5 && (
              <Button
                text={registrationStepContent[activeStep - 1].title}
                onClick={() => handlePrevious()}
              />
            )}
            <Button
              text={activeStep === 6 ? "Submit Information" : "Save & continue"}
              onClick={() => {
                activeStep === 6 ? handleSubmitApplication() : handleNext();
              }}
              isLoading={activeStep === 6 ? isLoading : false}
              disabled={
                activeStep === 0
                  ? faculty === "" || program === ""
                  : activeStep === 1
                  ? firstName === "" ||
                    lastName === "" ||
                    birthDate === "" ||
                    gender === "" ||
                    email === "" ||
                    phone === ""
                  : activeStep === 2
                  ? idCardNumber === "" ||
                    idCardDocument.fileName === "" ||
                    profilePicture.fileName === ""
                  : activeStep === 3
                  ? primaryLocation === "" || secondaryLocation === ""
                  : activeStep === 4
                  ? guardianOneName === "" ||
                    guardianOneContact === "" ||
                    guardianTwoContact === "" ||
                    guardianTwoName === ""
                  : activeStep === 5
                  ? coverLetter === "" || documents.length === 0
                  : activeStep === 6
                  ? isLoading
                  : false
              }
            />
          </div>
        )}

        {activeStep < 7 && (
          <div className={`${styles["already-exist"]}`}>
            <p>
              Already have an account?{" "}
              <span>
                <Link to="/login">Login</Link>
              </span>
            </p>
          </div>
        )}
      </div>
    </AuthLayout>
  );
}
