import styles from "./MainDashboard.module.css"
import { MdGroups2 } from "react-icons/md";
import { PiVideoFill } from "react-icons/pi";
import { FaArrowRightLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import globalstyles from "../../../admin/components/generalStyles.module.css";
import { useSelector } from 'react-redux';
import { useZoom } from '../../../../../query/tutor/useZoom';
import { useTutorAuth } from '../../../../../query/tutor/auth';
import { useAssessments } from "../../../../../query/tutor/useAssessments";
import { useChats } from "../../../../../query/tutor/useChat";
import { useCourses } from "../../../../../query/tutor/useCourses";
import { useState, useEffect } from "react";
import Loader from "../../../../../components/Loader/Loader";

const MainTutorDashboard = () => {
  const userInfo = useSelector((state) => state.user)
  const assessments = useSelector((state) => state.assessment.assessments.data);
  const [isLoading, setIsLoading] = useState(false)
  const [meetings, setMeetings] = useState([])
  const [coursesData, setCoursesData] = useState([]);
  const [tutorAssessments, setTutorAssessments] = useState([]);
  const [assignments, setAssignments] = useState([])
  const [chats, setChats] = useState([])
  const [groups, setGroups] = useState([]);

  const navigate = useNavigate();
  const { handleGetMeetings } = useZoom()
  const { getTutorInfo } = useTutorAuth()
  const { handleGetAssessments } = useAssessments();
  const { handleGetChats } = useChats();
  const { handleGetCourseGroups } = useCourses()

  const getTutorData = async () => {
    if (userInfo.user.id !== undefined) {
      const id = userInfo.user.id;
      getTutorInfo(id);
      return;
    }
  };

  async function handleGetLinks() {
    try {
      setIsLoading(true);
      await handleGetMeetings().then((res) => {
        const tutorMeetings = res.meetings.filter((item) => item.created_by.id === userInfo.user.id)
        const filtered = tutorMeetings?.filter((item) => {
          const dateObject = new Date(item?.start_time);
          const currentDate = new Date();
          return currentDate.getTime() < dateObject.getTime();
        });
        setMeetings(filtered)
        setIsLoading(false);
      })
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  async function getChatsHandler() {
    try {
      setIsLoading(true);
      await handleGetChats().then((res) => {
        setChats(res)
        setIsLoading(false);
        return
      })
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  const getAssessments = async () => {
    if (userInfo.user.id !== undefined) {
      const id = userInfo.user.id;
      await handleGetAssessments(id)
      return;
    }
  };

  const getTutorAssessments = () => {
    if (assessments?.length > 0) {
      const assmts = assessments.filter(
        (item) => item.instructor === userInfo.user.id
      );
      setTutorAssessments(assmts);
    }
  };

  const getGroups = async () => {
    setIsLoading(true);
    await handleGetCourseGroups().then((res) => {
      setGroups(res)
    }).catch((err) => {
      console.error(err)
      setIsLoading(false);
    })
  };

  useEffect(() => {
    setCoursesData(userInfo.user?.courses_detail);
  }, [userInfo.user]);

  useEffect(() => {
    getGroups()
    getTutorData();
    handleGetLinks()
    getAssessments();
    getChatsHandler()
  }, [])

  useEffect(() => {
    getTutorAssessments();
  }, [assessments]);

  return (
    <div>
      {isLoading && <Loader />}
      <div className={styles.cards}>
        <div className={styles.card}>
          <div className={styles.groupicon}>
            <MdGroups2 size={25} color="white" />
          </div>
          <div className={styles.details}>
            <h3>Classroom Groups</h3>
            <p>{groups?.length || 0}</p>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.videoicon}>
            <PiVideoFill size={25} color="white" />
          </div>
          <div className={styles.details}>
            <h3>Upcoming Lessons</h3>
            <p>{meetings?.length || 0}</p>
          </div>
        </div>
      </div>

      <div className={styles.cards}>
        <div className={styles.events}>
          <h3>Upcoming Classes</h3>
          <div className={styles.recent}><p>Recent</p></div>
          <ul className={styles.list}>
            {meetings.length > 0 ?
              meetings.slice(0, 5).map((item, index) => {
                const dateObject = new Date(item?.start_time);
                const formattedDate = dateObject.toLocaleDateString(undefined, {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                });
                const formattedTime = dateObject.toLocaleTimeString(undefined, {
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                  hour12: false
                });
                return (
                  <li key={index}>
                    <h4>{item.topic}</h4>
                    <p>{`${formattedDate} ${formattedTime}`}</p>
                  </li>
                )
              }) :
              <div>
                No Data Found
              </div>
            }
          </ul>
          {meetings?.length > 0 ?
            <div className={styles.viewall}>
              <a href="#"><span>View all</span> <FaArrowRightLong color="#800080" /></a>
            </div> : ""}
        </div>
        <div className={styles.courses}>
          <h3>Manage Courses</h3>
          <div className={styles.recent}><p>Recent</p></div>
          <table>
            <thead>
              <tr className={`${globalstyles["table-row"]}`}>
                <th className={`${globalstyles["table-header"]}`}>
                  <div className={`${globalstyles["first-square"]}`}>
                    {/* <div className={`${globalstyles["check-box"]}`}></div> */}
                    <p>Course Name</p>
                  </div>
                </th>
                <th className={`${globalstyles["table-header"]}`}>
                  Course Code
                </th>
                <th className={`${globalstyles["table-header"]}`}>Faculty</th>
                <th className={`${globalstyles["table-header"]}`}>Level</th>
              </tr>
            </thead>

            <tbody>
              {coursesData && coursesData.length > 0 ? (
                coursesData.slice(0, 5).map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={`${globalstyles["table-row-container"]}`}
                      onClick={() =>
                        navigate(
                          `/tutor/course/${item.course_title}/${item.id}/students`
                        )
                      }
                    >
                      <td
                        className={`${globalstyles["table-row"]}`}
                        style={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        {/* <div
                          className={`${globalstyles["check-box"]}`}
                          style={{ marginInlineEnd: 16 }}
                        ></div> */}
                        <p style={{ whiteSpace: "nowrap" }}>
                          {item.course_title}
                        </p>
                      </td>
                      <td className={` ${globalstyles["table-row"]}`}>
                        <p>{item.course_code}</p>
                      </td>
                      <td>{item.faculty_details.abbrev}</td>
                      <td className={` ${globalstyles["table-row"]}`}>
                        {item.course_level}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={6} style={{ textAlign: 'center' }}>No Course Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className={styles.cards}>
        <div className={styles.recentassessments}>
          <h3>Recent Assignments</h3>
          <div className={styles.recent}><p>Recent</p></div>
          <ul className={styles.list}>
            {assignments?.length > 0 ? assignments.map((item, index) => {
              return (
                <li key={index}>
                  <h4>Environment discussion</h4>
                  <p>01:00PM - 12:00PM</p>
                </li>
              )
            }) :
              <div>
                No Data Found
              </div>
            }
          </ul>
          {assignments?.length > 0 ?
            <div className={styles.viewall}>
              <a href="#"><span>View all</span> <FaArrowRightLong color="#800080" /></a>
            </div> : ""}
        </div>
        <div className={styles.assessments}>
          <h3>Assessments</h3>
          <div className={styles.recent}><p>Recent</p></div>
          <ul className={styles.list}>
            {tutorAssessments?.length > 0 && coursesData?.length > 0 ?
              tutorAssessments?.slice(0, 5).map((item, index) => {
                const courseTitle = coursesData?.filter((c) => item.course === c.id)[0].course_title
                return (
                  <li key={index}>
                    <h4>{item.title}</h4>
                    <p>{courseTitle}</p>
                  </li>
                )
              }) :
              <div>
                No Data Found
              </div>}
          </ul>
          {tutorAssessments?.length > 0 && coursesData?.length > 0 &&
            <div className={styles.viewall}>
              <a href="#"><span>View all</span> <FaArrowRightLong color="#800080" /></a>
            </div>}
        </div>
        <div className={styles.chats}>
          <h3>Recent Chats</h3>
          <div className={styles.recent}><p>Recent</p></div>
          <ul className={styles.chatlist}>
            {chats?.length > 0 ? chats?.map((item, index) => {
              return (
                <li key={index}>
                  <div className={styles.imgDiv}>
                    <img src="/images/image.png" />
                  </div>
                  <div>
                    <h4>Sir am talking about xyz topics</h4>
                    <p>01:00PM - 12:00PM</p>
                  </div>
                </li>
              )
            }) :
              <div>
                No Data Found
              </div>
            }
          </ul>
          {chats?.length > 0 &&
            <div className={styles.viewall}>
              <a href="#"><span>View all</span> <FaArrowRightLong color="#800080" /></a>
            </div>}
        </div>
      </div>
    </div>
  );
};

export default MainTutorDashboard;
