import React, { useEffect, useState } from "react";
import TextInput from "../../Inputs/TextInput/TextInput";
import Button from "../../Button/customButton/Button";
import styles from "./createCourse.module.css";
import Dropdown from "../../Inputs/Dropdown/Dropdown";
import { COURSESTATUS } from "../../../utils/CONSTANTS/courseStatus";
import { useSelector } from "react-redux";
import { LEVELDATA } from "../../../utils/CONSTANTS/Level";
import { useDispatch } from "react-redux";
import { institutionSlice } from "../../../redux/features/institutionSlice";
import { useCourse } from "../../../query/admin/useCourse";
import { useFaculty } from "../../../query/admin/useFaculty";
import { useDepartment } from "../../../query/admin/useDepartment";
import { IonSpinner } from "@ionic/react";

export default function CreateCourseModal({ setViewCourseModal, courseId }) {
  /** State management */
  const [courseTitle, setCourseTitle] = useState("");
  const [courseCode, setCourseCode] = useState("");
  const [level, setLevel] = useState("");
  const [department, setDepartment] = useState("");
  const [faculty, setFaculty] = useState("");
  const [status, setStatus] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isGettingInfo, setIsGettingInfo] = useState(false);

  /** State from store */
  const facultyData = useSelector((state) => state.institution.facultyData);
  const [departmentData, setDepartmentData] = useState([]);
  const [levels, setLevels] = useState([]);

  const { createCourse, getSingleCourseInfo, updateCourseInfo, deleteCourse } =
    useCourse();
  const { getDepartments } = useDepartment();
  const { getLevelsForFaculty } = useFaculty();

  useEffect(() => {
    if (faculty !== "") {
      setIsLoading(true);
      getDetailFromFaculty().then(() => {
        setIsLoading(false);
      });
    }
  }, [faculty]);

  async function getDetailFromFaculty() {
    const facultyId = facultyData.results.filter((item) => {
      return item.name === faculty;
    })[0]?.id;
    await getDepartments(faculty).then((response) => {
      if (response) {
        setDepartmentData(response.results);
      }
    });

    await getLevelsForFaculty(facultyId).then((response) => {
      if (response) {
        setLevels(response);
      }
    });
  }

  function handleCreateCourse() {
    setIsLoading(true);
    const facultyId = facultyData?.results?.filter((item) => {
      return item.name === faculty;
    })[0]?.id;
    const departmentId = departmentData?.filter((item) => {
      return item.name === department;
    })[0]?.id;
    const course = {
      course_title: courseTitle,
      course_code: courseCode,
      course_level: level,
      department: departmentId,
      faculty: facultyId,
      course_status: status,
    };

    createCourse(course).then((response) => {
      if (response) {
        setViewCourseModal(false);
      }
      setIsLoading(false);
    });
  }

  useEffect(() => {
    if (courseId !== "") {
      setIsGettingInfo(true);
      getSingleCourseInfo(courseId).then((course) => {
        if (course !== null) {
          setCourseTitle(course.course_title);
          setCourseCode(course.course_code);
          setLevel(course.course_level);
          setFaculty(course.faculty_details.name);
          setStatus(course.course_status);
          setDepartment(course.department_details.name);
        }
        setIsGettingInfo(false);
      });
    }
  }, []);

  function handleUpdateCourse() {
    setIsUpdating(true);
    const facultyId = facultyData?.results?.filter((item) => {
      return item.name === faculty;
    })[0]?.id;
    const departmentId = departmentData?.filter((item) => {
      return item.name === department;
    })[0]?.id;
    const course = {
      course_title: courseTitle,
      course_code: courseCode,
      course_level: level,
      department: departmentId,
      faculty: facultyId,
      course_status: status,
    };

    updateCourseInfo(course, courseId).then((response) => {
      if (response) {
        console.log(response);
        setViewCourseModal(false);
      }
      setIsUpdating(false);
    });
  }

  /** handle delete course */
  function handleDeleteCourse() {
    if (courseId !== "") {
      deleteCourse(courseId).then((response) => {
        if (response) {
          setViewCourseModal(false);
        }
      });
    }
  }

  return (
    <div className={`${styles.container}`}>
      {courseId !== "" && isGettingInfo ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "50vh",
            height: "50vh",
            background: "#fff",
          }}
        >
          <IonSpinner
            name="lines"
            color={"dark"}
            style={{ width: 50, height: 50 }}
          />
        </div>
      ) : (
        <div className={`${styles.sub_container}`}>
          <div
            style={{ position: "absolute", right: 20, top: 0 }}
            onClick={() => {
              !isLoading && setViewCourseModal(false);
            }}
          >
            <p style={{ fontSize: 50, cursor: "pointer", color: "red" }}>
              &times;
            </p>
          </div>
          <h2 className={`${styles.title}`}>
            {courseId !== "" ? "Update Course Details" : "Add New Course"}
          </h2>
          <div className={`${styles.form_container}`}>
            <div>
              <Dropdown
                label={"Select Status"}
                placeholder={"Compulsory"}
                value={status}
                setValue={setStatus}
                data={COURSESTATUS}
                disabled={isLoading}
                keyItem={"id"}
              />
            </div>
            <div>
              <Dropdown
                label={"Select Faculty"}
                placeholder={"Department of Engineering and Technology"}
                value={faculty}
                setValue={setFaculty}
                data={facultyData.results}
                disabled={isLoading}
                keyItem={"name"}
              />
            </div>
            <div>
              <Dropdown
                label={"Select Department"}
                placeholder={"FET"}
                value={department}
                setValue={setDepartment}
                data={departmentData}
                keyItem={"name"}
              />
            </div>
            <div>
              <Dropdown
                label={"Select Level"}
                placeholder={"200"}
                value={level}
                setValue={setLevel}
                data={levels}
                disabled={isLoading}
                keyItem={"name"}
              />
            </div>
            <div>
              <TextInput
                label={"Course Name"}
                placeholder={"Linear Algebra"}
                value={courseTitle}
                setValue={setCourseTitle}
                disabled={isLoading}
              />
            </div>

            <div>
              <TextInput
                label={"Course Code"}
                placeholder={"CEF400"}
                value={courseCode}
                setValue={setCourseCode}
                disabled={isLoading}
              />
            </div>
          </div>

          {
            <div
              style={{
                marginTop: 50,
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {courseId !== "" ? (
                <div style={{ display: "flex", gap: 10 }}>
                  <Button
                    text={"Delete"}
                    onClick={() => handleDeleteCourse()}
                    isLoading={isLoading || isDeleting}
                    disabled={isLoading || isUpdating || isDeleting}
                    width={"fit-content"}
                    backgroundColor={"red"}
                  />

                  <Button
                    text={"Update Course Details"}
                    onClick={() => handleUpdateCourse()}
                    isLoading={isUpdating || isLoading}
                    disabled={
                      (courseTitle === "" && courseCode === "") ||
                      isLoading ||
                      isUpdating ||
                      isDeleting
                    }
                    width={"fit-content"}
                  />
                </div>
              ) : (
                <Button
                  text={"Save"}
                  onClick={() => handleCreateCourse()}
                  isLoading={isDeleting || isLoading}
                  disabled={
                    (courseTitle === "" && courseCode === "") || isLoading
                  }
                  width={"fit-content"}
                />
              )}
            </div>
          }
        </div>
      )}
    </div>
  );
}
