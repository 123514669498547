import React, { useState, useEffect } from 'react'
import styles from "./Grading.module.css"
import Button from '../../../../../../components/Button/customButton/Button'
import { useAssessments } from "../../../../../../query/tutor/useAssessments";
import { useParams } from 'react-router-dom'

const GradingAssessment = () => {
    const { id, student, course } = useParams()
    const { handleListSubmissions, handleGetAssessmentDetails,
        handleGradeAssessment, handleGetStudentGrades } = useAssessments()
    const [isLoading, setIsLoading] = useState(false)
    const [questions, setQuestions] = useState([])
    const [responses, setResponses] = useState([])
    const [scores, setScores] = useState([]);
    const [grade, setGrade] = useState(null);

    const getSubmissions = async () => {
        setIsLoading(true);
        await handleListSubmissions(id).then((res) => {
            setResponses(res?.responses)
            setIsLoading(false);
        }).catch((err) => {
            console.error(err)
            setIsLoading(false)
        })

    };

    const getDetails = async () => {
        if (id !== undefined) {
            setIsLoading(true);
            await handleGetAssessmentDetails(id).then((res) => {
                setQuestions(res?.questions)
                setIsLoading(false);
            }).catch((err) => {
                console.error("err", err)
                setIsLoading(false);
            })
            return;
        }
    };

    const registerScore = (questionId, score) => {
        const existingScoreIndex = scores.findIndex(item => item.question_id === questionId);
        if (existingScoreIndex !== -1) {
            const updatedScores = [...scores];
            updatedScores[existingScoreIndex] = { question_id: questionId, score: parseInt(score) };
            setScores(updatedScores);
        } else {
            setScores(prevScores => [...prevScores, { question_id: questionId, score: parseInt(score) }]);
        }
    };

    const gradeSubmission = async () => {
        try {
            setIsLoading(true);
            const data = {
                assessment: parseInt(id),
                student: parseInt(student),
                scores
            }
            await handleGradeAssessment(data).then((res) => {
                console.log(res)
                setIsLoading(false);
            }).catch((err) => {
                console.error(err)
                setIsLoading(false)
            })
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }

    };

    const getStudentScore = async () => {
        try {
            setIsLoading(true);
            const data = {
                assessment: parseFloat(id),
                student: parseFloat(student),
            }
            await handleGetStudentGrades(data).then((res) => {
                setGrade(res)
                setIsLoading(false);
            }).catch((err) => {
                console.error(err)
                setIsLoading(false)
            })
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }

    };

    useEffect(() => {
        getSubmissions()
        getDetails()
        getStudentScore()
    }, [id])

    return (
        <div>
            <h2>{course} Review</h2>
            <div className={styles.cards}>
                <div>
                    <h4>Current Grade</h4>
                    {grade !== undefined ? <p>{grade?.grade}</p> : <span>Ungraded</span>}
                </div>
                <div>
                    <h4>Percentage Score</h4>
                    {grade !== undefined ? <p>{grade?.percentage_score}%</p> : <span>Ungraded</span>}
                </div>
                <div>
                    <h4>Total Score</h4>
                    {grade !== undefined ? <p>{grade?.total_score}</p> : <span>Ungraded</span>}
                </div>
            </div>
            {questions && responses && responses.length > 0 && questions.length > 0 ? questions.map((item, index) => {
                const response = responses?.filter((res) => (res?.student?.id === parseInt(student) && res?.question?.id === item.id))[0]?.text_response
                return (
                    <div style={{ marginTop: 20 }} key={index}>
                        <h3 style={{ textAlign: 'left', display: 'flex' }}>
                            <p>{index + 1}{". "}</p>
                            <div dangerouslySetInnerHTML={{ __html: item?.text }} /> <span style={{ marginLeft: 10 }}>({item?.mark_allocated} Marks)</span>
                        </h3>
                        <p style={{ marginTop: 10 }}>
                            {response}
                        </p>
                        <div style={{ marginTop: 10, display: 'flex', width: "100%" }}>
                            <input type='text' placeholder='Student Score'
                                style={{
                                    width: "100%",
                                    margin: 3,
                                    border: "none",
                                    outline: 'none',
                                    padding: 15,
                                    borderRadius: 5
                                }}
                                onChange={(e) => registerScore(item.id, e.target.value)} />
                            {/* <input type='text' placeholder='Mark Allocation'
                                style={{
                                    width: "50%",
                                    margin: 3,
                                    border: "none",
                                    outline: 'none',
                                    padding: 15,
                                    borderRadius: 5
                                }}
                                value={item.mark || ''}
                                onChange={(e) => handleMarkChange(item.id, e.target.value)} /> */}
                        </div>
                    </div>
                )
            }) :
                <div style={{ marginTop: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <p>Loading ...</p>
                </div>}
            <div style={{ marginTop: 30, display: "flex", justifyContent: 'flex-end' }}>
                <Button text={grade !== undefined ? "Review Submission" : "Publish Results"} width={"50%"} disabled={isLoading} isLoading={isLoading}
                    onClick={gradeSubmission} />
            </div>
        </div>
    )
}

export default GradingAssessment