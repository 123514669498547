import React from "react";
import styles from "./carousel.module.css";
import Button from "../../../../../../components/Button/customButton/Button";
import TableEditIcon from "../../../../../../assets/icons/table-edit.icon";

export default function Carousel({
  children,
  title,
  letter,
  activeTab,
  setActiveTab,
  index,
  setGotToEditInfo,
  locat,
}) {
  return (
    <div
      onClick={() => {
        setActiveTab(index);
      }}
    >
      <div className={`${styles["carousel-header"]}`}>
        <h2>
          {letter}. {title}
        </h2>
        <div>
          <p className={styles.plusSign}>{activeTab === index ? "-" : "+"}</p>
        </div>
      </div>

      {activeTab === index && (
        <>
          <div>{children}</div>

          {locat !== "dashboard" && (
            <Button
              text={`Edit ${title}`}
              backgroundColor={"transparent"}
              border={2}
              borderColor={"#2218a7"}
              color={"#2218a7"}
              rightIcon={<TableEditIcon />}
              onClick={() => setGotToEditInfo(index + 1)}
            />
          )}
        </>
      )}
    </div>
  );
}
