import React from 'react';
import styles from './/error.module.css';
import { useHistory, useNavigate } from 'react-router-dom';
import image404 from "../../assets/images/404.png"
import Button from '../../components/Button/customButton/Button';
import Logo from '../../components/logo/Logo';

const Error = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate('/');
  }

  return (
    <div className={`${styles["error_container"]}`}>
      <Logo />

      <div className={`${styles["sub-container"]}`}>
        <div className={`${styles["error_background-image"]}`}>
          <img src={image404} alt="404 error" />
        </div>

        <div className={`${styles["error_body"]}`}>
          <p>Oops! Looks like you got lost in space. No worries, we can take you back home.</p>
          <div className={`${styles["btn-container"]}`}>
            <Button text={"Go Home"} onClick={handleGoBack} backgroundColor={"#000"} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Error;
