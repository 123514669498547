import React, { useState, useEffect } from "react";
import Jobcard from "./Jobcard/Jobcard";
import DropDownS from "../../../../components/Inputs/DropdownS/DropDownS";
import styles from "./Opportunities.module.css";
import { useSelector } from "react-redux";
import { useOpportunities } from "../../../../query/tutor/useOpportunities";
const jobListing = [
  {
    image: "",
    jobTitle: "Sr. UX Designer",
    company: "Google",
    city: "New York",
    yrsOfExp: "3",
    jobType: "Full-time",
    description:
      "UX Designers are the synthesis of design and development. They take Google's most innovative product concepts...Read More",
    date: "2 days ago",
    salary: "50",
    bgColor: "",
  },
  {
    image: "",
    jobTitle: "Sr. UX Designer",
    company: "Google",
    city: "New York",
    yrsOfExp: "3",
    jobType: "Full-time",
    description:
      "UX Designers are the synthesis of design and development. They take Google's most innovative product concepts...Read More",
    date: "2 days ago",
    salary: "50",
    bgColor: "",
  },
  {
    image: "",
    jobTitle: "Sr. UX Designer",
    company: "Google",
    city: "New York",
    yrsOfExp: "3",
    jobType: "Full-time",
    description:
      "UX Designers are the synthesis of design and development. They take Google's most innovative product concepts...Read More",
    date: "2 days ago",
    salary: "50",
    bgColor: "",
  },
  {
    image: "",
    jobTitle: "Sr. UX Designer",
    company: "Google",
    city: "New York",
    yrsOfExp: "3",
    jobType: "Full-time",
    description:
      "UX Designers are the synthesis of design and development. They take Google's most innovative product concepts...Read More",
    date: "2 days ago",
    salary: "50",
    bgColor: "",
  },
  {
    image: "",
    jobTitle: "Sr. UX Designer",
    company: "Google",
    city: "New York",
    yrsOfExp: "3",
    jobType: "Full-time",
    description:
      "UX Designers are the synthesis of design and development. They take Google's most innovative product concepts...Read More",
    date: "2 days ago",
    salary: "50",
    bgColor: "",
  },
  {
    image: "",
    jobTitle: "Sr. UX Designer",
    company: "Google",
    city: "New York",
    yrsOfExp: "3",
    jobType: "Full-time",
    description:
      "UX Designers are the synthesis of design and development. They take Google's most innovative product concepts...Read More",
    date: "2 days ago",
    salary: "50",
    bgColor: "",
  },
  {
    image: "",
    jobTitle: "Sr. UX Designer",
    company: "Google",
    city: "New York",
    yrsOfExp: "3",
    jobType: "Full-time",
    description:
      "UX Designers are the synthesis of design and development. They take Google's most innovative product concepts...Read More",
    date: "2 days ago",
    salary: "50",
    bgColor: "",
  },
];
export default function JobsListing() {
  const { getAllJobs } = useOpportunities();
  const [selectedtype, setSelectedType] = useState("");

  const opportunities = useSelector(
    (state) => state.opportunity.opportunities.opportunities
  );

  const getAllOpportunities = async () => {
    await getAllJobs();
  };

  useEffect(() => {
    getAllOpportunities();
  }, []);

  const typeOptions = ["one", "two"];

  return (
    <div>
      <div className={styles.searchDiv}>
        <h3 style={{ marginTop: 10 }}>Opportunity Desk</h3>
        {/* <DropDownS
          data={typeOptions}
          placeholder="Opportunity type"
          setValue={setSelectedType}
        /> */}
      </div>
      <div className={styles.listJobs}>
        {opportunities &&
          opportunities?.length > 0 &&
          opportunities?.map((item) => {
            return (
              <Jobcard
                jobTitle={item.title}
                company={item.company}
                city={item.city}
                yrsOfExp={item.experience}
                jobType={item.employment_type}
                description={item.description}
                date={item.created_at}
                salary={item.salary}
              />
            );
          })}
      </div>
    </div>
  );
}
