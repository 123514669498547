import React, { useState } from "react";
import styles from "./dropdownS.module.css";
import ChevronIcon from "../../../assets/icons/chevron.icon";

export default function DropDownS({
  value,
  data,
  setValue,
  secondary,
  placeholder,
  keyItem,
}) {
  const [option, setOption] = useState("");
  const [viewDropDown, setViewDropDown] = useState(false);
  const OPTIONDATA = [
    "",
    "Faculty of Engineering",
    "Faculty of Science",
    "Faculty of Laws",
    "Faculty of Health Science",
  ];

  /** Handle Select */
  function handleSelect(option) {
    setOption(option);
    setViewDropDown(false);
    setValue(option);
  }

  return (
    <div className={`${styles["main-container"]}`}>
      <div
        className={`${styles["filter-container"]} ${secondary && styles["secondary-color"]
          }}`}
        style={secondary && { backgroundColor: "#2218a7", color: "#fff" }}
        onClick={() => setViewDropDown((prevState) => !prevState)}
      >
        <p style={secondary && { color: "#fff" }}>
          {value === ""
            ? placeholder
            : value.length > 15
              ? value.substring(0, 15) + "..."
              : value}
        </p>
        <div
          className={`${viewDropDown ? styles.active : styles["dropdown-icon"]
            }`}
        >
          <ChevronIcon />
        </div>
      </div>
      {viewDropDown && (
        <ul className={`${styles["dropdown-list"]}`}>
          <li
            onClick={() => {
              handleSelect("");
            }}
            className={`${value === "" && styles["active-option"]}`}
          >
            {placeholder}
          </li>
          {data?.map((opt, index) => {
            return (
              <li
                key={index}
                onClick={() => {
                  handleSelect(
                    keyItem === "name"
                      ? opt?.name
                      : keyItem === "id"
                        ? opt?.name
                        : opt
                  );
                }}
                className={`${value === opt?.name && styles["active-option"]}`}
              >
                {keyItem === "name"
                  ? opt?.name
                  : keyItem === "id"
                    ? opt?.name
                    : opt}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}
