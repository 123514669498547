import { useSelector } from "react-redux";
import client from "../../utils/api/axios";

export function useChats() {
    const token = useSelector((state) => state.user.token);

    async function handleGetChats() {
        const response = await client
            .get(`/api/inbox/`, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            })
            .then((response) => {
                const data = response.data;
                return response;
            })
            .catch((error) => {
                return console.error(`Error: ${error.message}`);
            });

        return response?.data
    }

    return {
        handleGetChats
    };
}