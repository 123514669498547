import React, { useEffect, useState } from "react";
import StatisticsCard from "../../../../../components/Layout/dashboard/components/StatisticsRow/components/StatisticsCard/StatisticsCard";
import Search from "../../../../../components/Search/Search";
import ChevronIcon from "../../../../../assets/icons/chevron.icon";
import globalStyles from "../../../admin/components/generalStyles.module.css";
import styles from "./students.module.css";
// import { useSelector } from "react-redux";
// import DropDownS from "../../../../../components/Inputs/DropdownS/DropDownS";
import { useStudent } from "../../../../../query/tutor/useStudents";
import Loader from "../../../../../components/Loader/Loader";
import { useParams } from "react-router-dom";
// import SubscriptionIcon from "../../../../../assets/icons/subscription.icon";
import GroupPeopleIcon from "../../../../../assets/icons/group-people.icon";
import { useCAResults } from "../../../../../query/tutor/useCAResults";

export default function ResultDetails() {
  const { student, details } = useParams();

  /** state management */
  const [results, setResults] = useState(null);

  // Filter variables
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setResults(JSON.parse(details))
  }, [details])

  console.log(results)

  return (
    <div>
      {isLoading && <Loader />}
      <div className={`${globalStyles["header-container"]}`}>
        <h2>{student} / #{results?.student_reference}</h2>

        <div className={`${globalStyles["action-container"]}`}>
          <div style={{ maxWidth: 300 }}>
            <Search
              value={searchValue}
              setValue={setSearchValue}
              placeholder={"Find Student Record"}
            />
          </div>
        </div>
      </div>

      <div style={{ width: "90%", display: "flex", justifyContent: 'space-between', marginTop: 20, marginBottom: 30 }}>
        <h3>Average Score: {results?.average_score}</h3>
        <h3>Grade: {results?.grade}</h3>
      </div>

      {/* Faculty Table */}
      <div className={`${globalStyles["table-container"]}`}>
        <table>
          <thead>
            <tr className={`${globalStyles["table-row"]}`}>
              <th className={`${globalStyles["table-header"]}`}>
                <div className={`${globalStyles["first-square"]}`}>
                  {/* <div className={`${globalStyles["check-box"]}`}></div> */}
                  <p>Assesment</p>
                </div>
              </th>
              <th className={`${globalStyles["table-header"]}`}>Score</th>
            </tr>
          </thead>

          <tbody>
            {results?.assessment_scores?.length > 0 ? (
              results?.assessment_scores?.map((score, index) => {
                return (
                  <tr
                    key={index}
                    className={`${globalStyles["table-row-container"]}`}
                    onClick={() => {
                    }}
                  >
                    <td
                      className={`${globalStyles["table-row"]}`}
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      {/* <div
                        className={`${globalStyles["check-box"]}`}
                        style={{ marginInlineEnd: 16 }}
                      ></div> */}
                      <p style={{ whiteSpace: "nowrap" }}>
                        {score.assessment_title}
                      </p>
                    </td>
                    <td className={` ${globalStyles["table-row"]}`}>
                      <p>{score.score === null ? 0 : score.score}</p>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={8}>No Assesment Found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {/* <div className={`${globalStyles["pagination-container"]}`}>
        <p>
          Page <span style={{ fontWeight: "bold" }}>1/1</span> Total:{" "}
          <span style={{ fontWeight: "bold" }}>
            {studentsData?.length ?? 0} Students
          </span>
        </p>
        <div className={`${globalStyles["btn-container"]}`}>
          <div className={`${globalStyles.icons} ${globalStyles["left-icon"]}`}>
            <ChevronIcon />
          </div>
          <div
            className={`${globalStyles.icons} ${globalStyles["right-icon"]}`}
          >
            <ChevronIcon />
          </div>
        </div>
      </div> */}
    </div>
  );
}
