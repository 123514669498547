import React, { useEffect } from "react";
import Footer from "../../Footer/Footer";
import Navbar from "../../NavBar/NavBar";
import styles from "./staticLayout.module.css";

export default function StaticLayout({ children }) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Navbar />
      <main className={`${styles.block}`}>{children}</main>
      <Footer />
    </>
  );
}
