import { useDispatch } from "react-redux";
import client from "../../utils/api/axios";
import { useSelector } from "react-redux";
import { institutionSlice } from "../../redux/features/institutionSlice";
import { useIonToast } from "@ionic/react";
import { studentSlice } from "../../redux/features/studentSlice";

export function useCourse() {
  /** state management */
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);
  const [toast] = useIonToast();

  async function createCourse(course) {
    return await client
      .post("/api/course/", course, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        getCourses();

        return true;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          buttons: ["Ok"],
          duration: 5000,
        });
        return null;
      });
  }

  async function getCourses() {
    return await client
      .get("/api/course/", {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        const result = response.data;
        if (result) {
          dispatch(institutionSlice.actions.setCourse(result));
        }
        return response.data;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          buttons: ["Ok"],
          duration: 5000,
        });
        return null;
      });
  }

  /**
   * updateFCourseInfo Updates department information using department id
   * @param department department object to be updated
   */
  async function updateCourseInfo(course, courseId) {
    return await client
      .put(`/api/course/${courseId}/`, course, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then(() => {
        getCourses();
        toast({
          message: `Course Updated Successfully!`,
          color: "success",
          duration: 3000,
          buttons: ["Ok"],
        });
        return true;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          buttons: ["Ok"],
          duration: 4000,
        });
        return null;
      });
  }

  async function getSingleCourseInfo(courseId) {
    return await client
      .get(`/api/course/${courseId}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        const result = response.data;
        return result;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          buttons: ["Ok"],
          duration: 4000,
        });
        return null;
      });
  }

  async function getRegisteredCourses() {
    return await client
      .get("/api/registered_courses/", {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(studentSlice.actions.setRegisterdCourse(response.data));
        return response.data;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          buttons: ["Ok"],
          duration: 2000,
        });
        return null;
      });
  }

  async function getFilteredCourses(
    faculty_name,
    department_name,
    course_level
  ) {
    return await client
      .get("/api/course/", {
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          faculty_name,
          department_name,
          course_level,
        },
      })
      .then((response) => {
        const result = response.data;
        return result;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          buttons: ["Ok"],
          duration: 4000,
        });
        return null;
      });
  }

  async function searchCourse(keyword) {
    return await client
      .get("/api/course/", {
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          keyword,
        },
      })
      .then((response) => {
        const result = response.data;
        return result;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          buttons: ["Ok"],
          duration: 4000,
        });
        return null;
      });
  }

  async function deleteCourse(courseId) {
    return await client
      .delete(`/api/course/${courseId}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then(() => {
        toast({
          message: `Course Deleted Successfully!`,
          color: "success",
          duration: 3000,
          buttons: ["Ok"],
        });
        getCourses();
        return true;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          buttons: ["Ok"],
          duration: 4000,
        });
        return null;
      });
  }

  async function registerCourse(courseId) {
    return await client
      .post(
        `/api/register_course/${courseId}/`,
        {},
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then(() => {
        toast({
          message: `Registered!`,
          color: "success",
          duration: 1500,
          buttons: ["Ok"],
        });
        getCourses();
        return true;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          buttons: ["Ok"],
          duration: 1500,
        });
        return null;
      });
  }

  async function dropRegisterCourse(courseId) {
    return await client
      .post(
        `/api/drop_course/${courseId}/`,
        {},
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then(() => {
        toast({
          message: `Dropped!`,
          color: "success",
          duration: 1500,
          buttons: ["Ok"],
        });
        return true;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          buttons: ["Ok"],
          duration: 1500,
        });
        return null;
      });
  }

  return {
    createCourse,
    getCourses,
    updateCourseInfo,
    getSingleCourseInfo,
    getFilteredCourses,
    searchCourse,
    deleteCourse,
    registerCourse,
    getRegisteredCourses,
    dropRegisterCourse,
  };
}
