import React from "react";

export default function PdfIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="92"
      height="116"
      viewBox="0 0 92 116"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.9837 0C57.1929 0 58.9837 1.79086 58.9837 4V30.1177C58.9837 32.3268 60.7746 34.1177 62.9837 34.1177H87.3195C89.5286 34.1177 91.3195 35.9085 91.3195 38.1177V112C91.3195 114.209 89.5286 116 87.3195 116H4.77929C2.57015 116 0.779297 114.209 0.779297 112V4C0.779297 1.79086 2.57016 0 4.7793 0H54.9837ZM42.667 40.9411C41.7034 40.9411 40.8368 41.3983 40.2095 42.2103C37.3574 45.8336 38.4116 53.8717 39.1359 58.6482C39.2976 59.7331 39.1618 60.8317 38.722 61.8211L32.5458 75.9117C32.0932 76.9352 31.3688 77.7609 30.4505 78.2999C26.5508 80.5858 20.0125 83.7178 19.2235 89.3336C18.9389 91.1623 19.5145 92.7385 20.8597 94.1579C21.7716 95.0721 22.8775 95.5293 24.0804 95.5293C28.7432 95.5293 31.6858 91.1964 34.8611 86.5564C36.2709 84.4889 38.3922 83.2197 40.7268 82.8785C45.0987 82.2439 50.337 80.9133 53.5059 80.0399C55.1034 79.5964 56.7331 79.7056 58.3046 80.231C61.0855 81.1726 62.1396 81.7458 65.3344 81.7458C68.2187 81.7458 70.1912 81.0361 71.1484 79.6646C71.918 78.6479 72.1055 77.2764 71.7304 75.7001C71.0514 73.1141 71.614 70.2823 64.1121 70.2823C62.4306 70.2823 60.7944 70.4529 59.4299 70.6508C57.3216 70.951 55.2327 69.9889 53.8746 68.1806C52.025 65.731 50.143 62.8378 48.8367 60.7361C47.9119 59.2555 47.6014 57.4745 47.9054 55.7141C48.7914 50.5964 48.9596 46.7957 46.5602 43.227C45.5513 41.7053 44.1997 40.9411 42.667 40.9411ZM65.4515 11.0525C65.4515 7.43696 69.8676 5.67674 72.3547 8.30093L83.9575 20.5431C86.3735 23.0923 84.5664 27.2947 81.0543 27.2947H69.4515C67.2423 27.2947 65.4515 25.5039 65.4515 23.2947V11.0525Z"
        fill="#2218A7"
      />
    </svg>
  );
}
