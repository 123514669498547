import React from "react";

export default function PhoneIcon({ ...props }) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        {...props}
      >
        <path
          d="M29.2933 24.4398C29.2933 24.9198 29.1867 25.4132 28.96 25.8932C28.7333 26.3732 28.44 26.8265 28.0533 27.2532C27.4 27.9732 26.68 28.4932 25.8667 28.8265C25.0667 29.1598 24.2 29.3332 23.2667 29.3332C21.9067 29.3332 20.4533 29.0132 18.92 28.3598C17.3867 27.7065 15.8533 26.8265 14.3333 25.7198C12.8 24.5998 11.3467 23.3598 9.95999 21.9865C8.58666 20.5998 7.34666 19.1465 6.23999 17.6265C5.14666 16.1065 4.26666 14.5865 3.62666 13.0798C2.98666 11.5598 2.66666 10.1065 2.66666 8.71984C2.66666 7.81317 2.82666 6.9465 3.14666 6.1465C3.46666 5.33317 3.97332 4.5865 4.67999 3.91984C5.53332 3.07984 6.46666 2.6665 7.45332 2.6665C7.82666 2.6665 8.19999 2.7465 8.53332 2.9065C8.87999 3.0665 9.18666 3.3065 9.42666 3.65317L12.52 8.01317C12.76 8.3465 12.9333 8.65317 13.0533 8.9465C13.1733 9.2265 13.24 9.5065 13.24 9.75984C13.24 10.0798 13.1467 10.3998 12.96 10.7065C12.7867 11.0132 12.5333 11.3332 12.2133 11.6532L11.2 12.7065C11.0533 12.8532 10.9867 13.0265 10.9867 13.2398C10.9867 13.3465 11 13.4398 11.0267 13.5465C11.0667 13.6532 11.1067 13.7332 11.1333 13.8132C11.3733 14.2532 11.7867 14.8265 12.3733 15.5198C12.9733 16.2132 13.6133 16.9198 14.3067 17.6265C15.0267 18.3332 15.72 18.9865 16.4267 19.5865C17.12 20.1732 17.6933 20.5732 18.1467 20.8132C18.2133 20.8398 18.2933 20.8798 18.3867 20.9198C18.4933 20.9598 18.6 20.9732 18.72 20.9732C18.9467 20.9732 19.12 20.8932 19.2667 20.7465L20.28 19.7465C20.6133 19.4132 20.9333 19.1598 21.24 18.9998C21.5467 18.8132 21.8533 18.7198 22.1867 18.7198C22.44 18.7198 22.7067 18.7732 23 18.8932C23.2933 19.0132 23.6 19.1865 23.9333 19.4132L28.3467 22.5465C28.6933 22.7865 28.9333 23.0665 29.08 23.3998C29.2133 23.7332 29.2933 24.0665 29.2933 24.4398Z"
          stroke="#292D32"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
      </svg>
    </div>
  );
}
