import React, { useState, useEffect } from 'react'
import styles from "./Assesments.module.css"
import Button from '../../../../../components/Button/customButton/Button'
import { useParams } from 'react-router-dom'
import { useAssessments } from "../../../../../query/tutor/useAssessments"
import { useIonToast } from "@ionic/react";
import { FaEdit, FaTrash } from "react-icons/fa";
import RichTextInput from '../../../../../components/Inputs/RichTextInput/RichTextInput'

const CreateAssessmentQuestions = () => {
    const [toast] = useIonToast();
    const { id, course } = useParams()
    const [isLoading, setIsLoading] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [qID, setQID] = useState("")
    const [structure, setStructure] = useState("")
    const [duration, setDuration] = useState("")
    const [question, setQuestion] = useState("")
    const [option1, setOption1] = useState({ text: "", is_correct: false })
    const [option2, setOption2] = useState({ text: "", is_correct: false })
    const [option3, setOption3] = useState({ text: "", is_correct: false })
    const [option4, setOption4] = useState({ text: "", is_correct: false })
    const [shortQuestion, setShortQueestion] = useState("")
    const [allocatedMark, setAllocatedMark] = useState("")
    const [details, setDetails] = useState(null)
    const { handleGetAssessmentDetails, handleCreateAssessmentsQuestion,
        handleCreateTextQuestion, handleUpdateTextQuestion, handleUpdateAssessmentsQuestion } = useAssessments()

    const getDetails = async () => {
        if (id !== undefined) {
            setIsLoading(true);
            await handleGetAssessmentDetails(id).then((res) => {
                setStructure(res?.assessment_details?.structure)
                setDuration(res?.assessment_details?.duration)
                setDetails(res)
            }).catch((err) => {
                console.error("err", err)
            })
            setIsLoading(false);
            return;
        }
    };

    async function handleCreateNeQuestion() {
        if (isEditing) {
            if (structure === "mcq") {
                if (question && option1 && option2 && option3 && option4) {
                    setIsLoading(true);
                    const data = {
                        id,
                        qID,
                        question: {
                            text: question
                        },
                        choices: [
                            option1,
                            option2,
                            option3,
                            option4
                        ]
                    };
                    console.log(data)
                    await handleUpdateAssessmentsQuestion(data).then((res) => {
                        setQuestion("")
                        setOption1({ text: "", is_correct: false })
                        setOption2({ text: "", is_correct: false })
                        setOption3({ text: "", is_correct: false })
                        setOption4({ text: "", is_correct: false })
                        setIsLoading(false);
                        setIsEditing(false)
                        setQID("")
                    }).catch((err) => {
                        console.log(err);
                        setIsLoading(false)
                    })
                } else {
                    toast({
                        message: `All fields are required`,
                        color: "danger",
                        buttons: ["Ok"],
                    });
                }
            } else {
                if (shortQuestion) {
                    if (allocatedMark > 10) {
                        toast({
                            message: `Mark allocated should not be greater than 10.`,
                            color: "danger",
                            buttons: ["Ok"],
                        });
                        return
                    }
                    setIsLoading(true);
                    const data = {
                        id,
                        qID,
                        text: shortQuestion,
                        mark_allocated: allocatedMark
                    }
                    await handleUpdateTextQuestion(data).then((res) => {
                        console.log(res)
                        setIsLoading(false)
                        setIsEditing(false)
                        setQID("")
                        setShortQueestion("")
                        setAllocatedMark("")
                    }).catch((err) => {
                        console.log(err);
                        setIsLoading(false)
                    })
                } else {
                    toast({
                        message: `Question is required`,
                        color: "danger",
                        buttons: ["Ok"],
                    });
                }
            }
        } else {
            if (structure === "mcq") {
                if (question && option1 && option2 && option3 && option4) {
                    setIsLoading(true);
                    const data = {
                        id,
                        question: {
                            text: question
                        },
                        choices: [
                            option1,
                            option2,
                            option3,
                            option4
                        ]
                    };
                    await handleCreateAssessmentsQuestion(data).then((res) => {
                        setQuestion("")
                        setOption1({ text: "", is_correct: false })
                        setOption2({ text: "", is_correct: false })
                        setOption3({ text: "", is_correct: false })
                        setOption4({ text: "", is_correct: false })
                        setIsLoading(false);
                    }).catch((err) => {
                        console.log(err);
                        setIsLoading(false)
                    })
                } else {
                    toast({
                        message: `All fields are required`,
                        color: "danger",
                        buttons: ["Ok"],
                    });
                }
            } else {
                if (shortQuestion) {
                    if (allocatedMark > 10) {
                        toast({
                            message: `Mark allocated should not be greater than 10.`,
                            color: "danger",
                            buttons: ["Ok"],
                        });
                        return
                    }
                    setIsLoading(true);
                    const data = {
                        id,
                        text: shortQuestion,
                        mark_allocated: allocatedMark
                    }
                    await handleCreateTextQuestion(data).then((res) => {
                        setIsLoading(false)
                        setQID("")
                        setShortQueestion("")
                        setAllocatedMark("")
                    }).catch((err) => {
                        console.log(err);
                        setIsLoading(false)
                    })
                } else {
                    toast({
                        message: `Question is required`,
                        color: "danger",
                        buttons: ["Ok"],
                    });
                }
            }
        }

    }

    const setEditItem = (item) => {
        setIsEditing(true)
        if (structure === 'mcq') {
            setQID(item?.id)
            setQuestion(item?.text)
            setOption1(item?.choices[0])
            setOption2(item?.choices[1])
            setOption3(item?.choices[2])
            setOption4(item?.choices[3])
        } else {
            setQID(item?.id)
            setShortQueestion(item?.text)
            setAllocatedMark(item?.mark_allocated)
        }
    }

    useEffect(() => {
        getDetails()
    }, [id])

    return (
        <div>
            <h3>{course} / Create Assessment Questions</h3>
            <div className={styles.assessmentTitle}>
                <input type='text' placeholder='Type assessment title here...' value={details && details.assessment_details.title} />
            </div>
            <div className={styles.assessmentTypeWrapper}>
                <div className={styles.assessmentType}>
                    <input type='text' placeholder='Assessment type' value={details && details.assessment_details.assessment_type} />
                </div>
                <div className={styles.assessmentDuration}>
                    <input type='text' placeholder='Duration in minutes' value={duration + " minutes"} />
                </div>
            </div>
            <div className={styles.questionSection}>
                <div className={styles.newQuestion}>
                    <h4>New Question</h4>
                    <div>
                        {details?.assessment_details.structure === "mcq" ?
                            <div>
                                <RichTextInput
                                    value={question}
                                    setValue={setQuestion}
                                />
                                <input type='text' placeholder='Option 1' className={styles.option}
                                    value={option1.text}
                                    style={{ marginTop: 55 }}
                                    onChange={(e) => {
                                        return (
                                            setOption1((prevState) => ({
                                                ...prevState,
                                                text: e.target.value
                                            }))
                                        )
                                    }} />
                                <div className={styles.isCorrecDiv}>
                                    <p>Is correct</p>
                                    <input type='checkbox' onChange={(e) => {
                                        return (
                                            setOption1((prevState) => ({
                                                ...prevState,
                                                is_correct: e.target.checked
                                            }))
                                        )
                                    }} />
                                </div>
                                <input type='text' placeholder='Option 2' className={styles.option}
                                    value={option2.text}
                                    onChange={(e) => {
                                        return (
                                            setOption2((prevState) => ({
                                                ...prevState,
                                                text: e.target.value
                                            }))
                                        )
                                    }} />
                                <div className={styles.isCorrecDiv}>
                                    <p>Is correct</p>
                                    <input type='checkbox' onChange={(e) => {
                                        return (
                                            setOption2((prevState) => ({
                                                ...prevState,
                                                is_correct: e.target.checked
                                            }))
                                        )
                                    }} />
                                </div>
                                <input type='text' placeholder='Option 3' className={styles.option}
                                    value={option3.text}
                                    onChange={(e) => {
                                        return (
                                            setOption3((prevState) => ({
                                                ...prevState,
                                                text: e.target.value
                                            }))
                                        )
                                    }} />
                                <div className={styles.isCorrecDiv}>
                                    <p>Is correct</p>
                                    <input type='checkbox' onChange={(e) => {
                                        return (
                                            setOption3((prevState) => ({
                                                ...prevState,
                                                is_correct: e.target.checked
                                            }))
                                        )
                                    }} />
                                </div>
                                <input type='text' placeholder='Option 4' className={styles.option}
                                    value={option4.text}
                                    onChange={(e) => {
                                        return (
                                            setOption4((prevState) => ({
                                                ...prevState,
                                                text: e.target.value
                                            }))
                                        )
                                    }} />
                                <div className={styles.isCorrecDiv}>
                                    <p>Is correct</p>
                                    <input type='checkbox' onChange={(e) => {
                                        return (
                                            setOption4((prevState) => ({
                                                ...prevState,
                                                is_correct: e.target.checked
                                            }))
                                        )
                                    }} />
                                </div>
                            </div> :
                            details?.assessment_details?.structure === "text" ?
                                <div>
                                    <RichTextInput
                                        value={shortQuestion}
                                        setValue={setShortQueestion}
                                    />
                                    <input type='number' placeholder='Allocated Mark' className={styles.option} value={allocatedMark}
                                        onChange={(e) => setAllocatedMark(e.target.value)} style={{ marginTop: 50 }} />
                                </div> :
                                <div style={{ textAlign: 'center', marginTop: 50 }}>Loading, Please wait ...</div>}
                    </div>
                    <div style={{ marginTop: 15 }}>
                        <Button text={isEditing ? "Update Question" : "Add Question"} width={"100%"}
                            onClick={handleCreateNeQuestion}
                            isLoading={isLoading}
                            disabled={isLoading} />
                    </div>
                </div>
                <div className={styles.savedQuestions}>
                    <h4>Saved Questions</h4>
                    {details?.assessment_details?.structure === "mcq" ?
                        <div className={styles.savedQuestionsList}>
                            {details && details.questions.map((item, index) => {
                                return (
                                    <div className={styles.questionItem} key={index}>
                                        <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', padding: "5px" }}>
                                            <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                                                <p>{index + 1}{". "}</p>
                                                <div dangerouslySetInnerHTML={{ __html: item?.text }} />
                                            </div>
                                            <div>
                                                <span style={{ marginRight: 8, cursor: "pointer" }}
                                                    onClick={() => setEditItem(item)}><FaEdit /></span>
                                                {/* <span style={{ cursor: 'pointer' }}><FaTrash /></span> */}
                                            </div>
                                        </div>
                                        {item?.choices.map((c, index) => {
                                            return (
                                                <input type='text'
                                                    placeholder={`Option ${index + 1}`}
                                                    className={styles.option} key={index}
                                                    value={c?.text}
                                                />
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </div> :
                        <div >
                            {details && details.questions.map((item, index) => {
                                return (
                                    <div className={styles.questionItem} key={index}>
                                        <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', padding: "5px" }}>
                                            <div style={{ display: "flex" }}>
                                                <p>{index + 1}{". "}</p>
                                                <div dangerouslySetInnerHTML={{ __html: item?.text }} />
                                            </div>
                                            <div>
                                                <span style={{ marginRight: 8, cursor: "pointer" }}
                                                    onClick={() => setEditItem(item)}><FaEdit /></span>
                                                {/* <span style={{ cursor: 'pointer' }}><FaTrash /></span> */}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>}
                </div>
            </div>
        </div>
    )
}

export default CreateAssessmentQuestions