import React, { useState, useEffect } from 'react'
import Button from "../../../../../components/Button/customButton/Button";
import { FaPlus } from "react-icons/fa";
import styles from "./Zoom.module.css"
import CreateModal from "./Create/CreateModal"
import { useZoom } from '../../../../../query/tutor/useZoom';
import { useTutorAuth } from '../../../../../query/tutor/auth';
import { useSelector } from 'react-redux';
import MeetingCard from './MeetingItem/Meeting';
import MeetingDetails from './MeetingItem/MeetingDetails';
import Loader from '../../../../../components/Loader/Loader';

const Zoom = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [openModal, setOpenModal] = useState(false);
    const [viewDetails, setViewDetails] = useState(false);
    const [coursesData, setCoursesData] = useState([])
    const [meetings, setMeetings] = useState([])
    const [filteredMeetings, setFilteredMeetings] = useState([])
    const [selected, setSelected] = useState('')
    const [data, setData] = useState(null)

    const { handleGetMeetings } = useZoom()
    const { getTutorInfo } = useTutorAuth();

    const userInfo = useSelector((state) => state.user)

    const getTutorData = async () => {
        if (userInfo.user.id !== undefined) {
            const id = userInfo.user.id;
            getTutorInfo(id);
            return;
        }
    };

    async function handleGetLinks() {
        try {
            setIsLoading(true);
            await handleGetMeetings().then((res) => {
                const tutorMeeting = res.meetings.filter((item) => item.created_by.id === userInfo.user.id)
                setMeetings(tutorMeeting)
                setIsLoading(false);
            })
        } catch (error) {
            console.error(error)
            setIsLoading(false)
        }
    }

    const filterMeetings = () => {
        const result = meetings.filter((meet) => {
            const dateObject = new Date(meet?.start_time);
            const currentDate = new Date();
            if (selected === "Upcoming") {
                return currentDate.getTime() < dateObject.getTime()
            } else if (selected === "Present") {
                return currentDate.getTime() === dateObject.getTime()
            } else if (selected === "Passed") {
                return currentDate.getTime() > dateObject.getTime()
            } else if (selected === "all") {
                return true;
            }
        })
        setFilteredMeetings(result)
    }

    useEffect(() => {
        const courses = userInfo.user?.courses_detail
        const orderedCourses =
            courses?.length > 0
                ? courses.map((item) => {
                    return (
                        { id: item.id, name: item.course_title }
                    )
                })
                : [];
        setCoursesData(orderedCourses);
    }, [userInfo.user]);

    useEffect(() => {
        setSelected("Upcoming")
        handleGetLinks()
    }, [])

    useEffect(() => {
        getTutorData()
    }, [userInfo.user.id])

    useEffect(() => {
        filterMeetings()
    }, [selected])

    return (
        <div>
            {isLoading && <Loader />}
            {openModal && <CreateModal setViewModal={setOpenModal} courses={coursesData} />}
            {viewDetails && <MeetingDetails setViewModal={setViewDetails} data={data} />}
            <div className={styles.heading}>
                <div>
                    <h2>Zoom Meetings</h2>
                    <div className={styles.meetingFilter}>
                        <select value={selected} onChange={(e) => setSelected(e.target.value)}>
                            <option>Filter Meetings</option>
                            <option value="all">All Meetings</option>
                            <option value="Upcoming">Upcoming Meetings</option>
                            <option value="Present">Present Meetings</option>
                            <option value="Passed">Passed Meetings</option>
                        </select>
                    </div>
                </div>
                <div>
                    <Button text="Generate Link" rightIcon={<FaPlus />} onClick={() => { setOpenModal(true); }} />
                </div>
            </div>
            <div>
                {isLoading ? <div>Loading...</div> :
                    <div className={styles.meetingCards}>
                        {filteredMeetings.length > 0 ? filteredMeetings.map((item, index) => {
                            const dateObject = new Date(item?.start_time);
                            const currentDate = new Date();
                            const formattedDate = dateObject.toLocaleDateString(undefined, {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                            });
                            const formattedTime = dateObject.toLocaleTimeString(undefined, {
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit',
                                hour12: false
                            });
                            let status = ""
                            if (currentDate.getTime() < dateObject.getTime()) {
                                status = 'Upcoming'
                            } else if (currentDate.getTime() === dateObject.getTime()) {
                                status = 'Present';
                            } else {
                                status = 'Passed';
                            }
                            return (
                                <MeetingCard keyIndex={index}
                                    topic={item?.topic}
                                    course={item?.course?.course_title}
                                    startTime={`${formattedDate} ${formattedTime}`}
                                    status={status}
                                    onClick={() => {
                                        setData(item);
                                        setViewDetails(true)
                                    }} />
                            )
                        }) : <div className={styles.noDataDiv}>No Meetings Found</div>}
                    </div>}
            </div>
        </div>
    )
}

export default Zoom