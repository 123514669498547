import React from "react";
import { useNavigate } from "react-router-dom";

import AuthLayout from "../../../../../components/Layout/auth/AuthLayout";
import Button from "../../../../../components/Button/customButton/Button";

import image from "../../../../../assets/images/All-Good.png";

import styles from "./applicationSubmitted.module.css";
import ClockIcon from "../../../../../assets/icons/clock.icon";

export default function ApplicationSubmitted() {
  /** State management */
  const navigate = useNavigate();

  const Icon = [
    <ClockIcon
      width={212}
      height={212}
      className={`${styles["large-person"]}`}
    />,
    <ClockIcon
      width={112}
      height={112}
      className={`${styles["medium-person"]}`}
    />,
  ];
  return (
    <>
      <div className={`${styles.submissionContainer}`}>
        <div className={`${styles.image_container}`}>
          <img src={image} alt="Confirmation image" />
        </div>
        <h1>Your Application was submitted</h1>
        <p>
          Thank you very much for applying to{" "}
          <strong>Xashy Tech Institute.</strong> Your application was submitted
          successfully. Check your email address to see your confirmation letter
          and important information
        </p>

        <Button
          text={"Go Home"}
          width={"fit-content"}
          onClick={() => navigate("/")}
          paddingBlock={20}
        />
      </div>
    </>
  );
}
