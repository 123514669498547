import React from 'react'
import styles from "./Assesments.module.css"
import { Link } from 'react-router-dom'

const RANDOMCOLORS = [
    "#2218A7",
    "#5248D5",
    "#951B95",
    "#BF39BF",
    "#363736",
    "#000000",
];

const AssessmentCard = ({ title, desc, link, course, keyIndex, assessment_type, structure, duration }) => {
    const index = Math.floor(Math.random() * RANDOMCOLORS.length);
    return (
        <Link to={link} className={styles.cardWrapper} key={keyIndex}  >
            <div className={styles.topDetails} style={{ backgroundColor: RANDOMCOLORS[index] }}>
                <p>{course}</p>
                <h3>{title} ({duration}mins)</h3>
            </div>
            <div className={styles.footerDetails}>
                <p>{desc} ({assessment_type.toUpperCase()}:{structure.toUpperCase()})</p>
            </div>
        </Link>

    )
}

export default AssessmentCard