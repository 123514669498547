import React, { useState } from "react";
import styles from "./header.module.css";
import SearchIcon from "../../../../../assets/icons/search.icon";
import Search from "../../../../Search/Search";

import { useSelector } from "react-redux";

export default function Header() {
  const userInfo = useSelector((state) => state.user);

  /** State management */
  const [searchValue, setSearchValue] = useState("");
  return (
    <header className={`${styles["heading-container"]}`}>
      <div className={`${styles["greetings-container"]}`}>
        <p>Hi {userInfo?.user.first_name},</p>
        <h2>Welcome!</h2>
      </div>

      <Search
        placeholder={"Search"}
        value={searchValue}
        setValue={setSearchValue}
        extraStyles={{ maxWidth: 700 }}
      />
    </header>
  );
}
