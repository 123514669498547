import React, { useEffect, useState } from "react";
import StatisticsCard from "../../../../../components/Layout/dashboard/components/StatisticsRow/components/StatisticsCard/StatisticsCard";
import Search from "../../../../../components/Search/Search";
import ChevronIcon from "../../../../../assets/icons/chevron.icon";
import globalStyles from "../../../admin/components/generalStyles.module.css";
import styles from "./students.module.css";
import { useSelector } from "react-redux";
import DropDownS from "../../../../../components/Inputs/DropdownS/DropDownS";
import { useStudent } from "../../../../../query/tutor/useStudents";
import Loader from "../../../../../components/Loader/Loader";
import { useParams } from "react-router-dom";
import SubscriptionIcon from "../../../../../assets/icons/subscription.icon";
import GroupPeopleIcon from "../../../../../assets/icons/group-people.icon";

export default function Students() {
  const { course, id } = useParams();

  /** state management */
  const userInfo = useSelector((state) => state.user);
  const stateStudents = useSelector((state) => state.institution.studentData);
  const [studentsData, setStudentsData] = useState([]);
  const [filterdStudentsData, setFilteredStudentssData] = useState([]);
  // console.log(studentsData);

  // Filter variables
  const [searchValue, setSearchValue] = useState("");
  const [selectedFaculty, setSelectedFaculty] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  /** Query functions */
  const { getCourseStudents, getFilteredStudents } = useStudent();

  const filterStudents = () => {
    const filtered = studentsData.filter((item) => {
      // Check each condition separately and use logical OR (||)
      const facultyMatch =
        !selectedFaculty || item.faculty_details.name === selectedFaculty;
      const departmentMatch =
        !selectedDepartment ||
        item.department_details.name === selectedDepartment;

      // Return true if at least one condition is met
      return facultyMatch && departmentMatch;
    });

    setFilteredStudentssData(filtered);
  };

  const searchStudents = async () => {
    await getFilteredStudents(id, searchValue);
  };

  useEffect(() => {
    getCourseStudents(id);
  }, [id]);

  useEffect(() => {
    setStudentsData(stateStudents);
  }, [stateStudents]);

  useEffect(() => {
    filterStudents();
  }, [selectedFaculty, selectedDepartment]);

  useEffect(() => {
    setIsLoading(true);
    searchStudents();
    setIsLoading(false);
    return;
  }, [searchValue]);

  const facultyOptions =
    studentsData?.length > 0
      ? studentsData.map((item) => item.faculty_details?.name)
      : [];

  const departmentOptions =
    studentsData?.length > 0
      ? studentsData.map((item) => item.department_details?.name)
      : [];

  const CardOptions = [
    // {
    //   title: "Video Lessons",
    //   icon: <SubscriptionIcon />,
    //   color: "#BF39BF",
    //   data: 0,
    // },

    {
      title: "Students",
      icon: <GroupPeopleIcon color="white" />,
      color: "#800080",
      data: studentsData.length ?? 0,
    },
  ];

  return (
    <div>
      {isLoading && <Loader />}
      <div className={`${globalStyles["header-container"]}`}>
        <h2>{course} / Students</h2>

        <div className={`${globalStyles["action-container"]}`}>
          <DropDownS
            value={selectedFaculty}
            setValue={setSelectedFaculty}
            placeholder={"Select Faculty"}
            data={facultyOptions}
            keyItem={"name"}
          />
          <DropDownS
            value={selectedDepartment}
            setValue={setSelectedDepartment}
            placeholder={"Select Department"}
            data={departmentOptions}
            keyItem={"name"}
          />

          <div style={{ maxWidth: 300 }}>
            <Search
              value={searchValue}
              setValue={setSearchValue}
              placeholder={"Find Student Record"}
            />
          </div>
        </div>
      </div>

      <div className={styles.statistics_row_container}>
        {/* {CardOptions.map((item, index) => {
          return (
            <StatisticsCard
              key={index}
              title={item.title}
              color={item.color}
              icon={item.icon}
              data={item.data}
            />
          );
        })} */}
        {CardOptions.map((item, index) => {
          return (
            <div className={styles.card} key={index}>
              <div className={index === 0 ? styles.videoicon : styles.groupicon}>
                {item.icon}
              </div>
              <div className={styles.details}>
                <h3>{item.title}</h3>
                <p>{item.data}</p>
              </div>
            </div>
          );
        })}
      </div>

      {/* Faculty Table */}
      <div className={`${globalStyles["table-container"]}`}>
        <table>
          <thead>
            <tr className={`${globalStyles["table-row"]}`}>
              <th className={`${globalStyles["table-header"]}`}>
                <div className={`${globalStyles["first-square"]}`}>
                  {/* <div className={`${globalStyles["check-box"]}`}></div> */}
                  <p>Registration Number</p>
                </div>
              </th>
              <th className={`${globalStyles["table-header"]}`}>First Name</th>
              <th className={`${globalStyles["table-header"]}`}>Last Name</th>
              <th className={`${globalStyles["table-header"]}`}>Gender</th>
              <th className={`${globalStyles["table-header"]}`}>DOB</th>
              <th className={`${globalStyles["table-header"]}`}>Nationality</th>
              <th className={`${globalStyles["table-header"]}`}>Email</th>
              <th className={`${globalStyles["table-header"]}`}>Contact</th>
            </tr>
          </thead>

          <tbody>
            {filterdStudentsData.length > 0 ? (
              filterdStudentsData?.map((student, index) => {
                return (
                  <tr
                    key={index}
                    className={`${globalStyles["table-row-container"]}`}
                  >
                    <td
                      className={`${globalStyles["table-row"]}`}
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      {/* <div
                        className={`${globalStyles["check-box"]}`}
                        style={{ marginInlineEnd: 16 }}
                      ></div> */}
                      <p style={{ whiteSpace: "nowrap" }}>
                        {student.student_id}
                      </p>
                    </td>
                    <td className={` ${globalStyles["table-row"]}`}>
                      <p>{student.user.first_name}</p>
                    </td>
                    <td className={` ${globalStyles["table-row"]}`}>
                      <p>{student.user.last_name}</p>
                    </td>
                    <td>{student.user.gender}</td>
                    <td>{student.user.date_of_birth}</td>
                    <td className={` ${globalStyles["table-row"]}`}>
                      <p>{student.user.nationality}</p>
                    </td>
                    <td className={` ${globalStyles["table-row"]}`}>
                      <p>{student.user.email}</p>
                    </td>
                    <td>{student.user.phone}</td>
                  </tr>
                );
              })
            ) : studentsData.length > 0 ? (
              studentsData?.map((student, index) => {
                return (
                  <tr
                    key={index}
                    className={`${globalStyles["table-row-container"]}`}
                  >
                    <td
                      className={`${globalStyles["table-row"]}`}
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className={`${globalStyles["check-box"]}`}
                        style={{ marginInlineEnd: 16 }}
                      ></div>
                      <p style={{ whiteSpace: "nowrap" }}>
                        {student.student_id}
                      </p>
                    </td>
                    <td className={` ${globalStyles["table-row"]}`}>
                      <p>{student.user.first_name}</p>
                    </td>
                    <td className={` ${globalStyles["table-row"]}`}>
                      <p>{student.user.last_name}</p>
                    </td>
                    <td>{student.user.gender}</td>
                    <td>{student.user.date_of_birth}</td>
                    <td className={` ${globalStyles["table-row"]}`}>
                      <p>{student.user.nationality}</p>
                    </td>
                    <td className={` ${globalStyles["table-row"]}`}>
                      <p>{student.user.email}</p>
                    </td>
                    <td>{student.user.phone}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={8}>No Students Found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className={`${globalStyles["pagination-container"]}`}>
        <p>
          Page <span style={{ fontWeight: "bold" }}>1/1</span> Total:{" "}
          <span style={{ fontWeight: "bold" }}>
            {studentsData?.length ?? 0} Students
          </span>
        </p>
        <div className={`${globalStyles["btn-container"]}`}>
          <div className={`${globalStyles.icons} ${globalStyles["left-icon"]}`}>
            <ChevronIcon />
          </div>
          <div
            className={`${globalStyles.icons} ${globalStyles["right-icon"]}`}
          >
            <ChevronIcon />
          </div>
        </div>
      </div>
    </div>
  );
}
