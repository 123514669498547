import React from "react";
// import NavBar from "../../components/NavBar/NavBar";
// import Footer from "../../components/Footer/Footer";
import Hero from "./components/Hero/Hero";
import FacultyDirectory from "./components/FacultyDirectory/FacultyDirectory";
import AcademicSupportServices from "./components/AcademicSupportServices/AcademicSupportServices";
import AcademicsResources from "./components/AcademicResources/AcademicResources";
import ProgramDetails from "./components/ProgramDetails/ProgramDetails";
import StaticLayout from "../../components/Layout/StaticLayout/StaticLayout";

export default function Academics() {
  return (
    <StaticLayout>
      <Hero />
      <FacultyDirectory />
      <ProgramDetails />
      <AcademicsResources />
      <AcademicSupportServices />
    </StaticLayout>
  );
}
