import React, { useState } from "react";
import styles from "./chatMessagesComponent.module.css";
import SmileEmojiIcon from "../../../../../../assets/icons/smile.emoji.icon";
import SendIcon from "../../../../../../assets/icons/send.icon";
import AttachmentIcon from "../../../../../../assets/icons/attachment.icon";

export default function ChatMessagesComponent() {
  /** State management */
  const [messageText, setMessageText] = useState("");
  return (
    <div className={`${styles["chatMessage-container"]}`}>
      <div className={`${styles["chatMessage-header"]}`}>
        <div className={`${styles["profile-message-sub-container"]}`}>
          <div className={`${styles["profile-message-card-image-container"]} `}>
            <img
              src="https://avatars.githubusercontent.com/u/72765949?v=4?s=400"
              alt=""
            />
          </div>
          <div className={`${styles["chat-details-container"]} `}>
            <h5>Lara Mueller</h5>
          </div>
        </div>
      </div>

      <section className={`${styles["chatMessage-message-box"]}`}>
      </section>

      <div className={`${styles["chatMessage-messaging-section"]}`}>
        <div>
          <SmileEmojiIcon width={24} height={24} />
        </div>
        <div>
          <AttachmentIcon width={20} height={20} />
        </div>

        <textarea
          className={`${styles["chat-textarea-box"]}`}
          value={messageText}
          onChange={(e) => setMessageText(e.target.value)}
          placeholder="Write a message..."
        ></textarea>

        <div>
          <SendIcon style={{ opacity: messageText.length > 0 ? 1 : 0.5 }} />
        </div>
      </div>
    </div>
  );
}
