import React, { useState } from "react";
import Button from "../../../../components/Button/customButton/Button";
import styles from "./manageGroups.module.css"
import { useCourses } from "../../../../query/tutor/useCourses";
import { useIonToast } from "@ionic/react";

export default function CreateGroupModal({ setGroupModal, courses }) {
    const [toast] = useIonToast();
    const { handleCreateClassGroup } = useCourses()
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState("")
    const [groupName, setGroupName] = useState('')

    async function handleCreateNewGroup() {
        try {
            setIsLoading(true);
            const data = { name: groupName, courseId: selectedCourse }
            await handleCreateClassGroup(data).then(() => {
                setGroupModal(false);
                setIsLoading(false);
                toast({
                    message: `Success`,
                    color: "success",
                    buttons: ["Ok"],
                });
            })
        } catch (error) {
            console.error(error)
            setIsLoading(false)
            toast({
                message: `Error creating class`,
                color: "danger",
                buttons: ["Ok"],
            });
        }


    }

    return (
        <div className={`${styles.container}`}>
            <div className={`${styles.sub_container}`}>
                <div
                    style={{ position: "absolute", right: 20, top: 0 }}
                    onClick={() => !isLoading && setGroupModal(false)}
                >
                    <p style={{ fontSize: 50, cursor: "pointer", color: "red" }}>
                        &times;
                    </p>
                </div>
                <h2 className={`${styles.title}`}>Select Course To Create Classroom</h2>

                <div style={{ marginTop: 25, display: 'flex' }}>
                    <h4 >GROUP TITLE</h4>
                </div>

                <input type="text" placeholder="Group name" style={{
                    width: "100%", marginTop: 20, padding: 15,
                    outline: 'none', borderRadius: 5
                }}
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)} />

                <select style={{
                    width: "100%", marginTop: 20, padding: 15,
                    outline: 'none', borderRadius: 5
                }}
                    onChange={(e) => setSelectedCourse(e.target.value)}
                >
                    <option>Select Course</option>
                    {courses && courses.length > 0 ?
                        courses.map((item, index) => {
                            return (
                                <option key={index}
                                    value={item.id}
                                >
                                    {item.course_title}
                                </option>
                            )
                        })
                        : <option>
                            No Data Found
                        </option>}
                </select>

                {
                    <div
                        style={{
                            marginTop: 50,
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Button
                            text={"Save"}
                            onClick={() => handleCreateNewGroup()}
                            isLoading={isLoading}
                            disabled={isLoading}
                            width={"fit-content"}
                        />
                    </div>
                }
            </div>
        </div >
    );
}
