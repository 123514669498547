import React from "react";

export default function ChartIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="44"
      viewBox="0 0 90 44"
      fill="none"
      {...props}
    >
      <path
        d="M2 41.535C2 41.535 8.2853 -6.01245 24.0195 16.0124C39.7536 38.0372 46.0389 37.0256 52.0442 20.0159C59.4676 -1.01016 86.833 17.0939 89.5774 2"
        stroke="url(#paint0_linear_1773_5115)"
        strokeWidth="4.00354"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1773_5115"
          x1="-1.50311"
          y1="46.5394"
          x2="70.6385"
          y2="-16.9311"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
