import React from "react";
import academic_cap from "../../../../assets/images/academic_cap.png";
import communication from "../../../../assets/images/fluent_communication.png";
import career from "../../../../assets/images/work_icon.png";
import styles from "./ourCoreValue.module.css";

export default function OurCoreValues() {
  const COREVALUES = [
    {
      value: "Academic Excellence",
      icon: academic_cap,
    },
    {
      value: "Critical Thinking",
      icon: academic_cap,
    },
    { value: "Communication Skills", icon: communication },
    { value: "Career Readiness", icon: career },
  ];
  return (
    <div className={`${styles.our_core_value_container}`}>
      <div className={styles.container}>
        <h4 className={`${styles.our_core_value_header}`}>OUR CORE VALUES</h4>
        <p className={`${styles.our_core_value_desc}`}>
          We Equip all of our Students with these Value
        </p>
      </div>
      <ul className={`${styles.core_value_holders}`}>
        {COREVALUES.map((coreValue, index) => {
          return <CoreValueCard key={index} coreValue={coreValue} />;
        })}
      </ul>

      <p className={`${styles.our_core_value_desc_full}`}>
        Eu proin nisl integer enim sit mollis. Et vel id quis tincidunt vitae
        pretium. Justo non tincidunt quisque lectus. Ut ipsum non nullam
        pellentesque. Turpis platea in amet id arcu fermentum elementum quis
        adipiscing. Adipiscing malesuada sit nulla enim lorem turpis.
      </p>
    </div>
  );
}

function CoreValueCard({ coreValue }) {
  return (
    <li className={`${styles.core_value_container}`}>
      <div className={`${styles.core_value_image}`}>
        <img src={coreValue?.icon} alt={coreValue?.value} />
      </div>
      <p>{coreValue?.value}</p>
    </li>
  );
}
