import React from 'react'
import Logo from '../../logo/Logo'
import styles from "./authLayout.module.css"


export default function AuthLayout({ children, Icon }) {
    return (
        <div className={`${styles["layout-container"]}`}>
            {/* Left Side */}
            <div className={`${styles["left-side-container"]}`}>
                <Logo />

                <div className={`${styles["left-side-container__bottom"]}`}>
                    {
                        Icon[0]

                    }
                    {
                        Icon[1]
                    }
                    <p>Resume your journey with our amazing students.</p>
                </div>
            </div>

            <div className={`${styles["right-side-container"]}`}>
                {children}
            </div>
        </div>
    )
}
