import React from "react";
import styles from "./logo.module.css";
import { Link } from "react-router-dom";
import logo_image from "../../assets/images/xashy_logo1.png";

export default function MainLogo() {
  return (
    <div>
      <Link to="/" style={{ textDecoration: "none" }}>
        <div>
          <div className={`${styles["main-logo-container"]}`}>
            <div className={`${styles["main-logo-icon"]}`}>
              <img src={logo_image} alt="Xash Tech Logo" />
            </div>
            <span>Xashy Tech</span>
          </div>
        </div>
      </Link>
    </div>
  );
}
