import React, { useEffect, useState } from "react";
import own_styles from "./library.module.css";
import styles from "../generalStyles.module.css";
import Button from "../../../../../components/Button/customButton/Button";
import Search from "../../../../../components/Search/Search";
import Loader from "../../../../../components/Loader/Loader";
import ChevronIcon from "../../../../../assets/icons/chevron.icon";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLibrary } from "../../../../../query/admin/useLibrary";

export default function Library() {
  /** State management */
  const userInfo = useSelector((state) => state.user.user);
  const [libraryData, setLibraryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const navigate = useNavigate();

  const { getBooks } = useLibrary();

  useEffect(() => {
    setIsLoading(true);
    getBooks().then((books) => {
      if (books) {
        setLibraryData(books);
      }
      setIsLoading(false);
    });
  }, []);

  return (
    <div>
      {isLoading && <Loader />}
      <div className={`${own_styles["header-container"]}`}>
        <h2>Library Management</h2>

        <div className={`${own_styles["action-container"]}`}>
          {userInfo.role === "superadmin" && (
            <Button
              text={"Upload New Book"}
              onClick={() => navigate("/library/add-new-book")}
              width={"fit-content"}
              paddingBlock={17}
            />
          )}

          <div style={{ width: "100%" }}>
            <Search
              placeholder={"Find book..."}
              value={searchValue}
              setValue={setSearchValue}
            />
          </div>
        </div>
      </div>

      {/* Library Table */}
      <div className={`${own_styles["table-container"]}`}>
        <table>
          <thead>
            <tr className={`${styles["table-row"]}`}>
              <th className={`${own_styles["table-header"]}`}>
                <div className={`${styles["first-square"]}`}>
                  <div className={`${styles["check-box"]}`}></div>
                  <p>Book ID</p>
                </div>
              </th>
              <th className={`${own_styles["table-header"]}`}>Book Title</th>
              <th className={`${own_styles["table-header"]}`}>Download Ref</th>
            </tr>
          </thead>

          <tbody>
            {libraryData.results?.map((book, index) => {
              return (
                <tr
                  key={index}
                  className={`${styles["table-row-container"]}`}
                //   onClick={() => {
                //     setSelectedCourseId(course.id);
                //     setViewUpdateModal(true);
                //   }}
                >
                  <td
                    className={`${styles["table-row"]}`}
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className={`${styles["check-box"]}`}
                      style={{ marginInlineEnd: 16 }}
                    ></div>
                    <p style={{ whiteSpace: "nowrap" }}>{book.id}</p>
                  </td>
                  <td className={` ${styles["table-row"]}`}>
                    <p>{book.title}</p>
                  </td>
                  <td className={` ${styles["table-row"]}`}>
                    <p>Click to Download</p>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className={`${styles["pagination-container"]}`}>
        <p>
          Page <span style={{ fontWeight: "bold" }}>1/1</span> Total:{" "}
          <span style={{ fontWeight: "bold" }}>
            {libraryData.count ?? 0} Books
          </span>
        </p>
        <div className={`${styles["btn-container"]}`}>
          <div className={`${styles.icons} ${styles["left-icon"]}`}>
            <ChevronIcon />
          </div>
          <div className={`${styles.icons} ${styles["right-icon"]}`}>
            <ChevronIcon />
          </div>
        </div>
      </div>
    </div>
  );
}
