import React, { useState } from "react";
import Carousel from "./component/Carousel";
import styles from "./confirmInfo.module.css";
import PersonalInformationDetails from "./component/PersonalInformation/PersonalInformation";
import IdentityDetails from "./component/Identity/Identity";
import ResidenceInfoDetails from "./component/ResidenceInformation/ResidenceInfo";
import GuardianInfoDetails from "./component/GuardianInformation/GuardianInfo";
import SupportingDocumentsDetails from "./component/SupportingDocuments/SupportingDocuments";

export default function ConfirmInfo({ studentInfo, setGotToEditInfo }) {
  /** State management */
  const [activeTab, setActiveTab] = useState(0);
  console.log("confirmInfo:", studentInfo);
  const details = [
    {
      letter: "A",
      title: "Personal Information",
      node: (
        <PersonalInformationDetails
          firstName={studentInfo.firstName}
          lastName={studentInfo.lastName}
          nationality={studentInfo.nationality}
          gender={studentInfo.gender}
          birthDate={studentInfo.birthDate}
          email={studentInfo.email}
          phone={studentInfo.phone}
        />
      ),
    },
    {
      letter: "B",
      title: "Identity (KYC)",
      node: (
        <IdentityDetails
          idCardNumber={studentInfo.idCardNumber}
          idCardDocument={studentInfo.idCardDocument}
          profilePicture={studentInfo.profilePicture}
        />
      ),
    },
    {
      letter: "C",
      title: "Residence Information",
      node: (
        <ResidenceInfoDetails
          primaryLocation={studentInfo.primaryLocation}
          secondaryLocation={studentInfo.secondaryLocation}
        />
      ),
    },
    {
      letter: "D",
      title: "Guardian Information",
      node: (
        <GuardianInfoDetails
          guardianOneName={studentInfo.guardianOneName}
          guardianOneContact={studentInfo.guardianOneContact}
          guardianTwoContact={studentInfo.guardianTwoContact}
          guardianTwoName={studentInfo.guardianTwoName}
        />
      ),
    },
    {
      letter: "E",
      title: "Supporting Information",
      node: (
        <SupportingDocumentsDetails
          coverLetter={studentInfo.coverLetter}
          documents={[...studentInfo.documents]}
        />
      ),
    },
  ];

  return (
    <div>
      <h1>Thank you!🎉 </h1>
      <p>
        Thank you for having made it this far. Before making a final submission
        of your information, we would like you to thoroughly check your
        information below to make sure everything is correct and proper.
      </p>

      <div className={`${styles.detail_info}`}>
        {details.map((detail, index) => {
          return (
            <Carousel
              key={index}
              title={detail.title}
              letter={detail.letter}
              children={detail.node}
              index={index}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              setGotToEditInfo={setGotToEditInfo}
            />
          );
        })}
      </div>
    </div>
  );
}
