import React, { useState } from "react";
import MainLogo from "../../../../components/logo/MainLogo";
import Button from "../../../../components/Button/customButton/Button";
import TextInput from "../../../../components/Inputs/TextInput/TextInput";
import styles from "./contactus.module.css";
import home_styles from "../../home.module.css";

export default function ContactUs() {
  /** State management */
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [content, setContent] = useState("");

  return (
    <div className={styles.container}>
      <div id="contact" className={`${styles.contact_us_container}`}>
        <h4 className={`${home_styles.section_heading_title}`}>Contact Us</h4>
        <p
          className={`${home_styles.section_heading_desc}`}
          style={{ width: "70%" }}
        >
          Have questions or need more information? We're here to help!
        </p>

        <div className={`${styles.contact_us_body}`}>
          <form className={`${styles.contact_us_form}`}>
            <TextInput
              value={name}
              setValue={setName}
              placeholder={"Full Name"}
            />
            <TextInput
              value={email}
              setValue={setEmail}
              placeholder={"Email Address"}
            />
            <TextInput
              value={phoneNumber}
              setValue={setPhoneNumber}
              placeholder={"Phone Number"}
            />
            <textarea
              className={`${styles.textarea_contact_us}`}
              value={content}
              onChange={(e) => setContent(e.target.value)}
              placeholder="How can we help you?"
            ></textarea>

            <Button text={"Send My Message"} />
          </form>

          <div className={`${styles.contact_us_social}`}>
            <div>
              <MainLogo />
              <p>Bring learning closer to you</p>

              {/* Social media */}
              <ul>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>

            <div>
              <p className={`${styles.catching_desc_contact_us}`}>
                Send us a message using the form, and our friendly team will get
                back to you as soon as possible.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
