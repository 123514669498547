import React from "react";
import StaticLayout from "../../components/Layout/StaticLayout/StaticLayout";
import styles from "./employeesDetails.module.css";

export default function EmployeeDetails() {
  const EMPLOYEESDETAILS = [
    {
      title: "Background",
      details:
        "Jassica Oliver is known for her ability to take a creative brief and run with it, coming back with fresh ideas and a perfectly built design file every time. From digital design to long-format layouts, she blends beautiful and intuitive with each project she touches. She also happens to be the queen of deadline-crushing, all while maintaining a can-do, Zen attitude that keeps the whole Statement team centered. When he’s not building strong alliances with other creatives, project managers and stakeholders alike, you’ll find him giving voice to client strategies with fresh, compelling concepts and delightfully clever messaging",
    },
    {
      title: "Professional History",
      details:
        "Jassica Oliver is known for her ability to take a creative brief and run with it, coming back with fresh ideas and a perfectly built design file every time. From digital design to long-format layouts, she blends beautiful and intuitive with each project she touches. She also happens to be the queen of deadline-crushing, all while maintaining a can-do, Zen attitude that keeps the whole Statement team centered. When he’s not building strong alliances with other creatives, project managers and stakeholders alike, you’ll find him giving voice to client strategies with fresh, compelling concepts and delightfully clever messaging",
    },
  ];

  return (
    <StaticLayout>
      <div className={`${styles.employees_details_container}`}>
        <div className={`${styles.employees_profile_image_container}`}>
          <img src="" alt="employee image" />
        </div>
        <div className={`${styles.employees_detail_info_container}`}>
          <div className={`${styles.employees_infos}`}>
            <h1>Philip FREEMAN</h1>
            <p>Dean of Management and Finances</p>
          </div>

          <div className={`${styles.employees_details_sub_container}`}>
            {EMPLOYEESDETAILS.map((details, index) => {
              return (
                <div key={index} className={`${styles.employee_detail_info}`}>
                  <h3>{details.title}</h3>
                  <p>{details.details}</p>
                </div>
              );
            })}
          </div>

          <div>
            <h3 className={`${styles.employee_detail_info_sub_title}`}>
              Follow
            </h3>
            <ul>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </div>
      </div>
    </StaticLayout>
  );
}
