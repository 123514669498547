import React from "react";
import styles from "./statisticsCard.module.css";
import { Link } from "react-router-dom";

export default function StatisticsCard({ title, color, icon, data, link }) {
  return (
    <Link to={link} style={{ textDecoration: "none" }}>
      <article className={`${styles["card-container"]}`}>
        {icon !== "" && (
          <div
            className={`${styles["icon-container"]}`}
            style={{ backgroundColor: color ?? "", color: "white" }}
          >
            {icon}
          </div>
        )}
        <div className={`${styles["card-details"]}`}>
          <h5>{title}</h5>
          <p>{data}</p>
        </div>
      </article>{" "}
    </Link>
  );
}
