import React from "react";

export default function ClockIcon({ ...props }) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="212"
        height="212"
        viewBox="0 0 212 212"
        fill="none"
        {...props}
      >
        <path
          d="M92.6616 19.6983L48.5833 36.2166C38.425 40.0149 30.1217 52.0283 30.1217 62.8933V128.525C30.1217 138.948 37.0118 152.64 45.4035 158.912L83.3867 187.267C95.8417 196.63 116.335 196.63 128.79 187.267L166.773 158.912C175.165 152.64 182.055 138.948 182.055 128.525V62.8933C182.055 52.0283 173.752 40.0149 163.593 36.2166L119.515 19.6983C112.007 16.9599 99.9932 16.9599 92.6616 19.6983Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M106 136.917C125.514 136.917 141.333 121.097 141.333 101.583C141.333 82.0693 125.514 66.25 106 66.25C86.4859 66.25 70.6667 82.0693 70.6667 101.583C70.6667 121.097 86.4859 136.917 106 136.917Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M108.208 90.5415V98.7565C108.208 101.848 106.618 104.763 103.88 106.353L97.1667 110.417"
          stroke="white"
          strokeWidth="1.5"
          strokeLiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
