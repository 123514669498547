import { useDispatch, useSelector } from "react-redux";
import client from "../../utils/api/axios";
import { institutionSlice } from "../../redux/features/institutionSlice";
import { useIonToast } from "@ionic/react";

export function useDepartment() {
  /** State management */
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);
  const [toast] = useIonToast();

  async function createDepartment(department) {
    return await client
      .post("/api/department/", department, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response) {
          getDepartments();
        }
        return true;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          buttons: ["Ok"],
          duration: 4000,
        });
        return null;
      });
  }

  async function getDepartments(faculty_id = "") {
    return await client
      .get("/api/department/", {
        params: {
          faculty_id: faculty_id,
        },
      })
      .then((response) => {
        const result = response.data;
        if (result) {
          dispatch(institutionSlice.actions.setDepartment(result));
        }
        return response.data;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          buttons: ["Ok"],
          duration: 4000,
        });
        return null;
      });
  }

  /**
   * updatedepartmentInfo Updates department information using department id
   * @param department department object to be updated
   */
  async function updateDepartmentInfo(department, departmentId) {
    return await client
      .put(`/api/department/${departmentId}/`, department, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then(async () => {
        await getDepartments();
        toast({
          message: `Deparment Info Updated Successfull!:`,
          color: "success",
          duration: 3000,
          buttons: ["Ok"],
        });
        return true;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          buttons: ["Ok"],
          duration: 4000,
        });
        return null;
      });
  }

  async function getSingleDepartmentInfo(departmentId) {
    return await client
      .get(`/api/department/${departmentId}/`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          buttons: ["Ok"],
          duration: 4000,
        });
        return null;
      });
  }

  async function getFilterDepartments(faculty_name) {
    return await client
      .get("/api/department/", {
        params: {
          faculty_name: `${faculty_name}`,
        },
      })
      .then((response) => {
        const result = response.data;

        return result;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          buttons: ["Ok"],
        });
        return null;
      });
  }

  async function searchDepartment(keyword) {
    return await client
      .get("/api/department/", {
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          keyword,
        },
      })
      .then((response) => {
        const result = response.data;
        return result;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          buttons: ["Ok"],
          duration: 4000,
        });
        return null;
      });
  }

  async function deleteDepartment(departmentId, departmentName) {
    return await client
      .delete(
        `/api/department/${departmentId}/`,

        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then(() => {
        getDepartments();
        return true;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          buttons: ["Ok"],
        });
        return null;
      });
  }

  return {
    createDepartment,
    getDepartments,
    getSingleDepartmentInfo,
    updateDepartmentInfo,
    getFilterDepartments,
    searchDepartment,
    deleteDepartment,
  };
}
