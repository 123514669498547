import React, { useState, useEffect } from "react";
import styles from "./Assesments.module.css";
import Search from "../../../../../components/Search/Search";
import AssessmentCard from "./AssessmentCard";
import Button from "../../../../../components/Button/customButton/Button";
import CreateAssessmentModal from "./CreateAssessmentModal";
// import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAssessments } from "../../../../../query/tutor/useAssessments";
import { useTutorAuth } from "../../../../../query/tutor/auth";
import Loader from "../../../../../components/Loader/Loader";
import { FaPlus } from "react-icons/fa6";

const Assessments = () => {
  // const navigate = useNavigate();
  const { getTutorInfo } = useTutorAuth();
  const { handleGetAssessments } = useAssessments();
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [coursesData, setCoursesData] = useState([]);
  const [tutorAssessments, setTutorAssessments] = useState([]);
  const userInfo = useSelector((state) => state.user);
  const assessments = useSelector((state) => state.assessment.assessments.data);

  // Filter variables
  // const [selectedFaculty, setSelectedFaculty] = useState("");
  // const [selectedDepartment, setSelectedDepartment] = useState("");
  // const [selectedLevel, setSelectedLevel] = useState("");

  // Seach text variable
  const [searchText, setSearchText] = useState("");

  const getTutorData = async () => {
    if (userInfo.user.id !== undefined) {
      const id = userInfo.user.id;
      getTutorInfo(id)
      return;
    }
  };

  const getAssessments = async () => {
    if (userInfo.user.id !== undefined) {
      setIsLoading(true)
      const id = userInfo.user.id;
      await handleGetAssessments(id).then(() => setIsLoading(false))
      return;
    }
  };

  const getTutorAssessments = () => {
    if (assessments?.length > 0) {
      const assmts = assessments.filter(
        (item) => item.instructor === userInfo.user.id
      );
      setTutorAssessments(assmts);
    }
    return
  };

  useEffect(() => {
    const courses = userInfo.user?.courses_detail;
    const orderedCourses =
      courses?.length > 0
        ? courses.map((item) => {
          return { id: item.id, name: item.course_title };
        })
        : [];
    setCoursesData(orderedCourses);
  }, []);

  useEffect(() => {
    getTutorData();
    getAssessments();
  }, []);

  useEffect(() => {
    getTutorAssessments();
  }, [assessments]);

  return (
    <div>
      {isLoading && <Loader />}
      {openModal && (
        <CreateAssessmentModal
          setViewCourseModal={setOpenModal}
          courses={coursesData}
        />
      )}
      <h3 style={{ marginTop: 10, fontSize: 20 }}>Assessments</h3>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div className={styles.filter_row_container}>
          {/* <DropDownS
          data={facultyOptions}
          placeholder="Assessment type"
          setValue={setSelectedFaculty}
        />
        <DropDownS
          data={facultyOptions}
          placeholder="Filter By Faculty"
          setValue={setSelectedFaculty}
        />
        <DropDownS
          data={facultyOptions}
          placeholder="Filter By Department"
          setValue={setSelectedDepartment}
        />
        <DropDownS
          data={facultyOptions}
          placeholder="Select Level"
          setValue={setSelectedLevel}
        /> */}
          <div style={{ width: "100%" }}>
            <Search
              placeholder={"Assessment title"}
              value={searchText}
              setValue={setSearchText}
            />
          </div>
        </div>
        <div>
          <div className={styles.createSection}>
            <Button
              text="Create Assessment"
              width={"100%"}
              rightIcon={<FaPlus />}
              onClick={() => {
                setOpenModal(true);
              }}
            />
          </div>
        </div>
      </div>
      {tutorAssessments.length > 0
        ?
        <div className={styles.listingAssessments}>
          {tutorAssessments && tutorAssessments.length > 0 && coursesData && coursesData.length > 0 && tutorAssessments?.map((item, index) => {
            const courseTitle = coursesData.filter((c) => item.course === c.id)[0]?.name
            return (
              <AssessmentCard link={`/tutor/assessment/${item.id}/${courseTitle}`}
                title={item.title} desc={item.description} course={courseTitle} courseId={item.course} key={index}
                assessment_type={item.assessment_type} structure={item.structure}
                duration={item.duration} />
            )
          }).reverse()}
        </div> :
        <div className={styles.notFound}>
          <p>No Assesments Found</p>
        </div>
      }
    </div>
  );
};

export default Assessments;
