import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  opportunities: []
};

export const opportunitySlice = createSlice({
  name: "opportunity",
  initialState,
  reducers: {
    setOpportunities: (state, action) => {
      state.opportunities = action.payload;
    },
    newOpportunity: (state, action) => {
      state.opportunities = { ...state.opportunities, ...action.payload.data };
    },
  },
});

export const { setOpportunities } = opportunitySlice.actions;
export default opportunitySlice.reducer;
