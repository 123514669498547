export const UNDERGRADUATEPROGRAM = [
  {
    title: "Computer Science",
    description:
      "A program that focuses on computer systems and software development.",
    duration: "4 years",
    faculty: "Computer Science",
    semesters: 8,
    internshipHours: 400,
    programCredit: 120,
  },
  {
    title: "Psychology",
    description: "A program that explores the human mind and behavior.",
    duration: "3 years",
    faculty: "Social Sciences",
    semesters: 6,
    internshipHours: 200,
    programCredit: 90,
  },
  {
    title: "Business Administration",
    description:
      "A program that focuses on various aspects of business management.",
    duration: "4 years",
    faculty: "Business and Economics",
    semesters: 8,
    internshipHours: 320,
    programCredit: 120,
  },
  {
    title: "Science in Biology",
    description:
      "A program that studies living organisms and their interactions.",
    duration: "4 years",
    faculty: "Life Sciences",
    semesters: 8,
    internshipHours: 300,
    programCredit: 120,
  },
  {
    title: "Arts in English Literature",
    description:
      "A program that explores literary works and develops language skills.",
    duration: "3 years",
    faculty: "Humanities",
    semesters: 6,
    internshipHours: 150,
    programCredit: 90,
  },
  {
    title: "Civil Engineering",
    description:
      "A program that focuses on the design and construction of infrastructure.",
    duration: "4 years",
    faculty: "Engineering",
    semesters: 8,
    internshipHours: 400,
    programCredit: 130,
  },
  {
    title: "Economics",
    description:
      "A program that analyzes the production, distribution, and consumption of goods and services.",
    duration: "3 years",
    faculty: "Social Sciences",
    semesters: 6,
    internshipHours: 200,
    programCredit: 90,
  },
  {
    title: "Environmental Science",
    description:
      "A program that studies the environment and addresses environmental challenges.",
    duration: "4 years",
    faculty: "Environmental Sciences",
    semesters: 8,
    internshipHours: 320,
    programCredit: 120,
  },
  {
    title: "Mathematics",
    description:
      "A program that explores mathematical theories and applications.",
    duration: "3 years",
    faculty: "Mathematics",
    semesters: 6,
    internshipHours: 100,
    programCredit: 90,
  },
  {
    title: "Fine Arts",
    description:
      "A program that focuses on artistic expression and creative development.",
    duration: "4 years",
    faculty: "Fine Arts",
    semesters: 8,
    internshipHours: 200,
    programCredit: 120,
  },
  {
    title: "Political Science",
    description:
      "A program that examines political systems, theories, and policies.",
    duration: "3 years",
    faculty: "Social Sciences",
    semesters: 6,
    internshipHours: 150,
    programCredit: 90,
  },
  {
    title: "Chemistry",
    description:
      "A program that explores the composition, properties, and reactions of substances.",
    duration: "4 years",
    faculty: "Chemistry",
    semesters: 8,
    internshipHours: 300,
    programCredit: 120,
  },
  {
    title: "Sociology",
    description:
      "A program that studies social behavior, relationships, and institutions.",
    duration: "3 years",
    faculty: "Social Sciences",
    semesters: 6,
    internshipHours: 150,
    programCredit: 90,
  },
  {
    title: "Mechanical Engineering",
    description:
      "A program that focuses on the design and operation of mechanical systems.",
    duration: "4 years",
    faculty: "Engineering",
    semesters: 8,
    internshipHours: 400,
    programCredit: 130,
  },
  {
    title: "Marketing",
    description: "A program that explores marketing principles and strategies.",
    duration: "3 years",
    faculty: "Business and Economics",
    semesters: 6,
    internshipHours: 200,
    programCredit: 90,
  },
  {
    title: "Physics",
    description:
      "A program that studies the fundamental principles of matter andenergy.",
    duration: "4 years",
    faculty: "Physics",
    semesters: 8,
    internshipHours: 320,
    programCredit: 120,
  },
  {
    title: "Journalism",
    description:
      "A program that focuses on reporting, writing, and media communication.",
    duration: "3 years",
    faculty: "Communication",
    semesters: 6,
    internshipHours: 150,
    programCredit: 90,
  },
  {
    title: "Biomedical Science",
    description:
      "A program that explores the intersection of biology and medicine.",
    duration: "4 years",
    faculty: "Life Sciences",
    semesters: 8,
    internshipHours: 300,
    programCredit: 120,
  },
  {
    title: "History",
    description: "A program that studies past events, societies, and cultures.",
    duration: "3 years",
    faculty: "Humanities",
    semesters: 6,
    internshipHours: 100,
    programCredit: 90,
  },
  {
    title: "Electrical Engineering",
    description:
      "A program that focuses on the study and application of electrical systems.",
    duration: "4 years",
    faculty: "Engineering",
    semesters: 8,
    internshipHours: 400,
    programCredit: 130,
  },
  {
    title: "International Relations",
    description:
      "A program that examines relations between countries and global issues.",
    duration: "3 years",
    faculty: "Social Sciences",
    semesters: 6,
    internshipHours: 200,
    programCredit: 90,
  },
];

export const POSTGRADUATEPROGRAM = [
  {
    title: "Computer Science",
    description:
      "An advanced program that focuses on computer systems and software development.",
    duration: "2 years",
    faculty: "Computer Science",
    semesters: 4,
    researchHours: 500,
    programCredit: 60,
  },
  {
    title: "Business Administration",
    description:
      "A prestigious program that provides advanced knowledge in business management.",
    duration: "1.5 years",
    faculty: "Business and Economics",
    semesters: 3,
    researchHours: 300,
    programCredit: 45,
  },
  {
    title: "Psychology",
    description:
      "An in-depth program that explores advanced topics in psychology.",
    duration: "2 years",
    faculty: "Social Sciences",
    semesters: 4,
    researchHours: 400,
    programCredit: 60,
  },
  {
    title: "Civil Engineering",
    description:
      "An advanced program that focuses on the design and construction of civil infrastructure.",
    duration: "2 years",
    faculty: "Engineering",
    semesters: 4,
    researchHours: 500,
    programCredit: 60,
  },
  {
    title: "Environmental Science",
    description:
      "An advanced program that addresses complex environmental challenges.",
    duration: "2 years",
    faculty: "Environmental Sciences",
    semesters: 4,
    researchHours: 400,
    programCredit: 60,
  },
  {
    title: "English Literature",
    description:
      "An advanced program that explores advanced concepts in English literature.",
    duration: "2 years",
    faculty: "Humanities",
    semesters: 4,
    researchHours: 400,
    programCredit: 60,
  },
  {
    title: "Data Science",
    description:
      "An advanced program that focuses on data analysis and machine learning.",
    duration: "2 years",
    faculty: "Computer Science",
    semesters: 4,
    researchHours: 500,
    programCredit: 60,
  },
  {
    title: "International Relations",
    description:
      "An advanced program that examines global politics and international affairs.",
    duration: "2 years",
    faculty: "Social Sciences",
    semesters: 4,
    researchHours: 400,
    programCredit: 60,
  },
  {
    title: "Biotechnology",
    description:
      "An advanced program that focuses on the application of biotechnology in various fields.",
    duration: "2 years",
    faculty: "Life Sciences",
    semesters: 4,
    researchHours: 400,
    programCredit: 60,
  },
  {
    title: "Business Analytics",
    description:
      "An advanced program that combines business strategy and analytics skills.",
    duration: "1.5 years",
    faculty: "Business and Economics",
    semesters: 3,
    researchHours: 300,
    programCredit: 45,
  },
];
