import React, { useState } from "react";
import TextInput from "../../../../../components/Inputs/TextInput/TextInput";
import styles from "./residenceInformation.module.css";

export default function ResidenceInformation({
  primaryLocation,
  setPrimaryLocation,
  secondaryLocation,
  setSecondaryLocation,
}) {
  return (
    <div className={`${styles["residence-container"]}`}>
      <TextInput
        label={"Primary Location"}
        placeholder={"Buea, Cameroon"}
        value={primaryLocation}
        setValue={setPrimaryLocation}
      />
      <TextInput
        label={"Secondary Location"}
        placeholder={"Douala, Cameroon"}
        value={secondaryLocation}
        setValue={setSecondaryLocation}
      />
    </div>
  );
}
