import React from 'react'
import styles from "./Styles.module.css"

const MeetingCard = ({ topic, course, keyIndex, startTime, status, onClick }) => {
    return (
        <div onClick={onClick} className={styles.cardWrapper} key={keyIndex}>
            <div >
                <h3>{topic}</h3>
                <p>{course}</p>
                <p>{startTime}</p>
                <div>
                    <p style={{
                        color: status === "Upcoming" ? "green" : status === "Present" ? "green" : "red",
                    }}>{status}</p>
                </div>
            </div>
        </div>
    )
}

export default MeetingCard