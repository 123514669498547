import React from "react";
import styles from "./facultyDirectory.module.css";

export default function FacultyDirectory() {
  return (
    <section className={`${styles.faculty_directory_container}`}>
      <div>
        <h4 className={`${styles.faculty_directory_container_title}`}>
          Faculty Directory
        </h4>
        <p className={`${styles.faculty_directory_container_sub_title}`}>
          A Look At The Institution Leaders
        </p>
        <p className={`${styles.desc}`}>
          Discover the exceptional individuals who drive our institution
          forward. Our esteemed faculty directors bring a wealth of knowledge,
          expertise, and passion to their respective fields. Through their
          guidance and mentorship, they inspire the next generation of thinkers,
          innovators, and change-makers. Get to know the dedicated minds shaping
          the academic landscape at Xashy Tech.
        </p>
      </div>

      <ul className={`${styles.faculty_directory_card_container}`}>
        {Array(9)
          .fill(0)
          .map((_v, index) => {
            return <FacultyDirectoryCard key={index} />;
          })}
      </ul>
    </section>
  );
}

function FacultyDirectoryCard() {
  return (
    <li>
      <article className={`${styles.faculty_directory_card}`}>
        <div className={`${styles.faculty_directory_card_image}`}>
          <img src="/admission_image1.png" alt="" />
        </div>
        <div className={`${styles.faculty_directory_card_details}`}>
          <h3>Joseph NAGATOMI</h3>
          <p>President</p>
        </div>
      </article>
    </li>
  );
}
