import { useDispatch, useSelector } from "react-redux";
import client from "../../utils/api/axios";
import { userSlice } from "../../redux/features/userSlice";
import { storeDataToLocalStorage } from "../../utils/functions/handleLocalStorage";
import { useIonToast } from "@ionic/react";

export function useTutorAuth() {
  /** State management */
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);
  const [toast] = useIonToast();

  async function getTutorInfo(tutorId) {
    client
      .get(`/api/teacher/${tutorId}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data) {
          dispatch(
            userSlice.actions.updateUserState({
              teacher_id: data.teacher_id,
              highest_degree: data.highest_degree,
              about: data.about,
              description: data.description,
              is_deleted: false,
              created_at: data.created_at,
              courses_detail: data.courses_detail,
              faculties_detail: data.faculties_detail,
              departments_detail: data.departments_detail,
            })
          );
        }
        return response;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.message}`,
          color: "danger",
          buttons: ["Ok"],
          duration: 4000,
        });
        return null;
      });
  }

  const handleTutorUpdate = async (data) => {
    return await client
      .put(`/api/teacher/${data.tutorId}/`, data.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data) {
          dispatch(userSlice.actions.setUser(data.user));
          toast({
            message: "Update successfully",
            color: "success",
            buttons: ["Ok"],
            duration: 4000,
          });
        }
        storeDataToLocalStorage("enchird-userInfo", data);

        return data;
        //   dispatch(setStudent(data));
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.message}`,
          color: "danger",
          buttons: ["Ok"],
          duration: 4000,
        });
      });
  };

  return {
    getTutorInfo,
    handleTutorUpdate,
  };
}
