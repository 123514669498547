import React from "react";
import { Link } from "react-router-dom";
import styles from "./lastestStories.module.css";
import BlogCard from "../../../blogs/BlogCard/BlogCard";

export default function LatestStories() {
  return (
    <div className={styles.container}>
      <div className={`${styles.story_container}`}>
        <h4 className={`${styles.heading}`}>LATEST STORIES</h4>
        <p className={`${styles.desc}`}>
          Stay updated with our latest news, events, and stories!
        </p>

        <div className={`${styles.story_card_holder}`}>
          {Array(3)
            .fill(0)
            .map((_v, index) => {
              return (
                <Link to={`/blogs/${index}`}
                  key={index}
                  className={`${index === 0 && styles.first_child}`}
                >
                  <BlogCard />
                </Link>
              )
            })}

        </div>
      </div>
    </div>
  );
}
