import React from "react";

export default function GuardianIcon({ ...props }) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="212"
        height="212"
        viewBox="0 0 212 212"
        fill="none"
        {...props}
      >
        <path
          d="M92.6616 19.6983L48.5833 36.2166C38.425 40.0149 30.1217 52.0281 30.1217 62.8931V128.525C30.1217 138.948 37.0118 152.64 45.4035 158.911L83.3867 187.267C95.8417 196.63 116.335 196.63 128.79 187.267L166.773 158.911C175.165 152.64 182.055 138.948 182.055 128.525V62.8931C182.055 52.0281 173.752 40.0149 163.593 36.2166L119.515 19.6983C112.007 16.9599 99.9932 16.9599 92.6616 19.6983Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M106 96.4597C105.647 96.4597 105.205 96.4597 104.852 96.4597C96.5483 96.1947 89.9233 89.3046 89.9233 80.913C89.9233 72.3446 96.9017 65.3662 105.47 65.3662C114.038 65.3662 121.017 72.3446 121.017 80.913C120.928 89.393 114.303 96.1947 106 96.4597Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M88.4217 121.193C79.9417 126.846 79.9417 136.121 88.4217 141.774C98.0501 148.223 113.862 148.223 123.49 141.774C131.97 136.121 131.97 126.846 123.49 121.193C113.95 114.744 98.1384 114.744 88.4217 121.193Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
