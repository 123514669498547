import React, { useState } from "react";
import styles from "./textInputS.module.css";
import CloseEyeIcon from "../../../../assets/icons/close-eye.icon";
import OpenEyeIcon from "../../../../assets/icons/open-eye.icon";

export default function TextInputS({
  value,
  setValue,
  placeholder,
  icon,
  disabled,
  type,
}) {
  /** State management */
  const [passwordType, setPasswordType] = useState("password");

  /** View Passowrd */
  function handleViewPassword() {
    if (passwordType === "password") setPasswordType("text");
    else setPasswordType("password");
  }
  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.iconInput}`}>{icon}</div>
      <input
        name=""
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        disabled={disabled}
        type={type === "password" ? passwordType : type ?? "text"}
      />
      <div onClick={() => handleViewPassword()} style={{ cursor: "pointer" }}>
        {type === "password" && (
          <>
            {passwordType !== "password" ? (
              <>
                <OpenEyeIcon width={24} height={24} color={"#4E504E"} />
              </>
            ) : (
              <>
                <CloseEyeIcon width={24} height={24} color={"#4E504E"} />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
