import React from "react";

export default function TimeAgo({ ...props }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="15"
            height="15"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        >
            <circle cx="12" cy="12" r="10" />
            <line x1="12" y1="5" x2="12" y2="12" />
            <line x1="12" y1="12" x2="16" y2="16" />
        </svg>

    );
}



