import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styles from "./richTextInput.module.css";

export default function RichTextInput({ value, setValue, label, required }) {
  return (
    <div>
      <label className={`${styles["input-label"]}`}>
        <span>{label}</span>
        {required && <span>*</span>}
      </label>
      <div style={{ marginTop: 20 }}>
        <ReactQuill
          theme="snow"
          value={value}
          onChange={setValue}
          style={{ height: 100 }}
        />
      </div>
    </div>
  );
}
