import React from "react";
import styles from "./personalInformation.module.css";

export default function PersonalInformationDetails({
  firstName,
  lastName,
  nationality,
  gender,
  birthDate,
  email,
  phone,
}) {
  return (
    <div className={`${styles["container"]}`}>
      <div className={`${styles["info"]}`}>
        <div className={`${styles["grid-column"]}`}>
          <h5>First Name</h5>
          <p>{firstName}</p>
        </div>
        <div className={`${styles["grid-column"]}`}>
          <h5>Last Name</h5>
          <p>{lastName}</p>
        </div>

        <div className={`${styles["grid-column"]}`}>
          <h5>Nationality</h5>
          <p>{nationality}</p>
        </div>

        <div className={`${styles["grid-column"]}`}>
          <h5>Gender</h5>
          <p>{gender}</p>
        </div>

        <div className={`${styles["grid-column"]}`}>
          <h5>Email Address</h5>
          <p>{email}</p>
        </div>

        <div className={`${styles["grid-column"]}`}>
          <h5>Contact / Phone Number</h5>
          <p>{phone}</p>
        </div>

        <div className={`${styles["grid-column"]}`}>
          <h5>Date of Birth</h5>
          <p>{birthDate}</p>
        </div>
      </div>
    </div>
  );
}
