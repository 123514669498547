import React, { useState } from "react";
import styles from "./Styles.module.css"
import { FiClipboard } from "react-icons/fi";
import { CopyToClipboard } from "react-copy-to-clipboard"


const MeetingDetails = ({ setViewModal, data }) => {
    const dateObject = new Date(data?.start_time);
    const formattedDate = dateObject.toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });
    const formattedTime = dateObject.toLocaleTimeString(undefined, {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    });
    return (
        <div className={`${styles.container}`}>
            <div className={`${styles.sub_container}`}>
                <div
                    style={{ position: "absolute", right: 20, top: 0 }}
                    onClick={() => setViewModal(false)}
                >
                    <p style={{ fontSize: 50, cursor: "pointer", color: "red" }}>
                        &times;
                    </p>
                </div>
                <h2 className={`${styles.title}`}>Meeting Details</h2>
                <div>
                    <div className={styles.itemDiv}>
                        <h4>Meeting Topic:</h4>
                        <p>{data?.topic}</p>
                    </div>
                    <div className={styles.itemDiv}>
                        <h4>Start Time:</h4>
                        <p>{formattedDate} {formattedTime}</p>
                    </div>
                    <div className={styles.itemDiv}>
                        <h4>Meeting Duration:</h4>
                        <p>{data?.duration} Minutes</p>
                    </div>
                    <div className={styles.itemDiv}>
                        <h4>Course:</h4>
                        <p>{data?.course?.course_title}</p>
                    </div>
                    <div className={styles.itemDiv}>
                        <h4>Meeting ID:</h4>
                        <p>{data?.meeting_id}</p>
                    </div>

                    <div className={styles.itemDiv}>
                        <h4>Meeting Password:</h4>
                        <p>{data?.password}</p>
                    </div>
                    <div className={styles.itemDivBtn}>
                        {/* <h4>Meeting Link</h4> */}
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <p className={styles.link}><a href={data?.join_url} target="_blank">Join Meeting</a></p>
                            <div style={{ marginTop: 17, marginLeft: 20 }}>
                                <CopyToClipboard
                                    className="my-2"
                                    text={data?.join_url}
                                // onCopy={() => toast.success("Link copied")}
                                >
                                    <button
                                        style={{
                                            border: "1px solid black",
                                            cursor: "pointer",
                                            width: "100%",
                                            textAlign: "center",
                                            padding: 10,
                                            borderRadius: 5
                                        }}
                                    >
                                        <FiClipboard />
                                    </button>
                                </CopyToClipboard>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MeetingDetails