import React, { useState, useEffect } from "react";
import Jobcard from "./Jobcard/Jobcard";
import Button from "../../../../../components/Button/customButton/Button";
import Search from "../../../../../components/Search/Search";
import styles from "./Opportunities.module.css";
import CreateOpportunity from "./createOpportunity/CreateOpportunity";
import { useOpportunities } from "../../../../../query/tutor/useOpportunities";
import { useSelector } from "react-redux";

export default function Opportunities() {
  const { getAllJobs } = useOpportunities();
  const [searchValue, setSearchValue] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const opportunities = useSelector(
    (state) => state.opportunity.opportunities.opportunities
  );

  const getAllOpportunities = async () => {
    await getAllJobs();
  };

  useEffect(() => {
    getAllOpportunities();
  }, []);

  return (
    <div>
      {openModal && <CreateOpportunity setViewCourseModal={setOpenModal} />}
      <h3 style={{ marginTop: 10 }}>Opportunity Desk</h3>
      <div className={styles.searchDiv}>
        <Button
          text="Add New Opportunity"
          width={"30%"}
          onClick={() => {
            setOpenModal(true);
          }}
        />
        <Search
          placeholder={"Find Opportunity..."}
          value={searchValue}
          setValue={setSearchValue}
        />
      </div>
      <div className={styles.listJobs}>
        {opportunities &&
          opportunities?.length > 0 &&
          opportunities?.map((item) => {
            return (
              <Jobcard
                jobTitle={item.title}
                company={item.company}
                city={item.city}
                yrsOfExp={item.experience}
                jobType={item.employment_type}
                description={item.description}
                date={item.created_at}
                salary={item.salary}
                link={item.link}
              />
            );
          })}
      </div>
    </div>
  );
}
