import React, { useState } from "react";
import TextInput from "../../../../../components/Inputs/TextInput/TextInput";
import styles from "./identity.module.css";
import UploadFileComponent from "../../../../../components/Inputs/UploadFileComponent/UploadFileComponent";

export default function Identity({
  idCardNumber,
  setIdCardNumber,
  idCardDocument,
  setIdCardDocument,
  profilePicture,
  setProfilePicture,
}) {
  return (
    <div className={`${styles["identity-container"]}`}>
      <TextInput
        label={"Identity Card Number"}
        value={idCardNumber}
        setValue={setIdCardNumber}
        placeholder={"10192209"}
      />
      <div>
        <UploadFileComponent
          file={idCardDocument}
          setFile={setIdCardDocument}
          identifier={"idcard"}
        />
      </div>

      <div>
        <h3>Upload a Picture of yourself</h3>
        <UploadFileComponent
          file={profilePicture}
          setFile={setProfilePicture}
          type={"img"}
          identifier="profilePicture"
        />
      </div>
    </div>
  );
}
