import React from "react";
import styles from "../AcademicResources/academicResources.module.css";
import own_styles from "./programDetail.module.css";
import ProgramDetailCard from "./components/ProgramCardDetails/ProgramDetailCard";
import {
  POSTGRADUATEPROGRAM,
  UNDERGRADUATEPROGRAM,
} from "../../../../utils/CONSTANTS/programs";

export default function ProgramDetails() {
  return (
    <div
      className={`${styles.academic_resources_container}`}
      style={{ background: "white" }}
    >
      <div className={`${styles.academic_resources_header_container}`}>
        <h4>PROGRAM DETAILS</h4>
        <p className={`${styles.academic_resources_header_sub_title}`}>
          The Reasons Why We Keep Growing Stronger
        </p>
        <p className={`${styles.desc}`}>
          Discover our transformative programs that empower you to excel. With
          innovative curriculum, dedicated faculty, and immersive learning
          experiences, we provide the tools for success. Whether it's
          groundbreaking research, creative expression, or entrepreneurial
          growth, we empower you to reach new heights. Join us and unleash your
          potential today!
        </p>
      </div>

      <div className={`${own_styles.section_program_details}`}>
        <h2 className={`${own_styles.heading_title}`}>
          UNDERGRADUATE PROGRAMS
        </h2>
        <ul className={`${own_styles.program_list}`}>
          {UNDERGRADUATEPROGRAM.map((program, index) => {
            return <ProgramDetailCard program={program} key={index} />;
          })}
        </ul>
      </div>

      <div className={`${own_styles.section_program_details}`}>
        <h2 className={`${own_styles.heading_title}`}>POSTGRADUATE PROGRAMS</h2>

        <ul className={`${own_styles.program_list}`}>
          {POSTGRADUATEPROGRAM.map((program, index) => {
            return <ProgramDetailCard program={program} key={index} />;
          })}
        </ul>
      </div>

      <div className={`${own_styles.academic_calendar}`}>
        <h5>Academic Calendar</h5>
        <p>
          Click <span>here</span> to download the academic calendar for this
          year. Be aware of important activities including important dates such
          as the start and end of each semester, holidays, exam periods, and
          special events.
        </p>
      </div>
    </div>
  );
}
