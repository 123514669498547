import React from "react";
import styles from "../AcademicResources/academicResources.module.css";

export default function AcademicSupportServices() {
  return (
    <div
      className={`${styles.academic_resources_container}`}
      style={{ background: "white" }}
    >
      <div className={`${styles.academic_resources_header_container}`}>
        <h4>ACADEMIC SUPPORT SERVICES</h4>
        <p className={`${styles.academic_resources_header_sub_title}`}>
          We Try To Support Our Students However We Can
        </p>
        <p className={`${styles.desc}`}>
          At Xashy Tech, we provide comprehensive academic support tailored to
          your needs. From personalized guidance and tutoring to study groups
          and career counseling, we are here to help you overcome challenges and
          reach your full potential. Your success is our priority, and we're
          with you every step of the way on your academic journey.
        </p>
      </div>

      <div className={`${styles.academic_resources_image_container}`}>
        <div className={`${styles.image_container}`}>
          <img src="/blog_image0.png" />
        </div>

        <div className={`${styles.image_container}`}>
          <img src="/blog_image0.png" />
        </div>

        <div className={`${styles.image_container}`}>
          <img src="/blog_image0.png" />
        </div>

        <div className={`${styles.image_container}`}>
          <img src="/blog_image0.png" />
        </div>
      </div>
    </div>
  );
}
