import React from "react";
import Button from "../../../../components/Button/customButton/Button";
import styles from "./hero.module.css";
import { useNavigate } from "react-router-dom";

export default function Hero() {
  const navigate = useNavigate();

  return (
    <div className={`${styles.hero_container}`}>
      <div className={styles.container}>
        <div className={`${styles.sub_core_container}`}>
          <h1>Unleash Your Potential: Ignite Your Journey at Xashy Tech.</h1>
          <p>
            Unleash your potential in a vibrant community of scholars and
            innovators. With cutting-edge programs, world-class faculty, and
            limitless opportunities, ignite your journey and shape a future that
            knows no boundaries.
          </p>

          <Button
            text={"Apply Now"}
            backgroundColor={"#800080"}
            paddingBlock={10}
            paddingInline={18}
            width={"fit-content"}
            onClick={() => navigate("/register")}
          />
        </div>
      </div>
    </div>
  );
}
