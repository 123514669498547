import React from "react";

export default function ContractIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      {...props}
    >
      <path
        fill="currentColor"
        d="m34.486 13.86l-.432-.432a3.17 3.17 0 0 0-.563-3.563a3.011 3.011 0 0 0-2.326-.87A3.439 3.439 0 0 0 30 9.272V2H2v32h28V17.82l3.09-3.09l.264.263a.371.371 0 0 1 0 .525l-2.353 2.354a.8.8 0 0 0 1.131 1.132l2.354-2.354a1.975 1.975 0 0 0 0-2.79M8 8.2h16.002v1.6H8Zm0 4h16.002v1.6H8Zm0 4h10.5v1.6H8Zm14.792 13.696h-7.804c-.902 0-1.746-.085-2.194-.824c-.459-.755-.003-1.632.33-2.274c.059-.113-.072-.253-.222-.098c-.44.452-1.14 1.266-1.703 1.92c-.595.692-.831.963-.957 1.077a.92.92 0 0 1-1.106.224a.869.869 0 0 1-.407-.969c.042-.25.756-3.032 1.403-5.533c-.86 1.527-3.59 6.1-3.718 6.315a.7.7 0 0 1-1.203-.718c.035-.057 3.455-5.788 3.886-6.62a1.425 1.425 0 0 1 1.776-.793a1.315 1.315 0 0 1 .755 1.626c-.36 1.388-.727 2.812-1.01 3.92c.457-.528.935-1.07 1.28-1.425a1.899 1.899 0 0 1 2.24-.517a1.183 1.183 0 0 1 .616 1.32a4.557 4.557 0 0 1-.388.916c-.267.555-.14 1.052.622 1.052h7.803a.7.7 0 0 1 0 1.4Zm-2.081-5.05l-2.604.687a.218.218 0 0 1-.216-.058a.215.215 0 0 1-.059-.216l.664-2.599l6.59-6.59l2.2 2.2Zm11.466-11.467l-3.76 3.76l-2.2-2.2l3.76-3.76a1.949 1.949 0 0 1 1.27-.586l.09-.003a1.423 1.423 0 0 1 1.022.407a1.7 1.7 0 0 1-.182 2.382"
      />
    </svg>
  );
}
