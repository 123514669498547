import React from "react";
import styles from "./academicResources.module.css";

export default function AcademicsResources() {
  return (
    <div className={`${styles.academic_resources_container}`}>
      <div className={`${styles.academic_resources_header_container}`}>
        <h4>Academic Resources</h4>
        <p className={`${styles.academic_resources_header_sub_title}`}>
          Our Students Have All The Necessary Resources
        </p>
        <p className={`${styles.desc}`}>
          Discover the resources that empower your academic journey. Xashy Tech
          is dedicated to equipping students with the tools they need to excel.
          From cutting-edge libraries to expert academic support, we ensure your
          success. Thrive beyond the classroom and achieve your goals with our
          comprehensive resources.
        </p>
      </div>

      <div className={`${styles.academic_resources_image_container}`}>
        <div className={`${styles.image_container}`}>
          <img src="/blog_image0.png" />
        </div>

        <div className={`${styles.image_container} ${styles.span_two_columns}`}>
          <img src="/blog_image0.png" />
        </div>

        <div
          className={`${styles.image_container} ${styles.span_two_columns_mobile}`}
        >
          <img src="/blog_image0.png" />
        </div>
      </div>
    </div>
  );
}
