export const testimonials = [
  {
    imgUrl: "image1.jpg",
    name: "John Smith",
    position: "Alumni",
    testimonial:
      "My time at Xashy Tech was nothing short of transformative. The dedicated faculty and hands-on learning experiences helped me develop the skills and confidence to excel in my career. I'm forever grateful for the opportunities and lifelong connections I made at this remarkable institution.",
  },
  {
    imgUrl: "image2.jpg",
    name: "Sarah Johnson",
    position: "Current Student",
    testimonial:
      "Choosing Xashy Tech was the best decision I ever made. The engaging courses, supportive professors, and collaborative environment have nurtured my growth and pushed me to new heights. From research projects to extracurricular activities, every day is a chance to discover my passions and make a difference in the world.",
  },
  {
    imgUrl: "image3.jpg",
    name: "David Adams",
    position: "Industry Professional",
    testimonial:
      "As an employer, I've had the pleasure of working with many graduates from Xashy Tech. The caliber of talent they bring to the table is exceptional. Their strong work ethic, critical thinking skills, and ability to adapt to real-world challenges truly set them apart. I highly recommend Cashy Tech to anyone seeking top-notch talent.",
  },
];
