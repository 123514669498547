import React from "react";
import styles from "./loader.module.css";
import { IonSpinner } from "@ionic/react";

export default function Loader() {
  return (
    <div className={`${styles["container"]}`}>
      <IonSpinner name="lines" color={"light"} className={styles["spinner"]} />
    </div>
  );
}
