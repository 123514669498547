import React from "react";
import styles from "./blogs.module.css";
import { Link } from "react-router-dom";
import BlogCard from "./BlogCard/BlogCard";
import StaticLayout from "../../components/Layout/StaticLayout/StaticLayout";

export default function Blogs() {
  const FILTERS = [
    "All",
    "Academic",
    "Faculty and Research",
    "Admissions and Enrollment",
    "Alumni Success Stories",
    "Student Resources",
    "Student Life",
  ];
  return (
    <StaticLayout>
      <div className={`${styles.student_life_activities_container}`}>
        <div className={styles.container}>
          <div className={`${styles.student_life_activities_header_container}`}>
            <h1>Student Life & Activities</h1>
            <p>Get the Latest About Events on Campus</p>
          </div>

          <div
            className={`${styles.student_life_activities_filters_main_container}`}
          >
            <div
              className={`${styles.student_life_activities_upper_filter_container}`}
            >
              <div className={`${styles.search_field}`}>
                <input type="text" placeholder="Search" />
              </div>
              <div className={`${styles.student_life_activities_upper_filter}`}>
                <div className={`${styles.date_filter_container}`}>
                  <h5>From:</h5>
                  <div className={`${styles.date_filter}`}>
                    <input type="date" />
                  </div>
                </div>

                <div className={`${styles.date_filter_container}`}>
                  <h5>To:</h5>
                  <div className={`${styles.date_filter}`}>
                    <input type="date" />
                  </div>
                </div>
              </div>
            </div>

            <ul className={`${styles.student_life_activities_filters_container}`}>
              {FILTERS.map((filter, index) => {
                return (
                  <li key={index} className={`${styles.filter}`}>
                    {filter}
                  </li>
                );
              })}
            </ul>
          </div>

          <ul className={`${styles.student_life_activities_cards_container}`}>
            {Array(9)
              .fill(0)
              .map((_v, index) => {
                return (
                  <Link to={`/blogs/${index}`}
                    key={index}
                    className={`${index === 0 && styles.first_child}`}
                  >
                    <BlogCard />
                  </Link>
                );
              })}
          </ul>
        </div>
      </div>
    </StaticLayout>
  );
}
