import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import styles from "./mainDashboard.module.css";
import { useDispatch } from "react-redux";
import { institutionSlice } from "../../../../../redux/features/institutionSlice";
import { useApplication } from "../../../../../query/admin/applicationRequest";
import { useFaculty } from "../../../../../query/admin/useFaculty";
import { useDepartment } from "../../../../../query/admin/useDepartment";
import { useCourse } from "../../../../../query/admin/useCourse";
import { useTutors } from "../../../../../query/admin/useTutors";
import { checkTokenExpiry } from "../../../../../utils/functions/checkTokenExpiry";
import { useNavigate } from "react-router-dom";
import { getDataFromLocalStorage } from "../../../../../utils/functions/handleLocalStorage";
import { userSlice } from "../../../../../redux/features/userSlice";
import { useStudent } from "../../../../../query/admin/useStudent";
import Loader from "../../../../../components/Loader/Loader";
import RecentChats from "./components/RecentChats/RecentChats";
import UpcomingEvents from "./components/UpcomingEvent/UpcomingEvent";
import StudentDistributionChart from "./components/StudentDistribution/StudentDistributionChart";

export default function MainDashboard() {
  return (
    <div>
      {/* {isLoading && <Loader />} */}
      <div className={`${styles["main-container"]}`}>
        <StudentDistributionChart />

        <div className={`${styles["sub-container"]}`}>
          <UpcomingEvents /> <RecentChats />
        </div>
      </div>
    </div>
  );
}
