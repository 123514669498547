import React from "react";
import styles from "./guardianInfo.module.css";
export default function GuardianInfoDetails({
  guardianOneName,
  guardianOneContact,
  guardianTwoContact,
  guardianTwoName,
}) {
  return (
    <div className={`${styles["container"]}`}>
      <div className={`${styles["info"]}`}>
        <div className={`${styles["grid-column"]}`}>
          <h5>Guardian 1 Name</h5>
          <p>{guardianOneName}</p>
        </div>
        <div className={`${styles["grid-column"]}`}>
          <h5>Guardian 1 Contact</h5>
          <p>{guardianOneContact}</p>
        </div>

        <div className={`${styles["grid-column"]}`}>
          <h5>Guardian 2 Name</h5>
          <p>{guardianTwoName}</p>
        </div>

        <div className={`${styles["grid-column"]}`}>
          <h5>Guardian 2 Contact</h5>
          <p>{guardianTwoContact}</p>
        </div>
      </div>
    </div>
  );
}
