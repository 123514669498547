import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import loginpassicon from "../../../assets/svgs/loginpassicon.svg";
import mailIcon from "../../../assets/svgs/mailIcon.svg";
import logo from "../../../assets/svgs/logo.svg";
import logo_blue from "../../../assets/svgs/logo_blue.svg";
import view_password from "../../../assets/svgs/view_password.svg";
import profiles from "../../../assets/svgs/Profiles.svg";
import "./login.css";
import Button from "../../../components/Button/customButton/Button";
import { useAuth } from "../../../query/admin/auth";
import { userSlice } from "../../../redux/features/userSlice";

const Login = () => {
  /** State management */
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  // const user = useSelector((state) => state.session.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleLogin } = useAuth();

  const onLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await handleLogin(email, password).then((response) => {
      if (response) {
        if (window.history.state) {
          // go back to previous route
          navigate(-1);
        } else {
          // go to home page
          navigate("/", { replace: true });
        }
      }
      setIsLoading(false);
    });
    // if (data) {
    //   setErrors(data);
    // }
  };

  const updateEmail = (e) => {
    setEmail(e.target.value);
  };
  const updatePassword = (e) => {
    setPassword(e.target.value);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="auth_container">
      <Link to="/" style={{ textDecoration: "none" }}>
        <div className="auth_logo_div hidden">
          <div className="flex_row_start">
            <span className="auth_logo_span">
              <img src="/xashy_logo1.png" alt="Logo" className="auth_logo" />
              Xashy Tech
            </span>
          </div>
          <div className="logo_subtitle">
            <span>Bring learning closer to you</span>
          </div>
        </div>
      </Link>
      <div className="auth_body">
        <div className="first_auth_container">
          <Link to="/" style={{ textDecoration: "none", width: 380 }}>
            <div className="auth_logo_div">
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src="/xashy_logo1.png" alt="Logo" className="auth_logo" />
                <span className="auth_logo_span"> Xashy Tech </span>
              </div>
              <div className="logo_subtitle">
                <span>Bring learning closer to you</span>
              </div>
            </div>
          </Link>
          <div className="auth_footer">
            <div className="auth_stud_prof">
              <img src={profiles} alt="Student" />
            </div>
          </div>
        </div>
        <div className="second_auth_container">
          <div className="outer_form_auth_div">
            <div className="auth_form_div">
              <form className="auth_form" onSubmit={onLogin}>
                <div className="auth_form_header_div">
                  <h1>Welcome Back</h1>
                </div>
                <div className="error_div login">
                  <p>{errors}</p>
                </div>
                <div className="input_Div">
                  <label className="input">
                    <input
                      className="input__field"
                      type="text"
                      placeholder=" "
                      value={email}
                      onChange={updateEmail}
                      disabled={isLoading}
                    />
                    <span className="input__label login">
                      <img
                        className="auth_icon"
                        src={mailIcon}
                        alt="loginIcon"
                        width={32}
                        height={32}
                      />
                      Your Email
                    </span>
                  </label>
                </div>
                <div className="input_Div">
                  <label className="input">
                    <input
                      className="input__field"
                      type={showPassword ? "text" : "password"}
                      placeholder=" "
                      value={password}
                      onChange={updatePassword}
                      disabled={isLoading}
                    />
                    <span className="input__label login">
                      <img
                        className="auth_icon"
                        src={loginpassicon}
                        alt="loginIcon"
                        width={32}
                        height={32}
                      />
                      Your Password
                    </span>
                    <div
                      className="view_password_icon"
                      onClick={togglePasswordVisibility}
                    >
                      <img
                        className="auth_icon"
                        src={view_password}
                        alt="loginIcon"
                        width={32}
                        height={32}
                      />
                    </div>
                  </label>
                </div>

                <Link to="/forgot-password" style={{ textDecoration: "none" }}>
                  <div className="login_fp_div">
                    <p className="login_fp_p">Forgot Password?</p>
                  </div>
                </Link>

                <Button
                  text={"Login"}
                  rounded={true}
                  isLoading={isLoading}
                  disabled={isLoading}
                />

                <div className="hv_accnt">
                  <div className="hv_accnt_text">
                    <p>Don't yet have an account?</p>
                  </div>
                  <Link to="/register" style={{ textDecoration: "none" }}>
                    <div className="hv_accnt_redirect">
                      <p>Register</p>
                    </div>
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
