import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../../../components/Layout/dashboard/DashboardLayout";
import Button from "../../../../../components/Button/customButton/Button";
import styles from "./applicationResquestDetail.module.css";
import MessageGrayIcon from "../../../../../assets/icons/message-gray.icon";
import PhoneIcon from "../../../../../assets/icons/phone.icon";
import HouseIcon from "../../../../../assets/icons/house.icon";
import { useNavigate, useParams } from "react-router-dom";
import PersonalInformationDetails from "../../../../auth/Signup/components/Confirmation/component/PersonalInformation/PersonalInformation";
import IdentityDetails from "../../../../auth/Signup/components/Confirmation/component/Identity/Identity";
import ResidenceInfoDetails from "../../../../auth/Signup/components/Confirmation/component/ResidenceInformation/ResidenceInfo";
import GuardianInfoDetails from "../../../../auth/Signup/components/Confirmation/component/GuardianInformation/GuardianInfo";
import SupportingDocumentsDetails from "../../../../auth/Signup/components/Confirmation/component/SupportingDocuments/SupportingDocuments";
import Carousel from "../../../../auth/Signup/components/Confirmation/component/Carousel";
import { checkTokenExpiry } from "../../../../../utils/functions/checkTokenExpiry";
import { getDataFromLocalStorage } from "../../../../../utils/functions/handleLocalStorage";
import { userSlice } from "../../../../../redux/features/userSlice";
import { useDispatch } from "react-redux";
import { useApplication } from "../../../../../query/admin/applicationRequest";
import { IonSpinner } from "@ionic/react";

export default function ApplicationRequestDetail() {
  /** State management */
  const [activeTab, setActiveTab] = useState(0);
  const [studentInfo, setStudentInfo] = useState({
    applicant_id: "",
    date_of_birth: "",
    email: "",
    first_name: "",
    last_name: "",
    gender: "",
    guardian1_name: "",
    guardian1_contact: "",
    guardian2_name: "",
    guardian2_contact: "",
    id: "",
    id_card_number: "",
    scanned_id_document: "",
    profile_picture: "",
    primary_location: "",
    secondary_location: "",
    status: "",
    nationality: "",
    past_achievement_documents: [],
    motivation_letter: "",
    phone: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { getSingleApplicantInfo, rejectApplicant, acceptApplicant } =
    useApplication();
  const { id } = useParams();

  const [isAccepting, setIsAccepting] = useState(false);
  const [isDeclining, setIsDeclining] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const tokenExpiry = checkTokenExpiry();
    if (tokenExpiry) {
      dispatch(userSlice.actions.setIsAuth(false));

      navigate("/login");
    } else {
      const userInfo = getDataFromLocalStorage("enchird-userInfo");
      const token = getDataFromLocalStorage("enchird-token");
      if (userInfo && token) {
        console.log("Application details", token);
        dispatch(userSlice.actions.setUserToken(token.token));
        dispatch(userSlice.actions.setIsAuth(true));
        dispatch(userSlice.actions.setUser(userInfo.user));
        getSingleApplicantInfo(id).then((response) => {
          console.log("Application details:", response);
          if (response) {
            setStudentInfo(response);
          }
          setIsLoading(false);
        });
      } else {
        navigate("/login");
      }
    }
  }, []);

  const details = [
    {
      letter: "A",
      title: "Personal Information",
      node: (
        <PersonalInformationDetails
          firstName={studentInfo.first_name}
          lastName={studentInfo.last_name}
          nationality={studentInfo.nationality}
          gender={studentInfo.gender}
          birthDate={studentInfo.date_of_birth}
          email={studentInfo.email}
          phone={studentInfo.phone}
        />
      ),
    },
    {
      letter: "B",
      title: "Identity (KYC)",
      node: (
        <IdentityDetails
          idCardNumber={studentInfo.id_card_number}
          idCardDocument={studentInfo.scanned_id_document}
          profilePicture={studentInfo.profile_picture}
          locat={"dashboard"}
        />
      ),
    },
    {
      letter: "C",
      title: "Residence Information",
      node: (
        <ResidenceInfoDetails
          primaryLocation={studentInfo.primary_location}
          secondaryLocation={studentInfo.secondary_location}
        />
      ),
    },
    {
      letter: "D",
      title: "Guardian Information",
      node: (
        <GuardianInfoDetails
          guardianOneName={studentInfo.guardian1_name}
          guardianOneContact={studentInfo.guardian1_contact}
          guardianTwoContact={studentInfo.guardian2_contact}
          guardianTwoName={studentInfo.guardian2_name}
        />
      ),
    },
    {
      letter: "E",
      title: "Supporting Information",
      node: (
        <SupportingDocumentsDetails
          coverLetter={studentInfo.motivation_letter}
          documents={[...studentInfo.past_achievement_documents]}
          locat={"dashboard"}
        />
      ),
    },
  ];

  /** Handle validation and rejection */
  function handleRejectApplication() {
    setIsDeclining(true);
    rejectApplicant(studentInfo.applicant_id).then((response) => {
      if (response) {
        navigate("/admin/application-requests");
      }
      setIsDeclining(false);
    });
  }

  function handleAcceptApplication() {
    setIsAccepting(true);
    acceptApplicant(studentInfo.applicant_id).then((response) => {
      if (response) {
        navigate("/admin/application-requests");
      }
      setIsAccepting(false);
    });
  }

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "70vh",
          }}
        >
          <IonSpinner
            name="lines"
            color={"dark"}
            style={{ width: 50, height: 50 }}
          />
        </div>
      ) : (
        <>
          <div className={`${styles.main_container}`}>
            {/* Table entry */}
            <div className={`${styles["table-container"]}`}>
              <ul className={`${styles["table-row-container"]}`}>
                <li
                  className={`${styles["table-row"]}`}
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    className={`${styles["check-box"]}`}
                    style={{ marginInlineEnd: 16 }}
                  ></div>
                  <p style={{ whiteSpace: "nowrap" }}>
                    {studentInfo.applicant_id}
                  </p>
                </li>

                <li className={` ${styles["table-row"]}`}>
                  <p>{studentInfo.first_name}</p>
                </li>

                <li className={` ${styles["table-row"]}`}>
                  <p>{studentInfo.last_name}</p>
                </li>
                <li className={` ${styles["table-row"]}`}>
                  {studentInfo.gender}
                </li>

                <li className={` ${styles["table-row"]}`}>
                  {studentInfo.date_of_birth}
                </li>
                <li className={` ${styles["table-row"]}`}>
                  <p>{studentInfo.nationality}</p>
                </li>
                <li className={` ${styles["table-row"]}`}>
                  <p>{studentInfo.email}</p>
                </li>
                <li className={` ${styles["table-row"]}`}>
                  {studentInfo.phone}
                </li>
              </ul>
            </div>
            <div>
              <div className={`${styles["summary_container"]}`}>
                <div className={`${styles["profile_picture"]}`}>
                  <img src={studentInfo.profile_picture ?? ""} alt="" />
                </div>
                <div>
                  <h3 className={`${styles["contact_info_heading"]}`}>
                    Contact Information
                  </h3>
                  <ul className={`${styles["contact_info_container"]}`}>
                    <li>
                      <p>
                        <span>
                          <MessageGrayIcon width={24} height={24} />
                        </span>
                        <span>{studentInfo.email}</span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span>
                          <PhoneIcon width={24} height={24} />
                        </span>
                        <span>{studentInfo.phone}</span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span>
                          <HouseIcon width={24} height={24} />
                        </span>
                        <span>{studentInfo.primary_location}</span>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={`${styles["btn_container"]}`}>
                <div>
                  <Button
                    text={"Approve Registration Request"}
                    backgroundColor={"#00B707"}
                    onClick={() => handleAcceptApplication()}
                    disabled={isAccepting || isDeclining}
                    isLoading={isAccepting}
                  />
                </div>
                <div>
                  <Button
                    text={"Reject Request"}
                    backgroundColor={"#CE2727"}
                    onClick={() => handleRejectApplication()}
                    disabled={isAccepting || isDeclining}
                    isLoading={isDeclining}
                  />
                </div>
              </div>
            </div>

            <div className={`${styles.detail_info}`}>
              {details.map((detail, index) => {
                return (
                  <Carousel
                    key={index}
                    title={detail.title}
                    letter={detail.letter}
                    children={detail.node}
                    index={index}
                    setActiveTab={setActiveTab}
                    activeTab={activeTab}
                    locat={"dashboard"}
                    // setGotToEditInfo={setGotToEditInfo}
                  />
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
