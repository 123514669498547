import React from "react";
import styles from "./button.module.css";
import { IonSpinner } from "@ionic/react";

export default function Button({
  type,
  isLoading,
  backgroundColor,
  disabled,
  rounded,
  leftIcon,
  rightIcon,
  color,
  borderColor,
  border,
  onClick,
  text,
  width,
  paddingBlock,
  paddingInline,
  fontWeight,
}) {
  return (
    <button
      onClick={() => {
        onClick && onClick();
      }}
      className={`${styles["btn-container"]}`}
      style={{
        borderWidth: border ?? 0,
        borderRadius: rounded ? 45 : 4,
        background: backgroundColor ?? "#2218a7",
        borderColor: borderColor ?? "#2218a7",
        color: color ?? "#fff",
        width: width ?? "100%",
        opacity: disabled ? 0.7 : 1,
        cursor: disabled ? "not-allowed" : "pointer",
        paddingBlock: paddingBlock ?? 24,
        paddingInline: paddingInline ?? 32,
        fontWeight: fontWeight ?? 18,
      }}
      disabled={disabled}
    >
      {leftIcon && <span className={`${styles.icons}`}>{leftIcon}</span>}
      <span className={`${styles["btn-text"]}`}>{text}</span>
      {rightIcon && (
        <span className={`${styles.icons}`} style={{ marginBlockEnd: -7 }}>
          {rightIcon}
        </span>
      )}
      {isLoading && <IonSpinner color={"light"} />}
    </button>
  );
}
