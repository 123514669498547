import React from "react";
import styles from "./blogCard.module.css";
import blog_image from "../../../assets/images/blog_image0.png";

export default function BlogCard() {
  return (
    <div className={`${styles.blog_card_container}`}>
      <div className={`${styles.blog_card_image_container}`}>
        <img src={blog_image} alt="" />
      </div>

      <div className={`${styles.blog_card_details_container}`}>
        <h3>Enim tristique pretium pharetra blandit.</h3>
        <h5>Jenny Wilson</h5>
        <p>
          Proin vitae quis scelerisque quam at laoreet eget accumsan ac. Posuere
          nisl odio ac est metus dui facilisis leo dignissim. Commodo ac commodo
          mattis quis adipiscing... <span>Read More</span>
        </p>
      </div>
    </div>
  );
}
