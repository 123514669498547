import { useDispatch, useSelector } from "react-redux";
import client from "../../utils/api/axios";
import { useIonToast } from "@ionic/react";
import { institutionSlice } from "../../redux/features/institutionSlice";

export function useStudent() {
  /** State management */
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);
  const [toast] = useIonToast();

  //   async function createApplicant(student) {
  //     client
  //       .post("/api/student/", student, {
  //         headers: {
  //           Authorization: `Token ${token}`,
  //         },
  //       })
  //       .then((response) => {
  //         if (response.status === 200) {
  //           toast({
  //             message: "Application Submitted successfully",
  //             color: "success",
  //             buttons: ["Ok"],
  //           });
  //         }
  //       })
  //       .catch((error) => {
  //         toast({
  //           message: `Error submitting application: ${error.message}`,
  //           color: "danger",
  //           buttons: ["Ok"],
  //         });
  //       });
  //   }

  async function getStudents() {
    client
      .get("/api/student/", {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        const result = response.data;
        if (result) {
          dispatch(institutionSlice.actions.setStudent(result));
        }
        return response.data;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          duration: 4000,
          buttons: ["Ok"],
        });
      });
  }

  /**
   * updateFstudentInfo Updates department information using department id
   * @param student student object to be updated
   */
  async function updateStudentInfo(student) {
    client
      .put(`/api/student/${student.id}/`, student, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        toast({
          message: `Information Updated Successfully`,
          duration: 4000,
          color: "success",
          buttons: ["Ok"],
        });
        return true;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          duration: 4000,
          color: "danger",
          buttons: ["Ok"],
        });
        return null;
      });
  }

  async function getSingleStudentInfo(studentId) {
    return await client
      .get(`/api/student/${studentId}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          duration: 4000,
          color: "danger",
          buttons: ["Ok"],
        });
        return null;
      });
  }

  async function getFilteredStudents(gender, faculty_name, department_name) {
    return await client
      .get("/api/student/", {
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          gender,
          faculty_name,
          department_name,
        },
      })
      .then((response) => {
        const result = response.data;

        return result;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          duration: 4000,
          buttons: ["Ok"],
        });
        return null;
      });
  }

  async function searchStudent(keyword) {
    return await client
      .get("/api/student/", {
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          keyword,
        },
      })
      .then((response) => {
        const result = response.data;
        return result;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          buttons: ["Ok"],
          duration: 2000,
        });
        return null;
      });
  }

  return {
    getStudents,
    getSingleStudentInfo,
    updateStudentInfo,
    getFilteredStudents,
    searchStudent,
  };
}
