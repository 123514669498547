import React from "react";
import PdfIcon from "../../assets/icons/pdf.icon";
import styles from "./pdfCard.module.css";

export default function PDFCard({ name, handleClick, editable }) {
  return (
    <div className={`${styles.container}`}>
      {!editable && (
        <p className={`${styles.close}`} onClick={() => handleClick()}>
          &times;
        </p>
      )}
      <PdfIcon width={60} height={80} />
      <p>{name}</p>
    </div>
  );
}
