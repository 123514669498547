import React from "react";

export default function PersonWhiteIcon({ ...props }) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="212"
        height="212"
        viewBox="0 0 212 212"
        fill="none"
        {...props}
      >
        <g clipPath="url(#clip0_1516_988)">
          <path
            d="M70.6667 61.8333C70.6667 71.2043 74.3893 80.1915 81.0155 86.8178C87.6418 93.4441 96.629 97.1667 106 97.1667C115.371 97.1667 124.358 93.4441 130.984 86.8178C137.611 80.1915 141.333 71.2043 141.333 61.8333C141.333 52.4623 137.611 43.4752 130.984 36.8489C124.358 30.2226 115.371 26.5 106 26.5C96.629 26.5 87.6418 30.2226 81.0155 36.8489C74.3893 43.4752 70.6667 52.4623 70.6667 61.8333Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M53 185.5V167.833C53 158.462 56.7226 149.475 63.3489 142.849C69.9752 136.223 78.9623 132.5 88.3333 132.5H123.667C133.038 132.5 142.025 136.223 148.651 142.849C155.277 149.475 159 158.462 159 167.833V185.5"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1516_988">
            <rect width="212" height="212" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
