import React from "react";
import NavBar from "../../components/NavBar/NavBar";
import Footer from "../../components/Footer/Footer";
import styles from "./blogDetail.module.css";
import BlogCard from "../blogs/BlogCard/BlogCard";
import TextInput from "../../components/Inputs/TextInput/TextInput";
import TextArea from "../../components/Inputs/TextArea/TextArea";
import Button from "../../components/Button/customButton/Button";
import StaticLayout from "../../components/Layout/StaticLayout/StaticLayout";

export default function BlogDetail() {
  return (
    <StaticLayout>
      <main className={`${styles.blog_detail_container}`}>
        <div className={styles.container}>
          <div className={`${styles.blog_detail_header_container}`}>
            <h1 className={`${styles.blog_heading}`}>
              Enim tristique pretium pharetra blandit.
            </h1>
            <p className={`${styles.blog_author}`}>By Jenny Wilson</p>
          </div>

          <div className={`${styles.blog_cover_image}`}>
            <img src="/blog_image0.png" alt="" />
          </div>

          <div className={`${styles.blog_detail_main_container}`}>
            <div>
              <div className={`${styles.blog_detail_main_body}`}>
                <p>
                  Lorem ipsum dolor sit amet consectetur. Nulla eget mattis velit
                  aliquam vitae sapien donec. Viverra neque duis sit sed et.
                  Iaculis amet vitae eu purus et id. Lorem venenatis suscipit nunc
                  mi congue. Mauris pharetra ultrices adipiscing nunc proin. Duis
                  posuere dignissim adipiscing fames nibh venenatis enim erat
                  volutpat. Volutpat in turpis amet etiam. In nulla amet leo felis
                  enim faucibus tellus scelerisque. Aliquet nulla orci quam et
                  odio elit in fermentum in.
                </p>

                <div>
                  <h2>HEADING ONE</h2>
                  <p>
                    Sem amet amet vel proin faucibus pharetra. Amet in consectetur
                    at massa id. Convallis nisl sed fusce suscipit viverra. Sed
                    proin felis amet lectus. Pellentesque ullamcorper massa risus
                    tincidunt eu at luctus. Enim pretium amet sed vulputate. Nunc
                    eget vulputate duis lobortis cum in lacus nibh. Elementum in
                    placerat amet porttitor tincidunt massa a hac gravida. Sed
                    cras sagittis eu etiam facilisi. Auctor pharetra sollicitudin
                    rhoncus semper massa pretium enim. At et sem feugiat interdum
                    vitae. Felis eget tellus nec ornare sagittis eget magna
                    laoreet. Id nunc nec ut commodo aliquam ornare. Pretium amet
                    sagittis sapien integer volutpat lectus sit. Orci turpis non
                    nunc cursus at rhoncus in enim. Mattis facilisis malesuada
                    nulla nulla et quis odio quisque a. Tempus nisi ac massa et
                    vel metus lectus nibh nibh. Tortor massa a eget massa
                    vulputate pulvinar. Nulla sem elementum purus enim lobortis
                    viverra odio aliquam. Et at massa consequat nisl. Scelerisque
                    porttitor habitasse sit diam arcu. Lectus amet turpis odio
                    amet vitae pretium. Quis felis pellentesque ut placerat. Nulla
                    nibh at dignissim mauris neque nulla facilisis ipsum. Nec
                    mattis sapien amet commodo consequat ut vulputate.
                  </p>
                </div>

                <div className={`${styles.blog_detail_image}`}>
                  <img src="" alt="" />
                </div>

                <div>
                  <h2>HEADING ONE</h2>
                  <p>
                    Sem amet amet vel proin faucibus pharetra. Amet in consectetur
                    at massa id. Convallis nisl sed fusce suscipit viverra. Sed
                    proin felis amet lectus. Pellentesque ullamcorper massa risus
                    tincidunt eu at luctus. Enim pretium amet sed vulputate. Nunc
                    eget vulputate duis lobortis cum in lacus nibh. Elementum in
                    placerat amet porttitor tincidunt massa a hac gravida. Sed
                    cras sagittis eu etiam facilisi. Auctor pharetra sollicitudin
                    rhoncus semper massa pretium enim. At et sem feugiat interdum
                    vitae. Felis eget tellus nec ornare sagittis eget magna
                    laoreet. Id nunc nec ut commodo aliquam ornare. Pretium amet
                    sagittis sapien integer volutpat lectus sit. Orci turpis non
                    nunc cursus at rhoncus in enim. Mattis facilisis malesuada
                    nulla nulla et quis odio quisque a. Tempus nisi ac massa et
                    vel metus lectus nibh nibh. Tortor massa a eget massa
                    vulputate pulvinar. Nulla sem elementum purus enim lobortis
                    viverra odio aliquam. Et at massa consequat nisl. Scelerisque
                    porttitor habitasse sit diam arcu. Lectus amet turpis odio
                    amet vitae pretium. Quis felis pellentesque ut placerat. Nulla
                    nibh at dignissim mauris neque nulla facilisis ipsum. Nec
                    mattis sapien amet commodo consequat ut vulputate.
                  </p>
                </div>

                <div>
                  <h2>HEADING ONE</h2>
                  <p>
                    Sem amet amet vel proin faucibus pharetra. Amet in consectetur
                    at massa id. Convallis nisl sed fusce suscipit viverra. Sed
                    proin felis amet lectus. Pellentesque ullamcorper massa risus
                    tincidunt eu at luctus. Enim pretium amet sed vulputate. Nunc
                    eget vulputate duis lobortis cum in lacus nibh. Elementum in
                    placerat amet porttitor tincidunt massa a hac gravida. Sed
                    cras sagittis eu etiam facilisi. Auctor pharetra sollicitudin
                    rhoncus semper massa pretium enim. At et sem feugiat interdum
                    vitae. Felis eget tellus nec ornare sagittis eget magna
                    laoreet. Id nunc nec ut commodo aliquam ornare. Pretium amet
                    sagittis sapien integer volutpat lectus sit. Orci turpis non
                    nunc cursus at rhoncus in enim. Mattis facilisis malesuada
                    nulla nulla et quis odio quisque a. Tempus nisi ac massa et
                    vel metus lectus nibh nibh. Tortor massa a eget massa
                    vulputate pulvinar. Nulla sem elementum purus enim lobortis
                    viverra odio aliquam. Et at massa consequat nisl. Scelerisque
                    porttitor habitasse sit diam arcu. Lectus amet turpis odio
                    amet vitae pretium. Quis felis pellentesque ut placerat. Nulla
                    nibh at dignissim mauris neque nulla facilisis ipsum. Nec
                    mattis sapien amet commodo consequat ut vulputate.
                  </p>
                </div>
              </div>

              <div className={`${styles.comment_section}`}>
                <h2>Leave a Comment</h2>
                <TextInput placeholder={"Full Name"} />
                <TextInput placeholder={"Email Address"} />
                <TextInput placeholder={"Phone Number"} />
                <TextInput placeholder={"Write you comment here?"} />
                <Button text={"Post My Comment"} backgroundColor={"#1F201F"} />
              </div>
            </div>

            <aside className={`${styles.blog_aside_container}`}>
              <div className={`${styles.blog_detail_sidebar_container}`}>
                <h4>Join Our Community</h4>
                <div className={`${styles.subscribe_email_input_container}`}>
                  <input type="text" placeholder="Your Email" />
                  <button>Subscribe</button>
                </div>
              </div>

              <div className={`${styles.blog_detail_sidebar_container}`}>
                <h4>Follow Us</h4>
                <div></div>
              </div>

              <div className={`${styles.blog_detail_sidebar_container}`}>
                <h4>Related Posts</h4>
                <div>
                  {Array(3)
                    .fill(0)
                    .map((_v, index) => {
                      return <BlogCard key={index} />;
                    })}
                </div>
              </div>
            </aside>
          </div>
        </div>
      </main>
    </StaticLayout>
  );
}
