import { useIonToast } from "@ionic/react";
import client from "../../utils/api/axios";
import { useDispatch, useSelector } from "react-redux";
import { institutionSlice } from "../../redux/features/institutionSlice";

export function useLibrary() {
  /** State management */
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);
  const [toast] = useIonToast();

  async function addBook(book) {
    return await client
      .post("/api/library-book/", book, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        getBooks();
        return true;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          buttons: ["Ok"],
          duration: 5000,
        });
        return null;
      });
  }

  async function getBooks() {
    return await client
      .get("/api/library/book/", {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        const result = response.data;
        return result;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          buttons: ["Ok"],
          duration: 5000,
        });
        return null;
      });
  }

  async function getBookById(id) {
    return await client
      .get(`/api/library/book/${id}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          buttons: ["Ok"],
          duration: 5000,
        });
        return null;
      });
  }

  async function updateBook(id, book) {
    return await client.put(`/api/library/book/${id}`, book, {
      headers: { Authorization: `Token ${token}` },
    });
  }

  async function deleteBook(id) {
    return await client
      .delete(`/api/library/books/${id}/`)
      .then(() => {
        toast({
          message: `Book Delete Successfully!`,
          color: "success",
          buttons: ["Ok"],
          duration: 3000,
        });
        getBooks();
        return true;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          buttons: ["Ok"],
          duration: 5000,
        });
        return null;
      });
  }

  async function searchLibrary(keyword) {
    return await client
      .get("/api/library/book/", {
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          keyword,
        },
      })
      .then((response) => {
        const result = response.data;
        return result;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          buttons: ["Ok"],
          duration: 4000,
        });
        return null;
      });
  }

  return {
    addBook,
    getBooks,
    getBookById,
    updateBook,
    deleteBook,
  };
}
