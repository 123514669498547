import React from "react";

export default function ChevronIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="-5 -7.5 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="m7.071 2.828l-4.95 4.95A1 1 0 0 1 .707 6.364L6.364.707a1 1 0 0 1 1.414 0l5.657 5.657a1 1 0 0 1-1.414 1.414z"
      />
    </svg>
  );
}
