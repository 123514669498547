import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  assessments: [],
  questions: [],
  textQuestions: []
};

export const assessmentSlice = createSlice({
  name: "assessment",
  initialState,
  reducers: {
    setAssessments: (state, action) => {
      state.assessments = action.payload;
    },
    newAssessment: (state, action) => {
      state.assessments = { ...state.assessments, ...action.payload.data };
    },
    setQuestions: (state, action) => {
      state.questions = action.payload;
    },
    newQuestion: (state, action) => {
      state.questions = { ...state.questions, ...action.payload.data };
    },
    setTextQuestions: (state, action) => {
      state.textQuestions = action.payload;
    },
    newTextQuestion: (state, action) => {
      state.textQuestions = { ...state.textQuestions, ...action.payload.data };
    },
  },
});

export const { setAssessments, newAssessment, setQuestions, newQuestion,
  setTextQuestions, newTextQuestion } = assessmentSlice.actions;
export default assessmentSlice.reducer;
