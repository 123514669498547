import React, { useState } from "react";
import styles from "./supportingInformation.module.css";
import TextArea from "../../../../../components/Inputs/TextArea/TextArea";
import TextInput from "../../../../../components/Inputs/TextInput/TextInput";
import UploadFileComponent from "../../../../../components/Inputs/UploadFileComponent/UploadFileComponent";
import PDFCard from "../../../../../components/pdfCard/PDFCard";
// import PdfCard from "../../../../../components/pdfCard/PdfCard";
// import PdfCard from "../../../../../components/pdfCard/PdfCard";

export default function SupportingInformation({
  coverLetter,
  setCoverLetter,
  documents,
  setDocuments,
}) {
  return (
    <div className={`${styles["supporting-container"]}`}>
      <TextArea
        label={"Cover / Application Letter"}
        placeholder={"Please write here..."}
        value={coverLetter}
        setValue={setCoverLetter}
      />

      <div className={`${styles["upload-certificate-container"]}`}>
        <h2>
          Upload Past Achievements / Certificates / Transcript (Scanned PDF
          Formats)*
        </h2>

        <div style={{ marginBlockStart: 24 }}>
          <UploadFileComponent
            multiple={true}
            file={[...documents]}
            setFile={setDocuments}
            identifier={"supportive document"}
          />
        </div>
      </div>
    </div>
  );
}
