import React, { useEffect, useState } from "react";
import TextInput from "../../Inputs/TextInput/TextInput";
import Button from "../../Button/customButton/Button";
import styles from "./createDepartment.module.css";

import RichTextInput from "../../Inputs/RichTextInput/RichTextInput";
import Dropdown from "../../Inputs/Dropdown/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { institutionSlice } from "../../../redux/features/institutionSlice";
import { useDepartment } from "../../../query/admin/useDepartment";
import { IonSpinner } from "@ionic/react";

export default function CreateDepartmentModal({
  setViewDepartmentModal,
  departmentId,
}) {
  /** State management */
  const [faculty, setFaculty] = useState("");
  const [departmentName, setDepartmentName] = useState("");
  const [departmentAbbr, setDepartmentAbbr] = useState("");
  const [departmentDesc, setDepartmentDesc] = useState("");
  const [levels, setLevels] = useState([]);
  const [aboutdepartment, setAboutdepartment] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isGettingInfo, setIsGettingInfo] = useState(false);

  const facultyData = useSelector((state) => state.institution.facultyData);
  const token = useSelector((state) => state.user.token);
  const dispatch = useDispatch();
  const {
    createDepartment,
    getSingleDepartmentInfo,
    updateDepartmentInfo,
    deleteDepartment,
    getDepartments,
  } = useDepartment();

  function handleCreatedepartment() {
    setIsLoading(true);
    const facultyTemp = facultyData.results.filter((faculty) => {
      return faculty.name === faculty;
    })[0]?.id;
    const department = {
      faculty,
      name: departmentName,
      abbrev: departmentAbbr,
      description: departmentDesc,
      // levels,
      about: aboutdepartment,
    };
    createDepartment(department).then((response) => {
      if (response) {
        setViewDepartmentModal(false);
        getDepartments();
      }
      setIsLoading(false);
    });
    console.log("Create department");
  }

  useEffect(() => {
    if (departmentId !== "") {
      setIsGettingInfo(true);

      getSingleDepartmentInfo(departmentId).then((response) => {
        if (response) {
          setDepartmentName(response.name);
          setAboutdepartment(response.about);
          setDepartmentAbbr(response.abbrev);
          setDepartmentDesc(response.description);
          setFaculty(response.faculty_details.id);
        }
        setIsGettingInfo(false);
      });
    }
  }, [departmentId]);

  function handleUpdateDepartmentInfo() {
    setIsUpdating(true);
    const facultyTemp = facultyData.results.filter((faculty) => {
      return faculty.name === faculty;
    })[0]?.id;
    const department = {
      faculty,
      name: departmentName,
      abbrev: departmentAbbr,
      description: departmentDesc,
      about: aboutdepartment,
    };
    updateDepartmentInfo(department, departmentId).then((response) => {
      if (response) {
        setViewDepartmentModal(false);
        getDepartments();
      }
      setIsUpdating(false);
    });
  }

  function handleDeleteDeparment() {
    setIsDeleting(true);
    deleteDepartment(departmentId, departmentName).then((response) => {
      if (response) {
        setViewDepartmentModal(false);
        getDepartments();
      }
      setIsDeleting(false);
    });
  }

  return (
    <div className={`${styles.container}`}>
      {departmentId !== "" && isGettingInfo ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "50vh",
            height: "50vh",
            background: "#fff",
          }}
        >
          <IonSpinner
            name="lines"
            color={"dark"}
            style={{ width: 50, height: 50 }}
          />
        </div>
      ) : (
        <div className={`${styles.sub_container}`}>
          <div
            style={{ position: "absolute", right: 20, top: 0 }}
            onClick={() => setViewDepartmentModal(false)}
          >
            <p style={{ fontSize: 50, cursor: "pointer", color: "red" }}>
              &times;
            </p>
          </div>
          <h2 className={`${styles.title}`}>
            {departmentId !== ""
              ? "Update Department Information"
              : "Add New Department"}
          </h2>
          <div className={`${styles.form_container}`}>
            <div>
              <Dropdown
                label={"Select Faculty"}
                placeholder={"Engineering and Technology"}
                value={faculty}
                setValue={setFaculty}
                data={facultyData.results}
                keyItem={"id"}
              />
            </div>
            <div>
              <TextInput
                label={"Department Name"}
                placeholder={"Computer Engineering"}
                value={departmentName}
                setValue={setDepartmentName}
              />
            </div>
            <div>
              <TextInput
                label={"Abbreviation"}
                placeholder={"FET"}
                value={departmentAbbr}
                setValue={setDepartmentAbbr}
              />
            </div>
            <div>
              <TextInput
                label={"department Short Description"}
                placeholder={
                  "This is a short text that shows as a smal description of the given department..."
                }
                value={departmentDesc}
                setValue={setDepartmentDesc}
              />
            </div>
            {/* <div>
            <TextInput
              label={"Add Levels"}
              placeholder={"400"}
              value={levels}
              setValue={setLevels}
            />
          </div> */}

            <div>
              <RichTextInput
                label={"About"}
                value={aboutdepartment}
                setValue={setAboutdepartment}
              />
            </div>
          </div>

          <div
            style={{
              marginTop: 100,
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {departmentId !== "" ? (
              <div style={{ display: "flex", gap: 10 }}>
                <Button
                  text={"Delete"}
                  onClick={() => handleDeleteDeparment()}
                  isLoading={isLoading || isDeleting}
                  disabled={isLoading || isUpdating || isDeleting}
                  width={"fit-content"}
                  backgroundColor={"red"}
                />

                <Button
                  text={"Update Department Details"}
                  onClick={() => handleUpdateDepartmentInfo()}
                  isLoading={isUpdating || isLoading}
                  disabled={
                    departmentName === "" ||
                    departmentAbbr === "" ||
                    departmentDesc === "" ||
                    levels.length > 0 ||
                    aboutdepartment === "" ||
                    isUpdating ||
                    isDeleting
                  }
                  width={"fit-content"}
                />
              </div>
            ) : (
              <Button
                text={"Save"}
                onClick={() => handleCreatedepartment()}
                isLoading={isLoading}
                disabled={
                  isLoading ||
                  departmentName === "" ||
                  departmentAbbr === "" ||
                  departmentDesc === "" ||
                  levels.length > 0 ||
                  aboutdepartment === ""
                }
                width={"fit-content"}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
