import { useDispatch, useSelector } from "react-redux";
import client from "../../utils/api/axios";
import { useIonToast } from "@ionic/react";
import { institutionSlice } from "../../redux/features/institutionSlice";

export function useApplication() {
  /** State management */
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);
  const [toast] = useIonToast();

  async function createApplicant(applicant) {
    client
      .post("/api/applicant/", applicant, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          toast({
            message: "Application Submitted successfully",
            color: "success",
            buttons: ["Ok"],
          });
        }
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          duration: 4000,
          buttons: ["Ok"],
        });
      });
  }

  async function getApplicants() {
    client
      .get("/api/applicant/", {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        const result = response.data;
        if (result) {
          dispatch(institutionSlice.actions.setApplicationRequests(result));
        }
        return response.data;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          duration: 4000,
          buttons: ["Ok"],
        });
      });
  }

  /**
   * updateFapplicantInfo Updates department information using department id
   * @param department department object to be updated
   */
  async function updateApplicantInfo(application) {
    client.put(`/api/applicant/${application.id}/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
      data: application,
    });
  }

  async function getSingleApplicantInfo(applicantId) {
    return await client
      .get(`/api/applicant/${applicantId}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          duration: 3000,
          color: "danger",
          buttons: ["Ok"],
        });
        return null;
      });
  }

  async function getFilterApplicants(
    gender,
    status,
    departmentName,
    facultyName,
    orderByCreatedAt
  ) {
    return await client
      .get("/api/applicant/", {
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          gender,
          status,
          department_name: departmentName,
          faculty_name: facultyName,
          order_by_created_at: orderByCreatedAt,
        },
      })
      .then((response) => {
        const result = response.data;
        return result;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          duration: 4000,
          buttons: ["Ok"],
        });
      });
  }

  async function rejectApplicant(applicantId) {
    return client
      .post(
        "/api/admin/reject-application/",
        { applicant_id: applicantId },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        getApplicants();
        toast({
          message: "Application Rejected Successfully",
          color: "success",
          duration: 3000,
          buttons: ["Ok"],
        });
        return true;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          duration: 3000,
          buttons: ["Ok"],
        });
        return null;
      });
  }

  async function acceptApplicant(applicantId) {
    return client
      .post(
        "/api/admin/accept-application/",
        { applicant_id: applicantId },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        getApplicants();
        toast({
          message: "Application Accepted Successfully",
          color: "success",
          duration: 3000,
          buttons: ["Ok"],
        });
        return true;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          duration: 3000,
          buttons: ["Ok"],
        });
        return null;
      });
  }

  return {
    createApplicant,
    getApplicants,
    getSingleApplicantInfo,
    updateApplicantInfo,
    getFilterApplicants,
    rejectApplicant,
    acceptApplicant,
  };
}
