export const COURSESTATUS = [
  {
    id: "compulsory",
    name: "Compulsory",
  },
  {
    id: "requirement",
    name: "Requirement",
  },
  {
    id: "optional",
    name: "Optional",
  },
];
