import React from "react";
import StudentLayout from "../../components/Layout/StudentLayout";
import Button from "../../../../../components/Button/customButton/Button";
import styles from "../generalPaymentStyles.module.css";
import success_image from "../../../../../assets/images/All-Good.png";
import { useNavigate } from "react-router-dom";

export default function SuccessTransaction() {
  /** State management */
  const navigate = useNavigate();

  return (
    <StudentLayout>
      <div className={`${styles.payment_container}`}>
        <div className={`${styles.image_container}`}>
          <img src={success_image} alt="successful transaction image" />
        </div>

        <div className={`${styles.payment_desc_container}`}>
          <p className={`${styles.payment_desc_header}`}>
            CONGRATULATIONS, YOUR TUITION FEE WAS PAID SUCCESSFULLY.
          </p>
          <p className={`${styles.payment_desc}`}>
            Transaction successfully completed! You can now go forward to access
            all the features of your dashboard.
          </p>

          <div className={`${styles.payment_btn_container}`}>
            <Button
              text={"Go Home"}
              width={"fit-content"}
              border={1}
              paddingBlock={16}
              onClick={() => navigate("/dashboard/main")}
            />
          </div>
        </div>
      </div>
    </StudentLayout>
  );
}
