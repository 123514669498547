import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  registeredCourses: [],
};

export const studentSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setRegisterdCourse: (state, action) => {
      state.registeredCourses = action.payload;
    },
  },
});

export const { setRegisterdCourse } = studentSlice.actions;
export default studentSlice.reducer;
