import React, { useEffect, useState } from "react";
import TextInput from "../../../../../../components/Inputs/TextInput/TextInput";
import Button from "../../../../../../components/Button/customButton/Button";
import styles from "./CreateOpportunity.module.css"
import Dropdown from "../../../../../../components/Inputs/Dropdown/Dropdown";
import { useOpportunities } from "../../../../../../query/tutor/useOpportunities";

const positionTypes = [
    "remote",
    "on_site",
    "bybrid"
]

const employmentTypes = [
    "contract",
    "part_time",
    "full_time"
]

export default function CreateOpportunityModal({ setViewCourseModal }) {
    const { handleCreateJob } = useOpportunities()
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [position_type, setPositionType] = useState("");
    const [employment_type, setEmploymentType] = useState("");
    const [company, setCompany] = useState("");
    const [city, setCity] = useState("")
    const [salary, setSalary] = useState("")
    const [experience, setExperience] = useState("")
    const [jobLink, setJobLink] = useState("")

    const [isLoading, setIsLoading] = useState(false);

    async function handleCreateNewJob() {
        setIsLoading(true);
        const data = {
            title,
            description,
            position_type,
            employment_type,
            company,
            city,
            experience,
            salary,
            link: jobLink
        };
        await handleCreateJob(data).then(() => {
            setViewCourseModal(false);
            setIsLoading(false);
        })

    }

    return (
        <div className={`${styles.container}`}>
            <div className={`${styles.sub_container}`}>
                <div
                    style={{ position: "absolute", right: 20, top: 0 }}
                    onClick={() => !isLoading && setViewCourseModal(false)}
                >
                    <p style={{ fontSize: 50, cursor: "pointer", color: "red" }}>
                        &times;
                    </p>
                </div>
                <h2 className={`${styles.title}`}>Add New Opportunity</h2>
                <div className={`${styles.form_container}`}>

                    <div>
                        <TextInput
                            label={"Job Title"}
                            placeholder={"e.g Sr. UI/UX Designer"}
                            value={title}
                            setValue={setTitle}
                            disabled={isLoading}
                        />
                    </div>

                    <div>
                        <TextInput
                            label={"Job Description"}
                            placeholder={"e.g Designing web and mobile views for xyz company"}
                            value={description}
                            setValue={setDescription}
                            disabled={isLoading}
                        />
                    </div>
                    <div>
                        <TextInput
                            label={"Location"}
                            placeholder={"e.g New york"}
                            value={city}
                            setValue={setCity}
                            disabled={isLoading}
                        />
                    </div>

                    <div>
                        <TextInput
                            label={"Company"}
                            placeholder={"e.g Google"}
                            value={company}
                            setValue={setCompany}
                            disabled={isLoading}
                        />
                    </div>
                    <div>
                        <TextInput
                            label={"Years of Experience"}
                            placeholder={"e.g 4"}
                            value={experience}
                            setValue={setExperience}
                            disabled={isLoading}
                        />
                    </div>

                    <div>
                        <TextInput
                            label={"Monthly Salary in thousands"}
                            placeholder={"e.g 50"}
                            value={salary}
                            setValue={setSalary}
                            disabled={isLoading}
                        />
                    </div>
                    <div>
                        <Dropdown
                            label={"Position Type"}
                            placeholder={"position type"}
                            value={position_type}
                            setValue={setPositionType}
                            data={positionTypes}
                            disabled={isLoading}
                            keyItem={"position"}
                        />
                    </div>

                    <div>
                        <Dropdown
                            label={"Employment Type"}
                            placeholder={"Full-time"}
                            value={employment_type}
                            setValue={setEmploymentType}
                            data={employmentTypes}
                            disabled={isLoading}
                            keyItem={"employment"}
                        />
                    </div>
                    <div>
                        <TextInput
                            label={"Job link"}
                            placeholder={"e.g https://jobboard.com"}
                            value={jobLink}
                            setValue={setJobLink}
                            disabled={isLoading}
                        />
                    </div>
                </div>

                {
                    <div
                        style={{
                            marginTop: 50,
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Button
                            text={"Save"}
                            onClick={() => handleCreateNewJob()}
                            isLoading={isLoading}
                            disabled={(title === "" &&
                                description === "" &&
                                position_type === "" &&
                                employment_type === "" &&
                                salary === "" &&
                                city === "" &&
                                experience === "" &&
                                company === "") ||
                                isLoading}
                            width={"fit-content"}
                        />
                    </div>
                }
            </div>
        </div>
    );
}
