import React, { useEffect, useState } from "react";
import Button from "../../../../../components/Button/customButton/Button";
import Search from "../../../../../components/Search/Search";
import ChevronIcon from "../../../../../assets/icons/chevron.icon";
import own_styles from "./tutors.module.css";
import styles from "../generalStyles.module.css";
import { useSelector } from "react-redux";
import DropdownS from "../../../../../components/Inputs/DropdownS/DropDownS";
import { useDepartment } from "../../../../../query/admin/useDepartment";
import { useTutors } from "../../../../../query/admin/useTutors";
import Loader from "../../../../../components/Loader/Loader";
import CreateTutorModal from "../../../../../components/Modals/CreateTutorModal/CreateTutorModal";
import { IonSpinner } from "@ionic/react";

export default function Tutors() {
  /** state management */
  const [searchValue, setSearchValue] = useState("");
  const tutorData = useSelector((state) => state.institution.tutorRecords);
  const [tutorFilterData, setTutorFilterData] = useState(tutorData);
  const facultyData = useSelector((state) => state.institution.facultyData);
  const [departmentData, setDepartmentData] = useState([]);
  const [filterFaculty, setFilterFaculty] = useState("");
  const [filterDepartment, setFilterDepartment] = useState("");
  const [genderFilter, setGenderFilter] = useState("");
  const [orderByCreatedAtFilter, setOrderByCreatedAtFilter] = useState("");

  const [viewTutorModal, setViewTutorModal] = useState(false);
  const [viewEditTutorModal, setViewEditTutorModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [selectedTutorId, setSelectedTutorId] = useState("");

  /** Query functions */
  const { getFilteredTutorsRecord, searchTutor } = useTutors();
  // const { getLevelsForFaculty } = useFaculty();
  const { getFilterDepartments } = useDepartment();

  useEffect(() => {
    setIsLoading(true);
    getFilteredTutorsRecord(
      genderFilter,
      filterFaculty,
      filterDepartment,
      orderByCreatedAtFilter
    ).then((response) => {
      if (response) {
        setTutorFilterData(response);
      }
      setIsLoading(false);
    });
  }, [filterFaculty, filterDepartment]);

  useEffect(() => {
    if (filterFaculty !== "") {
      setIsLoading(true);
      getFilterDepartments(filterFaculty).then((response) => {
        if (response) {
          setDepartmentData(response.results);
        }
        setIsLoading(false);
      });
    } else {
      setDepartmentData([]);
    }
  }, [filterFaculty]);

  useEffect(() => {
    if (searchValue !== "") {
      setIsSearching(true);
      searchTutor(searchValue).then((response) => {
        if (response) {
          setTutorFilterData(response);
        }
        setIsSearching(false);
      });
    } else {
      setTutorFilterData(tutorData);
    }
  }, [searchValue]);

  return (
    <div>
      {isLoading && <Loader />}
      <div className={`${styles["header-container"]}`}>
        <h2>Manage Tutors</h2>

        <div className={`${styles["action-container"]}`}>
          <Button
            text={"Add New Tutor Record"}
            onClick={() => setViewTutorModal(true)}
            width={"fit-content"}
            paddingBlock={20}
          />
          <DropdownS
            value={filterFaculty}
            setValue={setFilterFaculty}
            secondary={true}
            placeholder={"Select Faculty"}
            data={facultyData.results}
            keyItem={"name"}
          />
          <DropdownS
            value={filterDepartment}
            setValue={setFilterDepartment}
            secondary={true}
            placeholder={"Select Department"}
            data={departmentData}
            keyItem={"name"}
          />

          <div style={{ maxWidth: 300 }}>
            <Search
              placeholder={"Find tutor record"}
              value={searchValue}
              setValue={setSearchValue}
            />
          </div>
        </div>
      </div>

      {/* Course Table */}
      <div className={`${styles["table-container"]}`}>
        <table>
          <thead>
            <tr className={`${styles["table-row"]}`}>
              <th className={`${styles["table-header"]}`}>
                <div className={`${styles["first-square"]}`}>
                  <div className={`${styles["check-box"]}`}></div>
                  <p>Matricule Number</p>
                </div>
              </th>
              <th className={`${styles["table-header"]}`}>First Name</th>
              <th className={`${styles["table-header"]}`}>Last Name</th>
              <th className={`${styles["table-header"]}`}>Gender</th>
              <th className={`${styles["table-header"]}`}>DOB</th>
              <th className={`${styles["table-header"]}`}>Nationality</th>
              <th className={`${styles["table-header"]}`}>Email</th>
              <th className={`${styles["table-header"]}`}>Contact</th>
            </tr>
          </thead>

          {isSearching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "70%",
                height: "20vh",
              }}
            >
              <IonSpinner
                name="lines"
                color={"dark"}
                style={{ width: 50, height: 50 }}
              />
            </div>
          ) : (
            <tbody>
              {tutorFilterData.results?.map((tutorData, index) => {
                return (
                  <tr
                    key={index}
                    onClick={() => {
                      setSelectedTutorId(tutorData.user.id);
                      setViewEditTutorModal(true);
                    }}
                    className={`${styles["table-row-container"]}`}
                  >
                    <td
                      className={`${styles["table-row"]}`}
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className={`${styles["check-box"]}`}
                        style={{ marginInlineEnd: 16 }}
                      ></div>
                      <p style={{ whiteSpace: "nowrap" }}>
                        {tutorData.teacher_id}
                      </p>
                    </td>
                    <td className={` ${styles["table-row"]}`}>
                      <p>{tutorData.user.first_name}</p>
                    </td>
                    <td className={` ${styles["table-row"]}`}>
                      <p>{tutorData.user.last_name}</p>
                    </td>
                    <td>{tutorData.user.gender}</td>
                    <td className={` ${styles["table-row"]}`}>
                      <p>{tutorData.user.date_of_birth}</p>
                    </td>
                    <td className={` ${styles["table-row"]}`}>
                      <p>{tutorData.user.nationality}</p>
                    </td>
                    <td>{tutorData.user.email}</td>
                    <td>{tutorData.user.phone}</td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
      <div className={`${styles["pagination-container"]}`}>
        <p>
          Page <span style={{ fontWeight: "bold" }}>1/1</span> Total:{" "}
          <span style={{ fontWeight: "bold" }}>
            {tutorFilterData.count ?? 0} Tutors
          </span>
        </p>
        <div className={`${styles["btn-container"]}`}>
          <div className={`${styles.icons} ${styles["left-icon"]}`}>
            <ChevronIcon />
          </div>
          <div className={`${styles.icons} ${styles["right-icon"]}`}>
            <ChevronIcon />
          </div>
        </div>
      </div>
      {viewTutorModal && (
        <CreateTutorModal setViewTutorModal={setViewTutorModal} tutorId={""} />
      )}

      {viewEditTutorModal && (
        <CreateTutorModal
          setViewTutorModal={setViewEditTutorModal}
          tutorId={selectedTutorId}
        />
      )}
    </div>
  );
}
