import React, { useEffect, useState } from "react";
import Button from "../../../../../components/Button/customButton/Button";
import Search from "../../../../../components/Search/Search";
import ChevronIcon from "../../../../../assets/icons/chevron.icon";
import own_styles from "./manageDepartments.module.css";
import styles from "../generalStyles.module.css";
import CreateDepartmentModal from "../../../../../components/Modals/CreateDepartmentModal/CreateDepartment";
import { useSelector } from "react-redux";
import DropDownS from "../../../../../components/Inputs/DropdownS/DropDownS";
import { useDepartment } from "../../../../../query/admin/useDepartment";
import Loader from "../../../../../components/Loader/Loader";
import { IonSpinner } from "@ionic/react";

export default function ManageDepartments() {
  /** State management */
  const departmentsData = useSelector(
    (state) => state.institution.departmentData
  );
  const facultyData = useSelector((state) => state.institution.facultyData);
  const [departmentsFilterData, setDepartmentsFilterData] =
    useState(departmentsData);

  const [createDepartment, setCreateDepartment] = useState(false);
  const [editingDepartment, setEditingDepartment] = useState(false);
  const [selectedDepartment, setSelectedDeparment] = useState("");

  const [filterFaculty, setFilterFaculty] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  /** Query functions */
  const { getFilterDepartments, searchDepartment } = useDepartment();

  useEffect(() => {
    setIsLoading(true);
    getFilterDepartments(filterFaculty).then((response) => {
      if (response) {
        setDepartmentsFilterData(response);
      }
      setIsLoading(false);
    });
  }, [filterFaculty]);

  useEffect(() => {
    if (searchValue !== "") {
      setIsSearching(true);
      searchDepartment(searchValue).then((response) => {
        if (response) {
          setDepartmentsFilterData(response);
        }
        setIsSearching(false);
      });
    } else {
      setDepartmentsFilterData(departmentsData);
    }
  }, [searchValue]);

  return (
    <div>
      {isLoading && <Loader />}
      <div className={`${styles["header-container"]}`}>
        <h2>Manage Departments</h2>

        <div className={`${styles["action-container"]}`}>
          <Button
            text={"Add New Department"}
            onClick={() => {
              setCreateDepartment(true);
            }}
            width={"fit-content"}
            paddingBlock={20}
          />
          <DropDownS
            value={filterFaculty}
            setValue={setFilterFaculty}
            placeholder={"Select Faculty"}
            keyItem={"name"}
            data={facultyData.results}
          />
          <div style={{ maxWidth: 300 }}>
            <Search
              value={searchValue}
              setValue={setSearchValue}
              placeholder={"Find Department"}
            />
          </div>
        </div>
      </div>
      {/* Faculty Table */}
      <div className={`${own_styles["table-container"]}`}>
        <table>
          <thead>
            <tr className={`${styles["table-row"]}`}>
              <th className={`${own_styles["table-header"]}`}>
                <div className={`${styles["first-square"]}`}>
                  <div className={`${styles["check-box"]}`}></div>
                  <p>Name</p>
                </div>
              </th>
              <th className={`${own_styles["table-header"]}`}>Faculty</th>
              <th className={`${own_styles["table-header"]}`}>Abbreviations</th>
              <th className={`${own_styles["table-header"]}`}>Description</th>
            </tr>
          </thead>

          {isSearching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "70%",
                height: "20vh",
              }}
            >
              <IonSpinner
                name="lines"
                color={"dark"}
                style={{ width: 50, height: 50 }}
              />
            </div>
          ) : (
            <tbody>
              {departmentsFilterData.results?.map((department, index) => {
                return (
                  <tr
                    key={index}
                    onClick={() => {
                      setSelectedDeparment(department.id);
                      setEditingDepartment(true);
                    }}
                    className={`${styles["table-row-container"]}`}
                  >
                    <td
                      className={`${styles["table-row"]}`}
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className={`${styles["check-box"]}`}
                        style={{ marginInlineEnd: 16 }}
                      ></div>
                      <p style={{ whiteSpace: "nowrap" }}>{department.name}</p>
                    </td>
                    <td className={` ${styles["table-row"]}`}>
                      <p>
                        {department.faculty_details.name.length > 20
                          ? department.faculty_details.abbrev
                          : department.faculty_details.name}
                      </p>
                    </td>
                    <td>{department.abbrev}</td>
                    <td className={` ${styles["table-row"]}`}>
                      <p>{department.description}</p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>{" "}
      <div className={`${styles["pagination-container"]}`}>
        <p>
          Page <span style={{ fontWeight: "bold" }}>1/1</span> Total:{" "}
          <span style={{ fontWeight: "bold" }}>
            {departmentsFilterData.count ?? 0} Departments
          </span>
        </p>
        <div className={`${styles["btn-container"]}`}>
          <div className={`${styles.icons} ${styles["left-icon"]}`}>
            <ChevronIcon />
          </div>
          <div className={`${styles.icons} ${styles["right-icon"]}`}>
            <ChevronIcon />
          </div>
        </div>
      </div>
      {createDepartment && (
        <CreateDepartmentModal
          setViewDepartmentModal={setCreateDepartment}
          departmentId={""}
        />
      )}
      {editingDepartment && (
        <CreateDepartmentModal
          setViewDepartmentModal={setEditingDepartment}
          departmentId={selectedDepartment}
        />
      )}
    </div>
  );
}
