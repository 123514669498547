import React, { useEffect, useRef, useState } from "react";
import Button from "../../../../../../components/Button/customButton/Button";
import styles from "./programDetailCard.module.css";

export default function ProgramDetailCard({ activeProgram, program }) {
  /** State management */
  const [viewDetails, setViewDetails] = useState(false);

  const programRef = useRef(null);
  const handleClickOutside = (event) => {
    if (programRef.current && !programRef.current.contains(event.target)) {
      setViewDetails(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [programRef]);
  return (
    <div
      className={`${styles.program_details_main_container}`}
      ref={programRef}
    >
      <div
        className={`${styles.program_title_container} ${
          viewDetails && styles.program_title_container_active
        }`}
        onClick={() => setViewDetails((prevState) => !prevState)}
      >
        <h3>{program.title}</h3>
      </div>

      {viewDetails && (
        <div className={`${styles.program_card_details_container}`}>
          <h4 className={`${styles.heading}`}>{program.title}</h4>
          <p className={`${styles.description}`}>{program.description}</p>

          <div className={`${styles.details_holder}`}>
            <div className={`${styles.program_card_details_sub_container}`}>
              <h5>Faculty</h5>
              <p>{program.faculty}</p>
            </div>
            <div className={`${styles.program_card_details_sub_container}`}>
              <h5>Tuition Fee</h5>
              <p>$5,000</p>
            </div>
            <div className={`${styles.program_card_details_sub_container}`}>
              <h5>Duration</h5>
              <p>{program.duration}</p>
            </div>
            <div className={`${styles.program_card_details_sub_container}`}>
              <h5>Semesters</h5>
              <p>{program.semesters}</p>
            </div>
            <div className={`${styles.program_card_details_sub_container}`}>
              <h5>Internship Hours</h5>
              <p>{program.internshipHours}</p>
            </div>
            <div className={`${styles.program_card_details_sub_container}`}>
              <h5>Program Credit</h5>
              <p>{program.programCredit}</p>
            </div>
          </div>

          <div className={`${styles.program_card_details_btn_container}`}>
            <Button text={"APPLY FOR THIS PROGRAM"} paddingBlock={16} />
            <Button
              text={"CANCEL"}
              color={"#2218A7"}
              backgroundColor={"transparent"}
              borderColor={"#2218A7"}
              border={1}
              paddingBlock={16}
              onClick={() => setViewDetails(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
}
