import React from "react";
import FeeAnnoucementBanner from "../FeeAnnouncementBanner/FeeAnnoucementBanner";
import styles from "./studentLayout.module.css";

export default function StudentLayout({ children, showProgram, showFee }) {
  return (
    <div className={`${styles["studentLayout-container"]}`}>
      {showFee && <FeeAnnoucementBanner />}

      {showProgram && (
        <div className={`${styles["studentLayout-program-Info"]}`}>
          <p>
            <strong>Faculty:</strong> Faculty of Engineering and Technology
          </p>
          <p>
            <strong>Department:</strong> Computer Engineering
          </p>
        </div>
      )}

      <div className={`${styles["studentLayout-container-sub-container"]}`}>
        {children}
      </div>
    </div>
  );
}
