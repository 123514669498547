import { useDispatch, useSelector } from "react-redux";
import client from "../../utils/api/axios";
import { storeDataToLocalStorage } from "../../utils/functions/handleLocalStorage";
import { userSlice } from "../../redux/features/userSlice";
import { useIonToast } from "@ionic/react";

export function useAuth() {
  /** State management */
  const dispatch = useDispatch();
  const [toast] = useIonToast();
  const token = useSelector((state) => state.user.token);

  async function handleCreateStudent(studentObject) {
    return await client
      .post("/api/applicant", studentObject)
      .then(() => {
        toast({
          message: "Application Submitted successfully",
          color: "success",
          buttons: ["Ok"],
        });
        return true;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          buttons: ["Ok"],
          duration: 5000,
        });
        return null;
      });
  }

  const handleLogin = async (email, password) => {
    return await client
      .post(
        "/api/auth/login/",
        { email, password },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const data = response.data;
        // console.log("Login credential:", data);
        if (data) {
          dispatch(userSlice.actions.setUserToken(data.Token));
          dispatch(userSlice.actions.setUser(data.user));
          dispatch(userSlice.actions.setIsAuth(true));
          storeDataToLocalStorage("enchird-token", {
            token: data.Token,
            timestamps: new Date().getTime(),
          });
          storeDataToLocalStorage("enchird-userInfo", data.user);
          toast({
            message: "Login successfully",
            color: "success",
            buttons: ["Ok"],
          });
        }
        storeDataToLocalStorage("enchird-userInfo", data);

        return true;
        //   dispatch(setStudent(data));
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          buttons: ["Ok"],
          duration: 3000,
        });
        return null;
      });
  };

  async function changePassword(passwords) {
    return await client
      .put("/api/change-password/", passwords, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        toast({
          message: "Password updated successfully!",
          color: "success",
          buttons: ["Ok"],
          duration: 5000,
        });
        return true;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          duration: 8000,
          color: "danger",
          buttons: ["Ok"],
        });
        return null;
      });
  }

  return {
    handleCreateStudent,
    handleLogin,
    changePassword,
  };
}
