import React from "react";
import NavBar from "../../components/NavBar/NavBar";
import Hero from "./components/Hero/Hero";
import Footer from "../../components/Footer/Footer";
import OurCoreValues from "./components/OurCoreValue/OurCoreValues";
import AdmissionProcess from "./components/AdmissionProcess/AdmissionProcess";
import Faqs from "./components/FAQs/Faqs";
import StaticLayout from "../../components/Layout/StaticLayout/StaticLayout";

export default function Admissions() {
  return (
    <StaticLayout>
      <Hero />
      <OurCoreValues />
      <AdmissionProcess />
      <Faqs />
    </StaticLayout>
  );
}
