import { useSelector } from "react-redux";
import client from "../../utils/api/axios";
import { useIonToast } from "@ionic/react";

export function usePayment() {
  /** State management */
  const token = useSelector((state) => state.user.token);
  const [toast] = useIonToast();

  async function stripePayment(paymentDetails) {
    return await client
      .post("/api/stripe-checkout/", paymentDetails, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          buttons: ["Ok"],
          duration: 2000,
        });
        return null;
      });
  }

  async function paypalPayment(paymentDetails) {
    return await client
      .post("/api/paypal-checkout/", paymentDetails, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        toast({
          message: `Error: ${error.response?.data?.error}`,
          color: "danger",
          buttons: ["Ok"],
          duration: 2000,
        });
        return null;
      });
  }

  return {
    stripePayment,
    paypalPayment,
  };
}
