import React, { useState } from "react";
import StudentLayout from "../components/Layout/StudentLayout";
import styles from "./coursesAndPrograms.module.css";
import Button from "../../../../components/Button/customButton/Button";
import Search from "../../../../components/Search/Search";
import TrashIcon from "../../../../assets/icons/trash.icon";
import RegisteredCourses from "./components/RegisteredCourses/RegisteredCourses";
import ManageRegisteredCourse from "./components/RegisteredCourses/ManageRegisteredCourse";
// import styles from "../../admin/components/generalStyles.module.css";

export default function CoursesAndPrograms() {
  /** State management */
  const [registerCourse, setRegisterCourse] = useState(false);

  return (
    <StudentLayout showProgram>
      <>
        {registerCourse ? (
          <RegisteredCourses setRegisterCourse={setRegisterCourse} />
        ) : (
          <ManageRegisteredCourse setRegisterCourse={setRegisterCourse} />
        )}
      </>
    </StudentLayout>
  );
}
