import React, { useRef } from "react";
import styles from "./textArea.module.css";

export default function TextArea({
  label,
  inputName,
  placeholder,
  value,
  setValue,
  required = true,
}) {
  const inputRef = useRef();

  function textAreaAdjust() {
    if (inputRef.current && inputRef.current.scrollHeight <= 500) {
      inputRef.current.style.height = inputRef.current.scrollHeight + "px";
    }
  }
  return (
    <div className={`${styles["input-container"]}`}>
      <label className={`${styles["input-label"]}`}>
        <span>{label}</span>
        {required && <span>*</span>}
      </label>
      <div
        className={`${styles["text-area-container"]}`}
        style={{ borderBlockEndWidth: 2, borderColor: "black" }}
      >
        <div>
          <p>
            Max: {value?.length > 0 ? `${value?.length}/1000` : "1000 words"}
          </p>
        </div>
        <textarea
          ref={inputRef}
          placeholder={placeholder}
          value={value.substring(0, 999)}
          onChange={(e) => {
            textAreaAdjust();
            setValue(e.target.value);
          }}
          maxLength={1000}
        ></textarea>
      </div>
    </div>
  );
}
