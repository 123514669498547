import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  facultyData: {},
  departmentData: {},
  courseData: {},
  tutorRecords: {},
  applicationRequests: {},
  studentData: {},
};

export const institutionSlice = createSlice({
  name: "institution",
  initialState,
  reducers: {
    setFaculty: (state, action) => {
      state.facultyData = action.payload;
    },
    setDepartment: (state, action) => {
      state.departmentData = action.payload;
    },
    setCourse: (state, action) => {
      state.courseData = action.payload;
    },
    setTutorsRecords: (state, action) => {
      state.tutorRecords = action.payload;
    },
    setApplicationRequests: (state, action) => {
      state.applicationRequests = action.payload;
    },
    setStudent: (state, action) => {
      state.studentData = action.payload;
    },
  },
});

export const {
  setFaculty,
  setCourse,
  setDepartment,
  setStudent,
  setTutorsRecords,
  setApplicationRequests,
} = institutionSlice.actions;
export default institutionSlice.reducer;
