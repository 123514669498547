import React from "react";
import academics_hero_image from "../../../../assets/images/academics_hero_image.png";
import Button from "../../../../components/Button/customButton/Button";
import styles from "./hero.module.css";
import { useNavigate } from "react-router-dom";

export default function Hero() {
  const navigate = useNavigate();
  return (
    <div className={`${styles.academics_hero_container}`}>
      <div className={`${styles.academics_sub_hero_container}`}>
        <h1>Unlock Your Academic Potential</h1>
        <p>
          Experience a world-class education that goes beyond the classroom. Our
          distinguished faculty, cutting-edge research opportunities, and
          immersive learning environment empower you to excel and make a
          meaningful impact in your field.
        </p>

        <Button
          onClick={() => navigate("/register")}
          text={"Apply Now"}
          backgroundColor={"#800080"}
          paddingBlock={10}
          paddingInline={18}
          width={"fit-content"}
        />
      </div>

      <div className={`${styles.academics_hero_sub_container_image}`}>
        <img src={academics_hero_image} alt="Academic hero image" />
      </div>
    </div>
  );
}
