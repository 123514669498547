import React from "react";
import StatisticsCard from "./components/StatisticsCard/StatisticsCard";
import styles from "./statisticsRow.module.css";
import SubscriptionIcon from "../../../../../assets/icons/subscription.icon";
import ContractIcon from "../../../../../assets/icons/contract.icon";
import GroupPeopleIcon from "../../../../../assets/icons/group-people.icon";
import { useSelector } from "react-redux";
import ChartIcon from "../../../../../assets/icons/chart.icon";

export default function StatisticsRow() {
  /** State management */
  const numOfCourses = useSelector((state) => state.institution.courseData);
  const numOfApplications = useSelector(
    (state) => state.institution.applicationRequests
  );
  const numOfStudents = useSelector((state) => state.institution.studentData);
  const numOfTutors = useSelector((state) => state.institution.tutorRecords);

  const ICONS = [
    {
      title: "Total Courses",
      color: "",
      data: numOfCourses?.count ?? 0,
      icon: "",
      link: "/admin/courses",
    },
    {
      title: "Tutors",
      icon: <GroupPeopleIcon />,
      color: "#BF39BF",
      data: numOfTutors?.count ?? 0,
      link: "/admin/tutors",
    },
    {
      title: "Applications",
      icon: <ContractIcon width={24} height={24} />,
      color: "#5248D5",
      data: numOfApplications?.count ?? 0,
      link: "/admin/application-requests",
    },
    {
      title: "Students",
      icon: <GroupPeopleIcon />,
      color: "#800080",
      data: numOfStudents.count ?? 0,
      link: "/admin/students",
    },
  ];

  return (
    <div className={`${styles["statistics-row-container"]}`}>
      {ICONS.map((item, index) => {
        return (
          <StatisticsCard
            key={index}
            title={item.title}
            color={item.color}
            icon={item.icon}
            data={item.data}
            link={item.link}
          />
        );
      })}

      <article className={`${styles["revenue-container"]}`}>
        <div className={`${styles["revenue-details"]}`}>
          <h5>Total Revenue</h5>
          <p>$0.00</p>
        </div>
        <ChartIcon />
      </article>
    </div>
  );
}
