export const COUNTRIES = [
  { id: "afghanistan", name: "Afghanistan" },
  { id: "albania", name: "Albania" },
  { id: "algeria", name: "Algeria" },
  { id: "andorra", name: "Andorra" },
  { id: "angola", name: "Angola" },
  { id: "antigua_and_barbuda", name: "Antigua and Barbuda" },
  { id: "argentina", name: "Argentina" },
  { id: "armenia", name: "Armenia" },
  { id: "australia", name: "Australia" },
  { id: "austria", name: "Austria" },
  { id: "azerbaijan", name: "Azerbaijan" },
  { id: "bahamas", name: "Bahamas" },
  { id: "bahrain", name: "Bahrain" },
  { id: "bangladesh", name: "Bangladesh" },
  { id: "barbados", name: "Barbados" },
  { id: "belarus", name: "Belarus" },
  { id: "belgium", name: "Belgium" },
  { id: "belize", name: "Belize" },
  { id: "benin", name: "Benin" },
  { id: "bhutan", name: "Bhutan" },
  { id: "bolivia", name: "Bolivia" },
  { id: "bosnia_and_herzegovina", name: "Bosnia and Herzegovina" },
  { id: "botswana", name: "Botswana" },
  { id: "brazil", name: "Brazil" },
  { id: "brunei", name: "Brunei" },
  { id: "bulgaria", name: "Bulgaria" },
  { id: "burkina_faso", name: "Burkina Faso" },
  { id: "burundi", name: "Burundi" },
  { id: "cabo_verde", name: "Cabo Verde" },
  { id: "cambodia", name: "Cambodia" },
  { id: "cameroon", name: "Cameroon" },
  { id: "canada", name: "Canada" },
  { id: "central_african_republic", name: "Central African Republic" },
  { id: "chad", name: "Chad" },
  { id: "chile", name: "Chile" },
  { id: "china", name: "China" },
  { id: "colombia", name: "Colombia" },
  { id: "comoros", name: "Comoros" },
  { id: "congo", name: "Congo" }, // This refers to Republic of the Congo
  { id: "costa_rica", name: "Costa Rica" },
  { id: "cote_divoire", name: "Côte d'Ivoire" },
  { id: "croatia", name: "Croatia" },
  { id: "cuba", name: "Cuba" },
  { id: "cyprus", name: "Cyprus" },
  { id: "czech_republic", name: "Czech Republic" },
  {
    id: "democratic_republic_of_ congo",
    name: "Democratic Republic of the Congo",
  },
  { id: "denmark", name: "Denmark" },
  { id: "djibouti", name: "Djibouti" },
  { id: "dominica", name: "Dominica" },
  { id: "dominican_republic", name: "Dominican Republic" },
  { id: "ecuador", name: "Ecuador" },
  { id: "egypt", name: "Egypt" },
  { id: "el_salvador", name: "El Salvador" },
  { id: "equatorial_guinea", name: "Equatorial Guinea" },
  { id: "eritrea", name: "Eritrea" },
  { id: "estonia", name: "Estonia" },
  { id: "ethiopia", name: "Ethiopia" },
  { id: "fiji", name: "Fiji" },
  { id: "finland", name: "Finland" },
  { id: "france", name: "France" },
  { id: "gabon", name: "Gabon" },
  { id: "gambia", name: "Gambia" },
  { id: "georgia", name: "Georgia" },
  { id: "germany", name: "Germany" },
  { id: "ghana", name: "Ghana" },
  { id: "greece", name: "Greece" },
  { id: "grenada", name: "Grenada" },
  { id: "guatemala", name: "Guatemala" },
  { id: "guinea", name: "Guinea" },
  { id: "guinea_bissau", name: "Guinea-Bissau" },
  { id: "guyana", name: "Guyana" },
  { id: "haiti", name: "Haiti" },
  { id: "honduras", name: "Honduras" },
  { id: "hungary", name: "Hungary" },
  { id: "holy_see", name: "Holy See (Vatican City)" },
  { id: "iceland", name: "Iceland" },
  { id: "india", name: "India" },
  { id: "indonesia", name: "Indonesia" },
  { id: "iran", name: "Iran" },
  { id: "iraq", name: "Iraq" },
  { id: "ireland", name: "Ireland" },
  { id: "israel", name: "Israel" },
  { id: "italy", name: "Italy" },
  { id: "jamaica", name: "Jamaica" },
  { id: "japan", name: "Japan" },
  { id: "jordan", name: "Jordan" },
  { id: "kazakhstan", name: "Kazakhstan" },
  { id: "kenya", name: "Kenya" },
  { id: "kiribati", name: "Kiribati" },
  { id: "kosovo", name: "Kosovo" }, // Partially recognized state
  { id: "kuwait", name: "Kuwait" },
  { id: "kyrgyzstan", name: "Kyrgyzstan" },
  { id: "laos", name: "Laos" },
  { id: "latvia", name: "Latvia" },
  { id: "lebanon", name: "Lebanon" },
  { id: "lesotho", name: "Lesotho" },
  { id: "liberia", name: "Liberia" },
  { id: "libya", name: "Libya" },
  { id: "liechtenstein", name: "Liechtenstein" },
  { id: "lithuania", name: "Lithuania" },
  { id: "luxembourg", name: "Luxembourg" },
  { id: "lesotho", name: "Lesotho" },
  { id: "liberia", name: "Liberia" },
  { id: "libya", name: "Libya" },
  { id: "liechtenstein", name: "Liechtenstein" },
  { id: "lithuania", name: "Lithuania" },
  { id: "luxembourg", name: "Luxembourg" },
  { id: "macedonia", name: "North Macedonia" }, // Formerly Macedonia
  { id: "madagascar", name: "Madagascar" },
  { id: "malawi", name: "Malawi" },
  { id: "malaysia", name: "Malaysia" },
  { id: "maldives", name: "Maldives" },
  { id: "mali", name: "Mali" },
  { id: " malta", name: "Malta" },
  { id: "marshall_islands", name: "Marshall Islands" },
  { id: "mauritania", name: "Mauritania" },
  { id: "mauritius", name: "Mauritius" },
  { id: "mexico", name: "Mexico" },
  { id: "micronesia", name: "Micronesia" },
  { id: "moldova", name: "Moldova" },
  { id: "monaco", name: "Monaco" },
  { id: "mongolia", name: "Mongolia" },
  { id: "montenegro", name: "Montenegro" },
  { id: "morocco", name: "Morocco" },
  { id: "mozambique", name: "Mozambique" },
  { id: "myanmar", name: "Myanmar" }, // Formerly Burma
  { id: "namibia", name: "Namibia" },
  { id: "nauru", name: "Nauru" },
  { id: "nepal", name: "Nepal" },
  { id: "netherlands", name: "Netherlands" },
  { id: "new_zealand", name: "New Zealand" },
  { id: "nicaragua", name: "Nicaragua" },
  { id: "niger", name: "Niger" },
  { id: "nigeria", name: "Nigeria" },
  { id: "niue", name: "Niue" }, // Associated state of New Zealand
  { id: "north_korea", name: "North Korea" },
  { id: "norway", name: "Norway" },
  { id: "oman", name: "Oman" },
  { id: "pakistan", name: "Pakistan" },
  { id: "palau", name: "Palau" },
  { id: "palestine", name: "State of Palestine" }, // Observer state at the UN
  { id: "panama", name: "Panama" },
  { id: "papua_new_ guinea", name: "Papua New Guinea" },
  { id: "paraguay", name: "Paraguay" },
  { id: "peru", name: "Peru" },
  { id: "philippines", name: "Philippines" },
  { id: "poland", name: "Poland" },
  { id: "portugal", name: "Portugal" },
  { id: "qatar", name: "Qatar" },
  { id: "romania", name: "Romania" },
  { id: "rwanda", name: "Rwanda" },
  { id: "saint_kitts_and_ Nevis", name: "Saint Kitts and Nevis" },
  { id: "saint_lucia", name: "Saint Lucia" },
  {
    id: "saint_vincent_and_ the Grenadines",
    name: "Saint Vincent and the Grenadines",
  },
  { id: "samoa", name: "Samoa" },
  { id: "san_marino", name: "San Marino" },
  { id: "sao_tome_and_ principe", name: "São Tomé and Príncipe" },
  { id: "saudi_arabia", name: "Saudi Arabia" },
  { id: "senegal", name: "Senegal" },
  { id: "serbia", name: "Serbia" },
  { id: "serbia", name: "Serbia" },
  { id: "seychelles", name: "Seychelles" },
  { id: "sierra_leone", name: "Sierra Leone" },
  { id: "singapore", name: "Singapore" },
  { id: "slovakia", name: "Slovakia" },
  { id: "slovenia", name: "Slovenia" },
  { id: "solomon_islands", name: "Solomon Islands" },
  { id: "somalia", name: "Somalia" },
  { id: "south_africa", name: "South Africa" },
  { id: "south_korea", name: "South Korea" },
  { id: "south_sudan", name: "South Sudan" },
  { id: "spain", name: "Spain" },
  { id: "sri_lanka", name: "Sri Lanka" },
  { id: "sudan", name: "Sudan" },
  { id: "suriname", name: "Suriname" },
  { id: "swaziland", name: "Eswatini" }, // Formerly Swaziland
  { id: "sweden", name: "Sweden" },
  { id: "switzerland", name: "Switzerland" },
  { id: "syria", name: "Syria" },
  { id: "tajikistan", name: "Tajikistan" },
  { id: "thailand", name: "Thailand" },
  { id: "the_former_yugoslav_republic_of_macedonia", name: "North Macedonia" }, // Alternative entry for North Macedonia
  { id: "timor_leste", name: "Timor-Leste" },
  { id: "togo", name: "Togo" },
  { id: "tonga", name: "Tonga" },
  { id: "trinidad_and_tobago", name: "Trinidad and Tobago" },
  { id: "tunisia", name: "Tunisia" },
  { id: "turkey", name: "Turkey" },
  { id: "turkmenistan", name: "Turkmenistan" },
  { id: "tuvalu", name: "Tuvalu" },
  { id: "uganda", name: "Uganda" },
  { id: "ukraine", name: "Ukraine" },
  { id: "united_arab_emirates", name: "United Arab Emirates" },
  { id: "united_kingdom", name: "United Kingdom" },
  { id: "united_republic_of_tanzania", name: "Tanzania" }, // United Republic of Tanzania
  { id: "united_states_of_america", name: "United States of America" },
  { id: "uruguay", name: "Uruguay" },
  { id: "uzbekistan", name: "Uzbekistan" },
  { id: "vanuatu", name: "Vanuatu" },
  { id: "venezuela", name: "Venezuela" },
  { id: "viet_nam", name: "Viet Nam" },
  { id: "wallis_and_futuna", name: "Wallis and Futuna" }, // French overseas collectivity
  { id: "western_sahara", name: "Western Sahara" }, // Partially recognized state
  { id: "yemen", name: "Yemen" },
  { id: "zambia", name: "Zambia" },
  { id: "zimbabwe", name: "Zimbabwe" },
];
