import React from "react";
import MainLogo from "../logo/MainLogo";
import styles from "./footer.module.css";

export default function Footer() {
  return (
    <footer className={`${styles.footer_main_container}`}>
      <div className={styles.container}>
        <div className={`${styles.footer_sub_container}`}>
          <div>
            <MainLogo />
            <div>
              <p>1234 Bonaberi, Douala</p>
              <p>admissions@xashytech.com</p>
              <p>+237655755855 / +237622331445</p>
            </div>
          </div>
          <div>
            <h5 className={`${styles.footer_heading}`}>Popular Links</h5>
            <ul className={`${styles.footer_list}`}>
              <li>Home</li>
              <li>About Us</li>
              <li>Admission</li>
              <li>Academics</li>
              <li>Partnership</li>
              <li>Activities</li>
              <li>Contact Us</li>
            </ul>
          </div>
          <div>
            <h5 className={`${styles.footer_heading}`}>Popular</h5>
            <ul className={`${styles.footer_list}`}>
              <li>Academic Policy</li>
              <li>Privacy Policy</li>
              <li>Financial Policy</li>
              <li>Code of Conduct</li>
              <li>Health and Safety Policy</li>
              <li>Sexual Misconduct Policy</li>
            </ul>
          </div>
          <div>
            <h5 className={`${styles.footer_heading}`}>Latest Stories</h5>
            <ul className={`${styles.footer_list}`}>
              <li>Admissions are now ngoing for this academic year</li>
              <li>2024 Matriculation Ceremony for 2023’2024</li>
              <li>Visit of the Vice Chancellor to Xashy Tech University</li>
              <li>HND Result Performance,, how did Xashy students perform?</li>
            </ul>
          </div>
          <div>
            <h5 className={`${styles.footer_heading}`}>Follow Us</h5>
          </div>
        </div>
        <div className={`${styles.footer_reserved_right}`}>
          <p>
            &copy; 2024 Xashy Tech University. All Rights Reserved | Powered by
            Zotech LTD
          </p>
        </div>
      </div>
    </footer>
  );
}
