import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../features/userSlice";
import institutionReducer from "../features/institutionSlice";
import studentReducer from "../features/studentSlice";
import assessmentReducer from "../features/assessmentSlice";
import opportunityReducer from "../features/opportunitySlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    institution: institutionReducer,
    student: studentReducer,
    assessment: assessmentReducer,
    opportunity: opportunityReducer,
  },
});
