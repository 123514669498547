import React, { useState } from "react";
import styles from "./multiSelectDropdown.module.css";
import ChevronIcon from "../../../assets/icons/chevron.icon";

export default function MultiSelectDropdown({
  label,
  required,
  data,
  value,
  secondary,
  keyItem,
  placeholder,
  setValue,
  disabled,
}) {
  /** State management */
  const [option, setOption] = useState([]);
  const [viewDropDown, setViewDropDown] = useState(false);

  const OPTIONDATA = [
    "Faculty of Engineering",
    "Faculty of Science",
    "Faculty of Laws",
    "Faculty of Health Science",
    "Faculty of Art",
    "Faculty of Social and Management",
    "College of Technology",
    "Advanced School of Translation",
    "Faculy of Journalism and Mass Communication",
    "Faculty of Political Sciences",
  ];

  /** Handle Select */
  function handleSelect(item) {
    if (option.includes(item)) {
      setOption((prevArr) => prevArr.filter((opt) => opt !== item));
      setValue((prevArr) => prevArr.filter((opt) => opt !== item));
    } else {
      setOption((prevArr) => [...prevArr, item]);
      setValue((prevArr) => [...prevArr, item]);
    }
  }

  /** Remove selected element */
  function handleRemoveElemet(elem) {
    setOption((prevArr) => prevArr.filter((opt) => opt !== elem));
    setValue((prevArr) => prevArr.filter((opt) => opt !== elem));
  }

  return (
    <div>
      <h5 className={`${styles["dropdown-label"]}`}>
        {label} {required && <span>*</span>}
      </h5>
      <div className={`${styles["main-container"]}`}>
        <div
          className={`${styles["filter-container"]} ${
            secondary && styles["secondary-color"]
          }}`}
          onClick={() => setViewDropDown((prevState) => !prevState)}
        >
          <p style={secondary && { color: "#fff" }}>
            {value.slice(0, 5).map((opt) => {
              return (
                <span>
                  {
                    data.filter((item) => item.id === opt)[0][
                      keyItem === "name"
                        ? "name"
                        : keyItem === "title"
                        ? "course_title"
                        : "name"
                    ]
                  }
                  ,{" "}
                </span>
              );
            })}{" "}
            {value.length > 5 && "..."}
          </p>
          <div
            className={`${
              viewDropDown ? styles.active : styles["dropdown-icon"]
            }`}
          >
            <ChevronIcon />
          </div>
        </div>

        <div className={`${styles["option-list"]}`}>
          {value.map((opt) => {
            return (
              <div className={`${styles["option-list__item__container"]}`}>
                <p>
                  {
                    data.filter((item) => item.id === opt)[0][
                      keyItem === "name"
                        ? "name"
                        : keyItem === "title"
                        ? "course_title"
                        : "name"
                    ]
                  }
                </p>

                <div
                  className={`${styles["close-icon"]}`}
                  onClick={() => handleRemoveElemet(opt)}
                >
                  <p>&times;</p>
                </div>
              </div>
            );
          })}{" "}
        </div>
        {viewDropDown && !disabled && (
          <ul className={`${styles["dropdown-list"]}`}>
            {data?.map((opt, index) => {
              return (
                <li
                  key={index}
                  onClick={() => {
                    handleSelect(
                      keyItem === "name"
                        ? opt.name
                        : keyItem === "id"
                        ? opt.id
                        : keyItem === "title"
                        ? opt.id
                        : opt
                    );
                  }}
                  className={`${
                    value.includes(
                      keyItem === "name"
                        ? opt.name
                        : keyItem === "id"
                        ? opt.id
                        : keyItem === "title"
                        ? opt.id
                        : opt
                    ) && styles["active-option"]
                  }`}
                >
                  {keyItem === "name"
                    ? opt.name
                    : keyItem === "id"
                    ? opt.name
                    : keyItem === "title"
                    ? opt.course_title
                    : opt}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
}
