import React, { useEffect, useState } from "react";
import TextArea from "../../../../../components/Inputs/TextArea/TextArea";
import styles from "./facultyAndProgram.module.css";
import TextInput from "../../../../../components/Inputs/TextInput/TextInput";
import Dropdown from "../../../../../components/Inputs/Dropdown/Dropdown";

import { useFaculty } from "../../../../../query/admin/useFaculty";
import { useIonToast } from "@ionic/react";
import { useDepartment } from "../../../../../query/admin/useDepartment";

export default function FacultyAndProgram({
  faculty,
  setFaculty,
  program,
  setProgram,
}) {
  /** State management */
  const [facultyData, setFacultyData] = useState([]);
  const [programData, setProgramData] = useState([]);
  const [toast] = useIonToast();
  const { getFaculty } = useFaculty();
  const { getFilterDepartments } = useDepartment();

  useEffect(() => {
    getFaculty()
      .then((result) => {
        setFacultyData(result.results);
      })
      .catch((err) => {
        toast({
          message: `Error fetching Faculty ${err.message}`,
          color: "danger",
          buttons: ["Ok"],
        });
      });
  }, []);

  useEffect(() => {
    if (faculty !== "") {
      getDetailFromFaculty();
    } else {
      setProgram("");
      setProgramData([]);
    }
  }, [faculty]);

  async function getDetailFromFaculty() {
    const facultyName = facultyData.filter((item) => {
      return item.id === faculty;
    })[0]?.name;
    console.log(facultyName, faculty);
    await getFilterDepartments(facultyName).then((response) => {
      if (response) {
        console.log("Programs: ", response);
        setProgramData(response.results);
      }
    });
  }
  return (
    <div className={`${styles["main-container"]}`}>
      <h1>Select Desired Faculty and Program</h1>

      <div className={`${styles.bubble}`}>
        <div className={`${styles["sub-container"]}`}>
          <h2>A. Select Faculty</h2>
          <Dropdown
            label={"Faculty"}
            value={faculty}
            setValue={setFaculty}
            placeholder={"Faculty of Engineering and Technology"}
            data={facultyData}
            keyItem={"id"}
          />
        </div>

        <div className={`${styles["sub-container"]}`}>
          <h2>B. Select Program</h2>
          <Dropdown
            label={"Program"}
            value={program}
            setValue={setProgram}
            placeholder={"Computer Engineering"}
            data={programData}
            keyItem={"id"}
          />
        </div>
      </div>
    </div>
  );
}
