import React from "react";
import styles from "./hero.module.css";

import admin_img1 from "../../../../assets/images/admission_image1.png";
import admin_img2 from "../../../../assets/images/admission_image2.png";
import admin_img3 from "../../../../assets/images/admission_image3.png";
import admin_img4 from "../../../../assets/images/admission_image4.png";

export default function Hero() {
  /** State management */
  const admission_images = [admin_img1, admin_img2, admin_img3, admin_img4];
  return (
    <div className={`${styles.admission_hero_container}`}>
      <div className={styles.container}>
        <ul className={`${styles.hero_images}`}>
          {admission_images.map((image, index) => {
            return (
              <li key={index} className={`${styles.hero_img}`}>
                <img src={image} alt="admission image" />
              </li>
            );
          })}
        </ul>
      </div>

      <div>
        <div className={`${styles.hero_desc_section}`}>
          <h4 className={`${styles.hero_header}`}>ADMISSIONS</h4>
          <p className={`${styles.hero_desc}`}>
            Explore the Possibilities of a Xashy Tech Education
          </p>
          <p className={`${styles.hero_desc_full}`}>
            Welcome to Xashy Tech, where possibilities become realities. Our
            transformative education prepares you for success in a rapidly
            evolving world. Join our vibrant community and discover endless
            possibilities. Apply now to embark on your journey at Xashy Tech.
          </p>
        </div>
      </div>
    </div>
  );
}
