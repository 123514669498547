import React, { useEffect, useState } from "react";
import Button from "../../../../../../components/Button/customButton/Button";
// import GroupCard from "../GroupCard/GroupCard";
import styles from "./groupDetails.module.css";
import ChatComponent from "./components/chatComponent/ChatComponent";
import AttachmentIcon from "../../../../../../assets/icons/attachment.icon";
import SendIcon from "../../../../../../assets/icons/send.icon";
import { FaPlus } from "react-icons/fa";
import { useCourses } from "../../../../../../query/tutor/useCourses";
import { useParams } from "react-router-dom";
import { useIonToast } from "@ionic/react";
import { useSelector } from "react-redux";
import Loader from "../../../../../../components/Loader/Loader";
import CreateMeetingModal from "../../../../tutor/components/Zoom/Create/CreateModal";
import { useTutorAuth } from "../../../../../../query/tutor/auth";

export default function GroupDetails() {
  const userInfo = useSelector((state) => state.user)
  const [toast] = useIonToast();
  const { handleGetGroupMessages,
    handleCreateClassGroupMessage, handleGetGroupDetails } = useCourses()
  const { getTutorInfo } = useTutorAuth();
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setmessages] = useState([])
  const [newMessage, setNewMessage] = useState('')
  const [groupDetails, setGroupDetails] = useState('')
  // 
  const [openModal, setOpenModal] = useState(false);
  const [coursesData, setCoursesData] = useState([])

  console.log(groupDetails)

  async function handleCreateGroupMessage(e) {
    e.preventDefault()
    try {
      setIsLoading(true);
      const data = { id, content: newMessage }
      await handleCreateClassGroupMessage(data).then(() => {
        setIsLoading(false);
        toast({
          message: `Success`,
          color: "success",
          buttons: ["Ok"],
        });
        setNewMessage("")
        getMessages()
      })
    } catch (error) {
      console.error(error)
      setIsLoading(false)
      toast({
        message: `Error sending message.`,
        color: "danger",
        buttons: ["Ok"],
      });
    }


  }

  async function handleGetDetails() {
    try {
      setIsLoading(true);
      await handleGetGroupDetails(id).then((res) => {
        setGroupDetails(res)
        setIsLoading(false);
      })
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }


  }

  const getMessages = async () => {
    setIsLoading(true);
    await handleGetGroupMessages(id).then((res) => {
      setmessages(res.results)
      setIsLoading(false);
    }).catch((err) => {
      console.error(err)
      setIsLoading(false);
    })
  };

  const getTutorData = async () => {
    if (userInfo.user.id !== undefined) {
      setIsLoading(true);
      const id = userInfo.user.id;
      getTutorInfo(id);
      setIsLoading(false);
      return;
    }
  };

  useEffect(() => {
    getTutorData()
  }, [userInfo.user.id])

  useEffect(() => {
    handleGetDetails()
    getMessages()
  }, [id])

  useEffect(() => {
    const courses = userInfo.user?.courses_detail
    const orderedCourses =
      courses?.length > 0
        ? courses.map((item) => {
          return (
            { id: item.id, name: item.course_title }
          )
        })
        : [];
    setCoursesData(orderedCourses);
  }, [userInfo.user]);


  return (
    <div className={`${styles["group-details-main-container"]}`}>
      {isLoading && <Loader />}
      {openModal && <CreateMeetingModal setViewModal={setOpenModal} courses={coursesData} courseId={groupDetails.course} />}
      <h2 className={`${styles["heading"]}`}>Group Chat</h2>

      <div className={`${styles["sub-container"]}`} style={{ marginTop: 30 }}>
        <div>
          <h2>{groupDetails?.name}</h2>
          <h3>Code: {groupDetails?.code}</h3>
        </div>
        <div>
          <Button text="Schedule a Class" rightIcon={<FaPlus />} onClick={() => { setOpenModal(true); }} />
        </div>
        {/* {user.role === "teacher" && (
          <div className={`${styles["btn-container"]}`}>
            <Button text={"Schedule a Class"} />
            <Button text={"New Class Asignment"} />
          </div>
        )} */}
      </div>

      {/* <GroupCard /> */}

      <div className={`${styles["chat-upcoming-container"]}`}>
        <div className={`${styles["upcoming-work"]}`}>
          <div>
            <h5 className={`${styles["upcoming-work-heading"]}`}>
              Upcoming work
            </h5>

            <p className={`${styles["upcoming-work-desc"]}`}>
              Woohoo, no work due soon!
            </p>
          </div>
          <div className={`${styles["upcoming-work-view-more"]}`}>
            <p>View All...</p>
          </div>
        </div>

        <div>
          <div className={`${styles["chat-container"]}`}>
            <ul>
              {messages?.map((item, index) => {
                return <ChatComponent key={index} notFirst={index !== messages?.length - 1} item={item} />;
              }).reverse()}
            </ul>

            {/* <div className={`${styles["comment-chat-box"]}`}>
              <div className={`${styles["comment-chat-sub-container"]}`}>
                <input placeholder="Leave a comment..." />
              </div>
              <div className={`${styles["send-icon-container"]}`}>
                <SendIcon />
              </div>
            </div> */}
          </div>

          <div className={`${styles["conversation-chat-box"]}`}>
            <div className={`${styles["conversation-chat-sub-container"]}`}>
              <AttachmentIcon />
              <input placeholder="New Message..." value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)} />
            </div>
            <div className={`${styles["send-icon-container"]}`}
              onClick={handleCreateGroupMessage}>
              <SendIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
