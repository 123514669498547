import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import styles from "./navbar.module.css";
import DashboardIcon from "../../../../../assets/icons/dashboard.icon";
import GroupPeopleIcon from "../../../../../assets/icons/group-people.icon";
import PaperIcon from "../../../../../assets/icons/paper.icon";
import ContractIcon from "../../../../../assets/icons/contract.icon";
import WalletIcon from "../../../../../assets/icons/wallet.icon";
import SettingsIcon from "../../../../../assets/icons/settings.icon";
import LogoWhiteIcon from "../../../../../assets/icons/logo-white.icon";
import ConversationIcon from "../../../../../assets/icons/conversation.icon";
import MenuIcon from "../../../../../assets/icons/menu.icon";
import CloseIcon from "../../../../../assets/icons/close.icon";
import WalletCreditIcon from "../../../../../assets/icons/wallet-credit.icon";
import PersonIcon from "../../../../../assets/icons/person.icon";
import ZoomIcon from "../../../../../assets/icons/zoom.icon";
import UserIcon from "../../../../../assets/icons/user.icon";
import BookIcon from "../../../../../assets/icons/book.icon";
import LogoutIcon from "../../../../../assets/icons/logout.icon";
import ShieldIcon from "../../../../../assets/icons/shield.icon";
import logo_image from "../../../../../assets/images/xashy_logo1.png";
import { userSlice } from "../../../../../redux/features/userSlice";
import { removDataFromLocalStorage } from "../../../../../utils/functions/handleLocalStorage";

export default function Navbar() {
  const userInfo = useSelector((state) => state.user.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [viewMobileMenu, setViewMobileMenu] = useState(false);

  function handleLogout() {
    removDataFromLocalStorage("enchird-token");
    removDataFromLocalStorage("enchird-userInfo");
    dispatch(userSlice.actions.setUserToken({}));
    dispatch(userSlice.actions.setUserToken(""));
    dispatch(userSlice.actions.setIsAuth(false));
    navigate("/");
  }

  useEffect(() => {
    if (userInfo?.role === undefined) {
      navigate("/login");
    }
  }, [userInfo]);

  const routes = {
    superadmin: [
      { to: "/admin/dashboard", icon: <DashboardIcon width={24} height={24} />, label: "Dashboard" },
      { to: "/admin/students", icon: <GroupPeopleIcon />, label: "Students" },
      { to: "/admin/courses", icon: <PaperIcon width={24} height={24} />, label: "Courses" },
      { to: "/admin/application-requests", icon: <ContractIcon width={24} height={24} />, label: "Application Requests" },
      { to: "/admin/tutors", icon: <GroupPeopleIcon />, label: "Tutors" },
      { to: "/admin/manage-faculties", icon: <WalletIcon />, label: "Manage Faculties" },
      { to: "/admin/manage-departments", icon: <WalletIcon />, label: "Manage Departments" },
      { to: "/chats", icon: <ConversationIcon />, label: "Chats" },
      { to: "/library", icon: <BookIcon />, label: "Library" },
      { to: "/admin/opportunities", icon: <ShieldIcon />, label: "Opportunity Desk" },
      { to: "/settings", icon: <SettingsIcon />, label: "Settings" },
    ],
    teacher: [
      { to: "/tutor/dashboard", icon: <DashboardIcon width={24} height={24} />, label: "Dashboard" },
      { to: "/tutor/manage-groups", icon: <GroupPeopleIcon width={24} height={24} />, label: "Manage Groups" },
      { to: "/tutor/courses", icon: <PaperIcon width={24} height={24} />, label: "Courses / Programs" },
      { to: "/tutor/ca-results", icon: <ContractIcon width={24} height={24} />, label: "CA Results" },
      { to: "/tutor/assessments", icon: <ContractIcon width={24} height={24} />, label: "Assessments" },
      { to: "/tutor/assessment-submissions", icon: <ContractIcon width={24} height={24} />, label: "Submissions" },
      { to: "/tutor/manage-profile", icon: <UserIcon width={24} height={24} />, label: "Manage Profile" },
      { to: "/tutor/zoom", icon: <ZoomIcon width={24} height={24} />, label: "Zoom Meetings" },
      { to: "/chats", icon: <ConversationIcon />, label: "Chats" },
      { to: "/library", icon: <BookIcon />, label: "Library" },
      { to: "/settings", icon: <SettingsIcon />, label: "Settings" },
    ],
    student: [
      { to: "/dashboard/main", icon: <DashboardIcon width={24} height={24} />, label: "Dashboard" },
      { to: "/dashboard/my-groups", icon: <GroupPeopleIcon width={24} height={24} />, label: "My Groups" },
      { to: "/dashboard/courses-and-programs", icon: <PaperIcon width={24} height={24} />, label: "Courses / Programs" },
      { to: "/dashboard/ca-results", icon: <ContractIcon width={24} height={24} />, label: "CA Results" },
      { to: "/dashboard/assessments", icon: <ContractIcon width={24} height={24} />, label: "Assessments" },
      { to: "/dashboard/exam", icon: <PaperIcon width={24} height={24} />, label: "Exam" },
      { to: "/dashboard/tuition-fees", icon: <WalletCreditIcon width={24} height={24} />, label: "Tuition Fees" },
      { to: "/library", icon: <BookIcon />, label: "Library" },
      { to: "/dashboard/jobs", icon: <ShieldIcon />, label: "Job Portal" },
      { to: "/settings", icon: <SettingsIcon />, label: "Settings" },
    ],
  };

  const renderRoutes = (routesArray) => {
    return routesArray.map((route, index) => (
      <li key={index}>
        <NavLink
          to={route.to}
          className={({ isActive }) =>
            !isActive ? `${styles["nav-list__item"]}` : `${styles["nav-list__item"]} ${styles.active}`
          }
        >
          {route.icon}
          <span>{route.label}</span>
        </NavLink>
      </li>
    ));
  };

  return (
    <nav>
      <div
        className={styles["nav-menu-icon"]}
        onClick={() => setViewMobileMenu((prevState) => !prevState)}
      >
        {viewMobileMenu ? <CloseIcon width={35} height={35} /> : <MenuIcon width={35} height={35} />}
      </div>
      <div className={styles["nav-larger-container"]}>
        <div className={styles["nav-container"]}>
          <div className={styles["nav-logo"]} onClick={() => navigate("/")}>
            <img src={logo_image} width={50} height={50} alt="Logo" />
            <p>Xashy Tech</p>
          </div>

          <div className={styles["profile-container"]}>
            <div className={styles["profile-picture"]}>
              <img src="" alt="Profile" />
            </div>
            <div className={styles["profile-details"]}>
              <h3>{userInfo.role === "teacher" ? "Tutor" : userInfo.role === "superadmin" ? "Admin" : "Student"}</h3>
              <p>DASHBOARD</p>
            </div>
          </div>

          <ul className={styles["nav-list"]}>
            {userInfo.role && renderRoutes(routes[userInfo.role])}
          </ul>

          <div className={styles["logout-btn-container"]}>
            <p
              onClick={() => handleLogout()}
              className={`${styles["nav-list__item"]} ${styles["logout-btn-sub-container"]}`}
            >
              <LogoutIcon className={styles["nav-icon"]} />
              <span>Logout</span>
            </p>
          </div>
        </div>
      </div>

      {viewMobileMenu && (
        <div className={styles["nav-mobile-container"]}>
          <div className={styles["nav-mobile-container__inner"]}>
            <div
              className={styles["nav-menu-icon"]}
              onClick={() => setViewMobileMenu((prevState) => !prevState)}
            >
              <CloseIcon width={35} height={35} />
            </div>

            <div className={styles["nav-container"]}>
              <div className={styles["nav-logo"]} onClick={() => navigate("/")}>
                <LogoWhiteIcon width={30} height={30} />
                <p>Enchird</p>
              </div>

              <div className={styles["profile-container"]}>
                <div className={styles["profile-picture"]}>
                  <img src="" alt="Profile" />
                </div>
                <div className={styles["profile-details"]}>
                  <h3>{userInfo.role === "teacher" ? "Tutor" : userInfo.role === "superadmin" ? "Admin" : "Student"}</h3>
                  <p>DASHBOARD</p>
                </div>
              </div>

              <ul className={styles["nav-list"]}>
                {userInfo.role && renderRoutes(routes[userInfo.role])}
              </ul>

              <div className={styles["logout-btn-container"]}>
                <p
                  onClick={() => handleLogout()}
                  className={`${styles["nav-list__item"]} ${styles["logout-btn-sub-container"]}`}
                >
                  <LogoutIcon className={styles["nav-icon"]} />
                  <span>Logout</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
}
