import React, { useState } from "react";
import StudentLayout from "../components/Layout/StudentLayout";
import DropDownS from "../../../../components/Inputs/DropdownS/DropDownS";
import { IonSpinner } from "@ionic/react";
import styles from "../../admin/components/generalStyles.module.css";
import Search from "../../../../components/Search/Search";

export default function ExamResults() {
  /** state management */
  const [searchValue, setSearchValue] = useState("");
  const [academicYear, setAcademicYear] = useState("");
  const [semester, setSemester] = useState("");

  /** Loading state management */
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  return (
    <StudentLayout showProgram={true}>
      <div>
        <div
          className={`${styles["header-container"]}`}
          style={{ flexDirection: "column", alignItems: "flex-start" }}
        >
          <h2>CA Results</h2>

          <div
            className={`${styles["action-container"]}`}
            style={{ justifyContent: "space-between" }}
          >
            <DropDownS
              value={academicYear}
              setValue={setAcademicYear}
              placeholder={"Filter By Academic Year"}
              data={[]}
              keyItem={"name"}
            />
            <DropDownS
              value={semester}
              setValue={setSemester}
              placeholder={"Filter By Semester"}
              data={[]}
              keyItem={"name"}
            />

            <div style={{ maxWidth: 300 }}>
              <Search
                placeholder={"Find Course"}
                value={searchValue}
                setValue={setSearchValue}
              />
            </div>
          </div>
        </div>

        {/* Course Table */}
        <div className={`${styles["table-container"]}`}>
          <table style={{ width: "100%" }}>
            <thead>
              <tr className={`${styles["table-row"]}`}>
                <th style={{ textAlign: "left", backgroundColor: "#F1F1F1" }}>
                  Course Name
                </th>
                <th style={{ textAlign: "left", backgroundColor: "#F1F1F1" }}>
                  Course Code
                </th>
                <th style={{ textAlign: "left", backgroundColor: "#F1F1F1" }}>
                  Exam Score
                </th>
                <th style={{ textAlign: "left", backgroundColor: "#F1F1F1" }}>
                  Overall Score
                </th>
                <th style={{ textAlign: "left", backgroundColor: "#F1F1F1" }}>
                  Grade
                </th>

                <th style={{ textAlign: "left", backgroundColor: "#F1F1F1" }}>
                  Credit Value
                </th>
              </tr>
            </thead>

            {isSearching ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "70%",
                  height: "20vh",
                }}
              >
                <IonSpinner
                  name="lines"
                  color={"dark"}
                  style={{ width: 50, height: 50 }}
                />
              </div>
            ) : (
              <tbody>
                {Array(10)
                  .fill(0)
                  .map((tutorData, index) => {
                    return (
                      <tr
                        key={index}
                        className={`${styles["table-row-container"]}`}
                      >
                        <td
                          className={`${styles["table-row"]}`}
                          style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ whiteSpace: "nowrap" }}>
                            {"Linear Algebra"}
                          </p>
                        </td>
                        <td className={` ${styles["table-row"]}`}>
                          <p>{"CEF201"}</p>
                        </td>
                        <td className={` ${styles["table-row"]}`}>
                          <p>{30}</p>
                        </td>
                        <td>{25}</td>
                        <td>{"A"}</td>
                        <td>{5}</td>
                      </tr>
                    );
                  })}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </StudentLayout>
  );
}
