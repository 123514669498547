import React, { useEffect, useState } from "react";
import StatisticsCard from "../../../../../components/Layout/dashboard/components/StatisticsRow/components/StatisticsCard/StatisticsCard";
import Search from "../../../../../components/Search/Search";
import ChevronIcon from "../../../../../assets/icons/chevron.icon";
import globalStyles from "../../../admin/components/generalStyles.module.css";
import styles from "./students.module.css";
// import { useSelector } from "react-redux";
// import DropDownS from "../../../../../components/Inputs/DropdownS/DropDownS";
import { useStudent } from "../../../../../query/tutor/useStudents";
import Loader from "../../../../../components/Loader/Loader";
import { useParams } from "react-router-dom";
// import SubscriptionIcon from "../../../../../assets/icons/subscription.icon";
import GroupPeopleIcon from "../../../../../assets/icons/group-people.icon";
import { useCAResults } from "../../../../../query/tutor/useCAResults";
import { useNavigate } from "react-router-dom";

export default function Students() {
  const { course, id } = useParams();
  const navigate = useNavigate();
  const { handleGetCAResults } = useCAResults()

  /** state management */
  const [studentsData, setStudentsData] = useState([]);

  // Filter variables
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  /** Query functions */
  const { getCourseStudents, getFilteredStudents } = useStudent();

  const searchStudents = async () => {
    await getFilteredStudents(id, searchValue);
  };

  const getAssessments = async () => {
    await handleGetCAResults(id).then((res) => {
      setStudentsData(res.students)
    }).catch((err) => {
      console.error(err)
    })
  };

  useEffect(() => {
    getAssessments()
  }, [id]);

  useEffect(() => {
    getCourseStudents(id);
  }, [id]);

  useEffect(() => {
    setIsLoading(true);
    searchStudents();
    setIsLoading(false);
    return;
  }, [searchValue]);

  const CardOptions = [
    // {
    //   title: "Video Lessons",
    //   icon: <SubscriptionIcon />,
    //   color: "#BF39BF",
    //   data: 0,
    // },

    {
      title: "Students",
      icon: <GroupPeopleIcon color="white" />,
      color: "#BF39BF",
      data: studentsData?.length,
    },
  ];

  return (
    <div>
      {isLoading && <Loader />}
      <div className={`${globalStyles["header-container"]}`}>
        <h2>{course} / Students Results</h2>

        <div className={`${globalStyles["action-container"]}`}>
          <div style={{ maxWidth: 300 }}>
            <Search
              value={searchValue}
              setValue={setSearchValue}
              placeholder={"Find Student Record"}
            />
          </div>
        </div>
      </div>

      <div className={styles.statistics_row_container}>
        {/* {CardOptions.map((item, index) => {
          return (
            <StatisticsCard
              key={index}
              title={item.title}
              color={item.color}
              icon={item.icon}
              data={item.data}
            />
          );
        })} */}
        {CardOptions.map((item, index) => {
          return (
            <div className={styles.card} key={index}>
              <div className={index === 0 ? styles.videoicon : styles.groupicon}>
                {item.icon}
              </div>
              <div className={styles.details}>
                <h3>{item.title}</h3>
                <p>{item.data}</p>
              </div>
            </div>
          );
        })}
      </div>

      {/* Faculty Table */}
      <div className={`${globalStyles["table-container"]}`}>
        <table>
          <thead>
            <tr className={`${globalStyles["table-row"]}`}>
              <th className={`${globalStyles["table-header"]}`}>
                <div className={`${globalStyles["first-square"]}`}>
                  {/* <div className={`${globalStyles["check-box"]}`}></div> */}
                  <p>Registration Number</p>
                </div>
              </th>
              <th className={`${globalStyles["table-header"]}`}>Full Name</th>
              <th className={`${globalStyles["table-header"]}`}>Average Score</th>
              <th className={`${globalStyles["table-header"]}`}>Grade</th>
            </tr>
          </thead>

          <tbody>
            {studentsData.length > 0 ? (
              studentsData?.map((student, index) => {
                return (
                  <tr
                    key={index}
                    className={`${globalStyles["table-row-container"]}`}
                    onClick={() => {
                      navigate(
                        `/tutor/ca-results/:${course}/${student.student_name}/${JSON.stringify(student)}`
                      )
                    }}
                  >
                    <td
                      className={`${globalStyles["table-row"]}`}
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      {/* <div
                        className={`${globalStyles["check-box"]}`}
                        style={{ marginInlineEnd: 16 }}
                      ></div> */}
                      <p style={{ whiteSpace: "nowrap" }}>
                        {student.student_reference}
                      </p>
                    </td>
                    <td className={` ${globalStyles["table-row"]}`}>
                      <p>{student.student_name}</p>
                    </td>
                    <td className={` ${globalStyles["table-row"]}`}>
                      <p>{student.average_score}</p>
                    </td>
                    <td>{student.grade}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={8}>No Students Found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className={`${globalStyles["pagination-container"]}`}>
        <p>
          Page <span style={{ fontWeight: "bold" }}>1/1</span> Total:{" "}
          <span style={{ fontWeight: "bold" }}>
            {studentsData?.length ?? 0} Students
          </span>
        </p>
        <div className={`${globalStyles["btn-container"]}`}>
          <div className={`${globalStyles.icons} ${globalStyles["left-icon"]}`}>
            <ChevronIcon />
          </div>
          <div
            className={`${globalStyles.icons} ${globalStyles["right-icon"]}`}
          >
            <ChevronIcon />
          </div>
        </div>
      </div>
    </div>
  );
}
